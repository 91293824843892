import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 100); // Delay ensures that the browser restores scroll before forcing reset
        };

        window.addEventListener('popstate', handleScroll);
        
        // Run scrollTo immediately on route change (for forward navigation)
        handleScroll();

        return () => {
            window.removeEventListener('popstate', handleScroll);
        };
    }, [location]);

    return <>{children}</>;
};

export default ScrollToTop;

import {
  CLEAR_ERROR,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_SEND_OTP_MOBILE_SUCCESS,
  USER_SEND_OTP_MOBILE_REQUEST,
  USER_SEND_OTP_MOBILE_FAIL,
  USER_VERITY_OTP_REQUEST,
  USER_VERITY_OTP_SUCCESS,
  USER_VERITY_OTP_FAIL,
  ADD_USER_DETAILS_REQUEST,
  ADD_USER_DETAILS_SUCCESS,
  ADD_USER_DETAILS_FAIL,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
} from "../../constant/userConstant/userConstant";

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: true,
  error: null,
};

export const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    // Loading states
    case USER_SEND_OTP_MOBILE_REQUEST:
    case USER_VERITY_OTP_REQUEST:
    case ADD_USER_DETAILS_REQUEST:
    case LOAD_USER_REQUEST:
    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    // Success states
    case USER_SEND_OTP_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        users: action.payload,
      };

    case USER_VERITY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        verifyOTP: action.payload,
      };

    case ADD_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        addUserDetails: action.payload,
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    // Logout success
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
      };

    // Failures
    case USER_SEND_OTP_MOBILE_FAIL:
    case USER_VERITY_OTP_FAIL:
    case ADD_USER_DETAILS_FAIL:
    case UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case USER_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Clear error
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// get all user
export const getAllUsersReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUsers: action.payload,
      };

    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { createSpaces } from "../../../../redux/actions/propertyActions/propertySpaceAction";
import { toast } from "react-toastify";

const ProeprtySpace = () => {
  const [spaces, setSpaces] = useState("");
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.propertySpace);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: spaces,
    };

    dispatch(createSpaces(data));
  };

  useEffect(() => {
    if (success) {
      toast.success("property spaces created successfull");
    }
  }, [dispatch, success]);

  return (
    <div className="flex pt-20">
      <SideMenu />
      <div className="w-full">
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">
            Create Property spaces
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="property"
              >
                Property space Name
              </label>
              <input
                type="text"
                id="spaces"
                value={spaces}
                onChange={(e) => setSpaces(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter property space name"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              create property space
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProeprtySpace;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPackageBySlug } from "../../redux/actions/PackagesAction/PackagesAction";
import { useNavigate, useParams } from "react-router-dom";
// import Slider from "react-slick";
import { MdOutlineShare } from "react-icons/md";
import { RWebShare } from "react-web-share";
import { FaArrowLeftLong } from "react-icons/fa6";
import CollapseItem from "../property/collapse";
// import { FaArrowLeft } from "react-icons/fa";
// import MapView from "../property/MapView";
import { PiMapPin } from "react-icons/pi";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md"; // Material Design Icons
import * as BsIcons from "react-icons/bs"; // Bootstrap Icons
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdWatchLater } from "react-icons/md";
import { BiSolidPrinter } from "react-icons/bi";
import "./PackageDetails.css";
import PackageEnquirySmallScreen from "./PackageEnquirySmallScreen";
import { Carousel } from "flowbite-react";
import PackageEnquiryForm from "./PackageEnquiryForm";
import { PiNotepad } from "react-icons/pi";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import Slider from "react-slick";
import { IoIosHeartEmpty } from "react-icons/io";

const PackagesDetails = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  //   const [isFixed, setIsFixed] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [enquiry, setEnquiry] = useState(false);
  //   const [amenitiesToggle, setAmenitiesToggle] = useState(false);
  //   const [visibleItems, setVisibleItems] = useState(6);
  const [openIndex, setOpenIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("includes");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [ageToggle, setAgetoggle] = useState(false);
  const [isFixed, setIsFixed] = useState(false);


  

  const videoRef = useRef();

  const { packageSlug } = useSelector((state) => state.packages);
  const { name } = useParams();
  // console.log(packageSlug);

    // discount 
    const discountPrice =
    ((packageSlug && packageSlug.price) * Number(packageSlug && packageSlug?.percent)) / 100;
  const totalDiscount = packageSlug && packageSlug.price - discountPrice;
  // console.log(discountPrice);

  const handleClear = () => {
    setAdults(0);
    setChildren(0);
  };

  const createWhatsAppLink = (phone, message) => {
    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
  };

  const onHandleBack = () => {
    navigate(`/packages/${packageSlug && packageSlug.package_category.slug}`);
  };

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const videoUrl = packageSlug && packageSlug.video_link.split("/").pop();
  // console.log(videoUrl);
  const scrollToVideo = () => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return `${words.slice(0, wordLimit).join(" ")}...`;
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const createIconMapping = () => {
    return {
      ...FaIcons,
      ...MdIcons,
      ...BsIcons,
    };
  };

  const fixedImageScroll = () => {
    if (window.scrollY >= 300) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", fixedImageScroll);

  // Generate the icon mapping
  const iconMapping = createIconMapping();

  useEffect(() => {
    dispatch(getPackageBySlug(name));
  }, [dispatch, name]);

  useEffect(() => {
      const timer = setTimeout(() => {
        setEnquiry(true);
      }, 20000);
      return () => clearTimeout(timer);
    }, []);
  

  return (
    <div className="pt-20 pb-10 pr-3 md:pr-5 pl-3 md:pl-5 font-Mont bg-gray-100">
      
      <div className="w-full flex">
        <div className="w-full flex hidden lg:block">
          <div className="w-full h-[400px] flex items-center justify-between overflow-hidden	">
            {/* Left side large image */}
            <div className="w-6/12 h-full">
              <img
                className="cursor-pointer w-full h-full rounded-lg hover:bg-slate-300"
                src={packageSlug && packageSlug?.picture[0]?.url}
                onClick={() => setToggle(true)}
                alt={packageSlug?.title}
                loading="lazy"
              />
            </div>

            {/* Right side small images */}
            <div className="flex  flex-wrap relative w-6/12">
              {packageSlug &&
                packageSlug?.picture.slice(1, 5).map((pic, index) => (
                  <div key={index} className="w-3/6 h-[200px] p-1">
                    <img
                      className="w-full h-full cursor-pointer rounded-lg hover:bg-slate-300"
                      onClick={() => setToggle(true)}
                      src={pic?.url}
                      alt={packageSlug?.title}
                      loading="lazy"
                    />
                  </div>
                ))}
              {/* View All images button */}
              <h1
                onClick={() => setToggle(true)}
                className="cursor-pointer rounded-full m-2 pl-3 pr-3 pt-2 pb-2 font-medium text-sm absolute right-3 bottom-3 flex items-center justify-center text-black bg-gray-200"
              >
                View All images
              </h1>
            </div>

            {/* {packageSlug?.picture &&
            packageSlug?.picture.map((pic) => {
              return (
                <img
                  onClick={() => setToggle(true)}
                  className="h-full cursor-pointer"
                  src={pic.url}
                  alt={packageSlug?.title}
                />
              );
            })} */}
          </div>
        </div>
        <div className="block lg:hidden w-full relative">
          {/* <Slider {...setting}>
            {packageSlug &&
              packageSlug?.picture.map((pic) => {
                // console.log(pic);
                return (
                  <div className="h-[250px] w-full relative" key={pic._id}>
                    <img
                      className="cursor-pointer  rounded-lg h-full w-full"
                      onClick={() => setToggle(true)}
                      src={pic?.url}
                      alt={packageSlug?.title}
                      loading="lazy"
                    />
                  </div>
                );
              })}
          </Slider> */}
          {/* <div className="h-56 sm:h-64 xl:h-80 2xl:h-96"> */}
          <Carousel slide={false} indicators={false}>
            {packageSlug &&
              packageSlug?.picture.map((pic) => {
                // console.log(pic);
                return (
                  <div className="h-[250px] w-full relative" key={pic._id}>
                    <img
                      className="cursor-pointer  rounded-lg h-full w-full"
                      onClick={() => setToggle(true)}
                      src={pic?.url}
                      alt={packageSlug?.title}
                      loading="lazy"
                    />
                  </div>
                );
              })}
          </Carousel>
          {/* </div> */}
          <div className="absolute top-3 left-3 p-3 shadow w-10 h-10 bg-white rounded-full p-2 flex items-center justify-center">
            <FaArrowLeftLong onClick={onHandleBack} />
          </div>
          <div className="absolute top-3 right-3">
            {/* https://plucky-balm-314606.el.r.appspot.com */}
            <RWebShare
              data={{
                text: "By Da Alohas",
                url: `https://thealohas.com/packages/${
                  packageSlug && packageSlug.package_category.slug
                }/${packageSlug?.slug}`,
                title: `I found this intersting package at Da Alohas. Have a look at ${packageSlug?.title} .`,
              }}
              // onClick={() => toast.success("shared successfully!")}
            >
              <button className="shadow w-10 h-10 bg-white rounded-full p-2 flex items-center justify-center">
                <MdOutlineShare size={20} />
              </button>
            </RWebShare>
          </div>
        </div>
        <div className=" ">
          {toggle && (
            <div className="w-full h-full  fixed top-0 left-0 right-0 z-30 h-[500px] overflow-y-scroll bg-white">
              <div
                className="h-20  bg-white shadow-sm shadow-indigo-100 pl-5 lg:pl-10 pr-5 lg:pr-10 fixed w-full top-0 left-0 "
                onClick={() => setToggle(false)}
              >
                <div className="cursor-pointer flex  items-center h-full">
                  <span className="bg-gray border p-3 rounded-full hover:bg-indigo-200 hover:text-white font-semibold text-base">
                    <FaArrowLeftLong />
                  </span>
                  <span className="ml-3">Back</span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center pt-[100px] pb-10 space-y-5 pl-3 pr-3 lg:pr-0 lg:pl-0">
                {packageSlug?.picture.map((pic) => {
                  return (
                    <img
                      key={pic._id}
                      onClick={() => setToggle(true)}
                      className="lg:h-[550px]"
                      src={pic.url}
                      alt={packageSlug?.title}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between pt-5 md:pt-10">
        <div className="w-full lg:w-8/12 md:mr-5">
          <div className="w-full  md:h-[60px] p-2 md:p-4 bg-white shadow rounded-md flex flex-col md:flex-row items-center justify-between">
            <p className="font-medium text-lg capitalize">Plan your trip:  </p>
            <div className="flex items-center justify-between space-x-3  md:space-x-4 mt-3">
              <button
                className="md:pl-4 md:pr-4 capitalize flex items-center "
                onClick={() => setEnquiry(!enquiry)}
              >
                <PiNotepad size={20} />
                <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                  enquire now
                </span>
              </button>
              <a href="mailto:info@thealohas.com">
                <button className="md:pl-4 md:pr-4 capitalize flex items-center ">
                  <MdOutlineEmail size={20} color="red" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Email
                  </span>
                </button>
              </a>
              <div>
                <a
                  className="flex items-center"
                  href={createWhatsAppLink(
                    9870141315,
                    `Please share Uttarakhand - Char Dham Yatra itinerary with me. \n${
                      packageSlug && packageSlug?.title
                    }`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp size={20} color="green" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Send Itinerary
                  </span>
                </a>
              </div>
            </div>
          </div>
          {enquiry && (
            <div className="fixed top-0 left-0 right-0 z-30 bg-black-200 w-full h-full flex items-center justify-center">
              <div className="bg-white w-full md:w-6/12 h-9/10 flex items-center justify-center rounded-md shadow">
                <PackageEnquiryForm setEnquiry={setEnquiry} />
              </div>
            </div>
          )}

          {/* Title & Price Section */}
          <div className="flex justify-between items-center mt-3 bg-white p-4 rounded-md shadow">
            <div className="w-full">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-xl md:text-2xl font-bold">
                    {packageSlug?.title}
                  </h1>
                  <div className="flex flex items-center ">
                    <MdWatchLater size={20} color="gray" />
                    <span className="ml-2">
                      {packageSlug && packageSlug.duration}
                    </span>
                  </div>
                </div>
                {/* <div className="hidden md:block">
                  <RWebShare
                    data={{
                      text: " By Da Alohas",
                      url: `https://thealohas.com/packages/${
                        packageSlug && packageSlug.package_category.slug
                      }/${packageSlug?.slug}`,
                      title: `I found this intersting package at Da Alohas. Have a look at ${packageSlug?.title} .`,
                    }}
                    // onClick={() => toast.success("shared successfully!")}
                  >
                    <button className="shadow w-10 h-10 bg-red-500 rounded-full p-2 flex items-center justify-center">
                      {" "}
                      <MdOutlineShare size={20} color="white" />
                    </button>
                  </RWebShare>
                </div> */}
              </div>
              <div className="flex justify-between w-full">
                <p className="text-gray-500 flex items-center font-medium text-sm">
                  <PiMapPin className="size-4 text-gray" />
                  <span className="ml-1 font-medium text-base">
                    {packageSlug?.origin_city}
                  </span>
                  {/* <div
                    onClick={scrollToMap}
                    className="pl-5 pr-5 pt-2 pb-2 capitalize text-red-400 cursor-pointer underline"
                  >
                    view Map
                  </div> */}
                </p>
                {videoUrl && (
                  <div className="mt-1">
                    <h1
                      onClick={scrollToVideo}
                      className="pl-3 md:pl-4 pr-3 md:pr-4 pt-1 md:pt-1 pb-1 md:pb-1 border capitalize rounded cursor-pointer text-white bg-red-500 font-meduim text-xs md:text-base"
                    >
                      video
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* summary */}
          <div className="bg-white p-4 mt-3 rounded-md shadow">
            <div
              className="text-justify"
              dangerouslySetInnerHTML={{
                __html: showMore
                  ? packageSlug?.summary
                  : truncateText(packageSlug?.summary, 100),
              }}
            />
            {packageSlug?.summary?.split(" ").length > 100 && (
              <button
                onClick={() => setShowMore(!showMore)}
                className="text-white bg-gray-500 font-medium text-xs mt-2 pl-2 pr-2 pt-1 pb-1 rounded"
              >
                {showMore ? "Read Less" : "Read More"}
              </button>
            )}
          </div>

          <div className="bg-white shadow pt-5 mt-3 rounded-md">
            <h1 className="font-bold text-lg capitalize pl-5 ">
              itinerary (Day plans)
            </h1>

            <div className="bg-white rounded-md">
              {packageSlug?.itinerary?.map((tour, index) => (
                <div
                  key={tour._id || index}
                  className="p-4 border-b rounded-md"
                >
                  {/* Header Section */}
                  <div
                    className="flex justify-between items-center cursor-pointer bg-gray-200"
                    onClick={() => toggleDropdown(index)}
                  >
                    <div className="flex items-center gap-3">
                      {/* Day Container */}
                      <div className="flex items-center bg-gray-100 text-black font-sans">
                        {/* Left Arrow Box */}
                        <div className="relative">
                          <div className="bg-red-500 text-white font-semibold px-4 py-2 relative pr-6">
                            Day {index + 1}
                            {/* Arrow Shape */}
                          </div>
                          <div
                            className="absolute top-0 right-[-10px] h-full w-0 
                        border-l-[12px] border-l-red-500 
                        border-y-[20px] border-y-transparent"
                          ></div>
                        </div>
                      </div>

                      {/* <div className="flex flex-col items-center justify-center">
                        <h1 className="uppercase text-red-600 text-sm font-medium">
                          Day
                        </h1>
                        <div className="bg-red-600 text-white w-10 h-10 flex items-center justify-center rounded-full text-lg font-bold">
                          {index + 1}
                        </div>
                      </div> */}
                      {/* Tour Title */}
                      <h2 className="text-lg font-semibold ml-2">
                        {tour.title}
                      </h2>
                    </div>

                    {openIndex === index ? (
                      <MdOutlineKeyboardArrowUp size={24} />
                    ) : (
                      <MdOutlineKeyboardArrowDown size={24} />
                    )}
                  </div>

                  {/* Dropdown Content */}
                  {openIndex === index && (
                    <div className="mt-4 text-gray-700">
                      <p dangerouslySetInnerHTML={{ __html: tour?.summary }} />

                      {/* Amenities Section */}
                      <div className="flex flex-wrap md:flex-row md:flex-wrap gap-2 mt-3">
                        {tour?.package_amenities?.map((ameti) => {
                          // console.log(ameti);
                          const IconComponent = ameti?.icons
                            ? iconMapping[ameti.icons]
                            : null;

                          return (
                            <div
                              className="flex  p-2 flex items-center"
                              key={ameti._id || ameti.name}
                            >
                              <div className="p-2 bg-red-300 rounded-full">
                                {IconComponent ? (
                                  <IconComponent className="text-base" />
                                ) : (
                                  <span className="text-gray-500">
                                    Icon not found
                                  </span>
                                )}
                              </div>
                              <h1 className="ml-2 capitalize">{ameti.name}</h1>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/*  */}
          <div className="w-full mt-3 bg-white shadow-md rounded-md">
            <div className="w-full bg-white">
              {/* Tabs */}
              <div className="flex border-b border-t border-white rounded-md">
                <button
                  className={`flex-1 text-center p-2 font-semibold ${
                    activeTab === "includes"
                      ? "border-b-2  border-green-400 text-green-400"
                      : "text-black"
                  }`}
                  onClick={() => setActiveTab("includes")}
                >
                  Inclusions
                </button>
                <button
                  className={`flex-1 text-center p-2 font-semibold ${
                    activeTab === "excludes"
                      ? "border-b-2 border-red-500 text-red-500"
                      : "text-black"
                  }`}
                  onClick={() => setActiveTab("excludes")}
                >
                  Exclusions
                </button>
              </div>

              {/* Content */}
              <div className="bg-white">
                {activeTab === "includes" ? (
                  <div
                    className="p-4 bg-green-100"
                    dangerouslySetInnerHTML={{
                      __html: packageSlug?.inclusions,
                    }}
                  />
                ) : (
                  <div
                    className="bg-red-100 p-4"
                    dangerouslySetInnerHTML={{
                      __html: packageSlug?.exclusions,
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* all collapse */}
          <div className="border-t border-b mt-3 bg-white shadow p-4 rounded-md">
            <CollapseItem title="Highlights" collaoseOpen={true}>
              {packageSlug?.highlights}
            </CollapseItem>
            <CollapseItem title="Guidelines" collaoseOpen={false}>
              {packageSlug?.guidelines}
            </CollapseItem>
            <CollapseItem title="terms and conditions" collaoseOpen={false}>
              {packageSlug?.terms_and_conditions}
            </CollapseItem>
            {/* Cancellation policy */}
            <CollapseItem title="Cancellation Policy" collaoseOpen={false}>
              {packageSlug?.cancellation_policy}
            </CollapseItem>
          </div>

          {/* space */}
          {packageSlug && packageSlug.places_to_visit.length > 0 ? (
            <div className="mt-3 bg-white p-4 shadow rounded-md">
              <h1 className="font-semibold text-base uppercase">
                places to visit
              </h1>
              {packageSlug && packageSlug.places_to_visit.length > 3 ? (
                <div className="mt-2">
                  <Slider {...settings}>
                    {packageSlug &&
                      packageSlug.places_to_visit.map((spac) => {
                        return (
                          <div
                            key={spac._id}
                            className="!w-[250px] h-[200px] bg-white border border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700"
                          >
                            <img
                              className="rounded-t-sm w-full h-[150px] object-cover"
                              src={spac.ImageUrl}
                              alt={spac.placeId.name}
                            />
                            <div className="p-2 h-[20px]">
                              <p className="mb-1 font-medium text-lg text-gray-700 dark:text-gray-400">
                                {spac.placeId.name}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                </div>
              ) : (
                <div className="flex space-x-2 mt-3">
                  {packageSlug &&
                    packageSlug.places_to_visit.map((spac) => {
                      // console.log(spac);
                      return (
                        <div
                          key={spac._id}
                          className="w-2/6 bg-white border border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700"
                        >
                          <img
                            className="rounded-t-sm h-4/6 md:h-[200px] w-full object-cover"
                            src={spac.ImageUrl}
                            alt={spac.placeId.name}
                          />
                          <div className="p-3 h-10 flex items-center justify-center">
                            <p className="font-medium text-lg text-gray-700 dark:text-gray-400 capitalize">
                              {spac.placeId.name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {/* video link */}
          {videoUrl && (
            <div className="pt-5 md:pt-20" ref={videoRef}>
              <iframe
                className="w-full  h-[250px] md:h-[300px] rounded"
                src={`https://www.youtube.com/embed/${videoUrl}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>

        {/*  */}
        <div className=" md:w-2/6">
          <div
            className={`package_img ${
              isFixed ? "fixed" : ""
            } h-auto w-full bg-white shadow-md p-6 rounded-md hidden md:block`}
          >
            <div>
              <h1 className="text-xl md:text-2xl font-bold">
                {packageSlug?.title}
              </h1>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex flex items-center ">
                <MdWatchLater size={20} color="gray" />
                <span className="ml-2">
                  {packageSlug && packageSlug.duration}
                </span>
              </div>
              <div className="flex space-x-3">
                {/* <div>
                  <a
                    href={createWhatsAppLink(
                      9870141315,
                      `I would like to enquire about this package \n${
                        packageSlug && packageSlug?.title
                      }, \n${adults} adults, \n${children} children`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={20} color="green" />
                  </a>
                </div> */}
                <div>
                  <a
                    href={packageSlug && packageSlug.itinerary_pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BiSolidPrinter size={20} />
                  </a>
                </div>
              </div>
            </div>
            <div>
              {/* <h1 className="font-semibold text-lg">
                ₹
                {Number(
                  packageSlug && packageSlug?.price.toFixed()
                ).toLocaleString()}
              </h1> */}
              <div className="flex items-center justify-between  font-semibold text-base mt-2">
              <div className="flex items-center space-x-2">
                <p> ₹ {(Number(totalDiscount?.toFixed()))?.toLocaleString()}</p>
                
                <h1>/</h1>
                <s className="font-semibold text-sm text-red-500">
                  ₹ {Number(Number(packageSlug && packageSlug.price).toFixed()).toLocaleString()}
                </s>
              </div>
              <div className="bg-red-500 pl-5 pr-5 pt-1 pb-1 rounded-full">
                <h1 className="font-medium text-xs text-white">
                  {" "}
                  Discount {packageSlug && packageSlug?.percent} %
                </h1>
              </div>
            </div>
            </div>

            <div className="mt-5">
              <div className="border rounded-lg w-full bg-white shadow-md relative">
                <div
                  className="flex justify-between items-center cursor-pointer p-4"
                  onClick={() => setAgetoggle(!ageToggle)}
                >
                  <div className="flex items-center space-x-2">
                    <FaIcons.FaUser className="text-gray-600" />
                    <span className="font-medium">
                      {adults} Adult(s), {children} Children(s)
                    </span>
                  </div>
                </div>
                <div className="absolute w-full bg-white shadow-md rounded-md">
                  {ageToggle && (
                    <div className="p-4">
                      <div className="space-y-4 ">
                        <div className="flex items-center justify-between">
                          <div>
                            <label className="block text-sm font-medium">
                              Adults
                            </label>
                            <span className="text-xs text-gray-500">
                              Age 13 or above
                            </span>
                          </div>
                          <div className="flex items-center mt-1 bg-gray-100 w-1/2 rounded-full px-4 py-2 justify-between">
                            <button
                              onClick={() => setAdults(Math.max(1, adults - 1))}
                              className="text-lg text-gray-500 font-semibold text-lg"
                            >
                              -
                            </button>
                            <span className="text-lg font-semibold text-gray-500">
                              {adults}
                            </span>
                            <button
                              onClick={() => setAdults(adults + 1)}
                              className="text-lg text-gray-500"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>
                            <label className="block text-sm font-medium">
                              Children
                            </label>
                            <span className="text-xs text-gray-500">
                              Age 6 to 12
                            </span>
                          </div>
                          <div className="flex items-center mt-1 bg-gray-100 w-1/2 rounded-full px-4 py-2 justify-between">
                            <button
                              onClick={() =>
                                setChildren(Math.max(0, children - 1))
                              }
                              className="text-lg text-gray-500"
                            >
                              -
                            </button>
                            <span className="text-lg font-semibold text-gray-500">
                              {children}
                            </span>
                            <button
                              onClick={() => setChildren(children + 1)}
                              className="text-lg text-gray-500"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={handleClear}
                          className="text-red-500 text-sm"
                        >
                          Clear
                        </button>
                        <button
                          onClick={() => setAgetoggle(false)}
                          className="px-6 py-2 bg-red-500 text-white rounded-full"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => setEnquiry(true)}
              className="mt-4 w-full bg-red-500 text-white p-3 rounded-full hover:bg-red-600 uppercase"
            >
              enquire now
            </button>
            <h1 className="pt-2 flex items-center"><span className="flex items-center justify-center bg-gray-100  w-10 h-10 rounded-full mr-2"> <IoIosHeartEmpty color="green" /> </span><span>Block your seat with INR 9999/-</span></h1>
            <div className="flex items-center justify-center mt-3 space-x-5">
              <a href="mailto:info@thealohas.com" className="w-full items-center  justify-center rounded border border-red-500">
                <button className="w-full capitalize flex items-center  justify-center shadow-md  pt-2 pb-2 ">
                  <MdOutlineEmail size={20} color="red" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Email
                  </span>
                </button>
              </a>
              <a href="tel:+91 9870141315" className="w-full items-center justify-center">
                <button className="w-full capitalize flex items-center  justify-center shadow-md  pt-2 pb-2 rounded border border-orange-500">
                  <span className="font-medium text-orange-500  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Call Now
                  </span>
                </button>
              </a>
              <div className="w-full">
                <a
                  className="flex items-center justify-center bg-green-100 shadow-md pl-5 pr-5 pt-2 pb-2 rounded"
                  href={createWhatsAppLink(
                    9870141315,
                    `I would like to enquire about this package \n${
                      packageSlug && packageSlug?.title
                    }, \n${adults} adults, \n${children} children`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp size={20} color="green" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Whatsapp
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="fixed bottom-0 left-0 right-0 bottom block md:hidden">
            <PackageEnquirySmallScreen packages={packageSlug} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagesDetails;

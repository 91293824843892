import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import CreateNewProperty from "./components/Admin/Property/Property/CreateNewProperty";
import Home from "./components/Home";
import Dashboard from "./components/Admin/dashboard/dashboard";
import PropertyDetails from "./components/property/propertyDetails";
import Navbar from "./components/Navbar/navbar";
import CreateAmenities from "./components/Admin/Property/Amenities/createAmenities";
import UpdateAmenities from "./components/Admin/Property/Amenities/UpdateAmenities.js";
import Footer from "./components/Footer/Footer";
import PropertyList from "./components/Admin/Property/Property/PropertyList";
import UpdateProperty from "./components/Admin/Property/Property/updateProperty";
import Category from "./components/Admin/Property/Category/Category";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Collection from "./components/Admin/Property/Collection/Collection";
import CollectionList from "./components/Admin/Property/Collection/CollectionList";
import CategoryList from "./components/Admin/Property/Category/CategoryList";
import ListOfAmenities from "./components/Admin/Property/Amenities/ListOfAmenities";
import FeaturesList from "./components/Admin/Property/Features/FeaturesList";
import Features from "./components/Admin/Property/Features/Features";
import PropertyTypeList from "./components/Admin/Property/propertyType/propertyTypeList";
import PropertyType from "./components/Admin/Property/propertyType/PropertyType";
import PropertySpaceList from "./components/Admin/Property/PropertySpaces/PropertySpaceList";
import ProeprtySpace from "./components/Admin/Property/PropertySpaces/ProeprtySpace";
import AllProperty from "./components/property/AllProperty.js";
// import Login from "./components/Auth/Login.js";
import Booking from "./components/Booking/booking.js";
import ProtectedRoute from "./components/ProtectedRoute.js";
// import { useDispatch } from "react-redux";
import { LoadUser } from "./redux/actions/userAction/userAction.js";
import SearchPropertyPage from "./components/PropertySearch/SearchPropertyPage.js";
// import PaymentSuccess from "./components/Paymentgateway/PaymentSuccess.js";
import PaymentSuccess from "./components/Booking/PaymentSuccess.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Registration from "./components/Auth/registration.js";
import FilterProperty from "./components/property/FilterProperty.js";
import UserDashboard from "./components/Profile/UserDashboard.js";
import ForgotPassword from "./components/Auth/ForgotPassword.js";
import ScrollToTop from "./components/ScrollToTop.js";
import PhoneNumberVerification from "./components/Auth/LoginSignup.js";
import ThemeUpload from "./components/Admin/Property/Theme/ThemeUpload.js";
import CreateCoupon from "./components/Admin/Property/Coupon/CreateCoupon.js";
import ListOfCoupon from "./components/Admin/Property/Coupon/ListOfCoupon.js";
import UpdateCouponCode from "./components/Admin/Property/Coupon/UpdateCouponCode.js";
import ListOfTheme from "./components/Admin/Property/Theme/ListOfTheme.js";
import UpdateTheme from "./components/Admin/Property/Theme/UpdateTheme.js";
import CreateAmenitiesType from "./components/Admin/Property/Amenities/AmenitiesType/CreateAmenitiesType.js";
import ListOfAmenitiesType from "./components/Admin/Property/Amenities/AmenitiesType/ListOfAmenitiesType.js";
// import store from "./redux/store.js";
import { useDispatch } from "react-redux";
import AboutUs from "./components/AboutUs/AboutUs.js";
import OurTeam from "./components/OurTeam/OurTeam.js";
import PartnerWithUs from "./components/Content/PartnerWithUs/PartnerWithUs.js";
import OwnerLeads from "./components/Admin/OwnerLeads/OwnerLeads.js";
import ContactUs from "./components/ContactUs.js";
import PrivacyAndPolicy from "./components/PrivacyAndPolicy.js";
import TermsANdConditions from "./components/TermsANdConditions.js";
import ContactList from "./components/Admin/ContactList/ContactList.js";
import Offers from "./components/Offers/Offers.js";
import OffersPage from "./components/Admin/Offers/Offers.js";
import CancellationsAndRefunds from "./components/CancellationsAndRefunds.js";
import OffersList from "./components/Admin/Offers/OffersList.js";
import PageNotFound from "./components/PageNotFound.js";
import UpdateOffer from "./components/Admin/Offers/UpdateOffer.js";
import CreatePackage from "./components/Admin/Packages/CreatePackages.js";
import CreatePlaceToVisit from "./components/Admin/Packages/placeTovisit/CreatePlaceToVisit.js";
import ListOfPackages from "./components/Admin/Packages/ListOfPackages.js";
import UpdatePackage from "./components/Admin/Packages/UpdatePackage.js";
import CreatePackageAmenities from "./components/Admin/Packages/PackageAmenities/CreatePackageAmenities.js";
import ListPackageAmenities from "./components/Admin/Packages/PackageAmenities/ListPackageAmenities.js";
import PackageType from "./components/Admin/Packages/PackageType/PackageType.js";
import PackageList from "./components/Admin/Packages/PackageType/PackageList.js";
import Packages from "./components/Packages/Packages.js";
import PackagesDetails from "./components/Packages/PackagesDetails.js";
import CreatePackageCategory from "./components/Admin/Packages/PackageCategory/CreatePackageCategory.js";
import PackageListCategory from "./components/Admin/Packages/PackageCategory/PackageListCategory.js";
import PlaceToVisitList from "./components/Admin/Packages/placeTovisit/PlaceToVisitList.js";
import MultiTypePackage from "./components/Packages/MultiTypePackage.js";
import PackageEnquiry from "./components/Admin/Packages/PackageEnquiry/PackageEnquiry.js";
import PaymentLinkSuccess from "./components/Booking/PaymentLinkSuccess.js";
import UserList from "./components/Admin/Users/UserList.js";
// import Services from "./components/Content/Services/Services.js";

const App = () => {
  const dispatch = useDispatch();

  // let { loading } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(LoadUser());
  }, [dispatch]);

  // if (loading) return <div>Loading...</div>;

  return (
    <div>
      <Navbar />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/all-locations" element={<AllProperty />} />
          <Route path="/search" element={<SearchPropertyPage />} />
          <Route path="/login" element={<PhoneNumberVerification />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/property/:name" element={<PropertyDetails />} />
          {/* <Route path="/payment-success" element={<PaymentSuccess />} /> */}
          <Route path="/all-locations/:city" element={<FilterProperty />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/paymentLink-success" element={<PaymentLinkSuccess />} />

          <Route element={<ProtectedRoute />} >
            <Route exact path="/booking/:id" element={<Booking />} />
            <Route
              exact
              path="/account-setting/*"
              element={<UserDashboard />}
            />
          </Route>

          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard/all-users" element={<UserList />} />
            <Route path="/dashboard/ownerleads" element={<OwnerLeads />} />
            <Route path="/dashboard/contact" element={<ContactList />} />
            <Route path="/dashboard/offers" element={<OffersPage />} />
            <Route path="/dashboard/offer-list" element={<OffersList />} />
            <Route path="/dashboard/offer-list/:id" element={<UpdateOffer />} />
          </Route>

          {/* dashboard */}
          <Route exact element={<ProtectedRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route
              path="/dashboard/property/add"
              element={<CreateNewProperty />}
            />
            <Route
              path="/dashboard/amenities/add"
              element={<CreateAmenities />}
            />
            <Route
              path="/dashboard/amenities/list"
              element={<ListOfAmenities />}
            />
            <Route
              path="/dashboard/amenities/list/:id"
              element={<UpdateAmenities />}
            />

            {/* amenities type */}
            <Route
              path="/dashboard/amenities-type/add"
              element={<CreateAmenitiesType />}
            />
            <Route
              path="/dashboard/amenities-type/list"
              element={<ListOfAmenitiesType />}
            />

            <Route path="/dashboard/category/add" element={<Category />} />
            <Route path="/dashboard/category/list" element={<CategoryList />} />
            <Route path="/dashboard/collection/add" element={<Collection />} />
            <Route
              path="/dashboard/collection/list"
              element={<CollectionList />}
            />
            <Route path="/dashboard/property/list" element={<PropertyList />} />
            <Route
              path="/dashboard/property/list/:id"
              element={<UpdateProperty />}
            />
            <Route path="/dashboard/features/add" element={<Features />} />
            <Route path="/dashboard/features/list" element={<FeaturesList />} />
            <Route
              path="/dashboard/property/type/add"
              element={<PropertyType />}
            />
            <Route
              path="/dashboard/property/type/list"
              element={<PropertyTypeList />}
            />
            <Route path="/dashboard/spaces/add" element={<ProeprtySpace />} />
            <Route
              path="/dashboard/spaces/list"
              element={<PropertySpaceList />}
            />

            <Route path="/dashboard/upload-theme" element={<ThemeUpload />} />
            <Route
              path="/dashboard/list-of-all-theme"
              element={<ListOfTheme />}
            />

            <Route
              path="/dashboard/list-of-all-theme/:id"
              element={<UpdateTheme />}
            />

            <Route
              path="/dashboard/generate-coupon"
              element={<CreateCoupon />}
            />
            <Route
              path="/dashboard/list-of-coupon"
              element={<ListOfCoupon />}
            />
            <Route
              path="/dashboard/list-of-coupon/:id"
              element={<UpdateCouponCode />}
            />
            {/* <Route path="/accounts-setting/profile" element={<Profile />} />
          <Route path="/accounts-setting/trips" element={<Trips />} />
          <Route path="/accounts-setting/trips/:id" element={<TripDetails />} /> */}
          </Route>

          <Route exact element={<ProtectedRoute />}>
            <Route path="/dashboard/package/add" element={<CreatePackage />} />
            <Route
              path="/dashboard/package/list"
              element={<ListOfPackages />}
            />
            <Route
              path="/dashboard/packages/list/:id"
              element={<UpdatePackage />}
            />
            <Route
              path="/dashboard/package/place-to-visit/add"
              element={<CreatePlaceToVisit />}
            />
            <Route
              path="/dashboard/package/place-to-visit/list"
              element={<PlaceToVisitList />}
            />
            <Route
              path="/dashboard/package/amenities/add"
              element={<CreatePackageAmenities />}
            />
            <Route
              path="/dashboard/package/amenities/list"
              element={<ListPackageAmenities />}
            />
            <Route
              path="/dashboard/package/type/add"
              element={<PackageType />}
            />
            <Route
              path="/dashboard/package/type/list"
              element={<PackageList />}
            />
            <Route
              path="/dashboard/package/category/add"
              element={<CreatePackageCategory />}
            />
            <Route
              path="/dashboard/package/category/list"
              element={<PackageListCategory />}
            />
          </Route>

          {/* package */}
          <Route path="/packages" element={<Packages />} />
          <Route path="/packages/:slug" element={<MultiTypePackage />} />
          <Route path="/packages/:slug/:name" element={<PackagesDetails />} />
          <Route path="/dashboard/package/enquiry" element={<PackageEnquiry />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/partner" element={<PartnerWithUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsANdConditions />}
          />
          <Route
            path="/cancellations-and-refunds"
            element={<CancellationsAndRefunds />}
          />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default App;

import React, { useCallback, useEffect, useState } from "react";
// import { HiUsers } from "react-icons/hi";
import DatePicker from "../PropertySearch/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getRentlPrice } from "../../redux/actions/propertyActions/PropertyAction";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import AgeSelecter from "./AgeSelecter";
// import { createOrder } from "../../redux/actions/createOrderActions/createOrderAction";
// import { IoIosArrowForward } from "react-icons/io";
import { getCouponByID } from "../../redux/actions/CouponAction/CouponCode";
import { IoCloseSharp } from "react-icons/io5";
import { CLEAR_COUPON_CODE_SUCCESS } from "../../redux/constant/CouponConstant/CouponCode";
// import { CLEAR_COUPON_CODE_SUCCESS } from "../../redux/constant/CouponConstant/CouponCode";
// import { toast } from "react-toastify";

const Availability = ({ propertyId }) => {
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [children05, setChildren05] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  // const [reservationCreated, setReservationCreated] = useState(false);
  // const [promoToggle, setPromoToggle] = useState(false);
  // const [promo, setPromoHandle] = useState("");
  // const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0); // Store calculated discount
  const [couponError, setCouponError] = useState(" ");
  const [gstAmount, setGstAmount] = useState(0);
  const [clearDate, setClearDate] = useState(false);

  // const [errorMessage, setErrorMessage] = useState("");
  const { couponId, loading, error } = useSelector((state) => state.coupon);
  // console.log(couponId);
  // console.log(user);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [checkIn, setCheckIn] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection",
  });

  const { rentlId } = useSelector((state) => state.propertyList);

  const createWhatsAppLink = (phone, message) => {
    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
  };

  // const

  // let percentOff = parseFloat(couponId?.PercentOff || 0);
  // let amountOff = parseFloat(couponId?.AmountOff || 0);

  // let totalAmount = Number(totalRentPrice) + Number(gstAmount);
  // let discount = 0;

  // // Calculate the discount
  // if (percentOff > 0) {
  //   discount = (totalAmount * percentOff) / 100;
  // } else if (amountOff > 0) {
  //   discount = amountOff;
  // }

  // // Apply the discount to the total amount
  // totalAmount -= discount;

  // const checkInDate = formatDate(checkIn.startDate);
  // const checkoutDate = formatDate(checkIn.endDate);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  const onHandleBooking = async () => {
    // const checkInDate = formatDate(checkIn.startDate);
    // const checkoutDate = formatDate(checkIn.endDate);

    // // Prepare booking data
    // const bookingData = {
    //   amount: totalAmount,
    //   user,
    //   propertyId,
    //   dateFrom: checkInDate,
    //   dateTo: checkoutDate,
    //   adults: adultCount,
    //   children612: childCount,
    //   children05,
    //   perNightPrice,
    //   number_of_nights: rentlId?.rentlPrice?.length || 0,
    //   base_amount: totalRentPrice,
    //   taxes: gstAmount,
    //   total_amount: totalAmount,
    // };

    // // Log the booking data for debugging
    // console.log("Booking data being sent to API:", bookingData);

    // if (!reservationCreated) {
    //   setReservationCreated(true);
    //   dispatch(createOrder(bookingData));
    // }

    // if(isAuthenticated){
    if (!isAuthenticated) {
      navigate(`/login?redirect=/booking/${propertyId?._id}`);
    } else {
      navigate(`/booking/${propertyId?._id}`);
    }
    // }
  };

  let TotalAdults = adultCount + childCount;

  useEffect(() => {
    if (propertyId && checkIn.startDate && checkIn.endDate && TotalAdults) {
      // const clearDate = sessionStorage.getItem("clearDate");
      // console.log("hello2");

      dispatch(
        getRentlPrice({
          dateFrom: formatDate(checkIn.startDate),
          dateTo: formatDate(checkIn.endDate),
          id: propertyId._id,
          adults: TotalAdults,
          clearDate,
        })
      );
    }
  }, [
    dispatch,
    adultCount,
    childCount,
    clearDate,
    checkIn,
    TotalAdults,
    propertyId,
  ]);

  const totalRentPrice =
    rentlId && rentlId?.rentlPrice.reduce((sum, item) => sum + item.price, 0);

  const calculateDiscount = useCallback(() => {
    if (!couponId || !couponId?.isActive) return 0; // Return 0 if no valid coupon
    const percentOff = parseFloat(couponId?.PercentOff || 0);
    const amountOff = parseFloat(couponId?.AmountOff || 0);

    if (percentOff > 0) {
      return (totalRentPrice * percentOff) / 100;
    } else if (amountOff > 0) {
      return amountOff;
    }
    return 0;
  }, [couponId, totalRentPrice]);

  const calculateGST = useCallback(() => {
    const taxableAmount = totalRentPrice - discount;
    return taxableAmount < 7500
      ? (taxableAmount * 12) / 100
      : (taxableAmount * 18) / 100;
  }, [totalRentPrice, discount]);

  useEffect(() => {
    const newDiscount = calculateDiscount();
    setDiscount(newDiscount);
  }, [totalRentPrice, couponId, calculateDiscount]);

  const onHandlePromo = () => {
    // setCouponError("");
    setDiscount(0);
    // sessionStorage.removeItem("discount");

    const validInfo = {
      propertyId: propertyId && propertyId._id,
      mobile: Number(user && user.mobile.slice(3)),
    };

    try {
      dispatch(getCouponByID(coupon, validInfo));

      if (error) {
        setCouponError((prev) => prev || error);
        setDiscount(0);
        return;
      }

      // If coupon is valid, calculate the discount
      // if (couponId && couponId.isActive) {
      const percentOff = parseFloat(couponId?.PercentOff || 0);
      const amountOff = parseFloat(couponId?.AmountOff || 0);
      let calculatedDiscount = 0;

      if (percentOff > 0) {
        calculatedDiscount = (totalRentPrice * percentOff) / 100;
      } else if (amountOff > 0) {
        calculatedDiscount = amountOff;
      }

      setDiscount(calculatedDiscount);
      setCouponError("");
      setToggle(false);
    } catch (err) {
      setCouponError("Failed to apply the coupon. Please try again.");
    }
  };

  // useEffect(() => {
  //   sessionStorage.setItem("discount", discount);
  // }, [discount]);

  // useEffect(() => {
  //   const storedDiscount = sessionStorage.getItem("discount");
  //   if (storedDiscount) {
  //     setDiscount(Number(storedDiscount));
  //   }
  // }, []);

  let perNightPrice =
    (totalRentPrice - discount) / (rentlId?.rentlPrice.length || 1);

  useEffect(() => {
    setGstAmount(calculateGST());

    // if (error) {
    //   // alert(error)
    //   dispatch(clearErrors);
    // }
    // dispatch({
    //   type: CLEAR_ERROR
    // })
  }, [
    dispatch,
    discount,
    totalRentPrice,
    calculateDiscount,
    calculateGST,
    error,
  ]);

  let totalAmount = Number(totalRentPrice - discount) + Number(gstAmount);

  return (
    <div
      className={
        rentlId && rentlId.rentlPrice.length === 0
          ? "h-auto border shadow-xl rounded p-4 pb-7 relative bg-white"
          : "h-auto border shadow-xl rounded p-4 relative bg-white"
      }
    >
      <div className="">
        {/* <h3 className="font-medium text-lg">Starting from</h3> */}
        <h1 className="font-medium text-lg">
          {rentlId && rentlId.rentlPrice?.length > 0 ? (
            <div>
              ₹
              {Number(
                (
                  rentlId.rentlPrice.reduce(
                    (price, val) => price + Number(val.price), // Ensure val.price is a number
                    0
                  ) / rentlId.rentlPrice.length
                ).toFixed(0) // Round to the nearest whole number
              ).toLocaleString()}
              /night
            </div>
          ) : (
            <div className="font-medium text-base">
              <h1 className="font-medium text-base text-gray-600">
                Starting From
              </h1>
              <span className="font-semibold text-base text-gray-800">{`₹ ${propertyId?.starting_from_price} /night`}</span>
            </div>
          )}
        </h1>
      </div>
      {/* Check-in and Guests Section */}
      <div className="mt-3  rounded-lg w-full">
        {/* Date Pickers */}
        <div className="w-full">
          <DatePicker
            checkIn={checkIn}
            setCheckIn={setCheckIn}
            TotalAdults={TotalAdults}
            align="right-0 z-20 "
            clearDate={clearDate}
            setClearDate={setClearDate}
          />
        </div>

        {/* guest */}
        <AgeSelecter
          propertyId={propertyId}
          setAdultCount={setAdultCount}
          adultCount={adultCount}
          setChildCount={setChildCount}
          childCount={childCount}
          children05={children05}
          setChildren05={setChildren05}
        />

        {/* display pay 50% percent available */}
        {Number(propertyId && propertyId.percent_enable) &&
        Number(
          propertyId &&
            propertyId.minimum_reservation_price > 0 &&
            (propertyId && propertyId.minimum_reservation_price) <
              (Number(totalAmount))
        ) &&
        Number(propertyId.checkin_date_distance) > 0 &&
        new Date(checkIn.startDate) >
          new Date(
            new Date().getTime() +
              Number(propertyId.checkin_date_distance) * 24 * 60 * 60 * 1000
          ) ? (
          <div className="flex items-center justify-between h-[40px] mt-3  bg-orange-100 p-2">
            <h1 className="font-semibold text-sm">Pay 50% to book and rest later</h1>
          </div>
        ) : (
          ""
        )}
        <button
          onClick={onHandleBooking}
          className={`${
            (rentlId && rentlId.rentlPrice.length === 0) ||
            (propertyId && propertyId.instant_booking === "no")
              ? "bg-gray-400"
              : "bg-red-500"
          } mt-3 w-full  text-white p-3 rounded-full`}
          disabled={
            (rentlId && rentlId.rentlPrice.length === 0) ||
            (propertyId && propertyId.instant_booking === "no")
          }
        >
          Book Now
        </button>
      </div>
      <button className="mt-3 w-full bg-black text-white p-3 rounded-full hover:bg-red-600 uppercase">
        <a
          href={createWhatsAppLink(
            9870141315,
            `I would like to enquire about this property \n${
              propertyId && propertyId?.title
            }, \ncheckIn - ${checkIn?.startDate
              ?.toDateString()
              ?.slice(0, 10)} ,\nCheckOut - ${checkIn?.endDate
              ?.toDateString()
              ?.slice(
                0,
                10
              )},\nAdults - ${adultCount},\nChildren - ${childCount},\ninfants - ${children05},\nTotal Amount - ${totalAmount
              .toFixed()
              .toLocaleLowerCase()} `
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          enquiry
        </a>
      </button>

      {rentlId?.rentlPrice.length > 0 ? (
        <div className="space-y-1 text-gray-700 mt-3">
          <div className="flex justify-between">
            <span>{`${Number(perNightPrice.toFixed()).toLocaleString()} X ${
              rentlId?.rentlPrice?.length
            } Night(s)`}</span>
            <span className="font-medium">
              ₹{Number(totalRentPrice.toFixed()).toLocaleString()}
            </span>
          </div>

          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <div>
              <div className="flex items-center justify-center text-green-600">
                <div className="flex justify-between w-full">
                  {couponId && couponId?.code !== null ? (
                    <spna>
                      Coupon Discount ({`${couponId && couponId?.code}`})
                    </spna>
                  ) : (
                    "Coupon Discount"
                  )}
                  <span className="font-medium">
                    {discount > 0 ? (
                      <div className="flex justify-between">
                        <span className="font-medium flex justify-between">
                          - ₹{Number(discount.toFixed()).toLocaleString()}
                          <IoCloseSharp
                            className="cursor-pointer"
                            color="red"
                            size={20}
                            onClick={() => {
                              setDiscount(0);
                              setCoupon("");
                              setCouponError("");
                              dispatch({ type: CLEAR_COUPON_CODE_SUCCESS });
                              // sessionStorage.removeItem("discount");
                            }}
                          />
                        </span>
                      </div>
                    ) : (
                      <button onClick={() => setToggle(!toggle)}>
                        Apply Coupon
                      </button>
                    )}
                  </span>
                </div>

                {toggle && (
                  <div className="w-full h-[200px] bg-gray-100 absolute top-0 flex flex-col items-center justify-center">
                    <input
                      className="w-10/12"
                      type="text"
                      onChange={(e) => setCoupon(e.target.value)}
                      placeholder="Enter your coupon code"
                    />
                    {couponError && (
                      <p className="text-red-400 ">{couponError}</p>
                    )}
                    <div className="flex space-x-2 mt-5">
                      <button
                        className="pl-4 pr-4 pt-1 pb-1 border"
                        onClick={() => setToggle(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="pl-4 pr-4 pt-1 pb-1 border"
                        onClick={onHandlePromo}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <span>Taxes</span>
                <span className="font-medium">
                  ₹{Number(gstAmount.toFixed()).toLocaleString()}
                </span>
              </div>

              <hr className="my-3" />
              <div className="flex justify-between text-lg font-semibold text-gray-800">
                <span className="font-medium text-base">Total Amount</span>
                <span className="font-semibold text-base">
                  ₹{Number(totalAmount.toFixed()).toLocaleString()}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Availability;

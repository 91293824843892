// login user
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

// get user details
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

// user login through the mobile number
export const USER_SEND_OTP_MOBILE_REQUEST = "USER_SEND_OTP_MOBILE_REQUEST";
export const USER_SEND_OTP_MOBILE_SUCCESS = "USER_SEND_OTP_MOBILE_SUCCESS";
export const USER_SEND_OTP_MOBILE_FAIL = "USER_SEND_OTP_MOBILE_FAIL";

//verify user mobile number
export const USER_VERITY_OTP_REQUEST = "USER_VERITY_OTP_REQUEST";
export const USER_VERITY_OTP_SUCCESS = "USER_VERITY_OTP_SUCCESS";
export const USER_VERITY_OTP_FAIL = "USER_VERITY_OTP_FAIL";

// add more user details
export const ADD_USER_DETAILS_REQUEST = "ADD_USER_DETAILS_REQUEST";
export const ADD_USER_DETAILS_SUCCESS = "ADD_USER_DETAILS_SUCCESS";
export const ADD_USER_DETAILS_FAIL = "ADD_USER_DETAILS_FAIL";

// update user profile
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

// user logout
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

// get All users
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"


// clear error
export const CLEAR_ERROR = "CLEAR_ERROR";

import {
  GET_ALL_PROPERTY_REQUEST,
  GET_ALL_PROPERTY_SUCCESS,
  GET_ALL_PROPERTY_FAIL,
  CREATE_NEW_PROPERTY_REQUEST,
  CREATE_NEW_PROPERTY_SUCCESS,
  CREATE_NEW_PROPERTY_FAIL,
  GET_PROPERTY_BY_ID_REQUEST,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_ID_FAIL,
  GET_PROPERTY_BY_SLUG_REQUEST,
  GET_PROPERTY_BY_SLUG_SUCCESS,
  GET_PROPERTY_BY_SLUG_FAIL,
  UPDATE_PROERTY_BY_ID_REQUEST,
  UPDATE_PROERTY_BY_ID_SUCCESS,
  UPDATE_PROERTY_BY_ID_FAIL,
  SEARCH_PROPERTY_REQUEST,
  SEARCH_PROPERTY_SUCCESS,
  SEARCH_PROPERTY_FAIL,
  GET_RENTLIO_PRICE_REQUEST,
  GET_RENTLIO_PRICE_SUCCESS,
  GET_RENTLIO_PRICE_FAIL,
  CLEAR_ERROR,
  GET_ALL_DISABLE_DATE_REQUEST,
  GET_ALL_DISABLE_DATE_SUCCESS,
  GET_ALL_DISABLE_DATE_FAIL,
} from "../../constant/propertyConstant/PropertyConstant";

export const createNewPropertyReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_PROPERTY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEW_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case CREATE_NEW_PROPERTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const getAllPropertyReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTY_REQUEST:
    case GET_PROPERTY_BY_ID_REQUEST:
    case GET_PROPERTY_BY_SLUG_REQUEST:
    case UPDATE_PROERTY_BY_ID_REQUEST:
    case GET_RENTLIO_PRICE_REQUEST:
      case GET_ALL_DISABLE_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
      };

    case GET_PROPERTY_BY_SLUG_SUCCESS:
    case GET_PROPERTY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        propertyId: action.payload,
      };

      case GET_ALL_DISABLE_DATE_SUCCESS:
        return{
          ...state,
          loading: false,
          disabled: action.payload
        }

    case UPDATE_PROERTY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        updated: action.payload,
      };

    case GET_RENTLIO_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        rentlId: action.payload,
      };

    case GET_ALL_PROPERTY_FAIL:
    case GET_PROPERTY_BY_ID_FAIL:
    case GET_PROPERTY_BY_SLUG_FAIL:
    case UPDATE_PROERTY_BY_ID_FAIL:
    case GET_RENTLIO_PRICE_FAIL:
    case GET_ALL_DISABLE_DATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case CLEAR_ERROR:
        return{
          ...state,
          error: null
        }

    default:
      return state;
  }
};

export const searchProperty = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_PROPERTY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEARCH_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        search: action.payload,
      };

    case SEARCH_PROPERTY_FAIL:
      return {
        ...state,
        loading: false,
        error:action.payload
      };
    default:
      return state;
  }
};

import axios from "axios";
import {
  CREATE_PLACE_TO_VISIT_REQUEST,
  CREATE_PLACE_TO_VISIT_SUCCESS,
  CREATE_PLACE_TO_VISIT_FAIL,
  GET_ALL_PLACE_TO_VISIT_FAIL,
  GET_ALL_PLACE_TO_VISIT_REQUEST,
  GET_ALL_PLACE_TO_VISIT_SUCCESS,
} from "../../constant/PackagesConstant/PackagesPlaceToVisitConstant";

export const createPlaceToVisit = (place) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PLACE_TO_VISIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/create-place", place, config);
    dispatch({
      type: CREATE_PLACE_TO_VISIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PLACE_TO_VISIT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all places
export const getAllPlaceToVisit = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PLACE_TO_VISIT_REQUEST,
    });

    const { data } = await axios.get("/api/v1/get-all-place");
    dispatch({
      type: GET_ALL_PLACE_TO_VISIT_SUCCESS,
      payload: data.allPlaces,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PLACE_TO_VISIT_FAIL,
      payload: error.response.data.message,
    });
  }
};

import { useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const CollapseItem = ({ title, children, collaoseOpen }) => {
  const [isOpen, setIsOpen] = useState(collaoseOpen);

  const toggleCollapse = () => setIsOpen(!isOpen);

  return (
    <div className="border-b last:border-none">
      {/* Title Section */}
      <button
        onClick={toggleCollapse}
        className="flex justify-between items-center w-full  pt-4 pb-4  text-black font-medium"
      >
        <span className="font-medium text-base uppercase">{title}</span>
        <span className={`${isOpen ? "rotate-90" : ""} transition-transform`}>
          <MdOutlineArrowForwardIos size={22} />
        </span>
      </button>

      {/* Content Section */}
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
          isOpen ? "max-h-screen  sm:pl-4 pb-4 pr-4" : "max-h-0"
        }`}
      >
        <div
          className="text-gray-700"
          dangerouslySetInnerHTML={{
            __html: children, 
          }}
        />
      </div>
    </div>
  );
};

export default CollapseItem;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";

const PaymentSuccess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("payment_id");
  const [reservation, setReservation] = useState(null);


  useEffect(() => {
    const fetchReservation = async () => {
      if (!paymentId) return;
      try {
        const { data } = await axios.get(
          `/api/v1/reservation_payment/${paymentId}`
        );
        setReservation(data.reservation);
      } catch (error) {
        console.error("Error fetching reservation:", error);
      }
    };
    fetchReservation();
  }, [paymentId]);

  console.log("reservation",reservation)

  // // send payment link to the user
  // if (reservation && reservation.paylater_status === "true" ) {
  //   (async () => {
  //     try { 
       
  //       await axios.post(`/api/v1/create-payment-link`,reservation);

  //       // setReservation(data.reservation);
  //     } catch (error) {
  //       console.error("Error fetching reservation:", error);
  //     }
  //   })();
  // }

  // // if (reservation && reservation.paylater_status === "true") {
  //   (async () => {
  //     try {
  //       await axios.get(`/api/v1/paymentLink/${razorpay_payment_link_id}`);

  //     } catch (error) {
  //       console.error("Error fetching payment details:", error);
  //     }
  //   })(); 
  // // }


  // Call API only when reservation._id is available
  useEffect(() => {
    if (reservation && reservation?._id && reservation.paylater_status === "true") {
      const sendPaymentLink = async () => {
        try {
          await axios.post(`/api/v1/create-payment-link`, reservation);
        } catch (error) {
          console.error("Error sending payment link:", error);
        }
      };
      sendPaymentLink();
    }
  }, [reservation?._id, reservation?.paylater_status, reservation]);

  

  if (!reservation) {
    return (
      <div className="flex justify-center items-center h-screen text-gray-600">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100  md:px-4 font-Mont">
      <div className="bg-white shadow-xl rounded-2xl p-2 md:p-6 max-w-lg w-full text-center">
        <div className="flex flex-col items-center">
          <FiCheckCircle className="text-green-500 text-5xl mb-3" />
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">
            Payment Successful
          </h1>
          {/* <p className="text-gray-600 mb-6">Thank you for your order!</p> */}
        </div>

        <div className="bg-gray-50 p-4 rounded-t-xl shadow-sm text-left border-b">
          <div className="flex items-center">
            <p className="text-gray-700 text-sm font-medium">
              Reservation ID :
            </p>
            <p className="text-base font-medium text-gray-900 ml-2">
              {reservation.reservation_id}
            </p>
          </div>
          <div className="border-t my-4" />

          <div className="flex items-center">
            <p className="text-gray-700 text-sm font-medium ">Property :</p>
            <p className="text-base font-medium text-gray-900 ml-2">
              {reservation.property_title}, {reservation.property_city}
            </p>
          </div>

          <p className="text-gray-700 mt-2">
            Check-In :
            <span className="font-medium text-gray-900 ml-2">
              {reservation.check_in_date}
            </span>
          </p>
          <p className="text-gray-700">
            Check-Out :
            <span className="font-medium text-gray-900 ml-2">
              {reservation.check_out_date}
            </span>
          </p>
          <p className="text-gray-700">
            No Nights :
            <span className="font-medium text-gray-900 ml-2">
              {reservation.number_of_nights}
            </span>
          </p>

          <p className="text-gray-700">
          Total Guests :
            <span className="font-medium text-gray-900 ml-2">
            {reservation?.adults} Adults,
                    {reservation?.children612} Children,
                    {reservation?.children05} Infants
            </span>
          </p>
        </div>
        


        <div className="flex items-center justify-between w-full bg-gray-50 rounded-b-xl">
          <div className="p-4 rounded-xl  text-left">
            <p className="text-gray-700 text-sm font-medium mb-1">
              Primary Guest :
            </p>
            <p className="text-lg font-semibold text-gray-900">
              {reservation.booker_first_name} {reservation.booker_last_name}
            </p>
            <p className="text-gray-700 text-sm">
              Phone : {reservation.booker_mobile}
            </p>
          </div>

          <div className="p-4 rounded-xl mt-4">
            <p className="text-gray-700 text-sm font-medium">Amount Paid</p>
            <p className="text-xl font-semibold text-green-600">
              ₹{Number(reservation.amount_paid).toLocaleString()}
            </p>
          </div>
        </div>

        <Link to="/account-setting/trips">
        <button className="mt-4 px-4 py-2 bg-red-400 text-white text-lg font-semibold rounded-lg hover:bg-red-500 transition-all">
          Go to Trips
        </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;

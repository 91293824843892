import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import { FaMapMarkerAlt } from "react-icons/fa";
import { getReservationId } from "../../redux/actions/createOrderActions/createOrderAction";
import MapView from "../property/MapView";
import Loader from "../Loader";
import MetaData from "../MetaData";

const TripDetails = () => {
  // const [isCancellationOpen, setCancellationOpen] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, tripsId } = useSelector((state) => state.createOrder);
  // console.log(tripsId);

  useEffect(() => {
    dispatch(getReservationId(id));
  }, [dispatch, id]);

  return (
    <div className="h-full">
      <MetaData title={`trip details by Da alohas`} />
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-100 p-2 md:p-4 mt-5 md:mt-0 font-Mont">
          {/* Header Section */}
          <div className="bg-white p-2 md:p-6 rounded-md  mb-2">
            <h2 className="text-xl font-bold mb-2">Your Trip Details</h2>
            <p className="text-sm text-gray-600">
              Booking ID:{" "}
              <span className="font-medium uppercase">
                {tripsId && tripsId?.reservation_id}
              </span>
              {/* | Reference No: <span className="font-medium">ABCDEFG</span> */}
            </p>
            {/* <p className="text-sm text-gray-600">
        <span className="font-medium">(Room 1), (Room 2)</span>
      </p> */}
          </div>

          {/* Booking Info Section */}
          <div className="bg-white p-2 md:p-6 rounded-md-t ">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-bold">
                  {tripsId && tripsId?.property_title}
                </h3>
                <div className="flex space-x-1">
                  <p className="text-sm text-gray-600 capitalize">
                    {tripsId && tripsId?.property_completeAddress }
                  </p>
                  {/* <p className="text-sm text-gray-600">{tripsId.property_city}</p> */}
                </div>
                <p className="text-sm text-gray-600 flex flex-wrap">
                  Guest Experience Executive: {tripsId && tripsId.executive_name}  ({tripsId && tripsId.executive_number})
                </p>
              </div>
              <div className="w-48 h-32">
                <img
                  src={tripsId && tripsId?.propertyId.picture[0].url}
                  alt={tripsId && tripsId?.property_title}
                  className="rounded-md"
                />
              </div>
            </div>

            <div className="grid grid-cols-3 gap-2 mt-4">
              <div>
                <p className="text-sm text-gray-600 font-medium">CHECK-IN</p>
                <p className="text-base font-bold">
                  {new Date(tripsId && tripsId.check_in_date).toDateString()}
                </p>
                <p className="text-sm text-gray-600">
                  {tripsId && tripsId?.propertyId.check_in_time}
                </p>
              </div>
              <div>
                <p className="text-xs font-medium uppercase">
                  {tripsId && tripsId?.number_of_nights} night stay
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-600 font-medium">CHECK-OUT</p>
                <p className="text-base font-bold">
                  {new Date(tripsId && tripsId?.check_out_date).toDateString()}
                </p>
                <p className="text-sm text-gray-600">
                  {tripsId && tripsId?.propertyId?.check_out_time}
                </p>
              </div>
            </div>

            {/* <div className="mt-4">
              <p className="text-sm font-medium">Room Type</p>
              <p className="text-base font-bold">
                2 × Pool View Suite King with Balcony Bathtub
              </p>
              <p className="text-sm text-green-600">Breakfast Included</p>
            </div> */}
          </div>

          {/* Booking Details Section */}
          <div className="bg-white p-2 md:p-6 rounded-md-t  flex flex-col md:flex-row items-center justify-between">
            <div className="w-full">
              <h3 className="text-lg font-bold mb-4">Booking Details</h3>

              <div className="mt-4">
                <p className="text-sm font-medium">Primary Guest</p>
                <p className="text-base font-bold">
                  {tripsId && tripsId.booker_first_name}
                  <span className="ml-2">
                    {tripsId && tripsId.booker_last_name}
                  </span>
                </p>
                <p className="text-sm text-gray-600">
                  Email: {tripsId && tripsId.booker_email}
                </p>
                <p className="text-sm text-gray-600">
                  Phone: {tripsId && tripsId.booker_mobile}
                </p>
              </div>

              <div className="mt-4">
                <p className="text-sm font-bold">Total Guests</p>
                <p className="text-base font-medium">
                  {`${tripsId && tripsId.adults} adults, ${
                    tripsId && tripsId.children612
                  } children, ${tripsId && tripsId.children05} infant`}
                </p>
              </div>

              {/* <div className="mt-4">
              <p className="text-sm font-medium">Room Details</p>
              <p className="text-base font-bold">
                2 × Pool View Suite King with Balcony Bathtub
              </p>
              <p className="text-sm text-gray-600">Breakfast Included</p>
            </div> */}

              <div className="mt-4">
                <p className="text-sm font-medium">Total Payment</p>
                <p className="text-base font-bold">
                  ₹ {Number(tripsId && tripsId.amount_paid).toLocaleString()}
                </p>
                <p className="text-sm text-gray-600">
                  ⁠Payment Status : Fully Paid or Partially Paid
                </p>
              </div>
            </div>
            <div className="w-full md:w-3/6 flex flex-col items-center justify-end">
              <h3 className="text-lg font-bold">Location</h3>
              <div className="w-full sm:w-full h-48 rounded-md overflow-hidden">
                <MapView propertyId={tripsId && tripsId.propertyId} />
              </div>

              <Link className="mt-5 flex flex-wrap" to={tripsId && tripsId.mapUrl}><span className="font-semibold text-base">Map Url :</span> <span className="font-edium text-base text-red-400">{`${tripsId && tripsId?.mapUrl}`}</span></Link>
            </div>
          </div>

          {/* Map Section */}
        </div>
      )}
    </div>
  );
};

export default TripDetails;

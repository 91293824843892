import {
  CREATE_PLACE_TO_VISIT_REQUEST,
  CREATE_PLACE_TO_VISIT_SUCCESS,
  CREATE_PLACE_TO_VISIT_FAIL,
  GET_ALL_PLACE_TO_VISIT_REQUEST,
  GET_ALL_PLACE_TO_VISIT_SUCCESS,
  GET_ALL_PLACE_TO_VISIT_FAIL,
} from "../../constant/PackagesConstant/PackagesPlaceToVisitConstant";

export const placeToVisitReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PLACE_TO_VISIT_REQUEST:
    case GET_ALL_PLACE_TO_VISIT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PLACE_TO_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PLACE_TO_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        allPlace: action.payload,
      };

    case CREATE_PLACE_TO_VISIT_FAIL:
    case GET_ALL_PLACE_TO_VISIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

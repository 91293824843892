import React from "react";
import { Guest } from "./Guest";

const GuestExeprience = () => {
  return (
    <div className="font-Mont flex flex-col items-center justify-center  pt-10 pb-10 pl-5 pr-5 font-Mont">
      <div className="flex flex-col items-start justify-start">
        <h1 className="font-bold md:font-semibold text-xl p-3 md:p-0">Ultimate Guest Experience</h1>
        <div className="flex flex-wrap md:flex-row items-center justify-center space-y-3 md:space-x-6 pt-5">
          {Guest &&
            Guest.map((guest) => {
              return (
                <div key={guest.id} className="md:w-[250px] h-auto md:h-[300px] space-y-2 p-3 md:p-2 rounded-md shadow border border-md bg-gray-100">
                  <div className="w-[120px] md:w-[90px] h-[110px] md:h-[90px]">
                  <img className="w-full h-full" src={guest.url} alt={guest.title} />
                  </div>
                  <div className="h-[120px] space-y-2">
                  <h1 className="font-bold md:font-semibold text-base capitalize">
                    {guest.title}
                  </h1>
                  <p className="flex text-wrap text-sm font-normal text-justify">
                    {guest.summary}
                  </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default GuestExeprience;

import {
  UPLOAD_THEME_REQUEST,
  UPLOAD_THEME_SUCCESS,
  UPLOAD_THEME_FAIL,
  GET_ALL_THEME_REQUEST,
  GET_ALL_THEME_SUCCESS,
  GET_ALL_THEME_FAIL,
  UPDATE_THEME_REQUEST,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
  GET_THEME_BY_ID_REQUEST,
  GET_THEME_BY_ID_SUCCESS,
  GET_THEME_BY_ID_FAIL,
  UPDATE_THEME_STATUS_REQUEST,
  UPDATE_THEME_STATUS_SUCCESS,
  UPDATE_THEME_STATUS_FAIL,
} from "../../constant/HomeTheme/HomeTheme";

export const HomeThemeReducers = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_THEME_REQUEST:
    case GET_ALL_THEME_REQUEST:
    case UPDATE_THEME_REQUEST:
    case GET_THEME_BY_ID_REQUEST:
    case UPDATE_THEME_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPLOAD_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        theme: action.payload,
      };

    case GET_ALL_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        allTheme: action.payload,
      };

    case UPDATE_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case GET_THEME_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        themeId: action.payload,
      };

    case UPDATE_THEME_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStatus: action.payload,
      };

    case UPLOAD_THEME_FAIL:
    case GET_ALL_THEME_FAIL:
    case UPDATE_THEME_FAIL:
    case GET_THEME_BY_ID_FAIL:
    case UPDATE_THEME_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

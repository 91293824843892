import React, { useEffect, useRef, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa"; // Using react-icons for icons
import { HiUsers } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { addDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { searchProperty } from "../../redux/actions/propertyActions/PropertyAction";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";

const SearchBar = () => {
  const [location, setLocation] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const dropdownRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dropdownDateRef = useRef(null);
  const { propertyId } = useSelector((state) => state.propertyList);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const [checkIn, setCheckIn] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(checkIn);

  useEffect(() => {
    const storedAdultCount = sessionStorage.getItem("adultCount");
    const storedChildCount = sessionStorage.getItem("childCount");

    if (storedAdultCount) {
      setAdultCount(parseInt(storedAdultCount, 10));
    }
    if (storedChildCount) {
      setChildCount(parseInt(storedChildCount, 10));
    }
  }, [setChildCount, setAdultCount]);

  const changeCount = (type, amount) => {
    const newAdultCount = type === "adult" ? adultCount + amount : adultCount;
    const newChildCount = type === "child" ? childCount + amount : childCount;

    if (type === "adult") {
      setAdultCount((prev) => Math.max(1, prev + amount));
      sessionStorage.setItem("adultCount", Math.max(1, newAdultCount));
    } else if (type === "child") {
      setChildCount((prev) => Math.max(0, prev + amount));
      sessionStorage.setItem("childCount", Math.max(0, newChildCount));
    }
  };

  const onHandleClear = (e) => {
    e.preventDefault();
    setAdultCount(1);
    setChildCount(0);
  };

  const toggleDatePicker = () => setIsDatePickerOpen((prev) => !prev);

  const handleClickOutsides = (event) => {
    if (
      dropdownDateRef.current &&
      !dropdownDateRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const calculateMaxDate = () => {
    const startDate = checkIn?.startDate || new Date();
    const maxDays = propertyId?.availability_in_advance || 90; // Default to 90 days
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + maxDays);
    return maxDate;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsides);
    };
  }, []);

 

  useEffect(() => {
    const savedDates = sessionStorage.getItem("checkInDates");
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates).map((range) => ({
        ...range,
        startDate: new Date(range.startDate),
        endDate: new Date(range.endDate),
      }));
      setCheckIn(parsedDates[0]); // Assuming only one range is stored
    }
  }, [setCheckIn]);
  

  useEffect(() => {
    sessionStorage.setItem("checkInDates", JSON.stringify([checkIn]));
  }, [checkIn]);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let max_guests = adultCount + childCount;
    const data = {
      city: location,
      dateFrom: formatDate(checkIn.startDate),
      dateTo: formatDate(checkIn.endDate),
      adults: max_guests,
    };

    dispatch(searchProperty(data));
    navigate(
      `/search?city=${location}&dateFrom=${formatDate(
        checkIn.startDate
      )}&dateTo=${formatDate(checkIn.endDate)}&adults=${max_guests}`
    );
  };

 
  const handleSelect = (ranges) => {
    setCheckIn(ranges.selection);
  };

  useEffect(() => {
    const city = sessionStorage.getItem("location");
    setLocation(city);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("location", location);
  }, [location]);

  return (
    <div className="w-full h-[145px] bg-white rounded-lg shadow-lg pl-6 pr-6 pt-3 pb-5  flex flex-col justify-center font-Mont">
      <h1 className="font-semibold text-lg">Where do you want to go?</h1>
      <form
        className="w-full flex items-center justify-between space-x-4 mt-5"
        onSubmit={handleSubmit}
      >
        {/* Location Selector */}
        <div className="h-12 flex items-center justify-center space-x-2 rounded-md w-3/12 p-2 border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700">
          <FaMapMarkerAlt className="text-gray-500" />
          <select
            className="bg-transparent border-none focus:outline-none text-gray-700 w-full"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="">All Locations</option>
            <option value="north goa">North Goa</option>
            <option value="south goa">South Goa</option>
            <option value="dehradun">Dehradun</option>
            <option value="mussoorie">Mussoorie</option>
            {/* <option value="dehradun">Dehradun</option> */}
            <option value="rishikesh">Rishikesh</option>
            <option value="mukteshwar">Mukteshwar</option>
            <option value="delhi">Delhi</option>
            <option value="jaipur">Jaipur</option>
            <option value="varanasi">Varanasi</option>
          </select>
        </div>
        {/* Date Pickers */}
        <div className="w-[300px]">
          <div>
            <div className="relative" ref={dropdownDateRef}>
              <div
                className="cursor-pointer w-full border border-gray-300 rounded-md"
                onClick={toggleDatePicker}
              >
                <div className="flex w-full h-12 justify-around pt-1 pb-1">
                  <div>
                    <h2 className="font-medium text-xs">Check in</h2>
                    <p className="font-medium text-sm">
                      {checkIn?.startDate?.toDateString()?.slice(0, 10) ||
                        "Select Date"}
                    </p>
                  </div>
                  <div className="border-l"></div>
                  <div>
                    <h2 className="font-medium text-xs">Check out</h2>
                    <p className="font-medium text-sm">
                      {checkIn?.endDate?.toDateString()?.slice(0, 10) ||
                        "Select Date"}
                    </p>
                  </div>
                </div>
              </div>

              {isDatePickerOpen && (
                <div className={`absolute z-10 rounded rounded-lg shadow-lg`}>
                  <div className="flex-1">
                    <DateRange
                      onChange={handleSelect}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={[
                        {
                          startDate: checkIn?.startDate || null,
                          endDate: checkIn?.endDate || null,
                          key: "selection",
                        },
                      ]}
                      direction="horizontal"
                      minDate={new Date()}
                      maxDate={calculateMaxDate()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Guests Selector */}
        <div
          className="relative inline-block text-left w-4/12"
          ref={dropdownRef}
        >
          <div>
            <button
              onClick={toggleDropdown}
              className="inline-flex h-12 justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
            >
              <span className="flex items-center">
                <HiUsers />
                <span className="ml-2 mr-2 font-semibold">
                  {adultCount} Adult(s),
                </span>
                {childCount} Children(s)
              </span>
            </button>
          </div>
          {isOpen && (
            <div className="absolute left-0 right-0 z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between pl-4 pr-5 pt-3 w-full">
                <h2 className="font-medium text-base">Total guests</h2>
                <button
                  className="text-red font-medium text-base"
                  onClick={onHandleClear}
                >
                  Clear
                </button>
              </div>

              <div className="py-2 flex flex-col items-center justify-center">
                <div className="flex justify-between items-center px-4 py-2 w-full">
                  <div className="flex flex-col">
                    <span className="font-medium text-base">Adults </span>
                    <p className="font-medium text-xs opacity-75">
                      Age 13 or above
                    </p>
                  </div>
                  <div className="flex items-center rounded-full border border-gray-200">
                    <button
                      type="button"
                      className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                      onClick={() => changeCount("adult", -1)}
                      disabled={adultCount <= 1}
                    >
                      <FaMinus color="#3FB1BD" />
                    </button>
                    <input
                      type="number"
                      value={adultCount}
                      className="w-[100px] border-transparent text-center text-indigo-600"
                      readOnly
                    />
                    <button
                      type="button"
                      onClick={() => changeCount("adult", 1)}
                      className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                    >
                      <FaPlus color="#3FB1BD" />
                    </button>
                  </div>
                </div>
                <div className="flex justify-between items-center px-4 py-2 w-full">
                  <div className="flex flex-col">
                    <span className="font-medium text-base">Children </span>
                    <p className="font-medium text-xs opacity-75">
                      Age 6 to 12
                    </p>
                  </div>
                  <div className="flex items-center rounded-full border border-gray-200">
                    <button
                      type="button"
                      className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                      onClick={() => changeCount("child", -1)}
                      disabled={childCount <= 0}
                    >
                      <FaMinus color="#3FB1BD" />
                    </button>
                    <input
                      type="number"
                      value={childCount}
                      className="w-[100px] border-transparent text-center text-indigo-600"
                      readOnly
                    />
                    <button
                      type="button"
                      onClick={() => changeCount("child", 1)}
                      className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                    >
                      <FaPlus color="#3FB1BD" />
                    </button>
                  </div>
                </div>

                {/* <button className="mt-4 w-10/12 bg-red-500 text-white p-3 rounded-full hover:bg-red-600">
                  Done
                </button> */}
              </div>
            </div>
          )}
        </div>
        {/* Search Button */}
        <button
          type="submit"
          className="bg-gray-400 text-white px-10 py-2 rounded-lg hover:bg-gray-500 transition"
        >
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchBar;

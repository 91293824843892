
// create order
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"


// get all booked rservation
export const GET_ALL_RESERVATION_REQUEST = "GET_ALL_RESERVATION_REQUEST"
export const GET_ALL_RESERVATION_SUCCESS = "GET_ALL_RESERVATION_SUCCESS"
export const GET_ALL_RESERVATION_FAIL = "GET_ALL_RESERVATION_FAIL"

// get reservation by id
export const GET_RESERVATION_BY_ID_REQUEST = "GET_RESERVATION_BY_ID_REQUEST"
export const GET_RESERVATION_BY_ID_SUCCESS = "GET_RESERVATION_BY_ID_SUCCESS"
export const GET_RESERVATION_BY_ID_FAIL = "GET_RESERVATION_BY_ID_FAIL"

// fatch payment 
export const GET_PAYMENT_SUCCESS_REQUEST = "GET_PAYMENT_SUCCESS_REQUEST"
export const GET_PAYMENT_SUCCESS_SUCCESS = "GET_PAYMENT_SUCCESS_SUCCESS"
export const GET_PAYMENT_SUCCESS_FAIL = "GET_PAYMENT_SUCCESS_FAIL"


// get reservation status
export const GET_RESERVATION_STATUS_REQUEST = "GET_RESERVATION_STATUS_REQUEST"
export const GET_RESERVATION_STATUS_SUCCESS = "GET_RESERVATION_STATUS_SUCCESS"
export const GET_RESERVATION_STATUS_FAIL = "GET_RESERVATION_STATUS_FAIL"


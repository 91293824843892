import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineEmail, MdWatchLater } from "react-icons/md";
import { BiSolidPrinter } from "react-icons/bi";
import { FaUser, FaWhatsapp } from "react-icons/fa6";
import { IoIosHeartEmpty } from "react-icons/io";

const PackageEnquirySmallScreen = ({ packages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [ageToggle, setAgetoggle] = useState(false);

  const createWhatsAppLink = (phone, message) => {
    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
  };

  // discount
  const discountPrice =
    ((packages && packages.price) * Number(packages && packages?.percent)) /
    100;
  const totalDiscount = packages && packages.price - discountPrice;
  //  console.log(discountPrice);
  // console.log(packages);

  const handleClear = () => {
    setAdults(0);
    setChildren(0);
  };

  return (
    <div className="flex items-center justify-center">
      <button
        className="pt-3 pb-3 w-full text-white rounded-t-md  bg-red-500 shadow-md capitalize"
        onClick={() => setIsOpen(true)}
      >
        enquiry now
      </button>

      {/* {isOpen && ( */}
      <div
        className={`fixed z-30 inset-0 bg-black shadow-2xl shadow-black rounded-t-2xl bg-opacity-50 mt-[60px] flex justify-center items-end transition-opacity duration-300 ${
          isOpen ? "opacity-100 visible z-30" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`bg-white pl-4 pr-4 pt-6 rounded-t-2xl shadow-2xl shadow-black  h-full w-full md:w-3/4 transform ${
            isOpen ? "translate-y-0" : "translate-y-full"
          } transition-transform duration-300`}
        >
          <div className="flex items-center justify-between ">
            <h1 className="font-bold text-lg">Enquire now</h1>
            <IoCloseSharp size={25} onClick={() => setIsOpen(false)} />
          </div>
          <div className="mt-5 ">
            <div>
              <h1 className="font-medium text-lg">{packages?.title}</h1>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex items-center ">
                <MdWatchLater size={20} color="gray" />
                <span className="ml-2">{packages && packages.duration}</span>
              </div>
              <div>
                <a
                  href={packages && packages.itinerary_pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiSolidPrinter size={20} />
                </a>
              </div>
            </div>
            <div className="flex items-center justify-between  font-semibold text-base mt-2">
              <div className="flex items-center space-x-2">
                <p> ₹ {Number(totalDiscount?.toFixed())?.toLocaleString()}</p>

                <h1>/</h1>
                <s className="font-semibold text-sm text-red-500">
                  ₹{" "}
                  {Number(
                    Number(packages && packages.price).toFixed()
                  ).toLocaleString()}
                </s>
              </div>
              <div className="bg-red-500 pl-5 pr-5 pt-1 pb-1 rounded-full">
                <h1 className="font-medium text-xs text-white">
                  {" "}
                  Discount {packages && packages?.percent} %
                </h1>
              </div>
            </div>

            <div className="mt-5 relative">
              <div className="border rounded-lg w-full bg-white shadow-md relative">
                <div
                  className="flex justify-between items-center cursor-pointer p-4"
                  onClick={() => setAgetoggle(!ageToggle)}
                >
                  <div className="flex items-center space-x-2">
                    <FaUser className="text-gray-600" />
                    <span className="font-medium">
                      {adults} Adult(s), {children} Children(s)
                    </span>
                  </div>
                </div>
                <div className="absolute w-full bg-white shadow-md rounded-md">
                  {ageToggle && (
                    <div className="p-4">
                      <div className="space-y-4 ">
                        <div className="flex items-center justify-between">
                          <div>
                            <label className="block text-sm font-medium">
                              Adults
                            </label>
                            <span className="text-xs text-gray-500">
                              Age 13 or above
                            </span>
                          </div>
                          <div className="flex items-center mt-1 bg-gray-100 w-1/2 rounded-full px-4 py-2 justify-between">
                            <button
                              onClick={() => setAdults(Math.max(1, adults - 1))}
                              className="text-lg text-gray-500 font-semibold text-lg"
                            >
                              -
                            </button>
                            <span className="text-lg font-semibold text-gray-500">
                              {adults}
                            </span>
                            <button
                              onClick={() => setAdults(adults + 1)}
                              className="text-lg text-gray-500"
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>
                            <label className="block text-sm font-medium">
                              Children
                            </label>
                            <span className="text-xs text-gray-500">
                              Age 6 to 12
                            </span>
                          </div>
                          <div className="flex items-center mt-1 bg-gray-100 w-1/2 rounded-full px-4 py-2 justify-between">
                            <button
                              onClick={() =>
                                setChildren(Math.max(0, children - 1))
                              }
                              className="text-lg text-gray-500"
                            >
                              -
                            </button>
                            <span className="text-lg font-semibold text-gray-500">
                              {children}
                            </span>
                            <button
                              onClick={() => setChildren(children + 1)}
                              className="text-lg text-gray-500"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={handleClear}
                          className="text-red-500 text-sm"
                        >
                          Clear
                        </button>
                        <button
                          onClick={() => setAgetoggle(false)}
                          className="px-6 py-2 bg-red-500 text-white rounded-full"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <h1 className="pt-2 flex items-center">
              <span className="flex items-center justify-center bg-gray-100  w-10 h-10 rounded-full mr-2">
                {" "}
                <IoIosHeartEmpty color="green" />{" "}
              </span>
              <span>Block your seat with INR 9999/-</span>
            </h1>
            <div className="flex items-center justify-center mt-3 space-x-5">
              <a
                href="mailto:info@thealohas.com"
                className="w-full items-center  justify-center rounded border border-red-500"
              >
                <button className="w-full capitalize flex items-center  justify-center shadow-md  pt-2 pb-2 ">
                  <MdOutlineEmail size={20} color="red" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Email
                  </span>
                </button>
              </a>
              <a
                href="tel:+91 9870141315"
                className="w-full items-center justify-center"
              >
                <button className="w-full capitalize flex items-center  justify-center shadow-md  pt-2 pb-2 rounded border border-orange-500">
                  <span className="font-medium text-orange-500  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Call Now
                  </span>
                </button>
              </a>
              <div className="w-full">
                <a
                  className="flex items-center justify-center bg-green-100 shadow-md pl-5 pr-5 pt-2 pb-2 rounded"
                  href={createWhatsAppLink(
                    9870141315,
                    `I would like to enquire about this package \n${
                      packages && packages?.title
                    }, \n${adults} adults, \n${children} children`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp size={20} color="green" />
                  <span className="font-medium  md:font-semibold text-sm md:text-sm ml-1 md:ml-2">
                    Whatsapp
                  </span>
                </a>
              </div>
            </div>

            <a
              href={createWhatsAppLink(
                9870141315,
                `I would like to enquire about this package \n${
                  packages && packages?.title
                }, \n${adults} adults, \n${children} children`
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="fixed bottom-5 right-0 left-0 mt-4  w-full bg-red-500 text-white p-3 rounded-full hover:bg-red-600 uppercase">
                enquiry
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default PackageEnquirySmallScreen;

import {
  GET_ALL_PROERTY_FEATURES_REQUEST,
  GET_ALL_PROERTY_FEATURES_SUCCESS,
  GET_ALL_PROERTY_FEATURES_FAIL,
  CREATE_PROPERTY_FEATURES_REQUEST,
  CREATE_PROPERTY_FEATURES_SUCCESS,
  CREATE_PROPERTY_FEATURES_FAIL,
} from "../../constant/propertyConstant/propertyFeatures";

export const featuresReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PROERTY_FEATURES_REQUEST:
      case CREATE_PROPERTY_FEATURES_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case CREATE_PROPERTY_FEATURES_SUCCESS:
        return{
          ...state,
          loading: false,
          success: action.payload
        }
        
      case GET_ALL_PROERTY_FEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        Allfeatures: action.payload,
      };

    case GET_ALL_PROERTY_FEATURES_FAIL:
      case CREATE_PROPERTY_FEATURES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allPackage } from "../../../redux/actions/PackagesAction/PackagesAction";
import SideMenu from "../sideMenu/SideMenu";
import { Link } from "react-router-dom";

const ListOfPackages = () => {
  const { PackageList, loading } = useSelector((state) => state.packages);
  const dispatch = useDispatch();

  const livePackage = PackageList && PackageList.filter((live) => live.live === "Yes");

  useEffect(() => {
    dispatch(allPackage());
  }, [dispatch]);
  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full p-4">
        <div className="w-full mx-auto">
          <div className="flex items-center space-x-3">
            <h2 className="text-xl font-bold mb-3">
              Packages({PackageList && PackageList?.length}),
            </h2>
            <h className="text-xl font-bold mb-3">
              Live ({livePackage && livePackage?.length} items),
            </h>
            {/*  <h className="text-xl font-bold mb-3">
              Home ({HomeProperty.length} items)
            </h> */}
          </div>
          {/* Responsive Table */}
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="overflow-y-scroll max-h-screen">
              <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
                <thead>
                  <tr className="bg-gray-800 text-white text-left">
                    <th className="py-3 px-4">Property Image</th>
                    <th className="py-3 px-4">Property Title</th>
                    <th className="py-3 px-4">Live (Yes/No) </th>
                    <th className="py-3 px-4">Location ({"city"})</th>
                    <th className="py-3 px-4">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {PackageList?.length > 0 ? (
                    PackageList &&
                    PackageList.map((packages) => (
                      <tr
                        key={packages._id}
                        className="border-t border-gray-200"
                      >
                        <td className="py-4 px-4">
                          <img
                            src={packages?.picture[0]?.url}
                            alt={packages.title}
                            className="w-24 h-24 object-cover rounded-lg"
                          />
                        </td>
                        <td className="py-4 px-4">{packages.title}</td>
                        <td className="py-4 px-4">{packages.live}</td>

                        <td className="py-4 px-4">{packages.origin_city}</td>
                        {/* <td className="py-4 px-4">${packages.price}</td> */}
                        <td className="py-4 px-4">
                          <div className="flex space-x-2">
                            <button className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded">
                              View
                            </button>
                            <Link
                              to={`/dashboard/packages/list/${packages._id}`}
                              className="text-white bg-green-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                            >
                              Edit
                            </Link>
                            {/* <button className="text-red-500 hover:text-red-600 font-semibold">
                        Delete
                      </button> */}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>
                      <h1>Property list empty</h1>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListOfPackages;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLoginByMobile, userMobileVerify } from "../../redux/actions/userAction/userAction";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const PhoneNumberVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const location = useLocation();

  const welcomeText =
    location.pathname === "/login" && !location.search
      ? "Welcome to Da Alohas"
      : "Please verify your mobile number to proceed with booking.";

  const { verifyOTP } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleOtpChange = (e) => setOtp(e.target.value);

  const handleSendOtp = () => {
    if (phoneNumber.length === 10 && /^\d+$/.test(phoneNumber)) {
      const mobile = `+91${phoneNumber}`;
      dispatch(userLoginByMobile({ mobile }));
      setIsOtpSent(true);
      toast.info(`OTP sent to +91${phoneNumber}`);
    } else {
      toast.error("Please enter a valid 10-digit phone number.");
    }
  };

  const redirect = location.search ? location.search.split("=")[1] : "/account-setting/trips";

  const handleVerifyOtp = () => {
    if (otp.length === 6 && /^\d+$/.test(otp)) {
      const mobile = `+91${phoneNumber}`;
      dispatch(userMobileVerify({ mobile, otp }));
      navigate(redirect);
    } else {
      toast.error("Please enter a valid 6-digit OTP.");
    }
  };

  const handleBackToPhoneNumber = () => {
    setIsOtpSent(false);
  };

  useEffect(() => {
    if (verifyOTP) {
      toast.success("OTP verified successfully!");
    }
  }, [verifyOTP, navigate]);

  return (
    <div className="flex justify-center items-center pt-20 h-screen w-full font-Mont">
      <div className="bg-white rounded-lg shadow-lg md:h-5/6 flex flex-col md:flex-row w-11/12 lg:w-8/12">
        <div className="hidden md:block w-full md:w-1/2">
          <img
            src="https://images.unsplash.com/photo-1509233725247-49e657c54213?q=80&w=1949&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Nature"
            className="w-full h-full object-cover rounded-l-lg"
          />
        </div>

        <div className="w-full md:w-1/2 pt-5 pb-5 p-3 md:p-8">
          <h1 className="text-base md:text-xl font-bold mb-4">{welcomeText}</h1>
          <p className="text-lg font-medium mb-2">
            {isOtpSent ? "Verify OTP" : "Login / Signup"}
          </p>
          {isOtpSent ? (
            <>
              <p
                className="text-blue-500 cursor-pointer underline mb-2"
                onClick={handleBackToPhoneNumber}
              >
                +91 {phoneNumber}
              </p>
              <label className="block text-sm font-medium text-gray-700 mb-2">Enter OTP</label>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={handleOtpChange}
                maxLength="6"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
              />
              <button
                onClick={handleVerifyOtp}
                className="w-full mt-4 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white"
              >
                Verify OTP
              </button>
            </>
          ) : (
            <>
              <label className="block text-sm font-medium text-gray-700 mb-2">Enter Mobile Number</label>
              <input
                type="text"
                placeholder="Enter Mobile Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                maxLength="10"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
              />
              <button
                onClick={handleSendOtp}
                className="w-full mt-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white"
              >
                Send OTP
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberVerification;


/*
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMoreDetails,
  userLoginByMobile,
  userMobileVerify,
} from "../../redux/actions/userAction/userAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PhoneNumberVerification() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const { users, verifyOTP, addUserDetails } = useSelector(
    (state) => state.user
  );
  // console.log(users,verifyOTP,addUserDetails)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleCountryCodeChange = (e) => setCountryCode(e.target.value);
  const handleUserDetailsChange = (e) =>
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });

  const handleSendOtp = () => {
    if (phoneNumber.length === 10) {
      setIsOtpSent(true);
      const mobiledata = `${countryCode}${phoneNumber}`;
      const mobile = { mobile: mobiledata };
      dispatch(userLoginByMobile(mobile));
      alert(`OTP sent to ${countryCode}${phoneNumber}`);
    } else {
      alert("Please enter a valid 10-digit phone number.");
    }
  };

  const handleVerifyOtp = () => {
    const mobiledata = `${countryCode}${phoneNumber}`;
    const verifyMobile = { mobile: mobiledata, otp };

    // Simulate OTP verification
    dispatch(userMobileVerify(verifyMobile));
    alert("OTP Verified!");
    setIsVerified(true);
  };

  const handleSubmitDetails = () => {
    const mobiledata = `${countryCode}${phoneNumber}`;
    const userData = {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      mobile: mobiledata,
    };
    console.log("User Details Submitted: ", userData);
    dispatch(addMoreDetails(userData));
    alert("User details submitted successfully!");
  };

  useEffect(() => {
    if (verifyOTP && verifyOTP.profileIncomplete) {
      // Show profile form
      setIsVerified(true);
    } else if (verifyOTP) {
      // Redirect to home if profile is complete
      navigate("/home");
    }
  }, [verifyOTP]);

  useEffect(() => {
    if (users) {
      toast.success(users);
    }

    if (verifyOTP) {
      toast.success("verifyed successfull");
    }

    if (addUserDetails) {
      toast.success(addUserDetails.message);
    }
  }, [dispatch, users, verifyOTP, addUserDetails]);

  return (
    <div className="flex justify-center items-center pt-20 h-screen w-full">
      <div className="bg-white rounded-lg shadow-lg md:h-5/6 flex flex-col md:flex-row w-11/12 lg:w-8/12">
        {/* Image Section */

/*
        <div className="w-full md:w-1/2">
          <img
            src="https://images.unsplash.com/photo-1509233725247-49e657c54213?q=80&w=1949&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Nature"
            className="w-full h-full object-cover rounded-l-lg"
          />
        </div>
        {/* Form Section */
/*
        <div className="w-full md:w-1/2 p-8">
          <h1 className="text-2xl font-bold mb-4">Welcome to Da Alohas</h1>
          {!isVerified ? (
            <>
              <p className="text-lg font-medium mb-2">
                {isOtpSent ? "Verify OTP" : "Login / Signup"}
              </p>
              {!isOtpSent ? (
                <>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Enter your 10-digit mobile number
                  </label>
                  <div className="flex items-center border rounded-lg overflow-hidden">
                    <select
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      className="border-none px-3 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      <option value="+91">+91 IN</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Mobile Number*"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength="10"
                      className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                    />
                  </div>
                  <button
                    onClick={handleSendOtp}
                    className={`w-full mt-6 py-2 rounded-lg ${
                      phoneNumber.length === 10
                        ? "bg-indigo-500 hover:bg-indigo-600 text-white"
                        : "bg-gray-300 text-gray-500"
                    }`}
                    disabled={phoneNumber.length !== 10}
                  >
                    Send OTP
                  </button>
                </>
              ) : (
                <>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Enter 6-digit OTP sent to {countryCode} {phoneNumber}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    maxLength="6"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <button
                    onClick={handleVerifyOtp}
                    className="w-full mt-6 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white"
                  >
                    Verify OTP
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <h2 className="text-lg font-medium mb-2">
                Complete Your Profile
              </h2>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleUserDetailsChange}
                placeholder="First Name"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
              />
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleUserDetailsChange}
                placeholder="Last Name"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
              />
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={userDetails.email}
                onChange={handleUserDetailsChange}
                placeholder="Email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
              />
              <button
                onClick={handleSubmitDetails}
                className="w-full py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white"
              >
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberVerification;
*/

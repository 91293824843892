import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "45vh",
};

// const center = {
//   lat: 7.2905715, // default latitude
//   lng: 80.6337262, // default longitude
// };

const YOUR_API_KEY = "AIzaSyASDBMuaSVYTM5y-FRwdDbfsEK-fc4UfXk";

const MapView = ({ propertyId }) => {
  // console.log(propertyId);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: YOUR_API_KEY,
    libraries,
  });

  let center = {
    lat: Number(propertyId?.latitude),
    lng: Number(propertyId?.longitude),
  };



  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  // const mapdata = fetch(
  //   `https://www.google.com/maps/embed/v1/view?key=${YOUR_API_KEY}&center=${center.lat,center.lng}&zoom=18&maptype=satellite`
  // ).then((res)=>{
  //   console.log(res)
  // })
  // console.log(mapdata);


  return (
    <div >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
};

export default MapView;

import axios from "axios";
import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  GET_PAYMENT_SUCCESS_FAIL,
  GET_PAYMENT_SUCCESS_REQUEST,
  GET_PAYMENT_SUCCESS_SUCCESS,
  GET_ALL_RESERVATION_FAIL,
  GET_ALL_RESERVATION_REQUEST,
  GET_ALL_RESERVATION_SUCCESS,
  GET_RESERVATION_BY_ID_FAIL,
  GET_RESERVATION_BY_ID_REQUEST,
  GET_RESERVATION_BY_ID_SUCCESS,
  GET_RESERVATION_STATUS_REQUEST,
  GET_RESERVATION_STATUS_FAIL,
  GET_RESERVATION_STATUS_SUCCESS,
} from "../../constant/createOrderConstant/createOrder";

export const createOrder = (orderData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ORDER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/v1/reservation`, orderData, config);

    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get payment succes
export const fetchPayment = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAYMENT_SUCCESS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/v1/payment-response/${orderId}`,
      config
    );

    dispatch({
      type: GET_PAYMENT_SUCCESS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_PAYMENT_SUCCESS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all reservation
export const getAllReservation = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_RESERVATION_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get("/api/v1/reservation/me", config);

    dispatch({
      type: GET_ALL_RESERVATION_SUCCESS,
      payload: data.newReservation,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_RESERVATION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get reservation by id
export const getReservationId = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESERVATION_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/reservation/${id}`);
    dispatch({
      type: GET_RESERVATION_BY_ID_SUCCESS,
      payload: data.reservationId,
    });
  } catch (error) {
    dispatch({
      type: GET_RESERVATION_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get reservation by status
export const getReservationByStatus = (reservationId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESERVATION_STATUS_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/v1/reservation-status/${reservationId}`,
      config
    );
    dispatch({
      type: GET_RESERVATION_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_RESERVATION_STATUS_FAIL,
      payload: error.response.data.message,
    });
  }
};

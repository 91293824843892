import React, { Fragment, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SideBar from "./SideBar";
import Profile from "./Profile";
import Trips from "./Trips";
import TripDetails from "./TripsDetails";
import MetaData from "../MetaData";

const UserDashboard = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check the screen size on load and on resize
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); 
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Fragment>
      <div className="w-full  flex flex-col md:flex-row h-screen md:h-auto pt-20 pb-10 bg-gray-100">
      <MetaData title={`User profile by Da alohas`}/>
        {/* Sidebar */}
        <SideBar />

        {/* Main Content Area */}
        <div className="flex-1 md:p-4 h-auto absolute z-10 top-10 left-0  right-0 md:static md:block ">
          <Routes>
            {/* Conditionally render default route */}
            {!isSmallScreen && (
              <Route path="/" element={<Navigate to="trips" />} />
            )}
            <Route path="profile" element={<Profile />} />
            <Route path="trips" element={<Trips />} />
            <Route path="trips/:id" element={<TripDetails />} />
          </Routes>
        </div>
      </div>
    </Fragment>
  );
};

export default UserDashboard;

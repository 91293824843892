import Luxury_Homes from "../../image/villa.png";
import Hospitality from "../../image/hospitality.png";
import Guest_Experience from "../../image/guest.png";
import Hassle_Free from "../../image/hassle-free.png";

export const Guest = [
  {
    id: 0,
    url: Luxury_Homes,
    title: "Exclusive and Affordable Luxury Homes",
    summary:
      "Da Alohas features handpicked, high-end and affordable luxury properties designed for ultimate comfort and style. Enjoy top-tier amenities and unique vacation experiences for everyone.",
  },
  {
    id: 1,
    url: Hospitality,
    title: "Ultimate Hospitality",
    summary:
      "Expert Guest Experience Execs and Housekeeping teams with 5 star hospitality experience delivers ultimate stay experience.",
  },
  {
    id: 2,
    url: Guest_Experience,
    title: "Personalized Guest Experience and Concierge",
    summary:
      "From booking to check-out, Da Alohas offers personalized services to suit your needs. Every detail is carefully managed to ensure a memorable stay.",
  },

  {
    id: 3,
    url: Hassle_Free,
    title: "Hassle-Free Stays",
    summary:
      "With seamless check-ins, Da Alohas handles everything so you can fully relax. Enjoy a stress-free vacation from start to finish. Focus on making memories, not managing logistics.",
  },
];

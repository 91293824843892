import React from "react";
import { LiaMapMarkerSolid } from "react-icons/lia";
import { CiUser } from "react-icons/ci";
// import SimpleImageSlider from "react-simple-image-slider";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { MdOutlineBathroom, MdOutlineBedroomParent } from "react-icons/md";
import { FaBed } from "react-icons/fa6";
import { Carousel } from "flowbite-react";

const PropertyCard = ({ property }) => {
  // console.log(property);
  const navigate = useNavigate();

  // const showMoreText = (item) => {
  //   if (item?.length <= 42) return item;

  //   if (item?.length > 42) {
  //     return (
  //       <>
  //         <p>{item.slice(0, 42)}</p>
  //       </>
  //     );
  //   }
  // };

  function myFunction(summary) {
    if (summary.length > 40) {
      summary = summary.substring(0, 40) + "...";
    }
    return summary;
  }

  // const OnHandleImage = (e) => {
  //   e.stopPropagation();
  //   navigate(`/${property && property?.slug}`);
  // };

  const onHanldeImage = () => {
    navigate(`/property/${property.slug}`);
  };

  // console.log("property?.picture ", property?.picture);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="w-auto m-2 rounded-md shadow-lg shadow-indigo-300 bg-white">
      <div>
        {/* <div className="hidden md:block">
          <SimpleImageSlider
            images={property?.picture && property?.picture}
            showNavs={true}
            navSize={30}
            //   showBullets={true}
            width={370}
            height={300}
            onClick={() => onHanldeImage()}
          />
        </div> */}
        <div className="h-56 h-[250px] hidden md:block">
          <Carousel slide={false} indicators={false}>
            {property.picture && 
              property.picture.map((item) => {
                return (
                  <div
                    className="w-full flex items-center justify-center h-[250px] cursor-pointer"
                    key={item._id}
                  >
                    <img
                      key={item._id}
                      className="w-full h-full"
                      src={item.url}
                      onClick={() => onHanldeImage()}
                      alt={property?.title}
                      loading="lazy"
                    />
                  </div>
                );
              })}
          </Carousel>
        </div>

        <div className="w-[350px] h-[250px] block md:hidden rounded-md overflow-hidden relative">
          <Slider {...settings}>
            {property.picture &&
              property.picture.map((item) => {
                return (
                  <div
                    className="w-full flex items-center justify-center h-[250px] cursor-pointer"
                    key={item._id}
                  >
                    <img
                      key={item._id}
                      className="w-full h-full"
                      src={item.url}
                      onClick={() => onHanldeImage()}
                      alt={property?.title}
                      loading="lazy"
                    />
                  </div>
                );
              })}
          </Slider>
          <div className="w-[350px] absolute bottom-5   flex items-center justify-center space-x-1">
            <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
            <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
            <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
            <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
          </div>
        </div>

        {/* <img
          alt=""
          src={property.picture[0].url}
          className="h-72 w-full rounded-md object-cover"
        /> */}
        <Link
          Link
          to={`/property/${property?.slug}`}
          className="block rounded-lg pl-4 pr-4 pb-4 pt-3  w-[350px] md:w-[370px] "
        >
          <div className="flex flex-col justify-between h-[150px]">
            <dl>
              <div className="">
                <dt className="sr-only">name</dt>
                <dd className="font-medium">
                  {myFunction(property && property?.title)}
                </dd>
              </div>

              <div className="mt-2">
                <dt className="sr-only">Address</dt>
                <dd className="text-sm text-gray-500 flex items-center ">
                  <LiaMapMarkerSolid size={20} />
                  <span className="ml-1">{property?.Address}</span>
                </dd>
              </div>
            </dl>

            <div className=" flex flex-wrap justify-between  bg-white  rounded-lg">
              {/* Beds */}
              <div className="flex items-center space-x-1">
                <CiUser className="size-4 text-indigo-700" />
                <span className="text-gray-700 text-xs font-medium">
                  {property?.max_guests} Guests
                </span>
              </div>

              {/* Washrooms */}
              <div className="flex items-center space-x-1">
                <MdOutlineBathroom className="size-4 text-indigo-700" />
                <span className="text-gray-700 text-xs font-medium">
                  {property?.accomodation_washrooms} Bathroom
                </span>
              </div>

              {/* Bedrooms */}
              <div className="flex items-center space-x-1">
                <MdOutlineBedroomParent className="size-4 text-indigo-700" />
                <span className="text-gray-700 text-xs font-medium">
                  {property?.accomodation_bedrooms} Bedrooms
                </span>
              </div>

              {/* Guests */}
              <div className="flex items-center space-x-1">
                <FaBed className="size-4 text-indigo-700" />
                <span className="text-gray-700 text-xs font-medium">
                  {property?.accomodation_beds} beds
                </span>
              </div>
            </div>

            <div className="outline-dashed outline-[1px] w-full  bg-black"></div>
            <div className="flex items-center justify-between ">
              <div>
                <h1 className="text-xs font-medium">Starting from</h1>
                <div>
                  <h1>₹ {property && property?.starting_from_price}</h1>
                </div>
              </div>
              <div>
                <button className="pl-5 pr-5 pt-2 pb-2 rounded-full font-medium text-xs border border-gray-500 text-gray-500">
                  View Details
                </button>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PropertyCard;

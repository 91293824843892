// create amenities type
export const CREATE_AMENITIES_TYPE_REQUEST = "CREATE_AMENITIES_TYPE_REQUEST"
export const CREATE_AMENITIES_TYPE_SUCCESS = "CREATE_AMENITIES_TYPE_SUCCESS"
export const CREATE_AMENITIES_TYPE_FAIL = "CREATE_AMENITIES_TYPE_FAIL"

// get all amenities type

export const GET_ALL_AMENITIES_TYPE_REQUEST = "GET_ALL_AMENITIES_TYPE_REQUEST"
export const GET_ALL_AMENITIES_TYPE_SUCCESS = "GET_ALL_AMENITIES_TYPE_SUCCESS"
export const GET_ALL_AMENITIES_TYPE_FAIL = "GET_ALL_AMENITIES_TYPE_FAIL"


// get amenities type of id
export const GET_AMENITIES_BY_ID_REQUEST = "GET_AMENITIES_BY_ID_REQUEST"
export const GET_AMENITIES_BY_ID_SUCCESS = "GET_AMENITIES_BY_ID_SUCCESS"
export const GET_AMENITIES_BY_ID_FAIL = "GET_AMENITIES_BY_ID_FAIL"


// update amenities type 
export const UPDATE_AMENITIES_TYPE_REQUEST = "UPDATE_AMENITIES_TYPE_REQUEST"
export const UPDATE_AMENITIES_TYPE_SUCCESS = "UPDATE_AMENITIES_TYPE_SUCCESS"
export const UPDATE_AMENITIES_TYPE_FAIL = "UPDATE_AMENITIES_TYPE_FAIL"

// delete amenities type 
export const DELETE_AMENITIES_TYPE_REQUEST =  "DELETE_AMENITIES_TYPE_REQUEST"
export const DELETE_AMENITIES_TYPE_SUCCESS =  "DELETE_AMENITIES_TYPE_SUCCESS"
export const DELETE_AMENITIES_TYPE_FAIL =  "DELETE_AMENITIES_TYPE_FAIL"


export const HomeOwner = [
  {
    id: 0,
    title: "Hassle-Free Ownership",
    summary:
      "Say goodbye to the headaches of managing bookings, handling guests, and organizing maintenance. Da Alohas ensures your property is expertly cared for, allowing you to enjoy the benefits of vacation homeownership without the hassle.",
  },
  {
    id: 1,
    title: "Effortless Property Management",
    summary:
      "Da Alohas take care of everything—from regular maintenance and cleaning to booking management—ensuring your property is always in top shape and ready to welcome guests or you, at any time.",
  },
  {
    id: 2,
    title: "Maximized Earnings",
    summary:
      "With our expert marketing strategies and presence on top booking platforms, we ensure your property reaches the right audience. We make sure your home is booked consistently and at competitive rates, increasing your rental income.",
  },
  {
    id: 3,
    title: "Transparent & Trusted Partnership",
    summary:
      "At Da Alohas, we believe in complete transparency with our partners with monthly payouts. We provide regular updates on your property&#39;s performance, detailed financial reports, and clear communication at every step.",
  },
  {
    id: 4,
    title: "Unlimited Personal Use",
    summary:
      "Whether it’s a weekend getaway or an extended retreat, your property is always available for your personal use. With Da Alohas, you never have to choose between earning and enjoying your holiday home. Your home is always open for you.",
  },
  {
    id: 4,
    title: "Alohas Points",
    summary:
      "Earn Alohas Points with every booking made through Da Alohas. These points can be redeemed for stays at other Da Alohas-managed properties, giving you the flexibility to explore different destinations. Enjoy the luxury of choice and variety while maximizing the benefits of your investment.",
  },
];

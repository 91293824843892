import Airbnb from "../../image/airbnb.png"
import Google from "../../image/google.png"

export const ReviewObj = [
  {
    id: 0,
    name: "Sujal",
    date: "January 9, 2025",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "I had a wonderful stay here with my family. The amenities and pleasant surroundings made the experience truly enjoyable. If you're unsure, I recommend giving it a try!",
  },
  {
    id: 1,
    name: "Subbu",
    date: "January 2, 2025",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment: "Super room",
  },
  {
    id: 2,
    name: "Digvijay Singh", 
    date: "2024",
    rating: 5,
    logo: Google,
    url: "https://www.google.com/search?q=arvelia+by+da+alohas#lrd=0x3908d100726b11e7:0x7e12c36ae39276dc,1",
    comment: "We stayed in arvelia heritage which is almost 100 years old property and believe me our stay was amazing, the food was delicious , there staffed welcomed us very well, there gesture was so polite and always ready to help us, the view from the property is breath taking. You don't get to see this type of view from any other property in mussoorie. I'll definitely come again its worth the price.",
  },
  {
    id: 3,  
    name: "Lalit",
    date: "December 31, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "We were visiting Goa for the first time so we’re not familiar with the area, so selected Calangute as our destination due to the central location. If I go back, I would either stay in the far north or far south. Regardless, the Airbnb is in very good central location with walkable restaurant (Coconut curry) with a decent food selection if you do not have any other choice. You definitely need a car or access to a driver to visit the local Calingut market, you cannot walk at night. Due to the Taxi Mafia, there is no Uber or OLA service. Only “GoaMiles” app works which I used once but is not user friendly. Ask the host, Akshay and he arranged a great Taxi (His name was Vijay) who showed us the entire Goa and made our Goa experience worth staying. He also charged us reasonably so I highly recommend him. The host has many properties in Goa so take a look and select that fits your needs and budget. I like our stay at the property. We had minor issues which were solved by the host and team.",
  },
  {
    id: 4,
    name: "Rohan",
    date: "December 31, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Definitely worth staying again in this property. 100% felt like home.",
  },
  {
    id: 5,
    name: "hrishikesh bhagat",
    date: "2024",
    rating: 5,
    logo: Google,
    url: "https://bit.ly/4jM6bVB",
    comment:
      "Great place for family.",
  },
  {
    id: 6,
    name: "Avinash",
    date: "December 5, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "The place was beautiful, well kept and organized with enough kitchen utensils. Cleaning staff was prompt and helpful.",
  },
  {
    id: 7,
    name: "Jay",
    date: "December 1, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "We had an amazing stay!! Super aesthetic and clean place! all the appliances were there to make it easy!",
  },
  {
    id: 8,
    name: "Andy",
    date: "November 29, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "It was a good listing, The host accommodated us well. It's a Super Cozy place. Loved it!",
  },
  {
    id: 9,
    name: "Nisha",
    date: "November 17, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "We thoroughly enjoyed our stay here. The unit itself was clean, with great amenities. The view from the rooms was lovely and the team were super helpful and friendly. The locality was quaint and peaceful. It felt like home away from home :) I’d definitely come back just to stay here.",
  },
  {
    id: 10,
    name: "Varun",
    date: "November 9, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Honestly, this place left me feeling much better than when I stayed at the Taj the other day. i mean it felt right at home the cleanliness was awesome the features were good 2 tvs kitchen had everything one might need the beds were comfi and the place is better than what the pictures show. amazing for a family of 4-5 and if boys then i guess even 7 boys can manage here easily. pool is decent gym also decent for the price its all worth it .",
  },
  {
    id: 11,
    name: "Meghna",
    date: "November 1, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Host Sumit is very jam of person. He was at the room. He personally came to explain all the system of room. they have got everything high techsystem in the house. Very Neat and Clean house. All the system functions well. got 2 balcony in the house. Washroom are near and clean. Bed and Matress are very good quality. Perfect for family staycation.",
  },
  {
    id: 12,
    name: "Bharat",
    date: "October 21, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Great place, absolutely stunningly maintained, and the host was super helpful and very friendly , was available all time when needed, was shocked to see the place as shown in the pictures lovely stay will recommend one of my best stays in goa",
  },
  {
    id: 13,
    name: "Ansh",
    date: "October 5, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment: "Greatt!!",
  },
  {
    id: 14,
    name: "Shital",
    date: "September 30, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "The apartment was exactly as shown in the images and the experience was up to our expectations. It was cozy, luxurious and well equipped with everything one needs in a luxurious accommodation and more. I would definitely recommend this to anyone who asks for stays in Goa.",
  },
  {
    id: 15,
    name: "Shaket",
    date: "October 29, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment: "Nice location and extremely well maintained place.",
  },
  {
    id: 16,
    name: "Vivek",
    date: "September 22, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "House is beautifully decorated. The appliances are all high quality and top brand. Starting from door lock to bathroom exhaust fan everything is modernised. The rooms are clean and comfy. The location is super. Close to all the imp beache of Goa. Super stay. Super host.",
  },
  {
    id: 17,
    name: "Dhruval",
    date: "October 20, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment: "Great stay to live",
  },
  {
    id: 18,
    name: "Gunjan",
    date: "September 16, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Our host was mayur, and he is the best host we’ve ever met. Helpful, responsive & cooperative. It was amazing staying at this house.",
  },
  {
    id: 19,
    name: "Sutapa",
    date: "September 9, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "I and my two friends wanted to spend some quiet time together, talking, listening to music, generally relaxing while sipping tea/coffee, watching the rains fall over green fields and occasionally going out to the beach. We didn't want too much activities or noise around us. ( The beach (Calangute) is about 2 km, easily walkable through tree lined paths.. multiple rental options also available. ) This place turned out just perfect for us! This uber luxe flat felt just like home.Comfortable, clean, peaceful with a splendid view of green fields and coconut trees. The kitchen was very well equipped. Bathrooms were squeaky clean. There was ample space for us to lounge around. The huge flat screen in the living room was like a bonus we loved enjoying! And the refreshing pool surrounded by exotic tropical plants was amazing! Mayur was an excellent and helpful host. We would certainly like to stay here again! Worth the price!! Need I say more?",
  },
  {
    id: 20,
    name: "Arshbir",
    date: "August 19, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "We stayed for a couple of days. The place is as good if not better than what was advertised. Host was very proactive in making sure our stay was smooth. All the facilities were spick and span. Would totally recommend!",
  },
  {
    id: 21,
    name: "Pranav",
    date: "August 17, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment:
      "Loved the place and Sumit was very helpful and responsive at all times. Recommend this place.",
  },
  {
    id: 22,
    name: "Aditi",
    date: "August 6, 2024",
    rating: 5,
    logo: Airbnb,
    url: "https://www.airbnb.co.in/users/show/409092406",
    comment: "Great house!",
  },
];

import React, { useEffect } from "react";
import { getAllPackageEnquiry } from "../../../../redux/actions/PackagesAction/PackageEnquiryAction";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../sideMenu/SideMenu";
import Loader from "../../../Loader";

const PackageEnquiry = () => {
  const { allEnquiry, loading } = useSelector((state) => state.packageEnquiry);
//   console.log(allEnquiry);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPackageEnquiry());
  }, [dispatch]);
  return (
    <div className="flex pt-20 w-full font-Mont">
      <SideMenu />
      <div className="w-full">
        <div className="p-6">
          <h1 className="font-bold text-lg">Package Enquiry</h1>
          <div className="overflow-x-auto overflow-y-scroll h-screen">
            <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {/* <th className="px-6 py-3 text-left text-gray-600">ID</th> */}
                  <th className="px-6 py-3 text-left text-gray-600">Name</th>
                  <th className="px-6 py-3 text-left text-gray-600">Mobile</th>
                  <th className="px-6 py-3 text-left text-gray-600">
                    No. Adilts
                  </th>
                  <th className="px-6 py-3 text-left text-gray-600">Queries</th>
                  <th className="px-6 py-3 text-left text-gray-600">Preferred Date</th>
                  <th className="px-6 py-3 text-left text-gray-600">CreatedAt</th>
                </tr>
              </thead>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                allEnquiry &&
                allEnquiry.map((enq,index) => {
                  return (
                    <tbody>
                      <tr className="border-b hover:bg-gray-50" key={index}>
                        {/* <td className="px-6 py-3">{enq._id}</td> */}
                        <td className="px-6 py-3">{enq.name}</td>
                        <td className="px-6 py-3">{enq.mobile}</td>
                        <td className="px-6 py-3">{enq.adults}</td>
                        <td className="px-6 py-3">{enq.queries}</td>
                        <td className="px-6 py-3">{new Date(enq && enq.date).toDateString()}</td>
                        <td className="px-6 py-3">
                          {new Date(enq.createdAt).toDateString()}
                        </td>
                        {/* <td className="px-6 py-3">
                      <button className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
                        Edit
                      </button>
                      <button className="ml-2 px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                        Delete
                      </button>
                    </td> */}
                      </tr>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageEnquiry;

import axios from "axios";
import {
  CREATE_PACKAGE_CATEGORY_REQUEST,
  CREATE_PACKAGE_CATEGORY_SUCCESS,
  CREATE_PACKAGE_CATEGORY_FAIL,
  GET_ALL_PACKAGE_CATEGORY_FAIL,
  GET_ALL_PACKAGE_CATEGORY_REQUEST,
  GET_ALL_PACKAGE_CATEGORY_SUCCESS,
//   UPDATE_PACKAGE_CATEGORY_FAIL,
//   UPDATE_PACKAGE_CATEGORY_REQUEST,
//   UPDATE_PACKAGE_CATEGORY_SUCCESS,
  UPDATE_PACKAGE_CATEGORY_STATUS_SUCCESS,
  UPDATE_PACKAGE_CATEGORY_STATUS_FAIL,
  UPDATE_PACKAGE_CATEGORY_STATUS_REQUEST,
} from "../../constant/PackagesConstant/PackageCategoryConstant";

export const createPackageCategory = (packageCategory) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PACKAGE_CATEGORY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/create-package-category",
      packageCategory,
      config
    );

    dispatch({
      type: CREATE_PACKAGE_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PACKAGE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all package category
export const getAllPackageCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PACKAGE_CATEGORY_REQUEST,
    });

    const { data } = await axios.get("/api/v1/get-all-package-category");
    dispatch({
      type: GET_ALL_PACKAGE_CATEGORY_SUCCESS,
      payload: data.allcategory,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PACKAGE_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update package category
export const updatePackageCategoryStatus = (categoryUpdate) => async (dispatch) => {   
    try {
      dispatch({
        type: UPDATE_PACKAGE_CATEGORY_STATUS_REQUEST,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      // Send API request
      const { data } = await axios.put(
        `/api/v1/update-package-category-status`,
        categoryUpdate,
        config
      );
  
      dispatch({
        type: UPDATE_PACKAGE_CATEGORY_STATUS_SUCCESS,
        payload: data,
      });
  
    } catch (error) {
      dispatch({
        type: UPDATE_PACKAGE_CATEGORY_STATUS_FAIL,
        payload: error.response?.data?.message || "Something went wrong",
      });
    }
  };
  

import axios from "axios";
import {
  CREATE_PROPERTY_SPACES_REQUEST,
  CREATE_PROPERTY_SPACES_SUCCESS,
  CREATE_PROPERTY_SPACES_FAIL,
  GET_ALL_PROPERTY_SPACES_REQUEST,
  GET_ALL_PROPERTY_SPACES_SUCCESS,
  GET_ALL_PROPERTY_SPACES_FAIL,
} from "../../constant/propertyConstant/propertySpacesConstant";

export const createSpaces = (spaces) =>async(dispatch)=>{
    try {
        dispatch({
            type: CREATE_PROPERTY_SPACES_REQUEST
        })

        const {data} = await axios.post("/api/v1/create-space",spaces)
        dispatch({
            type: CREATE_PROPERTY_SPACES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_PROPERTY_SPACES_FAIL,
            payload: error.response.data.message
        })
    }
}

// get proeperty spaces
export const getAllSpaces = () =>async(dispatch)=>{
    try {
        dispatch({
            type:GET_ALL_PROPERTY_SPACES_REQUEST
        })

        const {data} = await axios.get('/api/v1/space-list');
        dispatch({
            type: GET_ALL_PROPERTY_SPACES_SUCCESS,
            payload: data.allSpaces
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_PROPERTY_SPACES_FAIL,
            payload: error.response.data.message
        })
    }
}
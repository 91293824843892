import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PackagesCard from "./PackagesCard";
import { allPackage } from "../../redux/actions/PackagesAction/PackagesAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { createpackageEnquiry } from "../../redux/actions/PackagesAction/PackageEnquiryAction";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import Banner from "../image/kedarnathbg.webp"

const MultiTypePackage = () => {
  //   const { packageSlug } = useSelector((state) => state.packages);
  const { PackageList, loading } = useSelector((state) => state.packages);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  // console.log()
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    numberOfAdults: "",
    queries: "",
    date:"",
  });
  const { success, error } = useSelector((state) => state.packageEnquiry);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));

    // Remove error message when user starts typing
    setErrors((prev) => ({ ...prev, [id]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.phoneNumber.trim())
      newErrors.phoneNumber = "Phone number is required";
    if (!formData.numberOfAdults.trim())
      newErrors.numberOfAdults = "Number of adults is required";
    if (!formData.queries.trim()) newErrors.queries = "Queries cannot be empty";
    if (!formData.date.trim()) newErrors.date = "Select your preferred date";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("mobile", formData.phoneNumber);
    formdata.append("adults", formData.numberOfAdults);
    formdata.append("queries", formData.queries);
    formdata.append("date", formData.date);
  

    dispatch(createpackageEnquiry(formdata));

    // console.log("Form Data:", formData);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  //   console.log(slug)
  //   console.log(PackageList)

  useEffect(() => {
    if (success) {
      toast.success("Enquiry send");
      //   setEnquiry(false)
    }

    if (error) {
      toast.error(error);
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    // dispatch(getPackageBySlug(slug));
    dispatch(allPackage());
  }, [dispatch, slug]);
  return (
    <div>
      <div>
        <div className="relative w-full flex items-center justify-center">
          <div
            className={`fixed bottom-0 left-0  right-0 z-40 bg-transparent  shadow-lg w-full flex  justify-center  transition-transform duration-700 ${
              isVisible ? "translate-y-0" : "translate-y-full"
            }`}
          >
            <div className="w-full md:w-5/12 bg-white">
              <div className="w-full p-10 font-Mont">
                <div className="flex items-center justify-between border-b pb-5">
                  <h1 className="font-semibold text-base">Enquire now</h1>
                  <IoMdClose
                    className="cursor-pointer"
                    size={25}
                    onClick={() => {
                      // console.log("hello")
                      setIsVisible(false);
                    }}
                  />
                </div>
                <form
                  className="flex flex-col gap-1 mt-3"
                  onSubmit={handleSubmit}
                >
                  <div>
                    <Label htmlFor="name" value="Name" className="mb-2 block" />
                    <TextInput
                      id="name"
                      type="text"
                      shadow
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>

                  <div>
                    <Label
                      htmlFor="phoneNumber"
                      value="Mobile Number"
                      className="mb-2 block"
                    />
                    <TextInput
                      id="phoneNumber"
                      type="number"
                      shadow
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>

                  <div className="flex ">
                    <div className="w-full">
                      <Label
                        htmlFor="numberOfAdults"
                        value="No of Adults"
                        className="mb-2 block"
                      />
                      <TextInput
                        id="numberOfAdults"
                        type="number"
                        shadow
                        value={formData.numberOfAdults}
                        onChange={handleChange}
                      />
                      {errors.numberOfAdults && (
                        <p className="text-red-500 text-sm">
                          {errors.numberOfAdults}
                        </p>
                      )}
                    </div>

                    <div className="w-full ml-2">
                      <Label
                        htmlFor="numberOfAdults"
                        value="Preferred date"
                        className="mb-2 block"
                      />
                      <TextInput
                        id="date"
                        type="date"
                        shadow
                        value={formData.date}
                        onChange={handleChange}
                      />
                      {errors.date && (
                        <p className="text-red-500 text-sm">{errors.date}</p>
                      )}
                    </div>
                  </div>

                  <div>
                    <Label
                      htmlFor="queries"
                      value="Write your message"
                      className="mb-2 block"
                    />
                    <Textarea
                      id="queries"
                      shadow
                      value={formData.queries}
                      onChange={handleChange}
                    />
                    {errors.queries && (
                      <p className="text-red-500 text-sm">{errors.queries}</p>
                    )}
                  </div>

                  <Button type="submit" className="bg-red-500">
                    Submit Enquiry
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="h-[300px] md:h-[400px]">
          {/* <Carousel slide={false} indicators={false}> */}
            <img className="object-cover h-full w-full"
              src={Banner}
              alt="..."
            />
          {/* </Carousel> */}
          <div className="absolute top-[130px] md:top-[170px] left-10 md:left-20">
            <h1 className="font-bold text-3xl md:text-6xl text-white">Kedarnath 2025</h1>
            <p className="font-medium text-xl md:text-3xl text-white">Tour Packages</p>
          </div>
        </div>
      </div>
      <div className="font-Mont w-full mt-10">
        <div className="flex flex-col items-center justify-between mb-10 font-Mont w-full">
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="flex flex-wrap items-center justify-around pt-10 w-full ">
              {PackageList &&
                PackageList.filter(
                  (packages) =>
                    packages.live === "Yes" &&
                    packages.package_category &&
                    packages.package_category.slug === slug
                ).map((packages) => {
                  return (
                    <PackagesCard packages={packages} key={packages._id} />
                  );
                })}
            </div>
          )}
          {/* <Link
        to="/all-locations"
        className="bg-black text-white pl-10 pr-10 pt-3 pb-3 mt-10 font-medium text-lg rounded"
      >
        View All
      </Link> */}
        </div>
      </div>
    </div>
  );
};

export default MultiTypePackage;

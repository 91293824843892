import React, { useEffect, useRef, useState } from "react";
import { HiUsers } from "react-icons/hi";

const AgeSelecter = ({
  propertyId,
  setAdultCount,
  adultCount,
  setChildCount,
  childCount,
  children05,
  setChildren05,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dropdownRef = useRef(null);
  // console.log(children05)
  let maxGuests = propertyId && propertyId.max_guests;

  useEffect(() => {
    const storedAdultCount = sessionStorage.getItem("adultCount");
    const storedChildCount = sessionStorage.getItem("childCount");
    const storedChildren05 = sessionStorage.getItem("children05");

    if (storedAdultCount) {
      setAdultCount(parseInt(storedAdultCount, 10));
    }
    if (storedChildCount) {
      setChildCount(parseInt(storedChildCount, 10));
    }
    if (storedChildren05) {
      setChildren05(parseInt(storedChildren05, 10));
    }
  }, [setAdultCount, setChildCount, setChildren05]);

  const changeCount = (type, amount) => {
    setErrorMessage("");

    // Calculate the new counts
    const newAdultCount = type === "adult" ? adultCount + amount : adultCount;
    const newChildCount = type === "child" ? childCount + amount : childCount;
    const newChildren05 = type === "child05" ? children05 + amount : children05;

    const totalGuests = newAdultCount + newChildCount;

    // let MaxInfants = 5;
    // if(MaxInfants > newChildren05){
    //   setErrorMessage(`Maximum infa occupancy reached ${MaxInfants}.`);
    //   return;
    // }

    // Check if the new total exceeds the maxGuests limit
    if (totalGuests > maxGuests) {
      setErrorMessage(`Maximum guest occupancy reached ${maxGuests}.`);
      return;
    }

    // Update counts based on type
    if (type === "adult") {
      setAdultCount(Math.max(1, newAdultCount));
      sessionStorage.setItem("adultCount", Math.max(1, newAdultCount));
    } else if (type === "child") {
      setChildCount(Math.max(0, newChildCount));
      sessionStorage.setItem("childCount", Math.max(0, newChildCount));
    } else if (type === "child05") {
      setChildren05(Math.max(0, newChildren05));
      sessionStorage.setItem("children05", Math.max(0, newChildren05));
    }
  };
  
  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onHandleClear = () => {
    setAdultCount(1);
    setChildCount(0);
    setChildren05(0);
    setErrorMessage("");
    sessionStorage.removeItem("adultCount");
    sessionStorage.removeItem("childCount");
    sessionStorage.removeItem("children05");
  };

  return (
    <div
      className="relative inline-block text-left w-full mt-3"
      ref={dropdownRef}
    >
      <div>
        <button
          onClick={toggleDropdown}
          className="inline-flex  justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span className="flex items-center">
            <HiUsers />
            <span className="ml-2 mr-2  font-semibold text-xs">
              {adultCount} guests,
            </span>
            <span className="font-semibold text-xs">{childCount} Children</span>

            <span className="font-semibold text-xs ml-2">
              {children05} Infants
            </span>
          </span>
        </button>
      </div>

      {isOpen && (
        <div className="absolute left-0 right-0 z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 pb-5">
          <div className="flex items-center justify-between pl-5 pr-5 pt-3">
            <h1>Total guests</h1>
          </div>

          <div className="py-2">
            <div className="flex justify-between items-center px-4 py-2">
              <div className="flex flex-col">
                <span className="font-medium text-base">Adults </span>
                <p className="font-medium text-xs opacity-75">
                  Age 13 or above
                </p>
              </div>

              <div className="flex items-center rounded border border-gray-200">
                <button
                  type="button"
                  className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                  onClick={() => changeCount("adult", -1)}
                  disabled={adultCount <= 0}
                >
                  &minus;
                </button>

                <input
                  type="number"
                  id="Quantity"
                  value={adultCount}
                  className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                />

                <button
                  type="button"
                  onClick={() => changeCount("adult", 1)}
                  className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                >
                  +
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center px-4 py-2">
              <div className="flex flex-col">
                <span className="font-medium text-base">Children </span>
                <p className="font-medium text-xs opacity-75">Age 6 to 12</p>
              </div>
              <div>
                <div className="flex items-center rounded border border-gray-200">
                  <button
                    type="button"
                    className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                    onClick={() => changeCount("child", -1)}
                    disabled={childCount <= 0}
                  >
                    &minus;
                  </button>

                  <input
                    type="number"
                    id="Quantity"
                    value={childCount}
                    className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                  />

                  <button
                    type="button"
                    onClick={() => changeCount("child", 1)}
                    className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center px-4 py-2">
              <div className="flex flex-col">
                <span className="font-medium text-base">Infants </span>
                <p className="font-medium text-xs opacity-75">
                  Aged 0 to 5 stay free—no additional charges apply!
                </p>
              </div>
              <div>
                <div className="flex items-center rounded border border-gray-200">
                  <button
                    type="button"
                    className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                    onClick={() => changeCount("child05", -1)}
                    disabled={children05 <= 0}
                  >
                    &minus;
                  </button>

                  <input
                    type="number"
                    id="Quantity"
                    value={children05}
                    className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                  />

                  <button
                    type="button"
                    onClick={() => changeCount("child05", 1)}
                    disabled={children05 >= 5}
                    className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-10 bg-white flex items-center justify-between pl-2 pr-2">
            <button
              className="flex items-end justify-end  bg-white text-red-500 underline"
              onClick={onHandleClear}            >
              Clear Guest(s)
            </button>
            <button
              className="pt-1 pb-1 flex items-center justify-center  bg-red-400 text-white w-[100px] rounded"
              onClick={toggleDropdown}
              >
              Next
            </button>
          </div>

          <div className="w-full flex-start pl-5 pr-5">
            {errorMessage && (
              <div className="text-red-500 text-sm  mb-2">{errorMessage}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeSelecter;

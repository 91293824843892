// create coupon
export const CREATE_COUPON_REQUEST  = "CREATE_COUPON_REQUEST"
export const CREATE_COUPON_SUCCESS  = "CREATE_COUPON_SUCCESS"
export const CREATE_COUPON_FAIL  = "CREATE_COUPON_FAIL"

// get all coupon
export const GET_ALL_COUPON_REQUEST = "GET_ALL_COUPON_REQUEST"
export const GET_ALL_COUPON_SUCCESS = "GET_ALL_COUPON_SUCCESS"
export const GET_ALL_COUPON_FAIL = "GET_ALL_COUPON_FAIL"


// upadte coupon code details
export const UPDATE_COUPON_CODE_REQUEST = "UPDATE_COUPON_CODE_REQUEST"
export const UPDATE_COUPON_CODE_SUCCESS = "UPDATE_COUPON_CODE_SUCCESS"
export const UPDATE_COUPON_CODE_FAIL = "UPDATE_COUPON_CODE_FAIL"

// get coupon code by id
export const GET_COUPON_CODE_BY_ID_REQUEST = "GET_COUPON_CODE_BY_ID_REQUEST" 
export const GET_COUPON_CODE_BY_ID_SUCCESS = "GET_COUPON_CODE_BY_ID_SUCCESS" 
export const GET_COUPON_CODE_BY_ID_FAIL = "GET_COUPON_CODE_BY_ID_FAIL" 

// get coupon by code
export const GET_COUPON_CODE_BY_CODE_REQUEST = "GET_COUPON_CODE_BY_CODE_REQUEST" 
export const GET_COUPON_CODE_BY_CODE_SUCCESS = "GET_COUPON_CODE_BY_CODE_SUCCESS" 
export const GET_COUPON_CODE_BY_CODE_FAIL = "GET_COUPON_CODE_BY_CODE_FAIL" 

// clear coupon apply
export const CLEAR_COUPON_CODE_REQUEST = "CLEAR_COUPON_CODE_REQUEST"
export const CLEAR_COUPON_CODE_SUCCESS = "CLEAR_COUPON_CODE_SUCCESS"
export const CLEAR_COUPON_CODE_FAIL = "CLEAR_COUPON_CODE_FAIL"

// 
export const CLEAR_ERROR = "CLEAR_ERROR"


import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { Label, TextInput, Textarea, Button } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { createpackageEnquiry } from "../../redux/actions/PackagesAction/PackageEnquiryAction";
import { toast } from "react-toastify";

const EnquiryForm = ({ setEnquiry, enquiry }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    numberOfAdults: "",
    queries: "",
    date:""
  });
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.packageEnquiry);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));

    // Remove error message when user starts typing
    setErrors((prev) => ({ ...prev, [id]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form fields
    let newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.phoneNumber.trim())
      newErrors.phoneNumber = "Phone number is required";
    if (!formData.numberOfAdults.trim())
      newErrors.numberOfAdults = "Number of adults is required";
    if (!formData.queries.trim()) newErrors.queries = "Queries cannot be empty";
    if (!formData.date.trim()) newErrors.date = "Select your preferred date";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formdata = new FormData();
    formdata.append("name", formData.name);
    formdata.append("mobile", formData.phoneNumber);
    formdata.append("adults", formData.numberOfAdults);
    formdata.append("queries", formData.queries);
    formdata.append("date", formData.date);

    dispatch(createpackageEnquiry(formdata));

    // console.log("Form Data:", formData);
  };

  useEffect(() => {
    if (success) {
      toast.success("Enquiry send");
      //   setEnquiry(false)
    }

    if (error) {
      toast.error(error);
    }
  }, [dispatch, error, success]);

  return (
    <div className="w-full p-10 font-Mont">
      <div className="flex items-center justify-between border-b pb-5">
        <h1 className="font-semibold text-base">Enquire now</h1>
        <IoMdClose
          className="cursor-pointer"
          size={25}
          onClick={() => setEnquiry(enquiry)}
        />
      </div>
      <form className="flex flex-col gap-1 mt-3" onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="name" value="Name" className="mb-2 block" />
          <TextInput
            id="name"
            type="text"
            shadow
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
        </div>

        <div>
          <Label
            htmlFor="phoneNumber"
            value="Mobile Number"
            className="mb-2 block"
          />
          <TextInput
            id="phoneNumber"
            type="number"
            shadow
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
          )}
        </div>

        <div>
          <Label
            htmlFor="numberOfAdults"
            value="No of Adults"
            className="mb-2 block"
          />
          <TextInput
            id="numberOfAdults"
            type="number"
            shadow
            value={formData.numberOfAdults}
            onChange={handleChange}
          />
          {errors.numberOfAdults && (
            <p className="text-red-500 text-sm">{errors.numberOfAdults}</p>
          )}
        </div>

        <div>
          <Label
            htmlFor="numberOfAdults"
            value="Preferred date"
            className="mb-2 block"
          />
          <TextInput
            id="date"
            type="date"
            shadow
            value={formData.date}
            onChange={handleChange}
          />
          {errors.date && (
            <p className="text-red-500 text-sm">{errors.date}</p>
          )}
        </div>

        <div>
          <Label
            htmlFor="queries"
            value="Write your message"
            className="mb-2 block"
          />
          <Textarea
            id="queries"
            shadow
            value={formData.queries}
            onChange={handleChange}
          />
          {errors.queries && (
            <p className="text-red-500 text-sm">{errors.queries}</p>
          )}
        </div>

        <Button type="submit" className="bg-red-500">
          Submit Enquiry
        </Button>
      </form>
    </div>
  );
};

export default EnquiryForm;

import { configureStore } from "@reduxjs/toolkit";
import {
  createNewPropertyReducers,
  getAllPropertyReducers,
  searchProperty,
} from "./reducers/propertyReducers/propertyReducer";
import { PropertyTypeReducers } from "./reducers/propertyReducers/propertyTypeReducer";
import { getAllpropertyCategoryReducers } from "./reducers/propertyReducers/propertyCategory";
import { createAmenitiesReducers } from "./reducers/propertyReducers/propertyAmenities";
import { collectionReducers } from "./reducers/propertyReducers/propertyCollection";
import { featuresReducers } from "./reducers/propertyReducers/PropertyFeatures";
import { propertySpaceReducers } from "./reducers/propertyReducers/propertySpacesReducers";
import { getAllUsersReducers, UserReducers } from "./reducers/userReducers/userReducers";
import { createOrderReducer } from "./reducers/createOrderReducers/createOrderReducers";
import { HomeThemeReducers } from "./reducers/HomeTheme/HomeTheme";
import { getCouponTypeReducers } from "./reducers/CouponReducers/CouponTypeReducer";
import { couponReducers } from "./reducers/CouponReducers/CouponCodeReducers";
import { amenitieTypeReducers } from "./reducers/propertyReducers/propertyAmenitiesType";
import { OwnerLeadsReducers } from "./reducers/OwnersLeads/OwnerLeads";
import { ContactReducers } from "./reducers/ContactReducers/ContactReducers";
import { offerReducers } from "./reducers/OffersReducers/OffersReducers";
import { placeToVisitReducers } from "./reducers/PackagesReducers/PackagesPlaceToVisitReducers";
import { createPackageReducer } from "./reducers/PackagesReducers/PackagesReducers";
import { PackageAmenitiesReducers } from "./reducers/PackagesReducers/PackageAmenitiesReducers";
import { packageTypeReducers } from "./reducers/PackagesReducers/PackageTypeReducers";
import { packageCategoryReducers } from "./reducers/PackagesReducers/PackageCategoryReducers";
import { packageEnquiryReducers } from "./reducers/PackagesReducers/PackageEnquiryReducers";

export default configureStore({
  reducer: {
    createProperty: createNewPropertyReducers,
    propertyList: getAllPropertyReducers,
    propertyType:PropertyTypeReducers,
    amenities: createAmenitiesReducers,
    category:getAllpropertyCategoryReducers,
    collection: collectionReducers,
    feature:featuresReducers,
    search:searchProperty,
    propertySpace:propertySpaceReducers,
    user: UserReducers,
    allUsers: getAllUsersReducers,
    createOrder:createOrderReducer,
    homeTheme: HomeThemeReducers,
    couponType: getCouponTypeReducers,
    coupon: couponReducers,
    amenitiesType: amenitieTypeReducers,
    ownersLeads: OwnerLeadsReducers,
    contact:ContactReducers,
    offers: offerReducers,
    placeVisit: placeToVisitReducers,
    packages: createPackageReducer,
    packageType: packageTypeReducers,
    packageAmenities: PackageAmenitiesReducers,
    packageCategory: packageCategoryReducers,
    packageEnquiry: packageEnquiryReducers
  },
});

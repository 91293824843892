import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa"; // FontAwesome Icons
import * as MdIcons from "react-icons/md"; // Material Design Icons
import * as BsIcons from "react-icons/bs"; // Bootstrap Icons
import * as FiIcons from "react-icons/fi"; // Feather Icons
import * as HiIcons from "react-icons/hi"; // Hero Icons
import * as IoIcons from "react-icons/io"; // Ionicons
import * as PiIcons from "react-icons/pi"; // Phosphor Icons
import * as SiIcons from "react-icons/si"; // Simple Icons
import * as AiIcons from "react-icons/ai"; // Ant Design Icons
import * as RiIcons from "react-icons/ri"; // Remix Icons
import * as CgIcons from "react-icons/cg"; // Chakra UI Icons
import * as WiIcons from "react-icons/wi"; // Weather Icons
import * as LiaIcons from "react-icons/lia"; // Line Awesome Icons
import { FaChevronDown } from "react-icons/fa"; // Dropdown arrow
import { toast } from "react-toastify";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { createAmenities } from "../../../../redux/actions/propertyActions/propertyAmenities";
import { getAllAmenities } from "../../../../redux/actions/propertyActions/propertyAmenitiesType";

const CreateAmenities = () => {
  const [formData, setFormData] = useState({
    name: "",
    icon: null, // Store selected icon component here
    amenitiesType: "",
  });
  const { success } = useSelector((state) => state.amenities);
  const { getAllAmenitiesType } = useSelector((state) => state.amenitiesType);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false); // Toggle dropdown
  const [searchTerm, setSearchTerm] = useState(""); // For filtering icons
  const [selectedIconKey, setSelectedIconKey] = useState(null); // For selected icon key

  console.log(selectedIconKey);
  // Combine icons from multiple sets
  const allIcons = {
    ...FaIcons,
    ...MdIcons,
    ...BsIcons,
    ...FiIcons,
    ...HiIcons,
    ...IoIcons,
    ...PiIcons,
    ...SiIcons,
    ...AiIcons,
    ...RiIcons,
    ...CgIcons,
    ...WiIcons,
    ...LiaIcons,
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle search term input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle icon selection
  const handleIconSelect = (iconKey) => {
    setSelectedIconKey(iconKey); // Set the key of the selected icon
    setFormData((prevData) => ({
      ...prevData,
      icon: allIcons[iconKey], // Store the icon component in formData
    }));
    setIsOpen(false); // Close dropdown
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      icons: selectedIconKey,
      amenitiesType: formData.amenitiesType
    };
    dispatch(createAmenities(data));
    // Reset form after submission if needed
    setFormData({ name: "", icon: null });
    setSelectedIconKey(null);
  };

  // Filter icons based on the search term
  const filteredIcons = Object.keys(allIcons).filter((iconKey) =>
    iconKey.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (success) {
      toast.success("Amenities created successfull");
    }
    dispatch(getAllAmenities());
  }, [dispatch, success]);

  return (
    <div className="flex pt-20">
      <SideMenu />

      <div className="pt-5 flex justify-center w-full ">
        <div className="w-3/6 h-[450px] p-6 border border-gray-300 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Create Amenities</h2>
          <form onSubmit={handleSubmit} className="p-4 border rounded">
            <div>
              <label htmlFor="name" className="block mb-2">
                Amenities Type
              </label>
              <select
                id="amenities type"
                name="amenitiesType"
                value={formData.amenitiesType}
                onChange={handleInputChange}
                className="w-full pt-2 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" className="uppercase">
                  Select Amenities Type
                </option>
                {getAllAmenitiesType &&
                  getAllAmenitiesType?.map((type) => (
                    <option
                      className="uppercase"
                      key={type._id}
                      value={type._id}
                    >
                      {type.name}
                    </option>
                  ))}
              </select>
            </div>
            {/* Name Field */}
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                required
              />
            </div>

            {/* Icon Dropdown */}
            <div className="mb-4 w-full">
              <label htmlFor="icon" className="block mb-2">
                Icon:
              </label>
              <div className="relative inline-block text-left w-full  ">
                {/* Button to trigger dropdown */}
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="flex items-center justify-between bg-gray-200 px-4 py-2 rounded-lg w-full"
                >
                  {selectedIconKey ? (
                    <span className="flex items-center">
                      {React.createElement(allIcons[selectedIconKey])}{" "}
                      {/* Display selected icon */}
                      <span className="ml-2">Selected Icon</span>
                    </span>
                  ) : (
                    "Select Icon"
                  )}
                  <FaChevronDown className="ml-2" />
                </button>

                {isOpen && (
                  <div className="absolute w-full right-0 mt-2 w-64 bg-white shadow-lg rounded-lg p-4 max-h-60 overflow-y-auto">
                    {/* Search input */}
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search icons..."
                      className="w-full mb-4 px-2 py-1 border border-gray-300 rounded-lg"
                    />

                    {/* Icon list */}
                    <ul className="grid grid-cols-3 gap-4">
                      {filteredIcons.length > 0 ? (
                        filteredIcons.map((iconKey) => {
                          const IconComponent = allIcons[iconKey];
                          return (
                            <li
                              key={iconKey}
                              className="cursor-pointer text-2xl"
                              onClick={() => handleIconSelect(iconKey)}
                            >
                              {React.createElement(IconComponent)}{" "}
                              {/* Render icon */}
                            </li>
                          );
                        })
                      ) : (
                        <li className="col-span-3 text-center text-gray-500">
                          No icons found
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded w-full"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAmenities;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import SideMenu from "../sideMenu/SideMenu";
import { getAllUsers } from "../../../redux/actions/userAction/userAction";

const UserList = () => {
  const { allUsers, loading } = useSelector((state) => state.allUsers);
//   console.log(allUsers);

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getAllUsers())
  },[dispatch])


  return (
    <div className="flex pt-20 w-full font-Mont">
      <SideMenu />
      <div className="w-full">
        <div className="p-6">
          <h1 className="font-bold text-lg">All Users ({allUsers && allUsers?.length} )</h1>
          <div className="overflow-x-auto overflow-y-scroll h-screen">
            <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {/* <th className="px-6 py-3 text-left text-gray-600">ID</th> */}
                  <th className="px-6 py-3 text-left text-gray-600">Name</th>
                  <th className="px-6 py-3 text-left text-gray-600">Email</th>
                  <th className="px-6 py-3 text-left text-gray-600">Mobile</th>
                  <th className="px-6 py-3 text-left text-gray-600">
                    Location
                  </th>
                  <th className="px-6 py-3 text-left text-gray-600">Created Date</th>
                </tr>
              </thead>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                allUsers &&
                allUsers.map((user,index) => {
                  return (
                    <tbody>
                      <tr className="border-b hover:bg-gray-50" key={index}>
                        {/* <td className="px-6 py-3">{user._id}</td> */}
                        <td className="px-6 py-3">{user.firstName} {user.lastName}</td>
                        <td className="px-6 py-3">{user.email}</td>
                        <td className="px-6 py-3">{user.mobile}</td>
                        <td className="px-6 py-3">{user.role}</td>
                        <td className="px-6 py-3">
                          {new Date(user.createdAt).toDateString()}
                        </td>
                        {/* <td className="px-6 py-3">
                        <button className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
                          Edit
                        </button>
                        <button className="ml-2 px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                          Delete
                        </button>
                      </td> */}
                      </tr>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;

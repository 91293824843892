
// create new property
export const CREATE_NEW_PROPERTY_REQUEST =  "CREATE_NEW_PROPERTY_REQUEST"
export const CREATE_NEW_PROPERTY_SUCCESS =  "CREATE_NEW_PROPERTY_SUCCESS"
export const CREATE_NEW_PROPERTY_FAIL =  "CREATE_NEW_PROPERTY_FAIL"

// update property by id
export const UPDATE_PROERTY_BY_ID_REQUEST = "UPDATE_PROERTY_BY_ID_REQUEST"
export const UPDATE_PROERTY_BY_ID_SUCCESS = "UPDATE_PROERTY_BY_ID_SUCCESS"
export const UPDATE_PROERTY_BY_ID_FAIL = "UPDATE_PROERTY_BY_ID_FAIL"

// get all property list
export const GET_ALL_PROPERTY_REQUEST = "GET_ALL_PROPERTY_REQUEST"
export const GET_ALL_PROPERTY_SUCCESS = "GET_ALL_PROPERTY_SUCCESS"
export const GET_ALL_PROPERTY_FAIL = "GET_ALL_PROPERTY_FAIL"


// get property by id
export const GET_PROPERTY_BY_ID_REQUEST = "GET_PROPERTY_BY_ID_REQUEST"
export const GET_PROPERTY_BY_ID_SUCCESS = "GET_PROPERTY_BY_ID_SUCCESS"
export const GET_PROPERTY_BY_ID_FAIL = "GET_PROPERTY_BY_ID_FAIL"

// get property by slug
export const GET_PROPERTY_BY_SLUG_REQUEST = "GET_PROPERTY_BY_SLUG_REQUEST";
export const GET_PROPERTY_BY_SLUG_SUCCESS = "GET_PROPERTY_BY_SLUG_SUCCESS";
export const GET_PROPERTY_BY_SLUG_FAIL = "GET_PROPERTY_BY_SLUG_FAIL";

// search property
export const SEARCH_PROPERTY_REQUEST = "SEARCH_PROPERTY_REQUEST"
export const SEARCH_PROPERTY_SUCCESS = "SEARCH_PROPERTY_SUCCESS"
export const SEARCH_PROPERTY_FAIL = "SEARCH_PROPERTY_FAIL"


// get rently price 
export const GET_RENTLIO_PRICE_REQUEST = "GET_RENTLIO_PRICE_REQUEST"
export const GET_RENTLIO_PRICE_SUCCESS = "GET_RENTLIO_PRICE_SUCCESS"
export const GET_RENTLIO_PRICE_FAIL = "GET_RENTLIO_PRICE_FAIL"


// grt all disable rently date 
export const GET_ALL_DISABLE_DATE_REQUEST = "GET_ALL_DISABLE_DATE_REQUEST"
export const GET_ALL_DISABLE_DATE_SUCCESS = "GET_ALL_DISABLE_DATE_SUCCESS"
export const GET_ALL_DISABLE_DATE_FAIL = "GET_ALL_DISABLE_DATE_FAIL"


// clear error
export const CLEAR_ERROR = "CLEAR_ERROR" 
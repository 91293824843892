import {
  CREATE_PACKAGE_ENQUIRY_REQUEST,
  CREATE_PACKAGE_ENQUIRY_SUCCESS,
  CREATE_PACKAGE_ENQUIRY_FAIL,
  GET_ALL_PACKAGE_ENQUIRY_REQUEST,
  GET_ALL_PACKAGE_ENQUIRY_SUCCESS,
  GET_ALL_PACKAGE_ENQUIRY_FAIL,
} from "../../constant/PackagesConstant/PackageEnquiryConstant";

export const packageEnquiryReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKAGE_ENQUIRY_REQUEST:
    case GET_ALL_PACKAGE_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PACKAGE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PACKAGE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        allEnquiry: action.payload,
      };

    case CREATE_PACKAGE_ENQUIRY_FAIL:
    case GET_ALL_PACKAGE_ENQUIRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import axios from "axios";
import {
  GET_ALL_PROPERTY_TYPE_REQUEST,
  GET_ALL_PROPERTY_TYPE_SUCCESS,
  GET_ALL_PROPERTY_TYPE_FAIL,
  CREATE_PROPERTY_TYPE_FAIL,
  CREATE_PROPERTY_TYPE_REQUEST,
  CREATE_PROPERTY_TYPE_SUCCESS,
} from "../../constant/propertyConstant/propertyTypeConstant";

// create property
export const createProperty = (propertyType) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PROPERTY_TYPE_REQUEST,
    });

    const { data } = await axios.post("/api/v1/property-type", propertyType);
    dispatch({
      type: CREATE_PROPERTY_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROPERTY_TYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all property type
export const getAllPropertyType = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PROPERTY_TYPE_REQUEST,
    });

    const { data } = await axios.get("/api/v1/all-property-type");
    // console.log(data);
    dispatch({
      type: GET_ALL_PROPERTY_TYPE_SUCCESS,
      payload: data.allCategory,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PROPERTY_TYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React, { useState } from "react";
import { ReviewObj } from "./ReviewsObj";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const Reviews = () => {
  const [expandedReviews, setExpandedReviews] = useState({});

  const toggleShowMore = (id) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return `${words.slice(0, wordLimit).join(" ")}...`;
  };

  return (
    <div className="w-full flex flex-col items-center justify-center font-Mont pt-10 pb-10 bg-gray-200">
      <h2 className="font-semibold text-2xl capitalize">
        reviews from our customers
      </h2>
      <div className="w-full md:w-11/12 mt-10 p-5">
        <Slider {...settings} className="">
          {ReviewObj &&
            ReviewObj.map((review) => {
              const isExpanded = expandedReviews[review.id] || false;
              return (
                <div
                  key={review.id}
                  className="p-4 w-[280px] md:!w-[370px] bg-white rounded-2xl border" 
                >
                  <div className="p-2">
                    <div className="flex items-center">
                      <div className="w-10 h-10 flex items-center justify-center bg-black text-white rounded-full text-lg font-bold">
                        {review.name.charAt(0)}
                      </div>
                      <div>
                        <h3 className="font-bold text-lg">{review.name}</h3>
                        <p className="text-gray-500 text-sm">{review.date}</p>
                      </div>
                    </div>

                    <div className="flex my-2">
                      {[...Array(5)].map((_, i) => (
                        <span
                          key={i}
                          className={`w-5 h-5 inline-block ${
                            i < review.rating
                              ? "text-yellow-500"
                              : "text-gray-300"
                          }`}
                        >
                          ★
                        </span>
                      ))}
                    </div>

                    <p className="text-gray-700 flex-wrap text-justify">
                      {isExpanded
                        ? review?.comment
                        : truncateText(review?.comment, 30)}
                      {review?.comment?.split(" ").length > 30 && (
                        <button
                          onClick={() => toggleShowMore(review.id)}
                          className="text-white bg-gray-500 font-medium text-xs mt-2 pl-2 pr-2 pt-1 pb-1 rounded"
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </p>

                    <Link
                      to={review.url}
                      className="mt-4 flex items-center gap-2"
                    >
                      <h2 className="font-medium text-xs">Posted on</h2>
                      <img src={review.logo} alt="Airbnb" className="w-5 h-5" />
                    </Link>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;

import React, { useState } from "react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  console.log(email);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="font-[sans-serif] relative pt-20 pb-10">
      <div className="h-[240px] font-[sans-serif] bg-blue-700">
        {/* <img
      src="https://readymadeui.com/cardImg.webp"
      alt="Banner"
      className="w-full h-full object-cover"
    /> */}
      </div>

      <div className="relative -mt-40 m-4 flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="bg-white max-w-xl w-5/12  shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-8 rounded-2xl"
        >
          <div className="mb-12">
            <h3 className="text-gray-800 text-3xl font-bold text-center">
              Forgot Password
            </h3>
          </div>

          <div className="mt-8">
            <label className="text-gray-800 text-xs block mb-2">Email</label>
            <div className="relative flex items-center">
              <input
                name="email"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
                placeholder="Enter email"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#bbb"
                stroke="#bbb"
                className="w-[18px] h-[18px] absolute right-2"
                viewBox="0 0 682.667 682.667"
              >
                <defs>
                  <clipPath id="a" clipPathUnits="userSpaceOnUse">
                    <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                  </clipPath>
                </defs>
                <g
                  clip-path="url(#a)"
                  transform="matrix(1.33 0 0 -1.33 0 682.667)"
                >
                  <path
                    fill="none"
                    stroke-miterlimit="10"
                    stroke-width="40"
                    d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                    data-original="#000000"
                  ></path>
                  <path
                    d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                    data-original="#000000"
                  ></path>
                </g>
              </svg>
            </div>
          </div>

          <div className="mt-4">
            <button
              type="submit"
              className="
                text-white bg-blue-600 w-full shadow-xl py-2.5 px-5 text-sm font-semibold tracking-wider rounded-md focus:outline-none transition-all"
            >
              submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;

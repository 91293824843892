// create offer
export const CREATE_OFFER_PAGE_REQUEST = "CREATE_OFFER_PAGE_REQUEST"
export const CREATE_OFFER_PAGE_SUCCESS = "CREATE_OFFER_PAGE_SUCCESS"
export const CREATE_OFFER_PAGE_FAIL = "CREATE_OFFER_PAGE_FAIL"


// get all offers
export const GET_ALL_OFFERS_REQUEST = "GET_ALL_OFFERS_REQUEST"
export const GET_ALL_OFFERS_SUCCESS = "GET_ALL_OFFERS_SUCCESS"
export const GET_ALL_OFFERS_FAIL = "GET_ALL_OFFERS_FAIL"

// get offer by id
export const GET_OFFER_BY_ID_REQUEST = "GET_OFFER_BY_ID_REQUEST"
export const GET_OFFER_BY_ID_SUCCESS = "GET_OFFER_BY_ID_SUCCESS"
export const GET_OFFER_BY_ID_FAIL = "GET_OFFER_BY_ID_FAIL"

// update offer 
export const UPDATE_OFFER_BY_ID_REQUEST = "UPDATE_OFFER_BY_ID_REQUEST"
export const UPDATE_OFFER_BY_ID_SUCCESS = "UPDATE_OFFER_BY_ID_SUCCESS"
export const UPDATE_OFFER_BY_ID_FAIL = "UPDATE_OFFER_BY_ID_FAIL"



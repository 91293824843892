import React, { useEffect } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupon } from "../../../../redux/actions/CouponAction/CouponCode";
import Loader from "../../../Loader";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";

const ListOfCoupon = () => {
  const { allCoupon, loading } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  // console.log(allCoupon);

  useEffect(() => {
    dispatch(getAllCoupon());
  }, [dispatch]);
  return (
    <div className="pt-20 flex">
      <SideMenu />

      <div className="">
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Total coupon</h1>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      property Name
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      Coupon Code
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      mobile
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      CouponType
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      coupon Expiration
                    </th>

                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      isActive
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      Update
                    </th>
                    <th className="px-4 py-2 border-b text-left text-gray-600">
                      delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allCoupon &&
                    allCoupon.map((item) => {
                      console.log(typeof item.isActive);
                      return (
                        <tr key={item._id} className="hover:bg-gray-100">
                          <td className="px-4 py-2 border-b">
                            {item?.propertyId?.title || ""}
                          </td>
                          <td className="px-4 py-2 border-b">{item.code}</td>
                          <td className="px-4 py-2 border-b">{item?.mobile}</td>
                          <td className="px-4 py-2 border-b uppercase">
                            {item.couponType.name}
                          </td>
                          <td className="px-4 py-2 border-b">
                            {item.couponExpiration}
                          </td>
                          <td className="px-4 py-2 border-b text-black">
                            {item.isActive === true ? "true" : "false"}
                          </td>
                          <td className="px-4 py-2 border-b text-black cursor-pointer">
                            <Link to={`/dashboard/list-of-coupon/${item._id}`}>
                              Edit
                            </Link>
                          </td>
                          <td className="px-4 py-2 border-b text-black cursor-pointer">
                            <MdDelete size={20} color="red" />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListOfCoupon;

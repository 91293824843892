import React, { useEffect, useState } from "react";
import { MdCall } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { IoTime } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../redux/actions/ContactAction/ContactAction";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
  });
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.contact);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createContact(formData));
  };

  useEffect(() => {
    if (success) {
      toast.success("form submit successfully");
    }

    if (error) {
      toast.error(error);
    }
  }, [success, error]);

  return (
    <div className="font-Mont pb-10 w-full">
      <div
        className="h-[400px] capitalize flex items-center justify-center"
        style={{
          backgroundImage:
            "url(https://d31za8na64dkj7.cloudfront.net/banner_Blog_05da511073.webp)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "400px",
        }}
      >
        <h1 className="font-bold text-4xl text-white">contact Us</h1>
      </div>
      <div className="w-full">
        <div className="mt-20">
          <div className="flex items-center justify-center">
            <div className="w-20 h-[2px] bg-red-400"></div>
            <h2 className="font-bold  md:text-4xl m-2">Get in Touch</h2>
            <div className="w-20 h-[2px] bg-red-400"></div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center justify-between p-5 md:p-10">
          <div className="w-full flex items-center justify-between ">
            <div className="w-full md:w-auto p-6 bg-white shadow-lg rounded-lg">
              <h2 className="text-2xl font-semibold text-center mb-4">
                Contact Us
              </h2>
              <form
                onSubmit={handleSubmit}
                className="space-y-4 w-full md:w-auto"
              >
                {/* Row 1: Full Name & Email */}
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Row 2: Mobile & Company Name */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input
                    type="tel"
                    name="mobile"
                    placeholder="Mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <input
                    type="text"
                    name="company"
                    placeholder="Company Name"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Row 3: Message Field */}
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                  required
                ></textarea>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
                >
                  Submit
                </button>
              </form>

              <div className="flex flex-col md:flex-row md:space-x-2 mt-5">
                <div className="w-full md:w-[145px] h-[100px] rounded-lg shadow-md flex flex-col items-center justify-between p-3">
                  <MdCall size={25} />
                  <h2 className="font-semibold text-sm">Call Us</h2>
                  <a className="font-medium text-xs" href="tel:919870141315">
                    +919870141315
                  </a>
                </div>
                <div className="w-full md:w-[145px] h-[100px] rounded-lg shadow-md flex flex-col items-center justify-between p-3">
                  <MdOutlineMail size={25} />
                  <h2 className="font-semibold text-sm">Email Id</h2>
                  <a className="font-medium text-xs" href="mailto:info@thealohas.com">
                    info@thealohas.com
                  </a>
                </div>
                <div className="w-full md:w-[145px] h-[100px] rounded-lg shadow-md flex flex-col items-center justify-between p-3">
                  <IoTime size={25} />
                  <h2 className="font-semibold text-sm">Working Hours</h2>
                  <p className="font-medium text-xs">
                    Mon-Sat 9am-7pm
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div class="mapouter w-full pt-10 md:pt-0">
              <div class="gmap_canvas w-full">
                <iframe
                  className="gmap_iframe w-full md:w-[550px] h-[250px] md:h-[500px] rounded-lg"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  title="myFrame"
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=NEXUS SHANTINIKETAN, Whitefield Main Rd, Thigalarapalya, Krishnarajapuram, Bengaluru, Karnataka 560067&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useEffect } from "react";
import SideMenu from "../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllOwnerLeads } from "../../../redux/actions/OwnerLeads/OwnerLeadsAction";
import Loader from "../../Loader";

const OwnerLeads = () => {
  const { allOwnerLeads ,loading} = useSelector((state) => state.ownersLeads);
//   console.log(allOwnerLeads);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOwnerLeads());
  }, [dispatch]);
  return (
    <div className="flex pt-20 w-full font-Mont">
      <SideMenu />
      <div className="w-full">
       
        <div className="p-6">
        <h1 className="font-bold text-lg">Owners Leads</h1>
          <div className="overflow-x-auto overflow-y-scroll h-screen">
            <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {/* <th className="px-6 py-3 text-left text-gray-600">ID</th> */}
                  <th className="px-6 py-3 text-left text-gray-600">Name</th>
                  <th className="px-6 py-3 text-left text-gray-600">Email</th>
                  <th className="px-6 py-3 text-left text-gray-600">Mobile</th>
                  <th className="px-6 py-3 text-left text-gray-600">Location</th>
                  <th className="px-6 py-3 text-left text-gray-600">Date</th>
                </tr>
              </thead>
           {loading ? (
            <div>
                <Loader />
            </div>
           ) : 
            allOwnerLeads && allOwnerLeads.map((leads)=>{
                return(
                    <tbody>
                    <tr className="border-b hover:bg-gray-50">
                      {/* <td className="px-6 py-3">{leads._id}</td> */}
                      <td className="px-6 py-3">{leads.name}</td>
                      <td className="px-6 py-3">{leads.email}</td>
                      <td className="px-6 py-3">{leads.mobile}</td>
                      <td className="px-6 py-3">{leads.location}</td>
                      <td className="px-6 py-3">{new Date(leads.createdAt).toDateString()}</td>
                      {/* <td className="px-6 py-3">
                        <button className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
                          Edit
                        </button>
                        <button className="ml-2 px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                          Delete
                        </button>
                      </td> */}
                    </tr>
                    
                  </tbody>
                )
            })
           }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerLeads;

import React, { useEffect } from "react";
// import image1 from "../image/photo.avif";
// import image2 from "../image/photo1.avif";
// import image3 from "../image/photo2.avif";
// import SimpleImageSlider from "react-simple-image-slider";
import whatsappIcon from "../image/whatsappicon.png";
// import PropertySearch from "../PropertySearch/PropertySearch";
import SearchBar from "../PropertySearch/PropertySearch";
// import { IoClose } from "react-icons/io5";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { HiUsers } from "react-icons/hi";
// import { FaPlus } from "react-icons/fa6";
// import { FaMapMarkerAlt, FaMinus } from "react-icons/fa";
// import { addDays } from "date-fns";
// import { DateRange } from "react-date-range";
// import { searchProperty } from "../../redux/actions/propertyActions/PropertyAction";
import { IoCallSharp } from "react-icons/io5";
// import { FiSearch } from "react-icons/fi";
import SmallScreenSearch from "../PropertySearch/SmallScreenSearch";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllTheme } from "../../redux/actions/HomeTheme/HomeTheme";
import "./Header.css";
import { Link } from "react-router-dom";

// import Slider from "../property/Slider"

const Header = () => {
  const { allTheme } = useSelector((state) => state.homeTheme);
  // console.log(allTheme);
  const dispatch = useDispatch();
  const activeTheme =
    allTheme && allTheme.filter((theme) => theme.isActive === true);
  // console.log(activeTheme)
  // const imageData = [
  //   {
  //     url: image1,
  //     para: "hello",
  //   },
  //   {
  //     url: image2,
  //     para: "hello",
  //   },
  //   {
  //     url: image3,
  //     para: "hello hey",
  //   },
  // ];

  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    dispatch(getAllTheme());
  }, [dispatch]);

  return (
    <div className="relative w-full">
      {/* <SimpleImageSlider
        images={imageData}
        showNavs={true}
        navSize={60}
        //   showBullets={true}
        width={"100%"}
        height={470}
      /> */}
      <div className="h-[350px] md:h-[490px] w-full relative">
        <Slider {...settings}>
          {activeTheme &&
            activeTheme.map((home) => {
              // console.log(home.isActive === true);
              let  routes = (home?.route !== undefined ) ? home?.route : ""
              return (
                <Link to={`${routes}`}
                  key={home._id}
                  className="w-full h-[350px] md:h-[520px] relative"
                >
                  {/* Background Image */}
                  <img
                    className="w-full h-full object-cover md:object-fill "
                    src={home.banner.url}
                    alt={home.summary}
                  />

                  {/* Text Overlay */}
                  {home.summary ? (
                    <div className="absolute top-1/2  transform -translate-y-1/2 text-white bg-black/20 p-4  rounded-lg">
                      <h1 className="w-5/6 sm:w-4/6 flex items-center justify-center text-sm sm:text-lg text-center font-semibold pl-10">
                        {home.summary}
                      </h1>
                    </div>
                  ) : (
                    " "
                  )}
                </Link>
              );
              // }
              // return null;
            })}
        </Slider>
      </div>

      <a
        href="https://api.whatsapp.com/send/?phone=9870141315&text=Hi, Please help me to book a stay with Da Alohas"
        className="p-3 fixed right-5 bottom-20 md:bottom-20 z-20 bg-white shadow-2xl shadow-indigo-800 rounded-full"
      >
        <img className="w-10 md:w-auto" src={whatsappIcon} alt="whatsappicon" />
      </a>

      <a
        href="tel:9870141315"
        className="p-3 block md:hidden fixed right-7 bottom-7 md:bottom-20 z-20 bg-white  shadow-2xl shadow-indigo-800 rounded-full"
      >
        <IoCallSharp size={20} className="text-red-500" />
      </a>

      <div className="w-full flex items-center justify-center">
        <div className="absolute bottom-[-50px] w-11/12  flex items-center justify-center  hidden lg:block">
          <SearchBar />
        </div>
        <div className="block lg:hidden absolute bottom-10 flex items-center justify-center  w-11/12">
          <SmallScreenSearch />
        </div>
      </div>
    </div>
  );
};

export default Header;

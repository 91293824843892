import React, { useEffect, useState } from "react";
import SideMenu from "../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupon } from "../../../redux/actions/CouponAction/CouponCode";
import { createOffer } from "../../../redux/actions/OfferAction/OffersAction";
import { toast } from "react-toastify";

const Offers = () => {
  const [image, setImage] = useState(null);
  const [previewURL, setImagePreview] = useState("");
  const [summary, setSummary] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const { allCoupon } = useSelector((state) => state.coupon);
  const {createdOffers} = useSelector((state)=>state.offers)
  const dispatch = useDispatch();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("offers", image);
    formdata.append("summary", summary);
    formdata.append("couponCode", couponCode);

    dispatch(createOffer(formdata));
  };

  useEffect(() => {
    dispatch(getAllCoupon());
    if(createdOffers){
      toast.success("offer page created successfull")
    }
  }, [dispatch,createdOffers]);

  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full">
        <div className="max-w-md mx-auto bg-white p-6 shadow-lg rounded-lg">
          <h2 className="text-xl font-bold mb-4">Offers</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Image Upload */}
            <div>
              <label className="block font-medium">Upload Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-2 w-full border p-2 rounded"
                required
              />
              {previewURL && (
                <img
                  src={previewURL}
                  alt="Preview"
                  className="mt-2 w-full h-32 object-cover rounded"
                />
              )}
            </div>

            {/* Summary Input */}
            <div>
              <label className="block font-medium">Summary</label>
              <textarea
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                className="w-full border p-2 rounded"
                placeholder="Enter summary"
                required
              />
            </div>

            {/* Dropdown */}
            <div>
              <label className="block font-medium">Coupon Code</label>
              <select
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                className="w-full border p-2 rounded"
                required
              >
                <option value="">Select a couponCode</option>
                {allCoupon &&
                  allCoupon.map((coupon) => {
                    
                    console.log(coupon)
                    if(coupon.isActive === true){
                        return <option value={coupon._id}>{coupon.code}</option>;
                    }
                    return null
                  })}
              </select>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Offers;

import React, { useEffect, useState } from "react";
import PropertyCard from "./propertyCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperty } from "../../redux/actions/propertyActions/PropertyAction";
import { Link } from "react-router-dom";

import Loader from "../Loader";
import TrendingDestinations from "./TrendingDestinations";
import Offers from "./Offers";

const Property = () => {
  const dispatch = useDispatch();
  const { propertyList, loading } = useSelector((state) => state.propertyList);

  const [visibleProperties, setVisibleProperties] = useState(12);

  useEffect(() => {
    const updateProperties = () => {
      if (window.innerWidth < 768) {
        setVisibleProperties(9);
      } else {
        setVisibleProperties(12);
      }
    };

    updateProperties(); 
    window.addEventListener("resize", updateProperties);
    return () => window.removeEventListener("resize", updateProperties);
  }, []);

  useEffect(() => {
    dispatch(getAllProperty());
  }, [dispatch]);
  return (
    <div className="font-Mont ">
      <div className="flex flex-col items-center justify-center mb-10 font-Mont">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="flex flex-wrap items-center justify-center pt-5 md:pt-20">
            {propertyList &&
              propertyList
                .filter(
                  (property) =>
                    property.live === "Yes" &&
                    property.home_page_display === "Yes"
                )
                .slice(0, visibleProperties)
                .map((property) => {
                  return <PropertyCard property={property} key={property._id} />;
                })}
          </div>
        )}
        <Link
          to="/all-locations"
          className="bg-black text-white pl-10 pr-10 pt-3 pb-3 mt-10 font-medium text-lg rounded"
        >
          View All
        </Link>
      </div>

      <div className="">
        <TrendingDestinations />
        <Offers />
      </div>
    </div>
  );
};

export default Property;

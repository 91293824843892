import {
  CREATE_AMENITIES_TYPE_REQUEST,
  CREATE_AMENITIES_TYPE_SUCCESS,
  CREATE_AMENITIES_TYPE_FAIL,
  GET_ALL_AMENITIES_TYPE_REQUEST,
  GET_ALL_AMENITIES_TYPE_SUCCESS,
  GET_ALL_AMENITIES_TYPE_FAIL,
  GET_AMENITIES_BY_ID_REQUEST,
  GET_AMENITIES_BY_ID_SUCCESS,
  GET_AMENITIES_BY_ID_FAIL,
  UPDATE_AMENITIES_TYPE_SUCCESS,
  UPDATE_AMENITIES_TYPE_FAIL,
  UPDATE_AMENITIES_TYPE_REQUEST,
  DELETE_AMENITIES_TYPE_REQUEST,
  DELETE_AMENITIES_TYPE_FAIL,
  DELETE_AMENITIES_TYPE_SUCCESS,
} from "../../constant/propertyConstant/proeprtyAmenitiesType";

export const amenitieTypeReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_AMENITIES_TYPE_REQUEST:
    case GET_ALL_AMENITIES_TYPE_REQUEST:
    case GET_AMENITIES_BY_ID_REQUEST:
    case UPDATE_AMENITIES_TYPE_REQUEST:
    case DELETE_AMENITIES_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_AMENITIES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_AMENITIES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllAmenitiesType: action.payload,
      };

    case GET_AMENITIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        amenitiesTypeId: action.payload,
      };

    case UPDATE_AMENITIES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case DELETE_AMENITIES_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDelete: action.payload,
      };

    case CREATE_AMENITIES_TYPE_FAIL:
    case GET_ALL_AMENITIES_TYPE_FAIL:
    case GET_AMENITIES_BY_ID_FAIL:
    case UPDATE_AMENITIES_TYPE_FAIL:
    case DELETE_AMENITIES_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

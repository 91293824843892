import React, { useState } from "react";
// import { Link } from "react-router-dom";

const Registration = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);

  const sendOtp = () => {
    // Simulate sending OTP
    setIsOtpSent(true);
    alert("OTP sent!");
  };

  const verifyOtp = () => {
    if (otp === "1234") {
      alert("OTP Verified!");
    } else {
      alert("Invalid OTP!");
    }
  };

  return (
    <div className="flex items-center justify-center pt-[100px] h-screen">
      <div className="bg-white shadow-lg rounded-lg p-6 w-96">
        <h2 className="text-2xl font-bold text-center mb-4">
          Phone Verification
        </h2>
        <div>
          {!isOtpSent ? (
            <>
              <label
                htmlFor="phone"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Enter Phone Number
              </label>
              <input
                id="phone"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
              />
              <button
                onClick={sendOtp}
                className="w-full mt-4 bg-indigo-500 text-white py-2 rounded-lg hover:bg-indigo-600 transition"
              >
                Send OTP
              </button>
            </>
          ) : (
            <>
              <label
                htmlFor="otp"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Enter OTP
              </label>
              <input
                id="otp"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter the OTP"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
              />
              <button
                onClick={verifyOtp}
                className="w-full mt-4 bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition"
              >
                Verify OTP
              </button>
              <button
                onClick={() => setIsOtpSent(false)}
                className="w-full mt-2 text-indigo-500 underline"
              >
                Edit Phone Number
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Registration;

// <div className="font-[sans-serif] relative pt-20">
//   <div className="h-[240px] font-[sans-serif]">
//     <img
//       src="https://readymadeui.com/cardImg.webp"
//       alt="Banner"
//       className="w-full h-full object-cover"
//     />
//   </div>

//   <div className="relative -mt-40 m-4">
//     <form className="bg-white max-w-xl w-full mx-auto shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-8 rounded-2xl">
//       <div className="mb-12">
//         <h3 className="text-gray-800 text-3xl font-bold text-center">
//           Register
//         </h3>
//       </div>

//       <div>
//         <label className="text-gray-800 text-xs block mb-2">Full Name</label>
//         <div className="relative flex items-center">
//           <input
//             name="name"
//             type="text"
//             required
//             className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
//             placeholder="Enter name"
//           />
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="#bbb"
//             stroke="#bbb"
//             className="w-[18px] h-[18px] absolute right-2"
//             viewBox="0 0 24 24"
//           >
//             <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
//             <path
//               d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
//               data-original="#000000"
//             ></path>
//           </svg>
//         </div>
//       </div>

//       <div className="mt-8">
//         <label className="text-gray-800 text-xs block mb-2">Email</label>
//         <div className="relative flex items-center">
//           <input
//             name="email"
//             type="text"
//             required
//             className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
//             placeholder="Enter email"
//           />
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="#bbb"
//             stroke="#bbb"
//             className="w-[18px] h-[18px] absolute right-2"
//             viewBox="0 0 682.667 682.667"
//           >
//             <defs>
//               <clipPath id="a" clipPathUnits="userSpaceOnUse">
//                 <path d="M0 512h512V0H0Z" data-original="#000000"></path>
//               </clipPath>
//             </defs>
//             <g
//               clip-path="url(#a)"
//               transform="matrix(1.33 0 0 -1.33 0 682.667)"
//             >
//               <path
//                 fill="none"
//                 stroke-miterlimit="10"
//                 stroke-width="40"
//                 d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
//                 data-original="#000000"
//               ></path>
//               <path
//                 d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
//                 data-original="#000000"
//               ></path>
//             </g>
//           </svg>
//         </div>
//       </div>

//       <div className="mt-8">
//         <label className="text-gray-800 text-xs block mb-2">Password</label>
//         <div className="relative flex items-center">
//           <input
//             name="password"
//             type="password"
//             required
//             className="w-full bg-transparent text-sm text-gray-800 border-b border-gray-300 focus:border-blue-500 px-2 py-3 outline-none"
//             placeholder="Enter password"
//           />
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             fill="#bbb"
//             stroke="#bbb"
//             className="w-[18px] h-[18px] absolute right-2 cursor-pointer"
//             viewBox="0 0 128 128"
//           >
//             <path
//               d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
//               data-original="#000000"
//             ></path>
//           </svg>
//         </div>
//       </div>

//       <div className="flex items-center mt-8">
//         <input
//           id="remember-me"
//           name="remember-me"
//           type="checkbox"
//           className="h-4 w-4 shrink-0 rounded"
//         />
//         <label for="remember-me" className="ml-3 block text-sm">
//           I accept the{" "}
//           <Link
//             to="/terms-and-conditions"
//             className="text-blue-500 font-semibold hover:underline ml-1"
//           >
//             Terms and Conditions
//           </Link>
//         </label>
//       </div>

//       <div className="mt-8">
//         <button
//           type="button"
//           className="w-full shadow-xl py-2.5 px-5 text-sm font-semibold tracking-wider rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none transition-all"
//         >
//           Register
//         </button>
//         <p className="text-gray-800 text-sm mt-8 text-center">
//           Already have an account?{" "}
//           <Link
//             to="/login"
//             className="text-blue-500 font-semibold hover:underline ml-1"
//           >
//             Login here
//           </Link>
//         </p>
//       </div>
//     </form>
//   </div>
// </div>

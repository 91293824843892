import {
  CREATE_PACKAGES_REQUEST,
  CREATE_PACKAGES_SUCCESS,
  CREATE_PACKAGES_FAIL,
  GET_ALL_PACKAGES_REQUEST,
  GET_ALL_PACKAGES_SUCCESS,
  GET_ALL_PACKAGES_FAIL,
  GET_PACKAGE_BY_ID_REQUEST,
  GET_PACKAGE_BY_ID_SUCCESS,
  GET_PACKAGE_BY_ID_FAIL,
  GET_PACKAGES_BY_SLUG_REQUEST,
  GET_PACKAGES_BY_SLUG_SUCCESS,
  GET_PACKAGES_BY_SLUG_FAIL,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAIL,
} from "../../constant/PackagesConstant/PackagesConstant";

export const createPackageReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKAGES_REQUEST:
    case GET_ALL_PACKAGES_REQUEST:
    case GET_PACKAGE_BY_ID_REQUEST:
    case GET_PACKAGES_BY_SLUG_REQUEST:
    case UPDATE_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        PackageList: action.payload,
      };

    case GET_PACKAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        packageId: action.payload,
      };

    case GET_PACKAGES_BY_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        packageSlug: action.payload,
      };

    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case CREATE_PACKAGES_FAIL:
    case GET_ALL_PACKAGES_FAIL:
    case GET_PACKAGE_BY_ID_FAIL:
    case GET_PACKAGES_BY_SLUG_FAIL:
    case UPDATE_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

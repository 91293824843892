import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllReservation } from "../../redux/actions/createOrderActions/createOrderAction";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import MetaData from "../MetaData";

const Trips = () => {
  const { allReservation, loading } = useSelector((state) => state.createOrder);
  const dispatch = useDispatch();
  // console.log(allReservation);
  const navigate = useNavigate();

  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if needed
  //   const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if needed
  //   return `${year}-${month}-${day}`;
  // };

  useEffect(() => {
    dispatch(getAllReservation());
  }, [dispatch]);
  return (
    <div className="w-full  bg-gray-100 font-Mont">
      <MetaData title={`trips by Da alohas`} />
      {/* Banner Section */}
      {/* <header className="bg-blue-500 text-white py-8">
          <div className="container mx-auto text-center">
            <h1 className="text-4xl font-bold">Your Trips</h1>
            <p className="mt-2 text-lg">
              View and manage all your trips in one place.
            </p>
          </div>
        </header> */}

      {/* Trips Table Section */}
      {loading ? (
        <div>
          <Loader />{" "}
        </div>
      ) : (
        <main className="w-full mt-5 md:mt-0 md:p-4 h-auto md:h-[450px] overflow-y-scroll">
          {allReservation && allReservation?.length === 0 ? (
            <div className="w-full bg-white h-screen md:min-h-screen p-5 shadow rounded-lg">
              <button
                onClick={() => navigate("/account-setting")} 
                className="md:hidden  bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
              >
                ← Back
              </button>
              <div className="mt-5">
                <h1 className="font-semibold text-xl">
                  No trips booked ... yet!
                </h1>
                <p className="font-medium text-sm">
                  Time to dust off your bags and start planning your next
                  adventure.
                </p>
                <div className="w-full mt-5">
                  <Link
                    to="/"
                    className="pl-10 pr-10 pt-2 pb-2 bg-gray-200 capitalize rounded"
                  >
                    start searching
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full bg-white shadow rounded-lg p-2 md:p-6">
              <button
                onClick={() => navigate("/account-setting")} // Navigate back to sidebar or root
                className="md:hidden  bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
              >
                ← Back
              </button>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4 mt-5">
                Trips List
              </h2>

              <table className="w-full border-collapse border border-gray-300 mt-5">
                <thead>
                  <tr className="bg-gray-200">
                    {/* <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-700">
                    Picture
                  </th> */}
                    <th className="hidden md:block text-wrap border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      Trip ID
                    </th>
                    <th className="border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      Title
                    </th>
                    <th className="border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      City
                    </th>
                    <th className="border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      Check In
                    </th>
                    <th className="border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      Check out
                    </th>
                    <th className="border border-gray-300 px-2 md:px-4 py-2 text-left text-sm font-medium text-gray-700">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/** Example Row */}
                  {allReservation &&
                    allReservation.map((reservation) => {
                      // console.log(reservation);
                      return (
                        <tr className="hover:bg-gray-100" key={reservation._id}>
                          {/* <td className="border border-gray-300 px-4 py-2 text-gray-700 uppercase">
                          {
                            reservation.reservation_id
                          }
                        </td> */}
                          <td className="hidden md:block break-words text-xs md:text-sm md:font-medium border border-gray-300 px-2 md:px-4 py-2 text-gray-700 uppercase">
                            {reservation.reservation_id}
                          </td>
                          <td className=" md:font-medium text-xs md:text-sm border border-gray-300 px-2 md:px-4 py-2 text-gray-700">
                            {reservation && reservation.property_title}
                          </td>
                          <td className=" md:font-medium text-xs md:text-sm border border-gray-300 px-2 md:px-4 py-2 text-gray-700">
                            {reservation && reservation.property_city}
                          </td>
                          <td className=" md:font-medium text-xs md:text-sm border border-gray-300 px-2 md:px-4 py-2 text-green-600">
                            {new Date(
                              reservation && reservation.check_in_date
                            ).toDateString()}
                          </td>
                          <td className=" md:font-medium text-xs md:text-sm border border-gray-300 px-2 md:px-4 py-2 text-green-600">
                            {new Date(
                              reservation && reservation.check_out_date
                            ).toDateString()}
                          </td>
                          <td className=" md:font-medium text-xs md:text-sm border border-gray-300 px-2 md:px-4 py-2">
                            <Link
                              to={`/account-setting/trips/${
                                reservation && reservation._id
                              }`}
                              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}

                  {/** Add more rows dynamically here */}
                </tbody>
              </table>
            </div>
          )}
        </main>
      )}
    </div>
  );
};

export default Trips;

import {
  GET_ALL_PROPERTY_TYPE_REQUEST,
  GET_ALL_PROPERTY_TYPE_SUCCESS,
  GET_ALL_PROPERTY_TYPE_FAIL,
  CREATE_PROPERTY_TYPE_REQUEST,
  CREATE_PROPERTY_TYPE_SUCCESS,
} from "../../constant/propertyConstant/propertyTypeConstant";

export const PropertyTypeReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTY_TYPE_REQUEST:
      case CREATE_PROPERTY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

      case CREATE_PROPERTY_TYPE_SUCCESS:
        return{
          ...state,
          loading: false,
          success: action.payload
        }

    case GET_ALL_PROPERTY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        allProprtyType: action.payload,
      };

    case GET_ALL_PROPERTY_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React from "react";

const Services = () => {
  return (
    <div className="bg-gray-100 w-full h-[200px] md:h-[200px] font-Mont text-white flex items-center justify-center text-black pl-2 pr-2">
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="font-semibold text-2xl text-black">Our Presence at a glance</h1>
        <div className="flex items-center justify-around w-full pt-[20px] md:pt-[50px]">
          <div className="flex flex-col items-center justify-center ">
            <h2 className="font-bold text-lg md:text-3xl text-black">4000</h2>
            <p className="font-medium text-xs md:text-base  text-black text-center flex flex-wrap md:flex-row">Nights Booked</p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold text-lg md:text-3xl text-black">3000+</h2>
            <p className="font-medium text-xs md:text-base  text-black text-center flex flex-wrap md:flex-row capitalize">5 star reviews</p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold text-lg md:text-3xl text-black">55+</h2>
            <p className="font-medium text-xs md:text-base  text-black text-center flex flex-wrap md:flex-row">Hand Picked properties</p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold text-lg md:text-3xl text-black">7</h2>
            <p className="font-medium text-xs md:text-base  text-black text-center flex flex-wrap md:flex-row">Cities</p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <h2 className="font-bold text-lg md:text-3xl text-black">10,000</h2>
            <p className="font-medium text-xs md:text-base  text-black text-center flex flex-wrap md:flex-row">Happy Guests</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

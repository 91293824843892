import axios from "axios";
import {
  CREATE_PACKAGE_TYPE_REQUEST,
  CREATE_PACKAGE_TYPE_SUCCESS,
  CREATE_PACKAGE_TYPE_FAIL,
  GET_ALL_PACKAGE_TYPE_FAIL,
  GET_ALL_PACKAGE_TYPE_REQUEST,
  GET_ALL_PACKAGE_TYPE_SUCCESS,
} from "../../constant/PackagesConstant/PackageTypeConstant";

export const createPackageType = (packageType) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PACKAGE_TYPE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/create-package-type`,
      packageType,
      config
    );
    dispatch({
      type: CREATE_PACKAGE_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PACKAGE_TYPE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all package type
export const getAllPackageType = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_ALL_PACKAGE_TYPE_REQUEST
        })

        const {data} = await axios.get("/api/v1/all-package-type")
        dispatch({
            type: GET_ALL_PACKAGE_TYPE_SUCCESS,
            payload: data.allpackageType
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_PACKAGE_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}

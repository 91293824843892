import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContact } from "../../../redux/actions/ContactAction/ContactAction";
import SideMenu from "../sideMenu/SideMenu";
import Loader from "../../Loader";

const ContactList = () => {
  const { allcontact, loading } = useSelector((state) => state.contact);
//   console.log(allcontact);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllContact());
  }, [dispatch]);
  return (
    <div className="flex pt-20 w-full font-Mont">
      <SideMenu />
      <div className="w-full">
        <div className="p-6">
          <h1 className="font-bold text-lg">Contact Form</h1>
          <div className="overflow-x-auto">
            <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {/* <th className="px-6 py-3 text-left text-gray-600">ID</th> */}
                  <th className="px-6 py-3 text-left text-gray-600">Name</th>
                  <th className="px-6 py-3 text-left text-gray-600">Email</th>
                  <th className="px-6 py-3 text-left text-gray-600">Mobile</th>
                  <th className="px-6 py-3 text-left text-gray-600">Message</th>
                  <th className="px-6 py-3 text-left text-gray-600">
                    CreatedAt
                  </th>
                </tr>
              </thead>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                allcontact &&
                allcontact.map((leads) => {
                  return (
                    <tbody key={leads._id}>
                      <tr className="border-b hover:bg-gray-50">
                        {/* <td className="px-6 py-3">{leads._id}</td> */}
                        <td className="px-6 py-3">{leads.fullName}</td>
                        <td className="px-6 py-3">{leads.email}</td>
                        <td className="px-6 py-3">{leads.mobile}</td>
                        <td className="px-6 py-3">{leads.message}</td>
                        <td className="px-6 py-3">
                          {new Date(leads.createdAt)
                            .toDateString()
                           }
                        </td>
                        {/* <td className="px-6 py-3">
                      <button className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600">
                        Edit
                      </button>
                      <button className="ml-2 px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600">
                        Delete
                      </button>
                    </td> */}
                      </tr>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactList;

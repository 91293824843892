import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail, MdWatchLater } from "react-icons/md";
import { Carousel } from "flowbite-react";
import { useDispatch } from "react-redux";
import { getAllPackageCategory } from "../../redux/actions/PackagesAction/PackageCategoryAction";
import { MdAddIcCall } from "react-icons/md";
import EnquiryForm from "./PackageEnquiryForm";

const PackagesCard = ({ packages }) => {
  const navigate = useNavigate();
  // const { packageCategory } = useSelector((state) => state.packageCategory);
  const dispatch = useDispatch();
  const [enquiry, setEnquiry] = useState(false);

  // console.log(packages);
  // const packageCategorySlug = packageCategory && packageCategory

  const discountPrice =
    ((packages && packages.price) * Number(packages.percent)) / 100;
  const totalDiscount = packages.price - discountPrice;
  // console.log(discountPrice);

  function myFunction(summary) {
    if (summary.length > 40) {
      summary = summary.substring(0, 40) + "...";
    }
    return summary;
  }
  const onHanldeImage = () => {
    navigate(`/packages/${packages.package_category.slug}/${packages.slug}`);
  };

  const createWhatsAppLink = (phone, message) => {
    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    dispatch(getAllPackageCategory());
  }, [dispatch]);

  return (
    <div className="bg-white shadow shadow-indigo-300 rounded-md">
      <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 hidden md:block ">
        <Carousel slide={false} indicators={false}>
          {packages.picture &&
            packages.picture.map((item) => {
              return (
                <div
                  className="w-full flex items-center justify-center h-[255px] cursor-pointer"
                  key={item._id}
                >
                  <img
                    key={item._id}
                    className="w-full h-full rounded-md"
                    src={item.url}
                    onClick={() => onHanldeImage()}
                    alt={packages?.title}
                    loading="lazy"
                  />
                </div>
              );
            })}
        </Carousel>
      </div>

      <div className="w-[350px] h-[250px] block md:hidden overflow-hidden relative">
        <Slider {...settings}>
          {packages.picture &&
            packages.picture.map((item) => {
              return (
                <div
                  className="flex items-center justify-center h-[250px] cursor-pointer"
                  key={item._id}
                >
                  <img
                    key={item._id}
                    className="w-full h-full rounded-t-md"
                    src={item.url}
                    onClick={() => onHanldeImage()}
                    alt={packages?.title}
                    loading="lazy"
                  />
                </div>
              );
            })}
        </Slider>
        <div className="w-[350px] absolute bottom-5   flex items-center justify-center space-x-1">
          <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
          <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
          <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
          <div className="w-3 h-3 border rounded-full bg-transparent border-white shadow shadow-black"></div>
        </div>
      </div>
      <div className="block pl-4 pr-4 pb-4 pt-3  w-[350px] md:w-[370px] ">
        <Link
          to={`/packages/${packages.package_category.slug}/${packages?.slug}`}
        >
          <div className="flex flex-col justify-between">
            <dl>
              <div className="">
                <dt className="sr-only">name</dt>
                <dd className="text-lg md:text-xl font-semibold">
                  {myFunction(packages && packages?.title)}
                </dd>
              </div>
            </dl>

            <dl>
              <div className="">
                <dt className="sr-only">city</dt>
                <dd className="font-medium flex items-center">
                  <FaMapMarkerAlt className="mr-1" color="gray" />{" "}
                  {packages && packages?.origin_city}
                </dd>
              </div>
            </dl>
            <dl>
              <div className=" flex items-center justify-between">
                <dt className="sr-only">city</dt>
                <dd className="font-medium flex items-center">
                  <MdWatchLater className="mr-1" color="gray" />{" "}
                  {packages && packages?.duration}
                </dd>
              </div>
            </dl>
            <div className="flex items-center justify-between  font-semibold text-base mt-1">
              <div className="flex items-center space-x-2">
                <p> ₹ {Number(totalDiscount?.toFixed())?.toLocaleString()}</p>
                <h1>/</h1>
                <s className="font-semibold text-sm text-red-500">
                  ₹ {Number(packages && packages.price?.toFixed())?.toLocaleString()}
                </s>
              </div>
              <div className="bg-red-500 pl-5 pr-5 pt-1 pb-1 rounded-full">
                <h1 className="font-medium text-xs text-white">
                  {" "}
                  Discount {packages && packages.percent} %
                </h1>
              </div>
            </div>

            <div className="outline-dashed outline-[1px] mt-2 w-full bg-black"></div>
          </div>
        </Link>
        <div className="flex items-center justify-between pt-2">
          <div>
            <div className="flex space-x-2">
              <div className="">
                <a
                  className="flex items-center justify-center rounded"
                  href={createWhatsAppLink(
                    9870141315,
                    `I would like to enquire about this package \n${
                      packages && packages?.title
                    },`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp size={22} color="green" />
                </a>
              </div>
              <a href="tel:+91 9870141315" className="">
                <button className="w-full capitalize flex items-center justify-center rounded">
                  <MdAddIcCall size={22} color="black" />
                </button>
              </a>

              <a href="mailto:info@thealohas.com" className="">
                <button className="w-full capitalize flex items-center justify-center rounded">
                  <MdOutlineEmail size={22} color="red" />
                </button>
              </a>
            </div>
          </div>
          <div className="space-x-1">
            <button
              onClick={() => setEnquiry(true)}
              className="pl-5 pr-5 pt-2 pb-2 rounded-full font-medium text-xs  text-white bg-red-500"
            >
              Enquire Now
            </button>
            <Link
              to={`/packages/${packages.package_category.slug}/${packages?.slug}`}
            >
              <button className="pl-5 pr-5 pt-2 pb-2 rounded-full font-medium text-xs border border-gray-500 text-gray-500">
                View Details
              </button>
            </Link>
          </div>
          {enquiry && (
            <div className="fixed top-0 left-0 right-0 z-30 bg-black-200 w-full h-full flex items-center justify-center">
              <div className="bg-white w-full md:w-6/12 h-9/10 flex items-center justify-center rounded-md shadow">
                <EnquiryForm setEnquiry={setEnquiry} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackagesCard;

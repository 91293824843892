import {
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  GET_PAYMENT_SUCCESS_REQUEST,
  GET_PAYMENT_SUCCESS_SUCCESS,
  GET_PAYMENT_SUCCESS_FAIL,
  GET_ALL_RESERVATION_REQUEST,
  GET_ALL_RESERVATION_SUCCESS,
  GET_ALL_RESERVATION_FAIL,
  GET_RESERVATION_BY_ID_REQUEST,
  GET_RESERVATION_BY_ID_SUCCESS,
  GET_RESERVATION_BY_ID_FAIL,
  GET_RESERVATION_STATUS_REQUEST,
  GET_RESERVATION_STATUS_SUCCESS,
  GET_RESERVATION_STATUS_FAIL,
} from "../../constant/createOrderConstant/createOrder";

export const createOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
    case GET_PAYMENT_SUCCESS_REQUEST:
    case GET_ALL_RESERVATION_REQUEST:
    case GET_RESERVATION_BY_ID_REQUEST:
    case GET_RESERVATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_PAYMENT_SUCCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationId: action.payload,
      };

    case GET_ALL_RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        allReservation: action.payload,
      };

    case GET_RESERVATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        tripsId: action.payload,
      };

    case GET_RESERVATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        reservationStatus: action.payload,
      };

    case CREATE_ORDER_FAIL:
    case GET_PAYMENT_SUCCESS_FAIL:
    case GET_ALL_RESERVATION_FAIL:
    case GET_RESERVATION_BY_ID_FAIL:
    case GET_RESERVATION_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import HomeOwnerBenifits from "../HomeOwnerBenifits/HomeOwnerBenifits";
import { useDispatch, useSelector } from "react-redux";
import { createOwnersLead } from "../../../redux/actions/OwnerLeads/OwnerLeadsAction";
import { toast } from "react-toastify";
import HappyPartner from "../HappyParters/HappyPartner";

const PartnerWithUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    location: "",
    email: "",
  });
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state.ownersLeads);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createOwnersLead(formData));
    setFormData(" ");
  };

  useEffect(() => {
    if (success) {
      toast.success("form submit");
      setFormData(" ");
    }

    if (error) {
      toast.error(error);
    }
  }, [dispatch, success, error]);

  return (
    <div className="font-Mont">
      <div
        className="max-h-screen flex flex-col md:flex-row items-center justify-around "
        style={{
          backgroundImage:
            "url(https://d31za8na64dkj7.cloudfront.net/partner_Banner_217a7dfc69.webp)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full h-screen flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
          <div className="pt-20 md:pt-0">
            <h1 className="font-semibold text-4xl text-white">
              Partner with us
            </h1>
          </div>
          <div className="w-full md:w-2/6 p-2 md:p-0">
            <div className="w-full bg-white p-6 rounded-lg shadow-md mt-5 md:mt-10">
              <h2 className="text-xl font-semibold text-center">
                Let's Get Started
              </h2>
              <p className="font-normal text-sm mb-5 mt-3">
                Give us your details and we will call you back
              </p>
              <form onSubmit={handleSubmit} className="space-y-2 w-full">
                {/* Name Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                {/* Email Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                {/* Mobile Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Mobile
                  </label>
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your mobile number"
                    required
                  />
                </div>

                {/* Location Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your location"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="pl-5 pr-5 bg-gray-500 text-white py-2 rounded transition"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <HomeOwnerBenifits />
      {/* <WhatWeDoForGuest /> */}

      <div className="p-5 md:p-10 flex flex-col md:flex-row items-center justify-between bg-white">
        <div className="md:w-1/4">
          <h2 className="font-bold text-4xl pb-5 ">Partner with Da Alohas</h2>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-[200px] p-2 shadow rounded flex flex-col items-center justify-between pt-5 bg-gray-200 m-2">
            <h2 className="font-bold text-3xl">7</h2>
            <p>Cities</p>
            {/* <div>
                    <img src="" alt="hello" />
                </div> */}
          </div>
          <div className="w-full sm:w-[200px] p-2 shadow rounded flex flex-col items-center justify-between pt-5 bg-gray-200 m-2">
            <h2 className="font-bold text-3xl">3000+</h2>
            <p>5 Star Reviews</p>
            {/* <div>
                    <img src="" alt="hello" />
                </div> */}
          </div>
          <div className="w-full sm:w-[200px] p-2 shadow rounded flex flex-col items-center justify-between pt-5  bg-gray-200 m-2">
            <h2 className="font-bold text-3xl">55+</h2>
            <p>Hand Picked Properties</p>
            {/* <div>
                    <img src="" alt="hello" />
                </div> */}
          </div>
          <div className="w-full sm:w-[200px] p-2 shadow rounded flex flex-col items-center justify-between pt-5 bg-gray-200 m-2">
            <h2 className="font-bold text-3xl">10,000</h2>
            <p>Happy Guests</p>
            {/* <div>
                    <img src="" alt="hello" />
                </div> */}
          </div>
        </div>
      </div>
      <HappyPartner />
    </div>
  );
};

export default PartnerWithUs;

import React, { useEffect, useRef, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa"; // Using react-icons for icons
import { HiUsers } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { addDays, format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { searchProperty } from "../../redux/actions/propertyActions/PropertyAction";
import { useLocation, useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { parseISO } from "date-fns";

const SmallScreenSearchPage = () => {
  const [searchToggle, setSearchToggle] = useState(false);
  const [location, setLocation] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dropdownDateRef = useRef(null);
  const { propertyId } = useSelector((state) => state.propertyList);
  const locations = useLocation();
  const params = new URLSearchParams(locations.search);

  const city = params.get("city");
  const dateFrom = params.get("dateFrom");
  const dateTo = params.get("dateTo");
  const adults = params.get("adults");


  const getFormattedDateRange = (dateFrom, dateTo) => {
    if (!dateFrom || !dateTo) return "";
  
    const startDate = parseISO(dateFrom); // Parse the ISO date string
    const endDate = parseISO(dateTo);
  
    const startMonth = format(startDate, "MMM"); // e.g., "Jan"
    const endMonth = format(endDate, "MMM"); // e.g., "Jan"
    const startDateNum = format(startDate, "d"); // e.g., "29"
    const endDateNum = format(endDate, "d"); // e.g., "31"
  
    // If both dates are in the same month
    if (startMonth === endMonth) {
      return ` ${startDateNum}-${endDateNum} ${startMonth}`;
    }
  
    // If dates are in different months
    return ` ${startDateNum} ${startMonth} -  ${endDateNum} ${endMonth}`;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const [checkIn, setCheckIn] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
    key: "selection",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const storedAdultCount = sessionStorage.getItem("adultCount");
    const storedChildCount = sessionStorage.getItem("childCount");

    if (storedAdultCount) {
      setAdultCount(parseInt(storedAdultCount, 10));
    }
    if (storedChildCount) {
      setChildCount(parseInt(storedChildCount, 10));
    }
  }, [setChildCount, setAdultCount]);

  const changeCount = (type, amount) => {
    const newAdultCount = type === "adult" ? adultCount + amount : adultCount;
    const newChildCount = type === "child" ? childCount + amount : childCount;

    if (type === "adult") {
      setAdultCount((prev) => Math.max(1, prev + amount));
      sessionStorage.setItem("adultCount", Math.max(1, newAdultCount));
    } else if (type === "child") {
      setChildCount((prev) => Math.max(0, prev + amount));
      sessionStorage.setItem("childCount", Math.max(0, newChildCount));
    }
  };

  // const onHandleClear = () => {
  //   setAdultCount(1);
  //   setChildCount(0);
  // };

  const toggleDatePicker = () => setIsDatePickerOpen((prev) => !prev);

  const SearchHandleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchToggle(false);
    }
  };

  const handleClickOutsides = (event) => {
    if (
      dropdownDateRef.current &&
      !dropdownDateRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const calculateMaxDate = () => {
    const startDate = checkIn?.startDate || new Date();
    const maxDays = propertyId?.availability_in_advance || 90; // Default to 90 days
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + maxDays);
    return maxDate;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsides);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", SearchHandleClickOutside);
    return () => {
      document.removeEventListener("mousedown", SearchHandleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedDates = sessionStorage.getItem("checkInDates");
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates).map((range) => ({
        ...range,
        startDate: new Date(range.startDate),
        endDate: new Date(range.endDate),
      }));
      setCheckIn(parsedDates[0]); // Assuming only one range is stored
    }
  }, [setCheckIn]);

  useEffect(() => {
    sessionStorage.setItem("checkInDates", JSON.stringify([checkIn]));
  }, [checkIn]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let max_guests = adultCount + childCount;
    const data = {
      city: location,
      dateFrom: formatDate(checkIn.startDate),
      dateTo: formatDate(checkIn.endDate),
      adults: max_guests,
    };

    dispatch(searchProperty(data));
    navigate(
      `/search?city=${location}&dateFrom=${formatDate(
        checkIn.startDate
      )}&dateTo=${formatDate(checkIn.endDate)}&adults=${max_guests}`
    );
   setSearchToggle(false)
  };

  const handleSelect = (ranges) => {
    setCheckIn(ranges.selection);
  };

  useEffect(() => {
    const city = sessionStorage.getItem("location");
    setLocation(city);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("location", location);
  }, [location]);

  return (
    <div className="w-full font-Mont">
      <div className="flex items-center justify-center w-full">
        <div className="flex justify-center items-center w-full">
          <div className="relative w-full">
            {/* Map Icon on the Left */}
            <span className="absolute inset-y-0 left-3 flex items-center text-gray-400">
              <FaMapMarkerAlt />
            </span>

            {/* Input Field */}
            <div
              onClick={() => setSearchToggle(!searchToggle)}
              className="w-full bg-white  shadow-xl border-[0.5px] border-gray-300 rounded-lg py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:border-black"
            >
              <div className="flex flex-col">
                <h1 className="font-semibold text-sm">{city || "All Location"}</h1>
                <div className="flex ">
                <p className="font-medium text-sm">{getFormattedDateRange(dateFrom,dateTo)}</p>,
                <p className="ml-3 font-medium text-sm">{adults} <span className="font-medium text-xs ">Guest(s)</span></p>
                </div>
              </div>
            </div>

            {/* Search Icon on the Right */}
            <span className="absolute inset-y-0 right-3 flex items-center text-gray-400">
              <div className="bg-red-700 p-1 w-8 h-8 rounded-full flex items-center justify-center">
                <FiSearch size={20} color="white" />
              </div>
            </span>
          </div>
        </div>
      </div>

      <div className="" ref={searchRef}>
        {searchToggle ? (
          <div className="w-full h-full z-20 bg-gray-100 " ref={searchRef}>
            <div className="w-full h-[600px] bg-white shadow-2xl shadow-black rounded-xl fixed  right-0  left-0 bottom-0  z-30 pt-5 md:pt-0 md:p-5">
              <div className="">
                <div className="flex text-center pl-5 md:pl-0">
                  <IoClose size={30} onClick={() => setSearchToggle(false)} />
                  <h1 className="font-semibold text-xl text-center flex itmes-center justify-center w-full">
                    Book your stay
                  </h1>
                </div>

                <div className="rounded-lg p-6 flex flex-col justify-center font-Mont">
                  <form
                    className="w-full h-full flex flex-col space-y-4 items-center justify-between mt-5"
                    onSubmit={handleSubmit}
                  >
                    <div className="w-full  space-y-4">
                      {/* Location Selector */}
                      <div className="h-12 flex items-center justify-center space-x-2 rounded-md w-full p-2 border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700">
                        <FaMapMarkerAlt className="text-gray-500" />
                        <select
                          className="bg-transparent border-none focus:outline-none focus:ring-[0.5px] focus:ring-black focus:border-black text-gray-700 w-full"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        >
                          <option value="">All Location</option>
                          <option value="north goa">North Goa</option>
                          <option value="south goa">South Goa</option>
                          <option value="dehradun">Dehradun</option>
                          <option value="mussoorie">Mussoorie</option>
                          {/* <option value="dehradun">Dehradun</option> */}
                          <option value="rishikesh">Rishikesh</option>
                          <option value="mukteshwar">Mukteshwar</option>
                          <option value="delhi">Delhi</option>
                          <option value="jaipur">Jaipur</option>
                          <option value="varanasi">Varanasi</option>
                        </select>
                      </div>
                      {/* Date Pickers */}
                      <div className="w-full">
                        <div>
                          <div className="relative" ref={dropdownDateRef}>
                            <div
                              className="cursor-pointer w-full border border-gray-300 rounded-md"
                              onClick={toggleDatePicker}
                            >
                              <div className="flex w-full h-12 justify-around pt-1 pb-1">
                                <div>
                                  <h2 className="font-medium text-xs">
                                    Check in
                                  </h2>
                                  <p className="font-medium text-sm">
                                    {checkIn?.startDate
                                      ?.toDateString()
                                      ?.slice(0, 10) || "Select Date"}
                                  </p>
                                </div>
                                <div className="border-l"></div>
                                <div>
                                  <h2 className="font-medium text-xs">
                                    Check out
                                  </h2>
                                  <p className="font-medium text-sm">
                                    {checkIn?.endDate
                                      ?.toDateString()
                                      ?.slice(0, 10) || "Select Date"}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {isDatePickerOpen && (
                              <div
                                className={`absolute flex flex-col items-center justify-center left-0 right-0  top-0 z-10 rounded rounded-lg shadow-lg`}
                              >
                                <div className="flex-1">
                                  <DateRange
                                    onChange={handleSelect}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={[
                                      {
                                        startDate:
                                          checkIn?.startDate || new Date(),
                                        endDate: checkIn?.endDate || new Date(),
                                        key: "selection",
                                      },
                                    ]}
                                    direction="vertical"
                                    scroll={{ enabled: true }}                                   
                                    minDate={new Date()}
                                    maxDate={calculateMaxDate()}
                                  />
                                </div>
                                <div className="w-full h-10 bg-white flex items-center justify-end pl-2 pr-2">
                                  <button
                                    className="pt-1 pb-1 flex items-center justify-center  bg-red-400 text-white w-[100px] rounded"
                                    onClick={() =>
                                      setIsDatePickerOpen(!isDatePickerOpen)
                                    }
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Guests Selector */}
                      <div
                        className="relative inline-block text-left w-full"
                        ref={dropdownRef}
                      >
                        <div>
                          <button
                            onClick={toggleDropdown}
                            className="inline-flex h-12 justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                          >
                            <span className="flex items-center">
                              <HiUsers />
                              <span className="ml-2 mr-2 font-semibold">
                                {adultCount} Adult(s),
                              </span>
                              {childCount} Children(s)
                            </span>
                          </button>
                        </div>
                        {isOpen && (
                          <div className="absolute left-0 right-0 z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="flex items-center justify-between pl-4 pr-5 pt-3 w-full">
                              <h2 className="font-medium text-base">
                                Total guests
                              </h2>
                              {/* <button
                              className="text-red font-medium text-base"
                              onClick={onHandleClear}
                            >
                              Clear
                            </button> */}
                            </div>

                            <div className="py-2 flex flex-col items-center justify-center">
                              <div className="flex justify-between items-center px-4 py-2 w-full">
                                <div className="flex flex-col">
                                  <span className="font-medium text-base">
                                    Adults{" "}
                                  </span>
                                  <p className="font-medium text-xs opacity-75">
                                    Age 13 or above
                                  </p>
                                </div>
                                <div className="flex items-center rounded-full border border-gray-200">
                                  <button
                                    type="button"
                                    className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                                    onClick={() => changeCount("adult", -1)}
                                    disabled={adultCount <= 1}
                                  >
                                    <FaMinus color="#3FB1BD" />
                                  </button>
                                  <input
                                    type="number"
                                    value={adultCount}
                                    className="w-[100px] border-transparent text-center text-indigo-600"
                                    readOnly
                                  />
                                  <button
                                    type="button"
                                    onClick={() => changeCount("adult", 1)}
                                    className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                                  >
                                    <FaPlus color="#3FB1BD" />
                                  </button>
                                </div>
                              </div>
                              <div className="flex justify-between items-center px-4 py-2 w-full">
                                <div className="flex flex-col">
                                  <span className="font-medium text-base">
                                    Children{" "}
                                  </span>
                                  <p className="font-medium text-xs opacity-75">
                                    Age 6 to 12
                                  </p>
                                </div>
                                <div className="flex items-center rounded-full border border-gray-200">
                                  <button
                                    type="button"
                                    className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                                    onClick={() => changeCount("child", -1)}
                                    disabled={childCount <= 0}
                                  >
                                    <FaMinus color="#3FB1BD" />
                                  </button>
                                  <input
                                    type="number"
                                    value={childCount}
                                    className="w-[100px] border-transparent text-center text-indigo-600"
                                    readOnly
                                  />
                                  <button
                                    type="button"
                                    onClick={() => changeCount("child", 1)}
                                    className="w-8 h-full flex items-center justify-center leading-10 text-gray-600"
                                  >
                                    <FaPlus color="#3FB1BD" />
                                  </button>
                                </div>
                              </div>

                              {/* <button className="mt-4 w-10/12 bg-red-500 text-white p-3 rounded-full hover:bg-red-600">
                  Done
                </button> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Search Button */}
                    <button
                      type="submit"
                      className="w-10/12 fixed bottom-7 bg-black text-white px-10 py-2 rounded-lg hover:bg-gray-500 transition"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SmallScreenSearchPage;

import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import {
  clearError,
  getPropertyById,
  updateProperty,
} from "../../../../redux/actions/propertyActions/PropertyAction";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { getAllSpaces } from "../../../../redux/actions/propertyActions/propertySpaceAction";
import { MdDelete } from "react-icons/md";
import MapComponent from "./getAddress";
import { getAllAmenities } from "../../../../redux/actions/propertyActions/propertyAmenities";
// import Category from "../Category/Category";
import { getAllPropertyType } from "../../../../redux/actions/propertyActions/propertyType";
import { getAllCategory } from "../../../../redux/actions/propertyActions/propertyCategory";
import { getPropertyCollection } from "../../../../redux/actions/propertyActions/propertyCollection";
import { getPropertyFeatures } from "../../../../redux/actions/propertyActions/PropertyFeatures";
import Counter from "../Counter";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import DragImageList from "./DragImageList";
import Loader from "../../../Loader";

const UpdateProperty = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { propertyId, updated, error, loading } = useSelector(
    (state) => state.propertyList
  );

  // console.log(propertyId);
  const { collections } = useSelector((state) => state.collection);
  const { Allfeatures } = useSelector((state) => state.feature);
  const { allProprtyType } = useSelector((state) => state.propertyType);
  const { Allcategory } = useSelector((state) => state.category);
  const { AllSpaces } = useSelector((state) => state.propertySpace);
  const { allAmenities } = useSelector((state) => state.amenities);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState(0); // Initial count is set to 0
  const [thingToKnow, setThingToKnow] = useState("");
  const [summary, setSummary] = useState("");
  const [aboutspace, setAboutSpace] = useState("");
  const [houseRules, setHouseRule] = useState("");
  const [nearestPoint, setNearestPoint] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [cancellation_policy, setCancellationPolicy] = useState("");
  const [dropdowns, setDropdowns] = useState([
    { selectedSpace: "", selectedOption: null, isOpen: false },
  ]);

  const [images, setImages] = useState("");
  // console.log(images);

  const [deleteImage, setDeleteImage] = useState([]);

  // console.log(AllSpaces)

  // console.log(count)
  const [category, setCategory] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [collection, setCollection] = useState("");
  const [features, setFeatures] = useState("");
  const [draggedIndex, setDraggedIndex] = useState(null);

  // console.log(collection)
  // console.log(aboutspace.aboutspace);
  // const [imagesToDelete, setImagesToDelete] = useState([]);
  // console.log(imagesToDelete)

  // handle delete image
  // const handleDeleteImage = (url) => {
  //   // Update the imagesToDelete state
  //   setImagesToDelete((prev) => {
  //     if (prev.includes(url)) {
  //       return prev.filter((image) => image !== url);
  //     } else {
  //       return [...prev, url];
  //     }

  //   });

  //   // // Also remove the image from the displayed images
  //   // const newPictures = propertyId.picture.filter((file) => file.url !== url);
  //   // // propertyId.picture = newPictures; // Update the propertyId's picture array
  //   // propertyId.picture = newPictures
  // };
  const [isOpen, setIsOpen] = useState(false); // Dropdown visibility
  const [selectedAmenities, setSelectedAmenities] = useState([]); // Selected amenities
  const [searchTerm, setSearchTerm] = useState("");

  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    internal_name: "",
    Address: "",
    completeAddress: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    video_link: "",
    summary: "",
    max_guests: "",
    accomodation_bedrooms: "",
    accomodation_beds: "",
    accomodation_washrooms: "",
    security_Deposit: "",
    instant_booking: "",
    same_day_instant_booking: "",
    availability_in_advance: "",
    starting_from_price: "",
    live: "",
    home_page_display: "",
    spaces: "",
    check_in_time: "",
    check_out_time: "",
    mapUrl: "",
    amenities: "",
    channel_manager1: "",
    channel_manager_unitTypeId: "",
    after_extra_guest_fee: "",
    executiveName: "",
    executiveNumber: "",
    checkinDateDistance: "",
    minimumReservationPrice: "",
    PercentEnable: "",
  });

  // console.log(propertyId);

  const toggleDropdownAmenities = () => {
    setIsOpen(!isOpen);
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (amenityId) => {
    console.log(selectedAmenities);
    if (selectedAmenities.includes(amenityId)) {
      setSelectedAmenities(selectedAmenities.filter((id) => id !== amenityId)); // Deselect
    } else {
      setSelectedAmenities([...selectedAmenities, amenityId]); // Select
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter amenities based on search term
  const filteredAmenities =
    allAmenities &&
    allAmenities.filter((amenity) =>
      amenity.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleChange = (e) => {
    const { target } = e; 
    if (target) {
      // Ensure target is defined
      const { name, value } = target; // Now it's safe to destructure
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSpaceChange = (index, value) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].selectedSpace = value;
    setDropdowns(newDropdowns);
  };

  const handleSelect = (index, option) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].selectedOption = option;
    newDropdowns[index].isOpen = false;
    setDropdowns(newDropdowns);
  };

  const toggleDropdown = (index) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].isOpen = !newDropdowns[index].isOpen;
    setDropdowns(newDropdowns);
  };

  const addDropdown = (e) => {
    e.preventDefault();
    setDropdowns([
      ...dropdowns,
      { selectedSpace: "", selectedOption: null, isOpen: false },
    ]);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (index) => {
    if (draggedIndex !== null) {
      const updatedImages = [...images];
      const draggedItem = updatedImages[draggedIndex];

      // Remove the dragged item from its old position
      updatedImages.splice(draggedIndex, 1);

      // Insert the dragged item at the new position
      updatedImages.splice(index, 0, draggedItem);

      setImages(updatedImages);
      setDraggedIndex(null);
    }
  };

  // handle delete
  const OnHandleDelete = (url) => {
    setDeleteImage((prev) => [...prev, url]);
    setImages((prevImages) => prevImages.filter((image) => image.url !== url));
  };

  useEffect(() => {
    if (propertyId) {
      setFormData({
        title: propertyId?.title,
        internal_name: propertyId?.internal_name,
        completeAddress: propertyId.completeAddress,
        Address: propertyId?.Address,
        city: propertyId?.city,
        state: propertyId?.state,
        country: propertyId?.country,
        pincode: propertyId?.pincode,
        video_link: propertyId?.video_link,
        max_guests: propertyId?.max_guests,
        accomodation_bedrooms: propertyId?.accomodation_bedrooms,
        accomodation_beds: propertyId?.accomodation_beds,
        accomodation_washrooms: propertyId?.accomodation_washrooms,
        security_Deposit: propertyId?.security_Deposit,
        instant_booking: propertyId?.instant_booking,
        same_day_instant_booking: propertyId?.same_day_instant_booking,
        availability_in_advance: propertyId?.availability_in_advance,
        starting_from_price: propertyId?.starting_from_price,
        after_extra_guest_fee: propertyId?.after_extra_guest_fee,
        live: propertyId?.live,
        home_page_display: propertyId?.home_page_display,
        check_in_time: propertyId?.check_in_time,
        check_out_time: propertyId?.check_out_time,
        longitude: propertyId?.longitude,
        latitude: propertyId?.latitude,
        mapUrl: propertyId?.mapUrl,
        amenities: propertyId?.amenities,
        channel_manager1: propertyId?.channel_manager1,
        channel_manager_unitTypeId: propertyId?.channel_manager_unitTypeId,
        executiveName: propertyId?.executive_name,
        executiveNumber: propertyId.executive_number,
        PercentEnable: propertyId.percent_enable,
        minimumReservationPrice: propertyId.minimum_reservation_price,
        checkinDateDistance: propertyId.checkin_date_distance,
      });
    }

    if (propertyId) {
      setImage({
        image: propertyId.picture,
      });

      setImages(propertyId && propertyId.picture);

      if (propertyId) {
        setAboutSpace(propertyId?.about_space);
      }

      setCancellationPolicy(propertyId?.cancellation_policy);

      setSummary(propertyId?.summary);
      setThingToKnow(propertyId?.thing_to_know);
      setHouseRule(propertyId?.house_roles);
      setLongitude(propertyId.longitude);
      setLatitude(propertyId.latitude);
      setPropertyType(propertyId?.property_type._id || " ");
      setCollection(propertyId?.collections._id || " ");
      setCategory(propertyId?.category._id || "");
      setFeatures(propertyId.special_feature._id || " ");
      setNearestPoint(propertyId?.nearestPoint || " ");
      // setCount(propertyId?.extra_guest)
    }

    // if (propertyId?.extra_guest && count === 0) {
    //   setCount(propertyId.extra_guest);
    // }

    if (propertyId && propertyId.amenities) {
      const amenityIds = propertyId.amenities.map((amenity) =>
        typeof amenity === "object" ? amenity._id : amenity
      );
      setSelectedAmenities(amenityIds);
    }
  }, [propertyId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("title", formData.title);
    formdata.append("internal_name", formData.internal_name);
    formdata.append("completeAddress", formData.completeAddress);
    formdata.append("Address", formData.Address);
    formdata.append("city", formData.city);
    formdata.append("state", formData.state);
    formdata.append("country", formData.country);
    formdata.append("pincode", formData.pincode);
    formdata.append("video_link", formData.video_link);
    formdata.append("summary", summary);
    formdata.append("about_space", aboutspace);
    formdata.append("house_roles", houseRules);
    formdata.append("property_type", propertyType);
    formdata.append("category", category);
    formdata.append("collections", collection);
    formdata.append("special_feature", features);
    formdata.append("max_guests", formData.max_guests);
    formdata.append("accomodation_bedrooms", formData.accomodation_bedrooms);
    formdata.append("accomodation_beds", formData.accomodation_beds);
    formdata.append("accomodation_washrooms", formData.accomodation_washrooms);
    formdata.append("security_Deposit", formData.security_Deposit);
    formdata.append("instant_booking", formData.instant_booking);
    formdata.append(
      "same_day_instant_booking",
      formData.same_day_instant_booking
    );
    formdata.append(
      "availability_in_advance",
      formData.availability_in_advance
    );
    formdata.append("checkin_date_distance", formData.checkinDateDistance);
    formdata.append(
      "minimum_reservation_price",
      formData.minimumReservationPrice
    );
    formdata.append("percent_enable", formData.PercentEnable);
    formdata.append("extra_guest", count);
    formdata.append("after_extra_guest_fee", formData.after_extra_guest_fee);
    formdata.append("starting_from_price", formData.starting_from_price);
    formdata.append("live", formData.live);
    formdata.append("home_page_display", formData.home_page_display);
    formdata.append("deleteImage", deleteImage);
    // formdata.append("title",formData.title)

    // if (image.length > 0) {
    // for (let i = 0; i < Math.max(image.length, images.length); i++) {
    //   if (image[i]) formdata.append("picture", image[i]);
    //   if (images[i]) formdata.append("picture", images[i]);
    // }
    // }
    // else {

    for (let i = 0; i < image.length; i++) {
      formdata.append("picture", image[i]);
    }

    for (let i = 0; i < images.length; i++) {
      const urlObject = {
        url: images[i].url,
        id: images[i]._id,
      };
      formdata.append("picture", JSON.stringify(urlObject));
    }
    // }

    formdata.append("nearestPoint", nearestPoint);
    formdata.append("mapUrl", formData.mapUrl);
    formdata.append("longitude", longitude);
    formdata.append("latitude", latitude);
    formdata.append("check_in_time", formData.check_in_time);
    formdata.append("check_out_time", formData.check_out_time);
    formdata.append("cancellation_policy", cancellation_policy);
    formdata.append("thing_to_know", thingToKnow);
    formdata.append("channel_manager1", formData.channel_manager1);
    formdata.append(
      "channel_manager_unitTypeId",
      formData.channel_manager_unitTypeId
    );
    for (let i = 0; i < selectedAmenities.length; i++) {
      formdata.append("amenities", selectedAmenities[i]);
    }
    formdata.append("executive_name", formData.executiveName);
    formdata.append("executive_number", formData.executiveNumber);

    // dropdowns.forEach((dropdown, index) => {
    //  formdata.append("space",dropdown)
    // });

    dropdowns.forEach((dropdown, index) => {
      console.log(dropdown);
      if (dropdown.selectedSpace) {
        formdata.append(`space[${index}][spaceId]`, dropdown.selectedSpace);
      }
      if (dropdown.selectedOption) {
        formdata.append(
          `space[${index}][ImageUrl]`,
          dropdown.selectedOption.url
        );
      }
    });

    dispatch(updateProperty(id, formdata));
    // if (updated) {
    toast.success("updated successfull");
    // }
  };

  // console.log("image", image);

  useEffect(() => {
    dispatch(getPropertyById(id));
    dispatch(getAllSpaces());
    dispatch(getAllAmenities());
    dispatch(getPropertyCollection());
    dispatch(getPropertyFeatures());
    dispatch(getAllPropertyType());
    dispatch(getAllCategory());

    if (error) {
      // toast.error(error);
      dispatch(clearError);
    }
  }, [dispatch, updated, error, id]);
  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full">
        {loading ? (
          <Loader />
        ) : (
          <form
            onSubmit={handleSubmit}
            className=" mx-auto p-6 bg-white rounded-lg shadow-md"
          >
            <h1 className="text-2xl font-bold mb-6">Update Property</h1>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Internal Name
              </label>
              <input
                type="text"
                name="internal_name"
                value={formData.internal_name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            {/* Address and city */}

            <div className="mb-4 w-full">
              <label className="block text-sm font-medium text-gray-700">
                Complete Address
              </label>
              <input
                type="text"
                name="completeAddress"
                value={formData.completeAddress}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <input
                  type="text"
                  name="Address"
                  value={formData.Address}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              {/* city */}
              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  City
                </label>

                <select
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select City</option>
                  <option value="north goa">North Goa</option>
                  <option value="south goa">South Goa</option>
                  <option value="dehradun">Dehradun</option>
                  <option value="mussoorie">Mussoorie</option>
                  {/* <option value="dehradun">Dehradun</option> */}
                  <option value="rishikesh">Rishikesh</option>
                  <option value="mukteshwar">Mukteshwar</option>
                  <option value="delhi">Delhi</option>
                  <option value="jaipur">Jaipur</option>
                  <option value="varanasi">Varanasi</option>
                </select>
              </div>
            </div>

            {/* state and country */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* pincode and max guests */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Pincode
                </label>
                <input
                  type="number"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Max Guests
                </label>
                <input
                  type="number"
                  name="max_guests"
                  value={formData.max_guests}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Picture
              </label>
              <input
                type="file"
                name="image"
                onChange={(e) => setImage(e.target.files)}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                multiple
              />
            </div>
            {/* 
            <DndProvider backend={HTML5Backend}>
              <DragImageList
                images={images}
                setImages={setImages}
                setDeleteImage={setDeleteImage}
              />
            </DndProvider> */}
            <div className="flex">
              <div className="flex flex-wrap items-center">
                {images &&
                  images.map((image, index) => {
                    return (
                      <div className="relative group">
                        <img
                          key={image.id}
                          src={image.url}
                          className={`w-[100px] h-[100px] border border-1 rounded-md p-1 m-[5px] border-red-500 ${
                            draggedIndex === index
                          }`}
                          alt="swap"
                          draggable
                          onDragStart={() => handleDragStart(index)}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={() => handleDrop(index)}
                        />
                        <MdDelete size={25}
                          onClick={() => OnHandleDelete(image.url)}
                          className="absolute top-2 right-2 text-red-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          

            {/* spaces */}
            <h1>Select your Spaces</h1>
            <div className="flex w-full">
              <div className="w-full flex flex-col">
                {dropdowns.map((dropdown, index) => (
                  <div key={index} className="w-full border flex mb-4">
                    <div className="flex items-center justify-center w-full">
                      {/* Space Dropdown */}
                      <div className="w-full">
                        <select
                          onChange={(e) =>
                            handleSpaceChange(index, e.target.value)
                          }
                          className="border rounded p-2 w-full"
                          value={dropdown.selectedSpace}
                        >
                          <option value="">Select space</option>
                          {AllSpaces &&
                            AllSpaces.map((space) => (
                              <option key={space._id} value={space._id}>
                                {space.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {/* Image Dropdown */}
                      <div className="relative inline-block w-full">
                        {/* Selected Option */}
                        <div
                          className="border rounded p-2 cursor-pointer"
                          onClick={() => toggleDropdown(index)}
                        >
                          {dropdown.selectedOption ? (
                            <div className="flex items-center">
                              <img
                                src={dropdown.selectedOption.url}
                                alt={dropdown.selectedOption.label}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "10px",
                                  borderRadius: "5px",
                                }}
                              />
                              {dropdown.selectedOption.label}
                            </div>
                          ) : (
                            <span>Select an image</span>
                          )}
                        </div>

                        {/* Dropdown Options */}
                        {dropdown.isOpen && (
                          <div className="absolute z-10 h-[200px] border bg-white w-full mt-2 rounded shadow-lg overflow-y-scroll">
                            {propertyId.picture.map((option, optionIndex) => (
                              <div
                                key={optionIndex}
                                onClick={() => handleSelect(index, option)}
                                className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                              >
                                <img
                                  src={option.url}
                                  alt={option.label}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                  }}
                                />
                                <span>{option.label}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Add new dropdown set */}
              <button
                className="pl-5 pr-5 h-10 font-bold text-2xl bg-[#add8e6]"
                onClick={addDropdown}
              >
                +
              </button>
            </div>

            {/* space */}
            <div className="flex">
              {propertyId &&
                propertyId.space.map((spa) => {
                  return (
                    <div className="m-2">
                      <h1>{spa.spaceId.name}</h1>
                      <img className="w-10" src={spa.ImageUrl} alt="" />
                    </div>
                  );
                })}
            </div>

            {/* Amentites */}
            <div className="relative w-full flex">
              <div className="w-full">
                <label className="block text-gray-700">Amenities</label>
                <div className="relative inline-block text-left w-full">
                  <button
                    onClick={toggleDropdownAmenities}
                    className="w-full flex items-center justify-between input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="button"
                  >
                    Select Amenities
                    <svg
                      className="w-2.5 h-2.5 ms-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  <div className="w-full">
                    {isOpen && (
                      <div className="w-full z-10 absolute right-0 mt-2 bg-indigo-200 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                        {/* Search Input */}
                        <div className="p-1 w-full">
                          <input
                            type="text"
                            placeholder="Search amenities..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          />
                        </div>

                        {/* Checkbox List */}
                        <ul className="w-full p-3 space-y-2 text-sm text-gray-700 dark:text-gray-200">
                          {filteredAmenities?.length > 0 ? (
                            filteredAmenities &&
                            filteredAmenities.map((amenity) => (
                              <li key={amenity._id}>
                                <div className="flex items-center">
                                  <input
                                    id={amenity._id}
                                    type="checkbox"
                                    checked={selectedAmenities.includes(
                                      amenity._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(amenity._id)
                                    }
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                                  />
                                  <label
                                    htmlFor={amenity._id}
                                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >
                                    {amenity.name}
                                  </label>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li className="text-gray-500">
                              No amenities found
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Property Type */}
            <div className="flex w-full mt-4">
              <div className="mb-4 w-full">
                <label className="block text-gray-700 mb-2">
                  Property Type
                </label>
                <select
                  name="propertyType"
                  value={propertyType}
                  onChange={(e) => setPropertyType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select a property type
                  </option>
                  {allProprtyType &&
                    allProprtyType.map((propertyType) => {
                      // console.log(propertyType);
                      return (
                        <option value={propertyType._id}>
                          {propertyType.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              {/* collection property */}
              <div className="mb-4 w-full ml-3">
                <label className="block text-gray-700 mb-2">Collection</label>
                <select
                  name="collection"
                  value={collection}
                  onChange={(e) => setCollection(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select a collection
                  </option>
                  {collections &&
                    collections.map((col) => {
                      // console.log(col);
                      return <option value={col._id}>{col.name}</option>;
                    })}
                </select>
              </div>
            </div>

            {/* features property */}
            <div className="flex w-full">
              <div className="mb-4 w-full">
                <label className="block text-gray-700 mb-2">Features</label>
                <select
                  name="features"
                  value={features}
                  onChange={(e) => setFeatures(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select a features
                  </option>
                  {Allfeatures &&
                    Allfeatures.map((col) => {
                      // console.log(col);
                      return <option value={col._id}>{col.name}</option>;
                    })}
                </select>
              </div>

              {/* category */}
              <div className="mb-4 w-full ml-3">
                <label className="block text-gray-700 mb-2">Category</label>
                <select
                  name="property_type"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {Allcategory &&
                    Allcategory.map((category) => {
                      return (
                        <option value={category._id}>{category.name}</option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="flex w-full">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Executive Name
                </label>
                <input
                  type="text"
                  name="executiveName"
                  value={formData.executiveName}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  required
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Executive Number
                </label>
                <input
                  type="text"
                  name="executiveNumber"
                  value={formData.executiveNumber}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* video url */}
            <div className="mb-4 mt-5">
              <label className="block text-sm font-medium text-gray-700">
                Video Link (URL)
              </label>
              <input
                type="text"
                name="video_link"
                value={formData.video_link}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            {/* summary */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Summary
              </label>
              <ReactQuill theme="snow" value={summary} onChange={setSummary} />
            </div>

            {/* about space */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                About Space
              </label>
              <ReactQuill
                theme="snow"
                value={aboutspace}
                onChange={setAboutSpace}
              />
            </div>

            {/* house rules */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                House Rules
              </label>
              <ReactQuill
                theme="snow"
                value={houseRules}
                onChange={setHouseRule}
              />
            </div>

            {/* things to know */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Things to know
              </label>
              <ReactQuill
                theme="snow"
                value={thingToKnow}
                onChange={setThingToKnow}
              />
            </div>

            {/* cancellation_policy */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Cancellation Policy
              </label>
              <ReactQuill
                theme="snow"
                value={cancellation_policy}
                onChange={setCancellationPolicy}
              />
            </div>

            {/* bedrooms and beds */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Accommodation Bedrooms
                </label>
                <input
                  type="number"
                  name="accomodation_bedrooms"
                  value={formData.accomodation_bedrooms}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Accommodation Beds
                </label>
                <input
                  type="number"
                  name="accomodation_beds"
                  value={formData.accomodation_beds}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* washrooms and deposits */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Accommodation Washrooms
                </label>
                <input
                  type="number"
                  name="accomodation_washrooms"
                  value={formData.accomodation_washrooms}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Security Deposit
                </label>
                <input
                  type="number"
                  name="security_Deposit"
                  value={formData.security_Deposit}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* check in and check out */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700 capitalize">
                  check in time
                </label>
                <input
                  type="time"
                  name="check_in_time"
                  value={formData.check_in_time}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700 capitalize">
                  check out time
                </label>
                <input
                  type="time"
                  name="check_out_time"
                  value={formData.check_out_time}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* select your cordinate  using map*/}
            <div className="mt-4">
              <button
                type="button"
                onClick={() => setToggle(!toggle)}
                class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              >
                Select your property address
              </button>
              <div className="sticky">
                {toggle ? (
                  <MapComponent
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* diraction url */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Direction (URL)
              </label>
              <input
                type="text"
                name="mapUrl"
                value={formData.mapUrl}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            {/* nearest point  */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Nearest </label>
              <ReactQuill
                theme="snow"
                value={nearestPoint}
                onChange={setNearestPoint}
              />
            </div>

            {/* 50 % enabled */}
            <div className="border mt-3 p-3">
              <h1 className="font-semibold text-lg">Booking enable for 50%</h1>
              <div className="flex pt-3">
                <div className="mb-4 w-full">
                  <label className="block text-sm font-medium text-gray-700">
                    50 % enablement
                  </label>
                  <input
                    type="number"
                    name="PercentEnable"
                    value={formData.PercentEnable}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  />
                </div>

                <div className="mb-4 w-full ml-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Minimum reservation price
                  </label>
                  <input
                    type="number"
                    name="minimumReservationPrice"
                    value={formData.minimumReservationPrice}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  />
                </div>
                <div className="mb-4 w-full ml-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Checkin Date Distance
                  </label>
                  <input
                    type="number"
                    name="checkinDateDistance"
                    value={formData.checkinDateDistance}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  />
                </div>
              </div>
            </div>

            {/* instant bookin and same day instant booking */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Instant Booking
                </label>
                <select
                  name="instant_booking"
                  value={formData.instant_booking}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Same Day Instant Booking
                </label>
                <select
                  name="same_day_instant_booking"
                  value={formData.same_day_instant_booking}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>

            {/* availability in advance and starting from price */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Availability in Advance (days)
                </label>
                <input
                  type="number"
                  name="availability_in_advance"
                  value={formData.availability_in_advance}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Starting from Price
                </label>
                <input
                  type="number"
                  name="starting_from_price"
                  value={formData.starting_from_price}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* extra guest price  */}
            <div className="flex">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Extra guest
                </label>
                <Counter
                  setCount={setCount}
                  count={count}
                  propertyId={propertyId}
                />
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Price Per extra guest
                </label>
                <input
                  type="text"
                  name="after_extra_guest_fee"
                  value={formData.after_extra_guest_fee}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>

            {/* channel manager */}
            <div className="flex items-center">
              <div className="relative w-full">
                <label className="block text-gray-700">Channel Manager 1</label>
                <input
                  type="text"
                  name="channel_manager1"
                  value={formData.channel_manager1}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* chanel manager 2 */}
              <div className="relative w-full ml-3">
                <label className="block text-gray-700 capitalize">
                  channel manager unitType Id
                </label>
                <input
                  type="text"
                  name="channel_manager_unitTypeId"
                  value={formData.channel_manager_unitTypeId}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* live and home page display */}
            <div className="flex mt-3">
              <div className="mb-4 w-full">
                <label className="block text-sm font-medium text-gray-700">
                  Live (Yes/No)
                </label>
                <select
                  name="live"
                  value={formData.live}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">Select live</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              <div className="mb-4 w-full ml-3">
                <label className="block text-sm font-medium text-gray-700">
                  Home Page Display
                </label>
                <select
                  name="home_page_display"
                  value={formData.home_page_display}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">Select home page display</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default UpdateProperty;

import React, { useEffect, useState } from 'react'
import SideMenu from '../../sideMenu/SideMenu'
import { useDispatch, useSelector } from "react-redux";
import { createProperty } from '../../../../redux/actions/propertyActions/propertyType';
import { toast } from 'react-toastify';



const PropertyType = () => {
  const [propertyType,setPropertyType] = useState("");
  const dispatch = useDispatch()
  const {success} = useSelector((state)=>state.propertyType)

  const handleSubmit = (e) =>{
    e.preventDefault()

    const data = {
      name: propertyType
    }

    dispatch(createProperty(data))
  }

  useEffect(()=>{
    if(success){
      toast.success("property type created successfull")
    }
  },[dispatch,success])

  return (
    <div className='flex pt-20'>
      <SideMenu />
      <div className="w-full">
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">
            Create Property type
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="categoryName"
              >
                Property Type Name
              </label>
              <input
                type="text"
                id="proeprtyType"
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter proeprty Type name"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Property Type
            </button>
          </form>
        </div>
      </div>
      </div>
  )
}

export default PropertyType
import Ownership  from "../../image/ownership.png"
import Property_Management from "../../image/real-estate.png"
import Revenue_Management from "../../image/earning.png"
import Trusted_Partnership from "../../image/handshake.png"
import Personal_Use from "../../image/house.png"
import Points from "../../image/loyal-customer.png"

export const OwnersObj = [
    {
        id: 0,
        icons: Ownership,
        title: "Hassle-Free Ownership",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    },
    {
        id: 1,
        icons: Property_Management,
        title: "Effortless Property Management",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    },
    {
        id: 2,
        icons: Revenue_Management,
        title: "Revenue Management and Maximized Earnings",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    },
    {
        id: 3,
        icons: Trusted_Partnership,
        title: "Transparent & Trusted Partnership",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    },
    {
        id: 4,
        icons: Personal_Use,
        title: "Unlimited Personal Use",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    },
    {
        id: 5,
        icons: Points,
        title: "Alohas Points",
        // summary: "Lorem Ipsum is simply dummy text of the printing and type setting industry. "
    }

]
import axios from "axios";
import {
  GET_ALL_PROERTY_FEATURES_REQUEST,
  GET_ALL_PROERTY_FEATURES_SUCCESS,
  GET_ALL_PROERTY_FEATURES_FAIL,
  CREATE_PROPERTY_FEATURES_REQUEST,
  CREATE_PROPERTY_FEATURES_FAIL,
  CREATE_PROPERTY_FEATURES_SUCCESS,
} from "../../constant/propertyConstant/propertyFeatures";

// create property Features
export const createFeatures = (features) =>async(dispatch)=>{
  try {
    dispatch({
      type: CREATE_PROPERTY_FEATURES_REQUEST
    })

    const {data} = await axios.post("/api/v1/new-feature",features);

    dispatch({
      type: CREATE_PROPERTY_FEATURES_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: CREATE_PROPERTY_FEATURES_FAIL,
      payload: error.response.data.message
    })
  }
}

// get all property features 
export const getPropertyFeatures = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PROERTY_FEATURES_REQUEST,
    });

    const { data } = await axios.get("/api/v1/list-features");
    // console.log(data);
    dispatch({
      type: GET_ALL_PROERTY_FEATURES_SUCCESS,
      payload: data.feature,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PROERTY_FEATURES_FAIL,
      payload: error.response.data.message,
    });
  }
};

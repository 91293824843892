import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperty } from "../../redux/actions/propertyActions/PropertyAction";
import PropertyCard from "./propertyCard";
import SearchBar from "../PropertySearch/PropertySearch";
import SmallScreenSearch from "../PropertySearch/SmallScreenSearch";

const AllProperty = () => {
  const dispatch = useDispatch();
  const { propertyList } = useSelector((state) => state.propertyList);
  // const { search } = useSelector((state) => state.search);

  // console.log(search);

  useEffect(() => {
    dispatch(getAllProperty());
  }, [dispatch]);
  return (
    <div className="pt-20 pb-10">
      <div className="flex items-center justify-center pt-10">
      <div className="hidden md:block w-11/12">
        <SearchBar />
      </div>
      <div className="block md:hidden w-11/12">
        <SmallScreenSearch />
      </div>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {/* {search?.properties && search?.properties.length > 0 ? (
          <div className="flex flex-wrap items-center justify-center pt-10">
            {search?.properties &&
              search?.properties.map((property) => {
                if (property.live === "Yes") {
                  return <PropertyCard property={property} />;
                }
                return null;
              })}
          </div> */}
        {/* ) : ( */}
          <div className="flex flex-wrap items-center justify-center pt-5 md:pt-10">
            {propertyList &&
              propertyList.map((property) => {
                if (property.live === "Yes") {
                  return <PropertyCard property={property} key={property._id} />;
                }
                return null;
              })}
          </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default AllProperty;

import {
  CREATE_PROPERTY_SPACES_REQUEST,
  CREATE_PROPERTY_SPACES_SUCCESS,
  CREATE_PROPERTY_SPACES_FAIL,
  GET_ALL_PROPERTY_SPACES_REQUEST,
  GET_ALL_PROPERTY_SPACES_SUCCESS,
  GET_ALL_PROPERTY_SPACES_FAIL,
} from "../../constant/propertyConstant/propertySpacesConstant";

export const propertySpaceReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROPERTY_SPACES_REQUEST:
    case GET_ALL_PROPERTY_SPACES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PROPERTY_SPACES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PROPERTY_SPACES_SUCCESS:
      return {
        ...state,
        loading: false,
        AllSpaces:action.payload
      };
    case CREATE_PROPERTY_SPACES_FAIL:
    case GET_ALL_PROPERTY_SPACES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

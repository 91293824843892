import React from "react";
import Banner from "./image/banner.webp";

const CancellationsAndRefunds = () => {
  return (
    <div className="pb-20 font-Mont ">
      <div
        className="h-[400px] w-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
        }}
      >
        <div className="w-full h-[400px] flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
          <div className="pt-20 md:pt-0">
            <h1 className="mt-3 font-bold md:font-semibold text-4xl text-white text-center">
              Cancellations And Refunds
            </h1>
          </div>
        </div>
      </div>
      <div className="pl-7  md:pl-10 pr-5 md:pr-10 pt-5 md:pt-10">
        <ul className="list-decimal">
          <li className="font-medium text-base text-justify">
            If you wish to cancel your reservation 14 days prior to the arrival
            date, we will refund 90%* of the booking value in the original
            payment mode or 100% in the form of a future credit note with a 3
            months validity.
          </li>
          <li className="font-medium text-base text-justify">
            Cancellations made between 7-14 days before the arrival date will
            receive a 50% refund of the total booking value or 60% in the form
            of a future credit note with a 3 months validity.
          </li>
          <li className="font-medium text-base text-justify">
            For any cancellations requested within 7 days of the check-in date,
            the booking will be non-refundable. Additionally, if the booking has
            been partially paid for, the cancellation will be deemed
            non-refundable in totality.
          </li>
          <li className="font-medium text-base text-justify">
            Please note that our cancellation policy does not apply to peak
            dates.
          </li>
        </ul>

        <div className="mt-5">
          <h2 className="font-bold md:font-semibold text-lg"># Peak Dates:</h2>
          <ul className="list-disc">
            <li>
              <span className="font-bold md:font-semibold text-base">Holi:</span> 14th to
              15th March 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Ramzan Eid:</span> 28th
              March to 31st April 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Good Friday:</span> 18th
              April to 20th April 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Independence Day:</span>{" "}
              15th to 17th August 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Gandhi Jayanti:</span>{" "}
              2nd to 4th October 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Bhai Dooj:</span> 23rd
              to 25th October 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">
                Christmas &amp; New Year:
              </span>{" "}
              22nd December to 4th January 2025
            </li>
            <li>
              <span className="font-bold md:font-semibold text-base">Republic Day:</span>{" "}
              23rd January to 26th January 2026
            </li>
          </ul>
        </div>
        <p className="font-bold md:font-semibold text-base text-justify">
          * Please note: A cancellation fee amounting to 10% of the booking value
          shall be levied for all refunds processed by Da Alohas. This fee
          primarily covers platform charges, convenience fees, and processing
          charges.*
        </p>
      </div>
    </div>
  );
};

export default CancellationsAndRefunds;

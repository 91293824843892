import React, { useEffect } from "react";
import { allPackage } from "../../redux/actions/PackagesAction/PackagesAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import PackagesCard from "./PackagesCard";

const Packages = () => {
  const { PackageList, loading } = useSelector((state) => state.packages);
  const dispatch = useDispatch();
  console.log(PackageList);

  useEffect(() => {
    dispatch(allPackage());
  }, [dispatch]);
  return (
    <div>
   
      <div className="font-Mont w-full">
        <div className="flex flex-col items-center justify-between mb-10 font-Mont w-full">
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <div className="flex flex-wrap items-center justify-around pt-20 w-full">
              {PackageList &&
                PackageList.filter((packages) => packages.live === "Yes").map(
                  (packages) => {
                    return (
                      <PackagesCard packages={packages} key={packages._id} />
                    );
                  }
                )}
            </div>
          )}
          {/* <Link
            to="/all-locations"
            className="bg-black text-white pl-10 pr-10 pt-3 pb-3 mt-10 font-medium text-lg rounded"
          >
            View All
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Packages;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SideMenu from "../sideMenu/SideMenu";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableImage from "../Property/Property/Drag_and_Drop";
import ReactQuill from "react-quill";
import {
  getPackagesById,
  updatePackages,
} from "../../../redux/actions/PackagesAction/PackagesAction";
import { getAllPlaceToVisit } from "../../../redux/actions/PackagesAction/PackagesPlaceToVisitAction";
import { getAllPackageAmenities } from "../../../redux/actions/PackagesAction/PackageAmenitiesAction";
import { getAllPackageType } from "../../../redux/actions/PackagesAction/PackageTypeAction";
import { getAllPackageCategory } from "../../../redux/actions/PackagesAction/PackageCategoryAction";

const UpdatePackage = () => {
  const dispatch = useDispatch();
  const [cancellation_policy, setCancellationPolicy] = useState("");
  const [summary, setSummary] = useState("");
  const [highlights, setHighlights] = useState("");
  const navigate = useNavigate();
  const [inclusions, setInclusions] = useState("");
  const [exclusions, setExclusions] = useState("");
  const [packagesType, setPackageType] = useState("");
  const [operatorDetails, setOperatorDetails] = useState("");
  const [packageCategorys, setPackageCategory] = useState("");
  const [termsAndConditions, setTermsandConditions] = useState("");
  const [guidelines, setGuidelines] = useState("");

  const [dropdowns, setDropdowns] = useState([
    { selectedSpace: "", selectedOption: null, isOpen: false },
  ]);
  const [itinerarypdf, setItinerarypdf] = useState(null);
  const { id } = useParams();

  // console.log(packagesType);

  const [formData, setFormData] = useState({
    title: "",
    internal_name: "",
    origin_city: "",
    country: "",
    pincode: "",
    video_link: "",
    price: "",
    percent:"",
    duration: "",
    operator_details: "",
  });

  const [live, setLive] = useState(false);
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  //   console.log(images)
  const [itinerary, setItinerary] = useState([
    {
      title: "",
      summary: "",
      amenities: [],
    },
  ]);

  const { packageId, error } = useSelector((state) => state.packages);
  const { allPlace } = useSelector((state) => state.placeVisit);
  const { packageAmenities } = useSelector((state) => state.packageAmenities);
  const { allPackgeType } = useSelector((state) => state.packageType);
  const { packageCategory } = useSelector((state) => state.packageCategory);

  // console.log(packageCategorys)
  // console.log(packagesType)
  // console.log(packageId);
  //   console.log(id);

  const addMoreItinerary = () => {
    setItinerary([
      ...itinerary,
      {
        title: "",
        summary: "",
        amenities: [],
      },
    ]);
    setIsOpen((prevState) => ({
      ...prevState,
      [itinerary.length]: false, 
    }));
  };

  const handleCheckboxChange = (amenityId, index) => {
    setItinerary((prevItinerary) =>
      prevItinerary.map((item, i) => {
        if (i === index) {
          const isAmenitySelected = item.amenities.includes(amenityId);
          return {
            ...item,
            amenities: isAmenitySelected
              ? item.amenities.filter((id) => id !== amenityId) // Remove if already selected
              : [...item.amenities, amenityId], // Add if not selected
          };
        }
        return item;
      })
    );
  };

  const handleChangeItinerary = (e, index) => {
    const data = [...itinerary];
    data[index][e.target.name] = e.target.value;
    setItinerary(data);
  };

  // New handler for ReactQuill
  const handleChangeQuill = (value, index) => {
    const data = [...itinerary];
    data[index].summary = value;
    setItinerary(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
  };

  // upload pdf
  const handleFilePdfChange = (e) => {
    if (e.target.files.length > 0) {
      setItinerarypdf(e.target.files[0]);
    }
  };

  // console.log(itinerarypdf);

  // image
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const fileObjects = files.map((file) => ({
      url: URL.createObjectURL(file),
      file,
      _id: file.name,
    }));

    // Update the images state
    setImages((prevImages) => [...prevImages, ...fileObjects]);
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages); // Update state with the new order
  };

  const handleSpaceChange = (index, value) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].selectedSpace = value;
    setDropdowns(newDropdowns);
  };

  const handleSelect = (index, option) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].selectedOption = option;
    newDropdowns[index].isOpen = false;
    setDropdowns(newDropdowns);
  };

  const toggleDropdowns = (index) => {
    const newDropdowns = [...dropdowns];
    newDropdowns[index].isOpen = !newDropdowns[index].isOpen;
    setDropdowns(newDropdowns);
  };

  const toggleDropdown = (index) => {
    // console.log(index)
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index], 
    }));
  };

  const addDropdown = (e) => {
    e.preventDefault();
    setDropdowns([
      ...dropdowns,
      { selectedSpace: "", selectedOption: null, isOpen: false },
    ]);
  };

  useEffect(() => {
    if (packageId) {
      setFormData({
        title: packageId?.title,
        internal_name: packageId?.internal_name,
        origin_city: packageId?.origin_city,
        state: packageId?.state,
        country: packageId?.country,
        pincode: packageId?.pincode,
        video_link: packageId?.video_link,
        duration: packageId.duration,
        price: packageId?.price,
        percent: packageId?.percent,
        mapUrl: packageId?.mapUrl,
      });
    }

    if (packageId) {
      setPackageType(packageId.package_type);
      // console.log(packageId.package_type)
    }

    if (packageId) {
      setImages(packageId.picture);
    }

    if (packageId) {
      setExclusions(packageId.exclusions);
      setSummary(packageId.summary);
      setInclusions(packageId.inclusions);
      setCancellationPolicy(packageId.cancellation_policy);
      setHighlights(packageId.highlights);
      setOperatorDetails(packageId.operator_details);
      setLive(packageId.live);
      setPackageCategory(packageId.package_category);
      setGuidelines(packageId.guidelines);
      setTermsandConditions(packageId.terms_and_conditions);
      setItinerarypdf(packageId.itinerary_pdf)
      // console.log(packageId.package_category)

    }

    // if (packageId && packageId.places_to_visit) {
    //   const places = [];
    //   for (let i = 0; i < packageId.places_to_visit.length; i++) {
    //     places.push({
    //       selectedSpace: packageId.places_to_visit[i].placeId,
    //       selectedOption: packageId.places_to_visit[i].ImageUrl,
    //       isOpen: false,
    //     });
    //   }
    //   setDropdowns(places);
    // }

    if (packageId && packageId?.itinerary) {
      let amenity = [];
      for (let i = 0; i < packageId.itinerary.length; i++) {
        amenity.push({
          title: packageId.itinerary[i].title || "",
          summary: packageId.itinerary[i].summary || "",
          amenities: packageId.itinerary[i].package_amenities || [],
        });
      }
      setItinerary(amenity);
    }
  }, [packageId]);

  // console.log("dropdown",dropdowns)

  // console.log(itinerary);
  // Submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    const data = new FormData();
    data.append("title", formData.title);
    data.append("internal_name", formData.internal_name);
    data.append("origin_city", formData.origin_city);
    data.append("country", formData.country);
    data.append("pincode", formData.pincode);
    for (let i = 0; i < images.length; i++) {
      data.append("picture", images[i].file);
    }
    data.append("video_link", formData.video_link);
    data.append("live", live);
    data.append("starting_from_price", formData.price);
    data.append("summary", summary);
    data.append("cancellation_policy", cancellation_policy);
    data.append("operator_details", operatorDetails);
    // data.append("itinerary", JSON.stringify(itinerary));
    data.append("highlights", highlights);
    // data.append("itinerary_pdf",itinerarypdf)
    // console.log(itinerary);

    data.append("inclusions", inclusions);
    data.append("exclusions", exclusions);
    data.append("duration", formData.duration);
    data.append("price", formData.price);
    data.append("percent",formData.percent)
    data.append("package_type", packagesType);
    data.append("package_category", packageCategorys);

    // console.log("packageCategorys",packageCategorys)

    data.append("terms_and_conditions", termsAndConditions);
    data.append("guidelines", guidelines);
    data.append("itinerary", JSON.stringify(itinerary));
    data.append("itinerary_pdf", itinerarypdf);

    const placesToVisit = dropdowns.map((dropdown) => ({
      placeId: dropdown.selectedSpace || "",
      ImageUrl: dropdown.selectedOption ? dropdown.selectedOption.url : "",
    }));

    data.append("places_to_visit", JSON.stringify(placesToVisit));

    // dropdowns.forEach((dropdown, index) => {
    //   // console.log(dropdown);
    //   if (dropdown.selectedSpace) {
    //     data.append(
    //       `places_to_visit[${index}][placeId]`,
    //       dropdown.selectedSpace
    //     );
    //   }
    //   if (dropdown.selectedOption) {
    //     data.append(
    //       `places_to_visit[${index}][ImageUrl]`,
    //       dropdown.selectedOption.url
    //     );
    //   }
    // });
    // data.append("places_to_visit",placeToVisit)

    dispatch(updatePackages(id, data));
    toast.success("update package successfull");
  };

  // console.log(images);

  useEffect(() => {
    return () => {
      images && images.forEach((image) => URL.revokeObjectURL(image.url));
    };
  }, [images]);

  // console.log(isUpdate)

  useEffect(() => {
    // if (isUpdate) {
    //   toast.success(isUpdate.message);
    //   console.log(isUpdate)
    //   // setTimeout(() => {
    //   //   navigate("/dashboard/package/list");
    //   // }, 3000);
    // }
    if (error) {
      toast.error(error);
    }
    dispatch(getAllPackageCategory());
    dispatch(getAllPlaceToVisit());
    dispatch(getPackagesById(id));
    dispatch(getAllPackageAmenities());
    dispatch(getAllPackageType());
  }, [dispatch, error, id, navigate]);
  return (
    <div className="flex pt-20 font-Mont">
      <SideMenu className="" />
      <div className="w-full bg-gray-100 p-3 flex items-center justify-center overflow-y-scroll">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-4 rounded-lg shadow-lg w-full"
        >
          {/* <MapComponent /> */}
          <h2 className="text-3xl font-bold mb-8 text-center">
            Update Package
          </h2>

          <div className="">
            {/* Title */}
            <div className="flex space-x-2">
              <div className="relative w-full">
                <label className="block text-gray-700">Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Internal Name */}
              <div className="relative w-full">
                <label className="block text-gray-700">Internal Name</label>
                <input
                  type="text"
                  name="internal_name"
                  value={formData.internal_name}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="flex space-x-2">
              {/* Address */}
              <div className="relative w-full">
                <label className="block text-gray-700">Duration</label>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* city */}
              <div className="relative w-full">
                <label className="block text-gray-700">City</label>
                <input
                  type="text"
                  name="origin_city"
                  value={formData.origin_city}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="flex space-x-2">
              {/* Country */}
              <div className="relative w-full">
                <label className="block text-gray-700">Country</label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Pincode */}
              <div className="relative w-full">
                <label className="block text-gray-700">Pincode</label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="flex space-x-2">
              {/* Video Link */}
              <div className="relative w-full">
                <label className="block text-gray-700">Video Link</label>
                <input
                  type="url"
                  name="video_link"
                  value={formData.video_link}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/*starting from price  */}
              <div className="relative w-full">
                <label className="block text-gray-700">Price</label>
                <input
                  type="text"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
               {/*starting from price  */}
               <div className="relative w-full">
                <label className="block text-gray-700">Percent Off</label>
                <input
                  type="text"
                  name="percent"
                  value={formData.percent}
                  onChange={handleChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="flex space-x-2 w-full">
              <div className="mb-4 w-full mt-2">
                <label className="block text-gray-700  capitalize">
                  package type
                </label>
                <select
                  name="packagesType"
                  value={packagesType}
                  onChange={(e) => setPackageType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select package type
                  </option>
                  {allPackgeType &&
                    allPackgeType.map((pal) => {
                      // console.log(pal);
                      return (
                        <option key={pal._id} value={pal._id}>
                          {pal.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="mb-4 w-full mt-2">
                <label className="block text-gray-700  capitalize">
                  package Category
                </label>
                <select
                  name="packageCategorys"
                  value={packageCategorys}
                  onChange={(e) => setPackageCategory(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select package Category
                  </option>
                  {packageCategory &&
                    packageCategory.map((pal) => {
                      // console.log(pal);
                      return (
                        <option key={pal._id} value={pal._id}>
                          {pal.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {/*starting from price  */}
            <div className="relative w-full mt-3 mb-3">
              <label className="block text-gray-700">
                Upload itinerary pdf
              </label>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFilePdfChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {/* <iframe src={itinerarypdf} className="w-full h-[300px]" /> */}
              {itinerarypdf   && (
                <p className="mt-2 text-sm text-gray-600">
                  Selected File: {itinerarypdf.name}
                </p>
              )}
            </div>

            {/* Picture */}
            <DndProvider backend={HTML5Backend}>
              <div className="image-upload-container">
                <label className="text-gray-700 mt-4">Upload New Images:</label>
                <input
                  type="file"
                  name="picture"
                  onChange={handleFileChange}
                  className="input-transition mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  multiple
                />

                <div className="flex flex-wrap justify-start">
                  {images &&
                    images.map((file, index) => (
                      <DraggableImage
                        key={file._id}
                        file={file}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                </div>
              </div>
            </DndProvider>

            <div className="w-full relative space-x-2">
              <h1 className="capitalize font-medium text-base">
                places to visit
              </h1>
              <div className="flex w-full">
                <div className="w-full flex flex-col">
                  {dropdowns.map((dropdown, index) => (
                    <div key={index} className="w-full  flex mb-4">
                      <div className="flex items-center justify-center w-full space-x-2">
                        {/* Space Dropdown */}
                        <div className="w-full">
                          <select
                            onChange={(e) =>
                              handleSpaceChange(index, e.target.value)
                            }
                            className="border rounded p-2 w-full"
                            value={dropdown.selectedSpace}
                          >
                            <option value="">Select Place</option>
                            {allPlace &&
                              allPlace.map((space) => (
                                <option key={space._id} value={space._id}>
                                  {space.name}
                                </option>
                              ))}
                          </select>
                        </div>

                        {/* Image Dropdown */}
                        <div className="relative inline-block w-full">
                          {/* Selected Option */}
                          <div
                            className="border rounded p-2 cursor-pointer"
                            onClick={() => toggleDropdowns(index)}
                          >
                            {dropdown.selectedOption ? (
                              <div className="flex items-center">
                                <img
                                  src={dropdown.selectedOption.url}
                                  alt={dropdown.selectedOption.label}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                  }}
                                />
                                {dropdown.selectedOption.label}
                              </div>
                            ) : (
                              <span>Select an image</span>
                            )}
                          </div>

                          {/* Dropdown Options */}
                          {dropdown.isOpen && (
                            <div className="absolute z-10 h-[200px] border bg-white w-full mt-2 rounded shadow-lg overflow-y-scroll">
                              {packageId.picture.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  onClick={() => handleSelect(index, option)}
                                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                                >
                                  <img
                                    src={option.url}
                                    alt={option.label}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      marginRight: "10px",
                                      borderRadius: "5px",
                                    }}
                                  />
                                  <span>{option.label}</span>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add new dropdown set */}
                <button
                  className="pl-5 pr-5 h-10 font-bold text-2xl bg-[#add8e6]"
                  onClick={addDropdown}
                >
                  +
                </button>
              </div>

              <div className="flex space-x-3">
                {
                  packageId && packageId.places_to_visit.map((place)=>{
                    return(
                      <div key={place._id} className="border p-1 flex flex-col items-center justify-center">
                        <img className="w-20" src={place.ImageUrl} alt={place.placeId.name} />
                        <h1>{place.placeId.name}</h1>
                        </div>
                    )
                  })
                }
              </div>
            </div>

            {/* Highlights */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Highlights</label>
              <ReactQuill
                theme="snow"
                value={highlights}
                onChange={setHighlights}
              />
            </div>

            {/*inclusions  */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Inclusions</label>
              <ReactQuill
                theme="snow"
                value={inclusions}
                onChange={setInclusions}
              />
            </div>

            {/* Exclusions */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Exclusions</label>
              <ReactQuill
                theme="snow"
                value={exclusions}
                onChange={setExclusions}
              />
            </div>

            {/* Summary */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Summary</label>
              <ReactQuill theme="snow" value={summary} onChange={setSummary} />
            </div>
          </div>

          {/* About Space */}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Cancellation Policy</label>
            <ReactQuill
              theme="snow"
              value={cancellation_policy}
              onChange={setCancellationPolicy}
            />
          </div>

          {/* operator_details */}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Operator Details</label>
            <ReactQuill
              theme="snow"
              value={operatorDetails}
              onChange={setOperatorDetails}
            />
          </div>

          {/* terms and conditions */}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Terms and Conditions</label>
            <ReactQuill
              theme="snow"
              value={termsAndConditions}
              onChange={setTermsandConditions}
            />
          </div>

          {/* duidelines */}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Guidelines</label>
            <ReactQuill
              theme="snow"
              value={guidelines}
              onChange={setGuidelines}
            />
          </div>

          <div>
            <div>
              <h1 className="font-bold text-lg text-gray-800">
                Tour Itinerary
              </h1>
              {itinerary &&
                itinerary.map((tour, index) => (
                  <div
                    key={index}
                    className="flex flex-col mt-4 space-y-4 bg-gray-50 p-4 rounded-lg shadow"
                  >
                    <label className="block text-gray-700 font-medium">
                      Day {index + 1}
                    </label>
                    <input
                      type="text"
                      name="title"
                      value={tour.title}
                      placeholder="Title"
                      onChange={(e) => handleChangeItinerary(e, index)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                    />
                    <ReactQuill
                      theme="snow"
                      value={tour.summary}
                      onChange={(value) => handleChangeQuill(value, index)}
                    />

                    {/* Amenities */}
                    <div className="relative w-full">
                      <label className="block text-gray-700">
                        Package Amenities
                      </label>
                      <div className="relative inline-block text-left w-full">
                        <button
                          onClick={()=>toggleDropdown(index)}
                          className="w-full flex items-center justify-between input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          type="button"
                        >
                          Select Amenities
                          <svg
                            className="w-2.5 h-2.5 ms-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        <div className="w-full">
                          {isOpen[index] && (
                            <div className="w-full z-10 absolute right-0 mt-2 bg-indigo-200 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                              {/* Checkbox List */}
                              <ul className="w-full p-3 space-y-2 text-sm text-gray-700 dark:text-gray-200">
                                {packageAmenities.length > 0 ? (
                                  packageAmenities.map((amenity) => {
                                    console.log(amenity);
                                    console.log(tour);
                                    return (
                                      <li key={amenity._id}>
                                        <div className="flex items-center">
                                          <input
                                            id={amenity._id}
                                            type="checkbox"
                                            checked={tour.amenities.includes(
                                              amenity._id
                                            )}
                                            onChange={() =>
                                              handleCheckboxChange(
                                                amenity._id,
                                                index
                                              )
                                            }
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                                          />
                                          <label
                                            htmlFor={amenity._id}
                                            className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                          >
                                            {amenity.name}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  })
                                ) : (
                                  <li className="text-gray-500">
                                    No package amenities found
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {itinerary.length - 1 === index && (
                      <button
                        type="button"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg self-start hover:bg-blue-600"
                        onClick={addMoreItinerary}
                      >
                        Add More
                      </button>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="flex w-full mt-5">
            {/*going to live yes / NO */}
            <div className="mb-4 w-1/2">
              <label className="block text-gray-700 mb-2">
                Live (Yes / No)
              </label>
              <select
                name="live"
                value={live}
                onChange={(e) => setLive(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select (Yes/No)
                </option>

                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>

          {/* More fields */}
          {/* Continue adding the rest fields as demonstrated above */}

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePackage;

import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { createFeatures } from "../../../../redux/actions/propertyActions/PropertyFeatures";
import { toast } from "react-toastify";

const Features = () => {
    const [features,setFeatures] = useState("");
    const {success} = useSelector((state)=>state.feature)
    const dispatch = useDispatch()
    const handleSubmit = (e) =>{
        e.preventDefault()
        const data = {
            name : features
        }

        dispatch(createFeatures(data))
    }

    useEffect(()=>{
        if(success){
            toast.success("features created successfull")
        }
    },[dispatch,success])


  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full">
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">
            Create Features
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="categoryName"
              >
                Feature Name
              </label>
              <input
                type="text"
                id="collection"
                value={features}
                onChange={(e) => setFeatures(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter collection name"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Create Collection
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Features;

import axios from "axios";
import {
  CREATE_OFFER_PAGE_REQUEST,
  CREATE_OFFER_PAGE_SUCCESS,
  CREATE_OFFER_PAGE_FAIL,
  GET_ALL_OFFERS_FAIL,
  GET_ALL_OFFERS_REQUEST,
  GET_ALL_OFFERS_SUCCESS,
  GET_OFFER_BY_ID_FAIL,
  GET_OFFER_BY_ID_REQUEST,
  GET_OFFER_BY_ID_SUCCESS,
  UPDATE_OFFER_BY_ID_FAIL,
  UPDATE_OFFER_BY_ID_REQUEST,
  UPDATE_OFFER_BY_ID_SUCCESS,
} from "../../constant/OfferConstant/OfferConstant";

export const createOffer = (offerData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_OFFER_PAGE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      "/api/v1/create-offer-page",
      offerData,
      config
    );
    dispatch({
      type: CREATE_OFFER_PAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_OFFER_PAGE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all offer
export const getAllOffersPage = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_OFFERS_REQUEST,
    });

    const { data } = await axios.get("/api/v1/get-all-offers");
    dispatch({
      type: GET_ALL_OFFERS_SUCCESS,
      payload: data.allOffers,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_OFFERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get offer by id
export const getOfferById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_OFFER_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/get-all-offers/${id}`);

    dispatch({
      type: GET_OFFER_BY_ID_SUCCESS,
      payload: data.offerId,
    });
  } catch (error) {
    dispatch({
      type: GET_OFFER_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update offer
export const updateOffer = (id, updateData) => async (dispatch) => {
  console.log(updateData)
  try {
    dispatch({
      type: UPDATE_OFFER_BY_ID_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `/api/v1/get-all-offers/${id}`,
      updateData,
      config
    );
    dispatch({
      type: UPDATE_OFFER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_OFFER_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// create Amenities
export const CREATE_NEW_AMENITIES_REQUEST = "CREATE_NEW_AMENITIES_REQUEST"
export const CREATE_NEW_AMENITIES_SUCCESS = "CREATE_NEW_AMENITIES_SUCCESS"
export const CREATE_NEW_AMENITIES_FAIL = "CREATE_NEW_AMENITIES_FAIL"

// get all amenities
export const GET_ALL_AMENITIES_REQUEST = "GET_ALL_AMENITIES_REQUEST"
export const GET_ALL_AMENITIES_SUCCESS = "GET_ALL_AMENITIES_SUCCESS"
export const GET_ALL_AMENITIES_FAIL = "GET_ALL_AMENITIES_FAIL"

// get amenities by id 
export const GET_AMENITIES_BY_ID_REQUEST =  "GET_AMENITIES_BY_ID_REQUEST";
export const GET_AMENITIES_BY_ID_SUCCESS =  "GET_AMENITIES_BY_ID_SUCCESS";
export const GET_AMENITIES_BY_ID_FAIL =  "GET_AMENITIES_BY_ID_FAIL";

// update amenities
export const GET_AMENITIES_BY_ID_UPADTE_REQUEST =  "GET_AMENITIES_BY_ID_UPADTE_REQUEST";
export const GET_AMENITIES_BY_ID_UPADTE_SUCCESS =  "GET_AMENITIES_BY_ID_UPADTE_SUCCESS";
export const GET_AMENITIES_BY_ID_UPADTE_FAIL =  "GET_AMENITIES_BY_ID_UPADTE_FAIL";


// delete amenities 
export const DELETE_AMENITIES_REQUEST = "DELETE_AMENITIES_REQUEST"
export const DELETE_AMENITIES_SUCCESS = "DELETE_AMENITIES_SUCCESS"
export const DELETE_AMENITIES_FAIL = "DELETE_AMENITIES_FAIL"



import axios from "axios";
import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_ALL_COUPON_FAIL,
  GET_ALL_COUPON_REQUEST,
  GET_ALL_COUPON_SUCCESS,
  UPDATE_COUPON_CODE_FAIL,
  UPDATE_COUPON_CODE_REQUEST,
  UPDATE_COUPON_CODE_SUCCESS,
  GET_COUPON_CODE_BY_ID_FAIL,
  GET_COUPON_CODE_BY_ID_REQUEST,
  GET_COUPON_CODE_BY_ID_SUCCESS,
  GET_COUPON_CODE_BY_CODE_SUCCESS,
  GET_COUPON_CODE_BY_CODE_REQUEST,
  GET_COUPON_CODE_BY_CODE_FAIL,
  CLEAR_ERROR,
} from "../../constant/CouponConstant/CouponCode";

export const CreateCouponCode = (coupon) => async (dispatch) => {
  // console.log(coupon);
  try {
    dispatch({
      type: CREATE_COUPON_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/create-coupon", coupon, config);
    // console.log(data);
    dispatch({
      type: CREATE_COUPON_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: CREATE_COUPON_FAIL,
      payload: error.response.data.messsage,
    });
  }
};

export const getAllCoupon = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_COUPON_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get("/api/v1/all-coupon", config);
    dispatch({
      type: GET_ALL_COUPON_SUCCESS,
      payload: data.allCoupon,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_COUPON_FAIL,
      payload: error.response.data.message,
    });
  }
};

// getCouponByid
export const getCouponId = (id)=>async(dispatch)=>{
  // console.log(id)
  try {
    dispatch({
      type: GET_COUPON_CODE_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/update-coupon/${id}`);
    // console.log(data)
    dispatch({
      type: GET_COUPON_CODE_BY_ID_SUCCESS,
      payload: data.getCoupon,
    });
  } catch (error) {
    dispatch({
      type: GET_COUPON_CODE_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
}

// get coupon code by id
export const getCouponByID = (code, validInfo) => async (dispatch) => {
  // console.log(validInfo);
  try {
    dispatch({
      type: GET_COUPON_CODE_BY_CODE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/v1/get-coupon/${code}`, validInfo, config);

    // console.log(data);
    dispatch({
      type: GET_COUPON_CODE_BY_CODE_SUCCESS,
      payload: data.getCoupon,
    });
  } catch (error) {
    dispatch({
      type: GET_COUPON_CODE_BY_CODE_FAIL,
      payload: error.response?.data?.message,
    });
  }
};


// update coupon code
export const updateCouponCode = (id,updateCoupon) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_COUPON_CODE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(`/api/v1/update-coupon/${id}`, updateCoupon, config);

    // console.log(data)

    dispatch({
      type: UPDATE_COUPON_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_COUPON_CODE_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../image/1.png";
import "./Navbar.css";
import { MdCall, MdMenu, MdDashboard, MdOutlineLogout } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction/userAction";
import { toast } from "react-toastify";
import { TbUserFilled } from "react-icons/tb";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
  const [fixed, setFixed] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  // console.log(user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Navigation Options
  const options = [
    {
      icon: <FaRegUserCircle />,
      name: "My Profile",
      func: () => navigate("/account-setting/profile"),
    },
    {
      icon: <FaRegUserCircle />,
      name: "My Trips",
      func: () => navigate("/account-setting/trips"),
    },
  ];
  if (user?.role === "admin") {
    options.unshift({
      icon: <MdDashboard />,
      name: "Dashboard",
      func: () => navigate("/dashboard"),
    });
  }

  // Scroll Logic
  const fixedNavScroll = () => {
    setFixed(window.scrollY >= 200);
  };

  const handleLogout = () => {
    dispatch(logOut());
    toast.success("Logged out successfully");
    navigate("/login");
  };

  const closeMenu = () => setMenuToggle(false);

  useEffect(() => {
    window.addEventListener("scroll", fixedNavScroll);
    return () => window.removeEventListener("scroll", fixedNavScroll);
  }, []);

  return (
    <div className={fixed ? "navbar fixed" : "navbar"}>
      <nav className="bg-transparent border-gray-200 dark:bg-gray-900 font-Mont w-full">
        <div className="w-full flex items-center justify-between mx-auto pt-3 pb-3">
          {/* Logo */}
          <Link to="/">
            <img
              className={`h-10 md:h-[45px]`}
              src={logo}
              alt="Logo"
            />
            {/* <img
              className={`h-10 md:h-[45px] ${fixed ? "hidden" : "logo2"}`}
              src={logo1}
              alt="Logo"
            /> */}
          </Link>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center space-x-8">
            <a href="tel:+919870141315" className="flex items-center font-semibold text-base">
              <MdCall size={23} />
              <span className="ml-2">Call us - +91 9870141315</span>
            </a>

            <Link to="/offers" className="capitalize font-semibold text-base">
              Offers
            </Link>

            <Link to="/partner" className="capitalize font-semibold text-base">
              List your home
            </Link>

            {isAuthenticated && user ? (
              <div className="relative group text-black">
                {isAuthenticated > 0 ? (
                  <button className="border rounded-full w-10 h-10 flex items-center justify-center">
                    {user.firstName > 0 ? (
                      user && user?.firstName[0]
                    ) : (
                      <button
                        className={`border rounded-full w-10 h-10 flex items-center justify-center`}
                      >
                        <TbUserFilled size={25} />
                      </button>
                    )}
                    {user.lastName > 0 ? user && user?.lastName[0] : ""}
                  </button>
                ) : (
                  <button className="border rounded-full w-10 h-10 flex items-center justify-center ">
                    <TbUserFilled size={25} />
                  </button>
                )}
                {/* Dropdown */}
                <div className="absolute right-0 mt-0 w-56 bg-white shadow-lg rounded-md opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-opacity duration-300">
                  <ul className="py-2">
                    <li className="px-4 pb-2 font-medium">
                      <span className="capitalize">{user?.firstName} {user?.lastName}</span> <br />
                      <span className="text-sm text-gray-500">
                        {user?.email}
                      </span>
                    </li>
                    {options.map((item, idx) => (
                      <li key={idx}>
                        <button
                          onClick={item.func}
                          className="w-full flex items-center px-4 py-2 hover:bg-gray-200"
                        >
                          {item.icon}
                          <span className="ml-3">{item.name}</span>
                        </button>
                      </li>
                    ))}
                    <li>
                      <button
                        onClick={handleLogout}
                        className="w-full flex items-center px-4 py-2 hover:bg-gray-200"
                      >
                        <MdOutlineLogout size={20} />
                        <span className="ml-3">Logout</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <Link
                to="/login"
                className="bg-red-500 text-white px-4 py-1 rounded"
              >
                Login / Signup
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setMenuToggle(!menuToggle)}
            className="lg:hidden border border-[1.5px] rounded-full p-2 flex items-center shadow-base space-x-2"
          >
            <MdMenu size={20} />
            <FaRegUserCircle size={20} />
          </button>
        </div>

        {/* Mobile Menu */}
        {menuToggle && (
          <ul className="lg:hidden min-h-screen bg-white shadow-md py-4">
            <li className="px-4 py-2 border-b flex items-center justify-between">
              <a href="tel:9870141315" className="flex items-center">
                <MdCall size={20} />
                <span className="ml-2">Call us - 9870141315</span>
              </a>
              <IoClose size={25} onClick={() => setMenuToggle(!menuToggle)} />
            </li>

            {/* <li className="px-4 py-2 border-b">
              <Link to="#">About Us</Link>
            </li>
            <li className="px-4 py-2 border-b">
              <Link to="#">Our Team</Link>
            </li>
            <li className="px-4 py-2 border-b">
              <Link to="#">Partner With Us</Link>
            </li> */}
            {isAuthenticated ? (
              <>
                <li className="px-4 py-2 border-b font-medium">
                  {user?.firstName} {user?.lastName}
                  <br />
                  <span className="text-sm text-gray-500">{user?.email}</span>
                </li>
                {options.map((item, idx) => (
                  <li key={idx} className="px-4 py-2 border-b">
                    <button
                      onClick={() => {
                        item.func();
                        closeMenu();
                      }}
                      className="flex items-center"
                    >
                      {item.icon}
                      <span className="ml-3">{item.name}</span>
                    </button>
                  </li>
                ))}
                <li className="px-4 py-2">
                  <button
                    onClick={() => {
                      handleLogout();
                      closeMenu();
                    }}
                    className="flex items-center text-red-500"
                  >
                    <MdOutlineLogout size={20} />
                    <span className="ml-3">Logout</span>
                  </button>
                </li>
              </>
            ) : (
              <li className="px-4 py-2">
                <Link
                  to="/login"
                  onClick={closeMenu}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Login / Signup
                </Link>
              </li>
            )}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Navbar;

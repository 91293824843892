import React from "react";
// import Navbar from "./Navbar/navbar"
import Header from "./Header/Header";
import Property from "./property/Property";
import Services from "./Content/Services/Services";
import GuestExeprience from "./Content/GuestExeprience/GuestExeprience";
import Owners from "./Content/Owners/Owners";
import Reviews from "./Content/Reviews/Reviews";



const Home = () => {
  return (
    <div className="bg-gray-100">
      {/* <Navbar /> */}
      <Header />
      <Property />
      <Services />
      <Reviews />
      <GuestExeprience />
      <Owners />
      
    </div>
  );
};

export default Home;

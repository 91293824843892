import axios from "axios";
import {
  CREATE_PACKAGE_ENQUIRY_REQUEST,
  CREATE_PACKAGE_ENQUIRY_SUCCESS,
  CREATE_PACKAGE_ENQUIRY_FAIL,
  GET_ALL_PACKAGE_ENQUIRY_REQUEST,
  GET_ALL_PACKAGE_ENQUIRY_SUCCESS,
  GET_ALL_PACKAGE_ENQUIRY_FAIL,
} from "../../constant/PackagesConstant/PackageEnquiryConstant";

export const createpackageEnquiry = (Enquiry) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PACKAGE_ENQUIRY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/package-enquiry`,
      Enquiry,
      config
    );

    dispatch({
      type: CREATE_PACKAGE_ENQUIRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PACKAGE_ENQUIRY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all package enquiry
export const getAllPackageEnquiry = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PACKAGE_ENQUIRY_REQUEST,
    });

    const { data } = await axios.get("/api/v1/all-package-enquiry");
    // console.log(data.allPackageEnquiry)
    dispatch({
      type: GET_ALL_PACKAGE_ENQUIRY_SUCCESS,
      payload: data.allPackageEnquiry,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PACKAGE_ENQUIRY_FAIL,
      payload: error.response.data.message,
    });
  }
};

import {
  CREATE_PACKAGE_AMENITIES_REQUEST,
  CREATE_PACKAGE_AMENITIES_SUCCESS,
  CREATE_PACKAGE_AMENITIES_FAIL,
  GET_ALL_PACKAGE_AMINITES_REQUEST,
  GET_ALL_PACKAGE_AMINITES_SUCCESS,
  GET_ALL_PACKAGE_AMINITES_FAIL,
} from "../../constant/PackagesConstant/PackageAmenitiesConstant";

export const PackageAmenitiesReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKAGE_AMENITIES_REQUEST:
    case GET_ALL_PACKAGE_AMINITES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PACKAGE_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PACKAGE_AMINITES_SUCCESS:
      return {
        ...state,
        loading: false,
        packageAmenities: action.payload,
      };

    case CREATE_PACKAGE_AMENITIES_FAIL:
    case GET_ALL_PACKAGE_AMINITES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

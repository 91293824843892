import React from "react";
import SideMenu from "../sideMenu/SideMenu";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="flex pt-20">
      <SideMenu />
      <div className="flex-1 flex flex-col">
        {/* Top Navigation */}
        {/* <header className="bg-white shadow-md w-full py-4 px-6 flex justify-between items-center">
          <h1 className="text-xl font-semibold text-gray-800">Dashboard</h1>
          <div className="flex items-center">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
              Add New
            </button>
          </div>
        </header> */}

        {/* Content Area */}
        <main className="flex-1 p-6 bg-gray-100">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Example Widgets */}
            <Link
              to="/dashboard/upload-theme"
              className="bg-white p-6 rounded-md shadow-md "
            >
              <h2 className="text-lg font-bold">HomeTheme</h2>
              {/* <p className="mt-4">Overview of analytics data.</p> */}
            </Link>
            <Link
              to="/dashboard/list-of-all-theme"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">All Theme</h2>
              {/* <p className="mt-4">Number of active users.</p> */}
            </Link>
            <Link
              to="/dashboard/generate-coupon"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Coupon</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/list-of-coupon"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">All Coupon</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/ownerleads"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Owners Leads</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/contact"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Contact Form</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/offers"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Offers</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/offer-list"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Offers List</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/package/enquiry"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">Package Enquiry</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
            <Link
              to="/dashboard/all-users"
              className="bg-white p-6 rounded-md shadow-md"
            >
              <h2 className="text-lg font-bold">All Users</h2>
              {/* <p className="mt-4">Sales performance overview.</p> */}
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

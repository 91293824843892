import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getPropertyById,
  // getRentlPrice,
} from "../../redux/actions/propertyActions/PropertyAction";
import Stapers from "./Stapers";
// import InPageRoute from "../InPageRoute";
// import { addDays } from "date-fns";
// import { HiUsers } from "react-icons/hi";
// import DatePicker from "../PropertySearch/DatePicker";
import { FaBed } from "react-icons/fa6";
// import { CiUser } from "react-icons/ci";
// import AgeSelecter from "../property/AgeSelecter";
// import PaymentGateway from "../Paymentgateway/Payment";
// import { load } from "@cashfreepayments/cashfree-js";
// import { createOrder } from "../../redux/actions/createOrderActions/createOrderAction";
// import DatePicker from "../PropertySearch/DatePicker";
import { HiUsers } from "react-icons/hi";
// import { getCouponByID } from "../../redux/actions/CouponAction/CouponCode";
// import { IoCloseSharp } from "react-icons/io5";
// import { CLEAR_COUPON_CODE_SUCCESS } from "../../redux/constant/CouponConstant/CouponCode";
// import Loader from "../Loader";
import { addMoreDetails } from "../../redux/actions/userAction/userAction";
import { toast } from "react-toastify";
import MetaData from "../MetaData";
// import { CiUser } from "react-icons/ci";
import { MdOutlineBathroom, MdOutlineBedroomParent } from "react-icons/md";
import axios from "axios";
// import { createOrder } from "../../redux/actions/createOrderActions/createOrderAction";
import { ToggleSwitch } from "flowbite-react";

const Booking = () => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  // const [reservationCreated, setReservationCreated] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [children05, setChildren05] = useState(0);
  const [switch3, setSwitch3] = useState(false);

  // const dropdownRef = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const [toggle, setToggle] = useState(false);
  // const [coupon, setCoupon] = useState("");
  // const [couponError, setCouponError] = useState(" ");
  const [discount, setDiscount] = useState(0);
  // const [promo,setPromo] = useState(" ");
  const [gstAmount, setGstAmount] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  // const [clearDate, setClearDate] = useState(false);

  // const [paymentSessionId, setPaymentSessionId] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  // const [isBookingCreated, setIsBookingCreated] = useState(false);
  const { user } = useSelector((state) => state.user);
  // const { success } = useSelector((state) => state.createOrder);
  // const { addUserDetails } = useSelector((state) => state.user);
  // console.log(addUserDetails)
  // console.log(user);
  // const location = useLocation(); // To access the full URL with query string
  // const searchParams = new URLSearchParams(location.search);

  // Extract query parameters
  // const checkin = searchParams.get("checkin");
  // const checkout = searchParams.get("checkout");
  // const adult = searchParams.get("adult");
  // const children = searchParams.get("children");
  // const infant = searchParams.get("infant");

  // console.log(checkin, checkout, adult, children, infant);

  const [checkIn, setCheckIn] = useState("");
  // const navigate = useNavigate();

  // console.log("checkIn",checkIn)

  // let checkIn = sessionStorage.getItem("checkInDates")

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const { propertyId } = useSelector((state) => state.propertyList);
  const { rentlId } = useSelector((state) => state.propertyList);
  const { couponId } = useSelector((state) => state.coupon);
  // console.log( couponId)

  // console.log(couponId);
  // let maxGuests = propertyId && propertyId.max_guests;

  const { id } = useParams();
  // console.log(propertyId);

  useEffect(() => {
    const storedAdultCount = sessionStorage.getItem("adultCount");
    const storedChildCount = sessionStorage.getItem("childCount");
    const storedChildren05 = sessionStorage.getItem("children05");

    if (storedAdultCount) {
      setAdultCount(parseInt(storedAdultCount, 10));
    }
    if (storedChildCount) {
      setChildCount(parseInt(storedChildCount, 10));
    }
    if (storedChildren05) {
      setChildren05(parseInt(storedChildren05, 10));
    }
  }, [setAdultCount, setChildCount, setChildren05]);

  // Update `sessionStorage` whenever `discount` changes
  // useEffect(() => {
  //   sessionStorage.setItem("discount", discount);
  // }, [discount]);

  // useEffect(() => {
  //   const savedDiscount = sessionStorage.getItem("discount");
  //   if (savedDiscount) {
  //     setDiscount(Number(savedDiscount));
  //   }
  // }, []);

  // chnage count age adult and child and infant
  // const changeCount = (type, amount) => {
  //   setErrorMessage("");

  //   // Calculate the new counts
  //   const newAdultCount = type === "adult" ? adultCount + amount : adultCount;
  //   const newChildCount = type === "child" ? childCount + amount : childCount;
  //   const newChildren05 = type === "child05" ? children05 + amount : children05;

  //   const totalGuests = newAdultCount + newChildCount;

  //   // let MaxInfants = 5;
  //   // if(MaxInfants > newChildren05){
  //   //   setErrorMessage(`Maximum infa occupancy reached ${MaxInfants}.`);
  //   //   return;
  //   // }

  //   // Check if the new total exceeds the maxGuests limit
  //   if (totalGuests > maxGuests) {
  //     setErrorMessage(`Maximum guest occupancy reached ${maxGuests}.`);
  //     return;
  //   }

  //   // Update counts based on type
  //   if (type === "adult") {
  //     setAdultCount(Math.max(1, newAdultCount));
  //     sessionStorage.setItem("adultCount", Math.max(1, newAdultCount));
  //   } else if (type === "child") {
  //     setChildCount(Math.max(0, newChildCount));
  //     sessionStorage.setItem("childCount", Math.max(0, newChildCount));
  //   } else if (type === "child05") {
  //     setChildren05(Math.max(0, newChildren05));
  //     sessionStorage.setItem("children05", Math.max(0, newChildren05));
  //   }
  // };

  // const onHandleClear = () => {
  //   setAdultCount(1);
  //   setChildCount(0);
  //   setChildren05(0);
  //   setErrorMessage("");
  //   sessionStorage.removeItem("adultCount");
  //   sessionStorage.removeItem("childCount");
  //   sessionStorage.removeItem("children05");
  // };
  // const toggleDropdown = (e) => {
  //   e.preventDefault();
  //   setIsOpen((prev) => !prev);
  // };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setIsOpen(false); // Close dropdown if clicked outside
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const totalRentPrice =
    rentlId && rentlId?.rentlPrice.reduce((sum, item) => sum + item.price, 0);

  const calculateDiscount = useCallback(() => {
    if (!couponId || !couponId?.isActive) return 0; // Return 0 if no valid coupon
    const percentOff = parseFloat(couponId?.PercentOff || 0);
    const amountOff = parseFloat(couponId?.AmountOff || 0);

    if (percentOff > 0) {
      return (totalRentPrice * percentOff) / 100;
    } else if (amountOff > 0) {
      return amountOff;
    }
    return 0;
  }, [couponId, totalRentPrice]);

  const calculateGST = useCallback(() => {
    const taxableAmount = totalRentPrice - discount;
    return taxableAmount < 7500
      ? (taxableAmount * 12) / 100
      : (taxableAmount * 18) / 100;
  }, [totalRentPrice, discount]);

  useEffect(() => {
    const newDiscount = calculateDiscount();
    setDiscount(newDiscount);
  }, [totalRentPrice, couponId, calculateDiscount]);

  // const onHandlePromo = () => {
  //   setCouponError("");
  //   setDiscount(0);
  //   dispatch(getCouponByID(coupon));

  //   const userMobile = user.mobile.slice(3);

  //   // Validate coupon
  //   if (!couponId || !couponId.isActive) {
  //     setCouponError("Invalid coupon");
  //     dispatch({ type: CLEAR_COUPON_CODE_SUCCESS });
  //     setToggle(true);
  //     return;
  //   }

  //   const currentDate = new Date();
  //   const couponExpiration = new Date(couponId?.couponExpiration);
  //   if (couponExpiration < currentDate) {
  //     setCouponError("Coupon has expired");
  //     dispatch({ type: CLEAR_COUPON_CODE_SUCCESS });
  //     setToggle(true);
  //     return;
  //   }

  //   console.log("userMobile", typeof userMobile);
  //   console.log("mobile", typeof couponId && couponId.mobile);

  //   if (!couponId || Number(userMobile) !== couponId.mobile) {
  //     setCouponError("Coupon invalid for this user");
  //     dispatch({ type: CLEAR_COUPON_CODE_SUCCESS });
  //     setToggle(true);
  //     return;
  //   }

  //   const percentOff = parseFloat(couponId?.PercentOff || 0);
  //   const amountOff = parseFloat(couponId?.AmountOff || 0);
  //   let calculatedDiscount = 0;

  //   if (percentOff > 0) {
  //     calculatedDiscount = (totalRentPrice * percentOff) / 100;
  //   } else if (amountOff > 0) {
  //     calculatedDiscount = amountOff;
  //   }

  //   setDiscount(calculatedDiscount);
  //   setToggle(false);
  // };

  let perNightPrice =
    (totalRentPrice - discount) / (rentlId?.rentlPrice.length || 1);

  useEffect(() => {
    setGstAmount(calculateGST());
  }, [discount, totalRentPrice, calculateDiscount, calculateGST]);

  // 50% pay later logic
  const newDate = new Date();
  const maxdatys = newDate;
  const maxDate = new Date(maxdatys);
  maxDate.setDate(maxDate.getDate() + 5);
  // console.log(max)

  let totalAmount = Number(totalRentPrice - discount) + Number(gstAmount);
  let percentOffPrice;

  if (
    Number(propertyId && propertyId.percent_enable) &&
    Number(propertyId && propertyId.minimum_reservation_price) &&
    switch3 === true &&
    new Date(checkIn.startDate) > newDate
  ) {
    percentOffPrice =
      ((Number(totalRentPrice - discount) + Number(gstAmount)) *
        Number(propertyId && propertyId.percent_enable)) /
      100;
  } else if (switch3 === false) {
    percentOffPrice = Number(totalRentPrice - discount) + Number(gstAmount);
  } else {
    totalAmount = Number(totalRentPrice - discount) + Number(gstAmount);
  }

  // console.log((percentOffPrice))
  // console.log(typeof switch3)

  // clear coupon code
  // const onHandleClearPromo = () => {
  //   dispatch({
  //     type: CLEAR_COUPON_CODE_SUCCESS,
  //   });
  // };

  // console.log(Number(totalAmount));

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // let maxGuests = propertyId && propertyId.max_guests;

  // const changeCount = (type, amount) => {
  //   setErrorMessage(""); // Clear any existing error message

  //   // Calculate the new counts
  //   const newAdultCount = type === "adult" ? adultCount + amount : adultCount;
  //   const newChildCount = type === "child" ? childCount + amount : childCount;
  //   const totalGuests = newAdultCount + newChildCount;

  //   // Check if the new total exceeds the maxGuests limit
  //   if (totalGuests > maxGuests) {
  //     setErrorMessage(`Maximum guest occupancy reached ${maxGuests}.`);
  //     return; // Exit without updating counts if limit is exceeded
  //   }

  //   // Update counts based on type
  //   if (type === "adult") {
  //     setAdultCount(Math.max(1, newAdultCount)); // Minimum 1 adult
  //   } else if (type === "child") {
  //     setChildCount(Math.max(0, newChildCount)); // Minimum 0 children
  //   }
  // };

  // const onHandleClear = () => {
  //   setAdultCount(1);
  //   setChildCount(0);
  //   setErrorMessage("");
  // };
  // let TotalAdults = adultCount + childCount;

  // const onHandleSubmit = (e) => {
  //   // e.preventDefault();

  // };

  useEffect(() => {
    if (user) {
      setUserDetails({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
      });
    }
  }, [user]);

  const handleUserDetailsChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitDetails = (e) => {
    if (
      userDetails.firstName.trim() &&
      userDetails.lastName.trim() &&
      /\S+@\S+\.\S+/.test(userDetails.email)
    ) {
      const mobile = `${user && user.mobile}`;
      dispatch(addMoreDetails({ mobile, ...userDetails }));
      setIsFormValid(true);
      return true;
    } else {
      toast.error("Please fill all fields correctly.");
      setIsFormValid(false); // Form is not valid
      return false;
    }
  };

  // console.log(checkIn.startDate);

  // const onHandlePayment = (e) => {
  //   e.preventDefault();
  //   if (
  //     userDetails.firstName.trim() &&
  //     userDetails.lastName.trim() &&
  //     /\S+@\S+\.\S+/.test(userDetails.email)
  //   ) {
  //     const mobile = `${user && user.mobile}`;
  //     dispatch(addMoreDetails({ mobile, ...userDetails }));
  //     setIsFormValid(true);

  //     // let userdetails = {
  //     //   firstName:userDetails.firstName,
  //     //   lastName: userDetails.lastName
  //     // }
  //     // Step 3: Prepare booking data

  //     const bookingData = {
  //       amount: totalAmount.toFixed().toLocaleString(),
  //       user: user,
  //       firstName: userDetails.firstName,
  //       lastName: userDetails.lastName,
  //       email: userDetails.email,
  //       propertyId,
  //       dateFrom: formatDate(new Date(checkIn.startDate)),
  //       dateTo: formatDate(new Date(checkIn.endDate)),
  //       adults: adultCount,
  //       children612: childCount,
  //       children05: children05,
  //       perNightPrice,
  //       couponCode: couponId && couponId.code,
  //       number_of_nights: rentlId?.rentlPrice?.length || 0,
  //       base_amount: totalRentPrice,
  //       taxes: gstAmount,
  //       total_amount: totalAmount.toFixed().toLocaleString(),
  //     };

  //     if (!reservationCreated) {
  //       setReservationCreated(true);
  //       dispatch(createOrder(bookingData));
  //     }

  //     return true;
  //   } else {
  //     toast.error("Please fill all fields correctly.");
  //     setIsFormValid(false);
  //     return false;
  //   }
  // };

  // const initializeSDK = async () => {
  //   const cashfreeInstance = await load({ mode: "production" });
  //   return cashfreeInstance;
  // };

  // const doPayment = async () => {
  //   // Ensure the form is valid and checkbox is checked
  //   if (!isFormValid || !isChecked || totalAmount === 0) {
  //     toast.error(
  //       "Please complete the form correctly before proceeding to payment."
  //     );
  //     return;
  //   }

  //   const cashfree = await initializeSDK();

  //   let checkoutOptions = {
  //     paymentSessionId: success,
  //     redirectTarget: "_self",
  //   };
  //   cashfree.checkout(checkoutOptions);
  // };

  // useEffect(() => {
  //   // if(loading) return <h1>loading...</h1>
  //   if (propertyId) {
  //     dispatch(
  //       getRentlPrice({
  //         dateFrom: formatDate(checkIn.startDate),
  //         dateTo: formatDate(checkIn.endDate),
  //         id: propertyId._id,
  //         adults: TotalAdults,
  //         clearDate,
  //       })
  //     ); // createBooking();
  //   }
  // }, [dispatch, TotalAdults, checkIn, propertyId, clearDate]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let total;
    let pendingAmount;
    let internal_PaymentStatus;
    if (switch3 === true) {
      total = percentOffPrice;
      pendingAmount = percentOffPrice;
      internal_PaymentStatus = "PARTIALLY_PAID"
    } else {
      total = totalAmount;
    }

    const bookingData = {
      amount: total.toFixed().toLocaleString(),
      user: user,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      paylaterStatus: switch3,
      propertyId,
      dateFrom: formatDate(new Date(checkIn.startDate)),
      dateTo: formatDate(new Date(checkIn.endDate)),
      adults: adultCount,
      children612: childCount,
      children05: children05,
      perNightPrice,
      couponCode: couponId && couponId.code,
      number_of_nights: rentlId?.rentlPrice?.length || 0,
      base_amount: totalRentPrice,
      taxes: gstAmount,
      amount_paid: total,
      internal_PaymentStatus,
      amount_pending: Number(pendingAmount).toFixed() || 0 , 
      total_amount: totalAmount.toFixed().toLocaleString(), 
    };

    // creating a new order
    const result = await axios.post(
      "/api/v1/reservation",
      bookingData
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: "rzp_live_X7U6z7Ue3zgG53",
      amount: amount.toString(),
      currency: currency,
      name: user.name,
      description: "Test Transaction",
      image: propertyId && propertyId.picture[0].url,
      order_id: order_id,
      callback_url: "/api/v1/webhook",  
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      notes: {
        address: "Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  useEffect(() => {
    dispatch(getPropertyById(id));
  }, [dispatch, id]);

  // console.log(sessionStorage.getItem("checkInDates"))

  useEffect(() => {
    const savedDates = sessionStorage.getItem("checkInDates");
    // console.log(savedDates);
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates).map((range) => ({
        ...range,
        startDate: new Date(range.startDate),
        endDate: new Date(range.endDate),
      }));
      setCheckIn(parsedDates[0]);
    }
  }, [setCheckIn]);

  return (
    <div className="pt-20 pb-20 flex flex-col itmes-center justify-center w-full font-Mont">
      {/* <div className="pt-3 pl-5 pr-5">
        <InPageRoute />
      </div> */}
      <MetaData title={`Book Da Alohas Property`} />
      <div className="flex items-center justify-center  md:pt-3">
        <Stapers activeSteps={1} />
      </div>
      <div className="flex flex-col lg:flex-row itmes-center justify-around pt-2  md:pt-10 pl-2 lg:pl-0 pr-2 lg:pr-0">
        <div className="w-full h-auto lg:w-6/12 p-1 md:p-4 bg-white border border-gray-200 rounded-lg">
          <Link
            to={`/property/${propertyId?.slug}`}
            className="flex flex-col md:flex-row"
          >
            <div className="md:w-1/3 h-[200px] md:h-[150px] p-2 md:p-0">
              <img
                className="w-full h-full rounded-lg object-cover"
                src={propertyId && propertyId.picture[0].url}
                alt={propertyId && propertyId?.title}
              />
            </div>

            <div className="w-3/4 pl-4 pt-1">
              <h3 className="text-xl font-semibold">
                {propertyId && propertyId.title}
              </h3>
              <p class="text-sm text-gray-500 capitalize">
                {propertyId && propertyId.city},{propertyId && propertyId.state}
              </p>

              <div className="w-full flex  gap-1 md:gap-4 bg-white  rounded-lg mt-1 md:mt-5">
                {/* Beds */}
                {/* <div className="flex items-center space-x-1 sm:space-x-2">
                                <CiUser className="size-4 md:size-6 text-indigo-700" />
                                <span className="text-gray-700 text-xs sm:text-base font-medium">
                                  {propertyId?.max_guests} Guests
                                </span>
                              </div> */}

                {/* Washrooms */}
                <div className="flex items-center space-x-1">
                  <MdOutlineBathroom className="size-4 md:size-6 text-indigo-700" />
                  <span className="text-gray-700 text-xs sm:text-base font-medium">
                    {propertyId?.accomodation_washrooms} Bathroom
                  </span>
                </div>

                {/* Bedrooms */}
                <div className="flex items-center space-x-1 md:space-x-2">
                  <MdOutlineBedroomParent className="size-4 md:size-6 text-indigo-700" />
                  <span className="text-gray-700 text-xs sm:text-base font-medium">
                    {propertyId?.accomodation_bedrooms} Bedrooms
                  </span>
                </div>

                {/* Guests */}
                <div className="flex items-center space-x-1 md:space-x-2">
                  <FaBed className="size-4 md:size-6 text-indigo-700" />
                  <span className="text-gray-700 text-xs sm:text-base font-medium">
                    {propertyId?.accomodation_beds} beds
                  </span>
                </div>
              </div>
            </div>
          </Link>

          <div className="w-full border-2  border-t border-dashed   mt-5 mb-5"></div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0">
            <div className="w-full md:w-1/2 border">
              <div className="flex w-full h-12 justify-around pt-1 pb-1">
                <div>
                  <h2 className="font-medium text-xs">Check In</h2>
                  <p className="font-medium text-sm">
                    {checkIn?.startDate?.toDateString().slice(0, 10)}
                  </p>
                </div>
                <div className="border-l"></div>
                <div>
                  <h2 className="font-medium text-xs">Check Out</h2>
                  <p className="font-medium text-sm">
                    {checkIn?.endDate?.toDateString().slice(0, 10)}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="w-1/2"> */}
            {/* <DatePicker
                checkIn={checkIn}
                setCheckIn={setCheckIn}
                TotalAdults={TotalAdults}
                setClearDate={setClearDate}
                clearDate={clearDate}
              /> */}
            {/* </div> */}
            {/* <div className="w-full md:w-1/2 flex items-center p-2 border space-x-3 md:ml-1"> */}
            {/* <div className="flex font-medium text-sm capitalize">
                <FaUserFriends size={18} />
                <span className="ml-2"> {adult}</span>{" "}
                <span className="ml-1">adults</span>
              </div>
              <div className="flex font-medium text-sm capitalize">
                {children} <span className="ml-1">children</span>
              </div>
              <div className="flex font-medium text-sm capitalize">
                {infant} <span className="ml-1">infant</span>
              </div> */}
            <div
              className="relative inline-block text-left w-full  md:w-1/2  mt-3"
              // ref={dropdownRef}
            >
              <div>
                <div
                  // onClick={toggleDropdown}
                  className="inline-flex h-[50px] justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="flex items-center">
                    <HiUsers />
                    <span className="ml-2 mr-2  font-semibold text-xs">
                      {adultCount} guests,
                    </span>
                    <span className="font-semibold text-xs">
                      {childCount} Children
                    </span>

                    <span className="font-semibold text-xs ml-2">
                      {children05} Infants
                    </span>
                  </span>
                </div>
              </div>

              {/* {isOpen && (
                <div className="absolute left-0 right-0 z-10 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 pb-5">
                  <div className="flex items-center justify-between pl-5 pr-5 pt-3">
                    <h1>Total guests</h1>
                    <button className="text-red" onClick={onHandleClear}>
                      Clear
                    </button>
                  </div>

                  <div className="py-2">
                    <div className="flex justify-between items-center px-4 py-2">
                      <div className="flex flex-col">
                        <span className="font-medium text-base">Adults </span>
                        <p className="font-medium text-xs opacity-75">
                          Age 13 or above
                        </p>
                      </div>

                      <div className="flex items-center rounded border border-gray-200">
                        <button
                          type="button"
                          className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                          onClick={() => changeCount("adult", -1)}
                          disabled={adultCount <= 0}
                        >
                          &minus;
                        </button>

                        <input
                          type="number"
                          id="Quantity"
                          value={adultCount}
                          className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                        />

                        <button
                          type="button"
                          onClick={() => changeCount("adult", 1)}
                          className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between items-center px-4 py-2">
                      <div className="flex flex-col">
                        <span className="font-medium text-base">Children </span>
                        <p className="font-medium text-xs opacity-75">
                          Age 6 to 12
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center rounded border border-gray-200">
                          <button
                            type="button"
                            className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                            onClick={() => changeCount("child", -1)}
                            disabled={childCount <= 0}
                          >
                            &minus;
                          </button>

                          <input
                            type="number"
                            id="Quantity"
                            value={childCount}
                            className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                          />

                          <button
                            type="button"
                            onClick={() => changeCount("child", 1)}
                            className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center px-4 py-2">
                      <div className="flex flex-col">
                        <span className="font-medium text-base">Infants </span>
                        <p className="font-medium text-xs opacity-75">
                          Aged 0 to 5 stay free—no additional charges apply!
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center rounded border border-gray-200">
                          <button
                            type="button"
                            className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                            onClick={() => changeCount("child05", -1)}
                            disabled={children05 <= 0}
                          >
                            &minus;
                          </button>

                          <input
                            type="number"
                            id="Quantity"
                            value={children05}
                            className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                          />

                          <button
                            type="button"
                            onClick={() => changeCount("child05", 1)}
                            disabled={children05 >= 5}
                            className="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex-start pl-5 pr-5">
                    {errorMessage && (
                      <div className="text-red-500 text-sm  mb-2">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                </div>
              )} */}
            </div>
            {/* </div> */}
          </div>

          <div className="mt-5">
            {/* <h2 className="text-lg font-medium mb-4">Complete Your Profile</h2> */}
            <div className="flex flex-col md:flex-wrap">
              {/* First Name Input */}
              <div className="mb-2 md:w-1/2">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={userDetails.firstName}
                  onChange={handleUserDetailsChange}
                  placeholder="Enter First Name"
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Last Name Input */}
              <div className="mb-2 md:w-1/2">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={userDetails.lastName}
                  onChange={handleUserDetailsChange}
                  placeholder="Enter Last Name"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {/* Email Input */}
              <div className="mb-2 md:w-1/2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleUserDetailsChange}
                  placeholder="Enter Email"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>
            </div>
            {/* <button
              onClick={handleSubmitDetails}
              className="w-full py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white"
            >
              Submit
            </button> */}
          </div>
        </div>

        <div className="w-full lg:w-4/12">
          {/* <div className="w-full border flex items-center justify-between rounded-md p-6 font-medium text-lg">
            Got a coupon code?{" "}
            <span>
              <IoIosArrowForward />
            </span>
          </div> */}
          <div className="p-6 bg-white shadow-md rounded-md border">
            <h2 className="text-xl font-bold text-gray-800 mb-4">
              Price Details
            </h2>
            {/* {loading ? (
              <div>
                <Loader />
              </div>
            ) : ( */}
            <div className="space-y-3 text-gray-700">
              <div className="flex justify-between">
                <span>{`${Number(perNightPrice.toFixed()).toLocaleString()} X ${
                  rentlId?.rentlPrice?.length
                } Night(s)`}</span>
                <span className="font-medium">
                  ₹{Number(totalRentPrice?.toFixed())?.toLocaleString()}
                </span>
              </div>

              <div className="flex items-center justify-center text-green-600">
                <div className="flex justify-between w-full">
                  {discount > 0 ? <span>Coupon Discount</span> : ""}
                  <span className="font-medium">
                    {discount > 0 ? (
                      <div className="flex justify-between">
                        <span className="font-medium flex justify-between">
                          - ₹{Number(discount.toFixed()).toLocaleString()}
                          {/* <IoCloseSharp
                              className="cursor-pointer"
                              color="red"
                              size={20}
                              onClick={() => {
                                setDiscount(0);
                                setCoupon("");
                                setCouponError("");
                                sessionStorage.clear("discount");
                              }}
                            /> */}
                        </span>
                      </div>
                    ) : (
                      ""
                      // <button onClick={() => setToggle(!toggle)}>
                      //   Apply Coupon
                      // </button>
                    )}
                  </span>
                </div>

                {/* {toggle && (
                    <div className="w-4/12 h-[400px] bg-gray-100 absolute top-20 flex flex-col items-center justify-center">
                      <input
                        className="w-10/12"
                        type="text"
                        onChange={(e) => setCoupon(e.target.value)}
                        placeholder="Enter your coupon code"
                      />
                      {couponError && (
                        <p className="text-red-400 ">{couponError}</p>
                      )}
                      <div className="flex space-x-2 mt-5">
                        <button
                          className="pl-4 pr-4 pt-1 pb-1 border"
                          onClick={() => setToggle(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="pl-4 pr-4 pt-1 pb-1 border"
                          onClick={onHandlePromo}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )} */}
              </div>

              <div className="flex justify-between">
                <span>Taxes</span>
                <span className="font-medium">
                  ₹{Number(gstAmount.toFixed()).toLocaleString()}
                </span>
              </div>

              <hr className="my-3" />
              <div className="flex justify-between text-lg font-semibold text-gray-800">
                <span>Total Amount</span>
                <span>
                  ₹{Number(Number(totalAmount?.toFixed()))?.toLocaleString()}
                </span>
              </div>
            </div>
            {/* )} */}

            {/* 
            <div className="bg-yellow-100 p-3 rounded-md my-4 flex justify-between items-center">
              <span className="text-red-800 font-semibold">
                Pay 50% Now, rest later
              </span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={payLater}
                  onChange={() => setPayLater(!payLater)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-red-800"></div>
                <div
                  className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform ${
                    payLater ? "transform translate-x-5" : ""
                  }`}
                ></div>
              </label>
            </div> */}
            {(Number(propertyId && propertyId.percent_enable) &&
            Number((propertyId && propertyId.minimum_reservation_price > 0) && (propertyId && propertyId.minimum_reservation_price) < (  Number(totalAmount))) &&
            (Number(propertyId.checkin_date_distance) > 0) && new Date(checkIn.startDate) > new Date(newDate.getTime() + Number(propertyId.checkin_date_distance) * 24 * 60 * 60 * 1000)) ? (
            <div className="flex items-center justify-between h-[50px] mt-3  bg-orange-100 p-2">
                <h1 className="font-semibold text-sm">
                  Pay 50% Now, rest later
                </h1>
                <ToggleSwitch
                  color="red"
                  checked={switch3}
                  onChange={setSwitch3}
                />
              </div>
            ) : (
              ""
            )}


            <div className="flex items-center my-4">
              <input
                id="agree"
                type="checkbox"
                className="w-5 h-5 text-red-800 border-gray-300 rounded"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                onClick={handleSubmitDetails}
              />
              <label
                htmlFor="agree"
                className="ml-2 text-gray-700 text-sm flex items-center flex-wrap"
              >
                I agree to the
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-red-800 underline ml-1 mr-1">
                  Terms and Conditions
                </a>
                of Da Alohas.
              </label>
            </div>

            {/* <button
              className="w-full py-2 bg-red-900 text-white font-bold rounded-md hover:bg-red-800"
            >
              Continue
            </button> */}
            <button
              type="submit"
              className={`${
                !isFormValid || !isChecked ? "bg-gray-300" : "bg-red-500"
              } w-full py-2 text-white font-medium rounded-md`}
              id="renderBtn"
              // onClick={doPayment}
              onClick={displayRazorpay}
              disabled={!isFormValid || !isChecked || totalAmount === 0}
            >
              Continue to pay ₹{" "}
              {Number(Number(percentOffPrice).toFixed()).toLocaleString()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;

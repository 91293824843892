import React from "react";
import { OwnersObj } from "./OwnersObj";
import { Link } from "react-router-dom";

const Owners = () => {
  return (
    <div className="p-4 md:p-10 bg-gray-200 font-Mont">
      <div className="flex flex-col  md:flex-row items-center justify-between ">
        <div className="w-full md:w-3/12">
          <p className="font-semibold text-4xl flex flex-col">
            Own a Vacation Home?
            <span className="pt-4">Partner with Da Alohas.</span>
          </p>
          <Link to="/partner">
            <button className="font-medium text-base border mt-5 md:mt-10 shadow-xl pl-5 pr-5 pt-2 pb-2 capitalize rounded-full">
              Know More
            </button>
          </Link>
        </div>
        <div className="flex flex-col items-start justify-center w-full md:w-9/12 pt-5 md:pt-0">
          <div className="space-y-2">
            <p className="font-bold md:font-semibold text-base">
              If you own a holiday home anywhere in India, Sri Lanka, Phuket and
              Bali, we would love to speak to you.
            </p>
            <p className="font-medium text-base">
              Owning a vacation home is a dream, but managing it can quickly
              become overwhelming. At Da Alohas, we specialize in transforming
              your retreat into a profitable, stress-free performing asset while
              ensuring it’s always ready for you when you need it
            </p>
            <p className="font-medium text-base">
              Join us and become a proud partner home owner with Da Alohas.
            </p>
          </div>
          <div className="flex flex-wrap mt-5 md:mt-0 mb-10 sm:mb-0 ">
            {OwnersObj &&
              OwnersObj.map((owner) => {
                return (
                  <div
                    className="w-full sm:w-[250px] flex flex-col items-center justify-center shadow-sm rounded shadow-gray-400 m-2 p-3"
                    key={owner.id}
                  >
                    <img
                      className="w-[100px] sm:w-[60px] md:w-[50px] "
                      src={owner.icons}
                      alt={owner.title}
                    />
                    <h1 className="font-semibold text-base mt-2">
                      {owner.title}
                    </h1>
                    <p className="font-normal text-base">{owner.summary}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Owners;

import axios from "axios"
import {GET_ALL_COUPON_TYPE_REQUEST,GET_ALL_COUPON_TYPE_SUCCESS,GET_ALL_COUPON_TYPE_FAIL} from "../../constant/CouponConstant/CouponType"


export const getCoupontype = () =>async(dispatch)=>{
    try {
        
        dispatch({
            type: GET_ALL_COUPON_TYPE_REQUEST
        })

        const {data} = await axios.get("/api/v1/coupon-type-list");

        dispatch({
            type: GET_ALL_COUPON_TYPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_ALL_COUPON_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}
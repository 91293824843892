import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffersPage } from "../../redux/actions/OfferAction/OffersAction";
import Loader from "../Loader";
import Banner from "../image/banner.webp";


const Offers = () => {
  const { allOffers, loading } = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const [copiedOfferId, setCopiedOfferId] = useState(null);

  // console.log("allOffers",allOffers)

  const copyToClipboard = async (code, offerId) => {
    try {
      await navigator.clipboard.writeText(code);
      setCopiedOfferId(offerId);
      setTimeout(() => setCopiedOfferId(null), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  useEffect(() => {
    dispatch(getAllOffersPage());
  }, [dispatch]);

  return (
    <div className="pb-20 flex flex-col items-center justify-center">
      <div
        className="h-[400px] w-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
        }}
      >
        <div className="w-full h-[400px] flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
          <div className="pt-20 md:pt-0">
            <h1 className="font-semibold text-4xl text-white">Our Latest Offers</h1>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-around pt-10">
        {loading ? (
          <Loader />
        ) : (
          allOffers &&
          allOffers.map((offer) => {
            if (
              offer.couponCode.isActive === true &&
              (new Date(offer.couponCode.couponExpiration) >
                new Date())
            ) {
              return (
                <div
                  key={offer._id}
                  className="w-full md:w-[300px] m-2 bg-white border border-gray-200 shadow-lg rounded-lg overflow-hidden"
                >
                  <div className="relative w-full">
                    <img
                      src={offer.picture.url}
                      alt="Luxury Villa"
                      className="w-full h-56 object-cover"
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
                      <span className="text-white text-2xl font-bold">
                        {offer.couponCode.code}
                      </span>
                    </div>
                  </div>
                  <div className="p-4 text-center w-full">
                    <p className="text-gray-600 h-[70px] overflow-hidden">
                      {offer.summary}
                    </p>
                    <div className="mt-4 flex justify-center items-center space-x-2">
                      <span className="border border-gray-400 text-gray-800 px-4 py-2 rounded-md bg-gray-100">
                        {offer.couponCode.code}
                      </span>
                      <button
                        onClick={() =>
                          copyToClipboard(offer.couponCode.code, offer._id)
                        }
                        className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800"
                      >
                        Copy Code
                      </button>
                    </div>
                    {copiedOfferId === offer._id && (
                      <p className="text-green-500 text-sm mt-2">Copied!</p>
                    )}
                  </div>
                </div>
              );
            }
            return null;
          })
        )}
      </div>
    </div>
  );
};

export default Offers;

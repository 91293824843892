import {
  CREATE_NEW_AMENITIES_REQUEST,
  CREATE_NEW_AMENITIES_SUCCESS,
  CREATE_NEW_AMENITIES_FAIL,
  GET_ALL_AMENITIES_REQUEST,
  GET_ALL_AMENITIES_SUCCESS,
  GET_ALL_AMENITIES_FAIL,
  GET_AMENITIES_BY_ID_REQUEST,
  GET_AMENITIES_BY_ID_SUCCESS,
  GET_AMENITIES_BY_ID_FAIL,
  GET_AMENITIES_BY_ID_UPADTE_FAIL,
  GET_AMENITIES_BY_ID_UPADTE_SUCCESS,
  GET_AMENITIES_BY_ID_UPADTE_REQUEST,
  DELETE_AMENITIES_REQUEST,
  DELETE_AMENITIES_SUCCESS,
  DELETE_AMENITIES_FAIL,
} from "../../constant/propertyConstant/propertyAmenities";

export const createAmenitiesReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_AMENITIES_REQUEST:
    case GET_ALL_AMENITIES_REQUEST:
    case GET_AMENITIES_BY_ID_REQUEST:
    case GET_AMENITIES_BY_ID_UPADTE_REQUEST:
    case DELETE_AMENITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_NEW_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_AMENITIES_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        amenitiesId: action.payload,
      };

    case GET_AMENITIES_BY_ID_UPADTE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    case GET_ALL_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        allAmenities: action.payload.allEmenities,
      };

    case DELETE_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case CREATE_NEW_AMENITIES_FAIL:
    case GET_ALL_AMENITIES_FAIL:
    case GET_AMENITIES_BY_ID_FAIL:
    case GET_AMENITIES_BY_ID_UPADTE_FAIL:
    case DELETE_AMENITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

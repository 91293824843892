// create place to visit
export const CREATE_PLACE_TO_VISIT_REQUEST = "CREATE_PLACE_TO_VISIT_REQUEST"
export const CREATE_PLACE_TO_VISIT_SUCCESS = "CREATE_PLACE_TO_VISIT_SUCCESS"
export const CREATE_PLACE_TO_VISIT_FAIL = "CREATE_PLACE_TO_VISIT_FAIL"


// get all place to visit data
export const GET_ALL_PLACE_TO_VISIT_REQUEST = "GET_ALL_PLACE_TO_VISIT_REQUEST"
export const GET_ALL_PLACE_TO_VISIT_SUCCESS = "GET_ALL_PLACE_TO_VISIT_SUCCESS"
export const GET_ALL_PLACE_TO_VISIT_FAIL = "GET_ALL_PLACE_TO_VISIT_FAIL"

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FaIcons from "react-icons/fa"; 
import * as MdIcons from "react-icons/md"; // Material Design Icons
import * as BsIcons from "react-icons/bs"; // Bootstrap Icons
import SideMenu from "../../sideMenu/SideMenu";
import { createPackageAmenities } from "../../../../redux/actions/PackagesAction/PackageAmenitiesAction";
import { toast } from "react-toastify";

const CreatePackageAmenities = () => {
  const [formData, setFormData] = useState({
    name: "",
    icon: null, 
  });
  const { success } = useSelector((state) => state.packageAmenities);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false); 
  const [searchTerm, setSearchTerm] = useState(""); 
  const [selectedIconKey, setSelectedIconKey] = useState(null); 

  console.log(selectedIconKey);
  // Combine icons from multiple sets
  const allIcons = {
    ...FaIcons,
    ...MdIcons,
    ...BsIcons
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle search term input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle icon selection
  const handleIconSelect = (iconKey) => {
    setSelectedIconKey(iconKey); 
    setFormData((prevData) => ({
      ...prevData,
      icon: allIcons[iconKey], 
    }));
    setIsOpen(false);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      icons: selectedIconKey,
    };
    dispatch(createPackageAmenities(data));
    // Reset form after submission if needed
    setFormData({ name: "", icon: null });
    setSelectedIconKey(null);
  };

  // Filter icons based on the search term
  const filteredIcons = Object.keys(allIcons).filter((iconKey) =>
    iconKey.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (success) {
      toast.success("Amenities created successfull");
    }
    // dispatch(getAllAmenities());
  }, [dispatch, success]);
  return (
    <div className="flex pt-20">
      <SideMenu />

      <div className="pt-5 flex justify-center w-full ">
        <div className="w-3/6 h-[450px] p-6 border border-gray-300 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Create Package Amenities</h2>
          <form onSubmit={handleSubmit} className="p-4 border rounded">
            {/* Name Field */}
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="p-2 border rounded w-full"
                required
              />
            </div>

            {/* Icon Dropdown */}
            <div className="mb-4 w-full">
              <label htmlFor="icon" className="block mb-2">
                Icon:
              </label>
              <div className="relative inline-block text-left w-full  ">
                {/* Button to trigger dropdown */}
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="flex items-center justify-between bg-gray-200 px-4 py-2 rounded-lg w-full"
                >
                  {selectedIconKey ? (
                    <span className="flex items-center">
                      {React.createElement(allIcons[selectedIconKey])}{" "}
                      {/* Display selected icon */}
                      <span className="ml-2">Selected Icon</span>
                    </span>
                  ) : (
                    "Select Icon"
                  )}
                  <FaIcons.FaChevronDown className="ml-2" />
                </button>

                {isOpen && (
                  <div className="absolute w-full right-0 mt-2 w-64 bg-white shadow-lg rounded-lg p-4 max-h-60 overflow-y-auto">
                    {/* Search input */}
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search icons..."
                      className="w-full mb-4 px-2 py-1 border border-gray-300 rounded-lg"
                    />

                    {/* Icon list */}
                    <ul className="grid grid-cols-3 gap-4">
                      {filteredIcons.length > 0 ? (
                        filteredIcons.map((iconKey) => {
                          const IconComponent = allIcons[iconKey];
                          return (
                            <li
                              key={iconKey}
                              className="cursor-pointer text-2xl"
                              onClick={() => handleIconSelect(iconKey)}
                            >
                              {React.createElement(IconComponent)}{" "}
                              {/* Render icon */}
                            </li>
                          );
                        })
                      ) : (
                        <li className="col-span-3 text-center text-gray-500">
                          No icons found
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded w-full"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePackageAmenities;

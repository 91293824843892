import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronRight,
  FaBuilding,
  FaClipboardList,
  FaFolder,
  FaCubes,
  FaThList,
} from "react-icons/fa";
const menuItems = [
  {
    title: "Properties",
    key: "properties",
    icon: <FaBuilding />,
    items: [
      {
        title: "Property",
        key: "property",
        icon: <FaClipboardList />,
        items: [
          { title: "Property List", path: "/dashboard/property/list" },
          { title: "Add Property", path: "/dashboard/property/add" },
        ],
      },
      {
        title: "Property Type",
        key: "propertyType",
        icon: <FaFolder />,
        items: [
          {
            title: "Property Type List",
            path: "/dashboard/property/type/list",
          },
          {
            title: "Add Property Type",
            path: "/dashboard/property/type/add",
          },
        ],
      },
      {
        title: "Amenities",
        key: "amenities",
        icon: <FaCubes />,
        items: [
          { title: "Amenities List", path: "/dashboard/amenities/list" },
          { title: "Add Amenity", path: "/dashboard/amenities/add" },
          {
            title: "Add Amenity Type",
            path: "/dashboard/amenities-type/add",
          },
          {
            title: "Amenities Type List",
            path: "/dashboard/amenities-type/list",
          },
        ],
      },
      {
        title: "Category",
        key: "category",
        icon: <FaThList />,
        items: [
          { title: "Category List", path: "/dashboard/category/list" },
          { title: "Add Category", path: "/dashboard/category/add" },
        ],
      },
      // collection
      {
        title: "Collection",
        key: "collection",
        icon: <FaFolder />,
        items: [
          { title: "Collection List", path: "/dashboard/collection/list" },
          { title: "Add Collection", path: "/dashboard/collection/add" },
        ],
      },

      // features
      {
        title: "Features",
        key: "features",
        icon: <FaFolder />,
        items: [
          { title: "Features List", path: "/dashboard/features/list" },
          { title: "Add Features", path: "/dashboard/features/add" },
        ],
      },
      // Property Spaces
      {
        title: "Spaces",
        key: "Spaces",
        icon: <FaFolder />,
        items: [
          { title: "Property Spaces List", path: "/dashboard/spaces/list" },
          { title: "Add Property Space", path: "/dashboard/spaces/add" },
        ],
      },
    ],
  },
  {
    title: "Packages",
    key: "packages",
    icon: <FaClipboardList />,
    items: [
      {
        title: "Packages",
        key: "packagesList",
        icon: <FaClipboardList />,
        items: [
          { title: "Package List", path: "/dashboard/package/list" },
          { title: "Add Package", path: "/dashboard/package/add" },
        ],
      },
      {
        title: "Place To Visit",
        key: "placetovisit",
        icon: <FaClipboardList />,
        items: [
          {
            title: "Place List",
            path: "/dashboard/package/place-to-visit/list",
          },
          {
            title: "Add place",
            path: "/dashboard/package/place-to-visit/add",
          },
        ],
      },
      {
        title: "Amenities",
        key: "amenities",
        icon: <FaClipboardList />,
        items: [
          {
            title: "Add Amenities",
            path: "/dashboard/package/amenities/add",
          },
          {
            title: "Amenities List",
            path: "/dashboard/package/amenities/list",
          },
        ],
      },
      {
        title: "Package type",
        key: "package_type",
        icon: <FaClipboardList />,
        items: [
          {
            title: "Add Package type",
            path: "/dashboard/package/type/add",
          },
          {
            title: "Package List",
            path: "/dashboard/package/type/list",
          },
        ],
      },
      {
        title: "Package Category",
        key: "packagecategory",
        icon: <FaClipboardList />,
        items: [
          {
            title: "Add Category",
            path: "/dashboard/package/category/add",
          },
          {
            title: "Category List",
            path: "/dashboard/package/category/list",
          },
        ],
      },
    ],
  },
];

const SideMenu = () => {
  const location = useLocation();
  const [openMenus, setOpenMenus] = useState({});

 

  const isActive = (path) => location.pathname === path;

  const toggleMenu = (key, isMainMenu = false) => {
    setOpenMenus((prev) => {
      const newState = isMainMenu
        ? { [key]: !prev[key] }
        : { ...prev, [key]: !prev[key] };
      return newState;
    });
  };

  useEffect(() => {
    const newOpenMenus = {};
    menuItems.forEach((menu) => {
      menu.items.forEach((subMenu) => {
        if (
          subMenu.items.some((item) => location.pathname.includes(item.path))
        ) {
          newOpenMenus[menu.key] = true;
          newOpenMenus[subMenu.key] = true;
        }
      });
    });
    setOpenMenus(newOpenMenus);
  }, [location.pathname]);

  return (
    <div className="w-72 min-h-screen bg-gray-900 text-white p-4">
      <div className="text-2xl font-bold pb-4">Dashboard</div>
      <nav className="space-y-2">
        <Link
          to="/dashboard"
          className={`block py-3 px-4 rounded-lg text-lg transition-all flex items-center gap-2 ${
            isActive("/dashboard") ? "bg-gray-700" : "hover:bg-gray-800"
          }`}
        >
          Home
        </Link>
        {menuItems.map((menu) => (
          <div key={menu.key}>
            <button
              onClick={() => toggleMenu(menu.key, true)}
              className={`w-full flex justify-between items-center py-3 px-4 rounded-lg text-lg transition-all ${
                openMenus[menu.key] ? "bg-gray-700" : "hover:bg-gray-800"
              }`}
            >
              <div className="flex items-center gap-2">
                {menu.icon} {menu.title}
              </div>
              {openMenus[menu.key] ? <FaChevronDown /> : <FaChevronRight />}
            </button>
            {openMenus[menu.key] && (
              <ul className="ml-6 mt-2 space-y-2">
                {menu.items.map((subMenu) => (
                  <li key={subMenu.key}>
                    <button
                      onClick={() => toggleMenu(subMenu.key)}
                      className="w-full flex justify-between items-center py-3 px-4 rounded-lg text-sm transition-all hover:bg-gray-700"
                    >
                      <div className="flex items-center gap-2">
                        {subMenu.icon} {subMenu.title}
                      </div>
                      {openMenus[subMenu.key] ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </button>
                    {openMenus[subMenu.key] && (
                      <ul className="ml-4 mt-2 space-y-2">
                        {subMenu.items.map((item, index) => (
                          <li key={index}>
                            <Link
                              to={item.path}
                              className={`block w-[180px]  py-3 px-4 text-sm rounded-lg transition-all flex items-center gap-2 ${
                                isActive(item.path)
                                  ? "bg-gray-600"
                                  : "hover:bg-gray-700"
                              }`}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SideMenu;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTheme,
  updateThemeStatus,
} from "../../../../redux/actions/HomeTheme/HomeTheme";
import SideMenu from "../../sideMenu/SideMenu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Loader";

const ListOfTheme = () => {
  const [selectedItems, setSelectedItems] = useState({});
  const { allTheme, updateStatus, loading, error } = useSelector(
    (state) => state.homeTheme
  );
  const dispatch = useDispatch();

  console.log(allTheme)

  const handleCheckboxChange = (id, currentStatus) => {
    setSelectedItems((prev) => ({
      ...prev,
      [id]: !prev[id] ?? !currentStatus,
    }));
  };

  const handleUpdateStatus = () => {
    // Prepare the payload for dispatch
    const themeUpdates = Object.entries(selectedItems).map(
      ([id, isActive]) => ({
        id,
        isActive,
      })
    );

    dispatch(updateThemeStatus({ themeUpdates }));
  };

  useEffect(() => {
    if (updateStatus) {
      toast.success("status updated successfull");
    }

    if (error) {
      toast.error(error);
    }
    dispatch(getAllTheme());
  }, [dispatch, updateStatus, error]);

  return (
    <div className="pt-20 flex font-Mont">
      <SideMenu />
      <div className="p-4 font-Mont">
        <h2 className="text-lg font-semibold mb-4">List of Themes ({allTheme && allTheme.length} Themes)</h2>
        <div className="space-y-4 h-screen overflow-y-scroll">
          {loading ? (
            <Loader />
          ) : (
            allTheme &&
            allTheme.map((item) => (
              <label
                key={item._id}
                className="flex items-center space-x-4 p-2 border rounded-lg shadow-sm hover:shadow-md cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="h-6 w-6 text-blue-600 rounded focus:ring focus:ring-blue-300"
                  checked={selectedItems[item._id] ?? item.isActive} // Use updated status or fallback to item's current status
                  onChange={() => handleCheckboxChange(item._id, item.isActive)}
                />
                <img src={item.banner.url} alt={item.label} className="h-10" />
                <span className="text-gray-700 font-medium text-sm w-9/12">
                  {item.summary}
                </span>
                <Link
                  to={`/dashboard/list-of-all-theme/${item._id}`}
                  className="flex items-center justify-center text-white h-10 pl-10 pr-10 bg-green-400 rounded"
                >
                  Edit
                </Link>
              </label>
            ))
          )}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={handleUpdateStatus}
        >
          Update Status
        </button>
      </div>
    </div>
  );
};

export default ListOfTheme;

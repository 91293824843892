import {
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAIL,
  GET_ALL_COUPON_REQUEST,
  GET_ALL_COUPON_SUCCESS,
  GET_ALL_COUPON_FAIL,
  UPDATE_COUPON_CODE_REQUEST,
  UPDATE_COUPON_CODE_SUCCESS,
  UPDATE_COUPON_CODE_FAIL,
  GET_COUPON_CODE_BY_ID_REQUEST,
  GET_COUPON_CODE_BY_ID_SUCCESS,
  GET_COUPON_CODE_BY_ID_FAIL,
  CLEAR_COUPON_CODE_REQUEST,
  CLEAR_COUPON_CODE_SUCCESS,
  CLEAR_COUPON_CODE_FAIL,
  GET_COUPON_CODE_BY_CODE_REQUEST,
  GET_COUPON_CODE_BY_CODE_FAIL,
  GET_COUPON_CODE_BY_CODE_SUCCESS,
  CLEAR_ERROR,
} from "../../constant/CouponConstant/CouponCode";




export const couponReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COUPON_REQUEST:
    case GET_ALL_COUPON_REQUEST:
    case UPDATE_COUPON_CODE_REQUEST:
    case GET_COUPON_CODE_BY_ID_REQUEST:
    case CLEAR_COUPON_CODE_REQUEST:
    case GET_COUPON_CODE_BY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        couponCode: action.payload,
      };

    case GET_ALL_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        allCoupon: action.payload,
      };

    case UPDATE_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case GET_COUPON_CODE_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        couponId: action.payload,
        error: null
      };

    case GET_COUPON_CODE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        couponId: action.payload,
      };

    case CLEAR_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        couponId: null,
      };

    case CREATE_COUPON_FAIL:
    case GET_ALL_COUPON_FAIL:
    case UPDATE_COUPON_CODE_FAIL:
    case GET_COUPON_CODE_BY_ID_FAIL:
    case CLEAR_COUPON_CODE_FAIL:
    case GET_COUPON_CODE_BY_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload || state.error,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

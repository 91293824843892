import axios from "axios";
import {
  CREATE_PACKAGE_AMENITIES_REQUEST,
  CREATE_PACKAGE_AMENITIES_SUCCESS,
  CREATE_PACKAGE_AMENITIES_FAIL,
  GET_ALL_PACKAGE_AMINITES_FAIL,
  GET_ALL_PACKAGE_AMINITES_REQUEST,
  GET_ALL_PACKAGE_AMINITES_SUCCESS,
} from "../../constant/PackagesConstant/PackageAmenitiesConstant";

export const createPackageAmenities = (amenities) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PACKAGE_AMENITIES_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/create-package-amenities",
      amenities,
      config
    );

    dispatch({
      type: CREATE_PACKAGE_AMENITIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PACKAGE_AMENITIES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all package amenities
export const getAllPackageAmenities = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PACKAGE_AMINITES_REQUEST,
    });

    const { data } = await axios.get("/api/v1/all-package-amenities");
    dispatch({
      type: GET_ALL_PACKAGE_AMINITES_SUCCESS,
      payload: data.packageAmenities,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PACKAGE_AMINITES_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React from "react";
import { Link, useLocation } from "react-router-dom";

const InPageRoute = () => {
  const location = useLocation();
  
  // Get the pathnames from the current URL
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav className="flex items-center space-x-2 text-red-800">
      <Link to="/" className="hover:underline">Home</Link>
      {pathnames.map((value, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return (
          <div key={index} className="flex items-center space-x-2">
            <span>{'>'}</span>
            {isLast ? (
              <span className="font-semibold capitalize">{decodeURIComponent(value)}</span>
            ) : (
              <Link to={routeTo} className="hover:underline capitalize ">
                {decodeURIComponent(value)}
              </Link>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default InPageRoute;

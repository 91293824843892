import {
  GET_ALL_PROPERTY_CATEGORY_REQUEST,
  GET_ALL_PROPERTY_CATEGORY_SUCCESS,
  GET_ALL_PROPERTY_CATEGORY_FAIL,
  CREATE_PROPERTY_CATEGORY_REQUEST,
  CREATE_PROPERTY_CATEGORY_SUCCESS,
  CREATE_PROPERTY_CATEGORY_FAIL,
} from "../../constant/propertyConstant/propertyCategory";


// create property categorty
export const getAllpropertyCategoryReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTY_CATEGORY_REQUEST:
      case CREATE_PROPERTY_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PROPERTY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        Allcategory: action.payload,
      };

      case CREATE_PROPERTY_CATEGORY_SUCCESS:
        return{
          ...state,
          loading: false,
          success: action.payload
        }

    case GET_ALL_PROPERTY_CATEGORY_FAIL:
      case CREATE_PROPERTY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllPropertyType } from "../../../../redux/actions/propertyActions/propertyType";
import { Link } from "react-router-dom";

const PropertyTypeList = () => {
  const dispatch = useDispatch()
  const {allProprtyType} =useSelector((state)=>state.propertyType)

  useEffect(()=>{
    dispatch(getAllPropertyType())
  },[dispatch])
  return (
    <div className="flex pt-20">
      <SideMenu />
      <div className="w-full shadow-xl pl-3 pr-3">
        <h1 className="font-semibold text-lg">Property Type List</h1>
        <div class="relative overflow-x-auto mt-5">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Property Type 
                </th>
                {/* <th scope="col" class="px-6 py-3">
                  Color
                </th> */}
                <th scope="col" class="px-6 py-3">
                  Edit
                </th>
                <th scope="col" class="px-6 py-3">
                  Delete
                </th>
              </tr>
            </thead>

            <tbody>
              {allProprtyType &&
                allProprtyType.map((col) => {
                  return (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {col.name}
                      </th>
                      {/* <td class="px-6 py-4">Silver</td> */}
                      <td className="py-4 px-4">
                        <div className="flex space-x-2">
                          <Link
                            to=""
                            className="text-white bg-green-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                          >
                            Edit
                          </Link>
                        </div>
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex space-x-2">
                          <button className="text-red-500 hover:text-red-600 font-semibold ">
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PropertyTypeList;

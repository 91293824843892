import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffersPage } from "../../redux/actions/OfferAction/OffersAction";
import Loader from "../Loader";

const Offers = () => {
  const { allOffers, loading } = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const [copiedOfferId, setCopiedOfferId] = useState(null);

  const allOffer =
    allOffers &&
    allOffers.filter((offer) => {
      // console.log(offer)
      if (
        offer.couponCode.isActive === true &&
        new Date(offer.couponCode.couponExpiration) > new Date()
      ) {
        return offer;
      }
      return null
    });

  // console.log(allOffer);

  const copyToClipboard = async (code, offerId) => {
    try {
      await navigator.clipboard.writeText(code);
      setCopiedOfferId(offerId);
      setTimeout(() => setCopiedOfferId(null), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  useEffect(() => {
    dispatch(getAllOffersPage());
  }, [dispatch]);

  return (
    <>
    {
      allOffer && allOffer?.length > 0  ?(
        <div className="pb-10 flex flex-col pl-10 pr-10 font-Mont bg-gray-50 pt-5">
        <h1 className="font-semibold text-xl text-black m-2">{`${
          allOffer && allOffer?.length > 0 ? "Our Latest Offers" : ""
        }`}</h1>
  
        <div className="flex flex-wrap pt-3">
          {loading ? (
            <Loader />
          ) : allOffers && allOffers.length > 0 ? (
            allOffers &&
            allOffers.map((offer) => {
              if (
                offer.couponCode.isActive === true &&
                new Date(offer.couponCode.couponExpiration) > new Date()
              ) {
                return (
                  <div
                    key={offer._id}
                    className="w-full md:w-[250px] m-2 bg-white border border-gray-200 shadow-lg rounded-lg overflow-hidden"
                  >
                    <div className="relative w-full">
                      <img
                        src={offer.picture.url}
                        alt="Luxury Villa"
                        className="w-full h-42 object-cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
                        <span className="text-white text-2xl font-bold">
                          {offer.couponCode.code}
                        </span>
                      </div>
                    </div>
                    <div className="p-4 text-center w-full">
                      <p className="text-gray-600  overflow-hidden">
                        {offer.summary}
                      </p>
                      <div className="mt-4 flex justify-center items-center space-x-2">
                        <span className="border border-gray-400 text-gray-800  rounded-md bg-gray-100 p-2 font-medium text-sm">
                          {offer.couponCode.code}
                        </span>
                        <button
                          onClick={() =>
                            copyToClipboard(offer.couponCode.code, offer._id)
                          }
                          className="bg-black text-white rounded-md hover:bg-gray-800 p-2 font-medium text-sm"
                        >
                          Copy Code
                        </button>
                      </div>
                      {copiedOfferId === offer._id && (
                        <p className="text-green-500 text-sm mt-2">Copied!</p>
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })
          ) : (
            ""
          )}
        </div>
      </div>
      ) : ""
    }
    </>
   
  );
};

export default Offers;

import React, { useEffect } from "react";
import logo from "../image/2.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProperty } from "../../redux/actions/propertyActions/PropertyAction";
import { SiInstagram } from "react-icons/si";
import { IoLogoFacebook } from "react-icons/io";
import { FaYoutube } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaWhatsappSquare } from "react-icons/fa";
import { getAllPackageCategory } from "../../redux/actions/PackagesAction/PackageCategoryAction";

const Footer = () => {
  const dispatch = useDispatch();
  const { propertyList } = useSelector((state) => state.propertyList);
  const { packageCategory } = useSelector((state) => state.packageCategory);
  // console.log(propertyList);
  const navigate = useNavigate();
  // const [data, setData] = useState("");

  const filterCityByProperty = (city) => {
    // Filter properties by city
    const filteredProperties =
      propertyList &&
      propertyList.filter(
        (property) =>
          property.city.toLowerCase() === city.toLowerCase() ||
          property.state.toLowerCase() === city.toLowerCase()
      );

    // Navigate to the new page and pass filtered properties as state
    navigate(`/all-locations/${city}`, {
      state: { property: filteredProperties },
    });
  };

  const cityObject = [
    {
      id: 0,
      city: "goa",
    },
    {
      id: 1,
      city: "Dehradun",
    },
    {
      id: 2,
      city: "Mussoorrie",
    },
    {
      id: 3,
      city: "rishikesh",
    },
    {
      id: 4,
      city: "Mukteshwar",
    },
  ];

  const date = new Date().getFullYear();

  useEffect(() => {
    dispatch(getAllProperty());
    dispatch(getAllPackageCategory());
  }, [dispatch]);

  return (
    <footer className="bg-gray-900 text-gray-300 py-5">
      <div className="pl-5 md:pl-10 pr-5 md:pr-10 mx-full">
        {/* Logo and About Section */}
        <div className="mb-6">
          <Link to="/">
            <img
              src={logo}
              className="text-2xl font-bold text-white mb-4 w-1/2 md:w-1/5"
              alt=""
            />
          </Link>
          <p className="font-medium text-sm text-justify w-full md:w-8/12">
            At Da Alohas, we are revolutionizing the experience of buying,
            owning, and enjoying vacation homes. As the premier second home
            investment and vacation rental management company in the country, we
            strive to ensure that every getaway feels like a homecoming.
          </p>
        </div>
        <div className="flex flex-wrap justify-between">
          {/* trending destinations */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h1 className="text-lg font-semibold text-white mb-4">
              Top Destinations
            </h1>
            <div className="space-y-2">
              {cityObject &&
                cityObject.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => filterCityByProperty(item.city)}
                    >
                      <p className="capitalize hover:text-gray-100 cursor-pointer">
                        {item.city}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* packages */}
          {
            packageCategory && packageCategory.length > 0 &&  <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold text-white mb-4">Packages</h3>
            <ul className="space-y-2">
              {packageCategory &&
                packageCategory.map((cat) => {
                  if(cat.isActive === true){
                    return (
                      <li key={cat._id}>
                        <Link
                          to={`/packages/${cat.slug}`}
                          className="capitalize hover:text-gray-100"
                        >
                          {cat.name}
                        </Link>
                      </li>
                    );
                  }
                  return null
                })}
            </ul>
          </div> 
          }
          

          {/* Navigation Links */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold text-white mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about-us" className="hover:text-gray-100">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/our-team" className="hover:text-gray-100">
                  Our Team
                </Link>
              </li>
              <li>
                <Link to="/offers" className="hover:text-gray-100">
                  Offers
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="hover:text-gray-100">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/partner" className="hover:text-gray-100 capitalize">
                  List your home
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold text-white mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="hover:text-gray-100">
                  Privacy policy
                </Link>
              </li>

              <li>
                <Link
                  to="/terms-and-conditions"
                  className="hover:text-gray-100"
                >
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/cancellations-and-refunds"
                  className="hover:text-gray-100"
                >
                  Cancellations and Refunds
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full sm:w-1/2 md:w-1/4 mb-6">
            <h3 className="text-lg font-semibold text-white mb-4">Social</h3>
            <ul className="flex items-center justify-start space-x-3">
              <li>
                <a
                  href="https://www.instagram.com/daalohas/"
                  className="hover:text-gray-100"
                >
                  <SiInstagram size={30} />
                </a>
              </li>

              <li>
                <a
                  href="https://www.facebook.com/daalohas/"
                  className="hover:text-gray-100"
                >
                  <IoLogoFacebook size={35} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@da_alohas"
                  className="hover:text-gray-100"
                >
                  <FaYoutube size={35} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/daalohas/"
                  className="hover:text-gray-100"
                >
                  <IoLogoLinkedin size={30} />
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send/?phone=9870141315"
                  className="hover:text-gray-100"
                >
                  <FaWhatsappSquare size={30} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 mt-5 pt-3 text-center">
          <p>
            &copy; {date} Da Alohas, a brand of Alohas Private Limited. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { HomeOwner } from "./HomeOwnerBenifitsObj";

const HomeOwnerBenifits = () => {
  return (
    <div className="w-full flex flex-col  p-5 md:p-10 font-Mont flex justify-between bg-gray-100">
     {/* <div className=" md:w-2/6"> */}
        <h1 className="font-bold text-2xl text-center">Home Owners Benefits</h1>
        {/* <img
          className="w-[200px]"
          src="https://www.thebluekite.com/images-bluekite/owners-benefits.png?v=1.0"
          alt=""
        />
      </div> */}
      <div className="w-full  flex flex-wrap items-center justify-around mt-5">
        {HomeOwner &&
          HomeOwner.map((home) => {
            return (
              <div
                key={home.id}
                className="border border-1 border-black rounded-xl h-[280px]  md:w-[340px] p-5 m-3"
              >
                <h2 className="font-bold text-xl">{home.title}</h2>
                <p className="font-normal text-base text-justify">
                  {home.summary}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default HomeOwnerBenifits;

import React, { useEffect, useState } from "react";
import SideMenu from "../sideMenu/SideMenu";
import { getAllCoupon } from "../../../redux/actions/CouponAction/CouponCode";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOfferById,
  updateOffer,
} from "../../../redux/actions/OfferAction/OffersAction";
import Loader from "../../Loader";

const UpdateOffer = () => {
  const [image, setImage] = useState(null);
  const [previewURL, setImagePreview] = useState("");
  const [summary, setSummary] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const { allCoupon } = useSelector((state) => state.coupon);
  const { offerId, loading } = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const { id } = useParams();
  // console.log(offerId);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  useEffect(() => {
    if (offerId) {
      setCouponCode(offerId.couponCode);
      setSummary(offerId.summary);
      setImagePreview(offerId.picture.url);
    }
  }, [offerId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("offers", image);
    formdata.append("summary", summary);
    formdata.append("couponCode", couponCode);

    dispatch(updateOffer(id, formdata));
  };

  useEffect(() => {
    dispatch(getAllCoupon());
    dispatch(getOfferById(id));
  }, [dispatch, id]);

  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full flex items-center justify-center">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div className="w-full bg-white p-6 shadow-lg rounded-lg">
            <h2 className="text-xl font-bold mb-4">Update Offers</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Image Upload */}
              <div>
                <label className="block font-medium">Upload Image</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-2 w-full border p-2 rounded"
                  required
                />
                {previewURL && (
                  <img
                    src={previewURL}
                    alt="Preview"
                    className="mt-2 w-3/6 h-32 object-cover rounded"
                  />
                )}
              </div>

              {/* Summary Input */}
              <div>
                <label className="block font-medium">Summary</label>
                <textarea
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  className="w-full h-full border p-2 rounded"
                  placeholder="Enter summary"
                  required
                />
              </div>

              {/* Dropdown */}
              <div>
                <label className="block font-medium">Coupon Code</label>
                <select
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="w-2/6 border p-2 rounded"
                  required
                >
                  <option value="">Select a couponCode</option>
                  {allCoupon &&
                    allCoupon.map((coupon) => {
                      console.log(coupon);
                      if (coupon.isActive === true) {
                        return (
                          <option value={coupon._id}>{coupon.code}</option>
                        );
                      }
                      return null;
                    })}
                </select>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
              >
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateOffer;

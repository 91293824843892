import React, { Fragment } from 'react'
import {Helmet} from "react-helmet"

const MetaData = ({title,image}) => {
  // console.log(title)
  // console.log(image)
  return (
    <Fragment>
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
        </Helmet>
    </Fragment>
  )
}

export default MetaData
import React from "react";
import { useSelector } from "react-redux";
// import { searchProperty } from "../../redux/actions/propertyActions/PropertyAction";
import PropertyCard from "../property/propertyCard";
import SearchBar from "./PropertySearch";
import { Link } from "react-router-dom";
import TrendingDestinations from "../property/TrendingDestinations";
import SmallScreenSearchPage from "./SmallScreenSearchPage";

const SearchPropertyPage = () => {
  const { search, loading } = useSelector((state) => state.search);
  //   console.log(search);
  // const dispatch = useDispatch();


  // useEffect(() => {

  // }, []);

  return (
    <div className="pt-20 pb-10 flex flex-col items-center justify-center w-full font-Mont">
      <div className="hidden md:block w-11/12">
        <SearchBar />
      </div>
      <div className="block md:hidden w-11/12">
        {/* <h2 className="flex flex-col">
          {city || "All Location"}, 
          <span className="flex">{getFormattedDateRange(dateFrom,dateTo)} . <p className="ml-3 ">{adults} guest(s)</p></span> 
        </h2> */}
        {/* <SmallScreenSearch /> */}
        <SmallScreenSearchPage />
      </div>
      <div className="flex flex-wrap items-center justify-center pt-5">
        {loading ? (
          <div>
            <h1>Loading...</h1>
          </div>
        ) : (
          <div className=" flex flex-wrap items-center justify-center">
            {search.properties?.length > 0 ? (
              search &&
              search.properties.map((property) => {
                if (
                  property.live === "Yes" &&
                  property.home_page_display === "Yes"
                ) {
                  return (
                    <PropertyCard key={property._id} property={property}/>
                  );
                }
                return null;
              })
            ) : (
              <div className="w-full flex flex-col items-center justify-center">
                <h1>No stay found for chosen dates</h1>

                <div className="bg-gray-400 mt-5  p-2 w-[150px] text-center font-medium text-sm">
                  <Link to="/all-locations">View All properties</Link>
                </div>

                <div className="w-[99vw] md:w-[70vw] mt-5">
                  <TrendingDestinations />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPropertyPage;

import axios from "axios";
import {
  GET_ALL_PROPERTY_REQUEST,
  GET_ALL_PROPERTY_SUCCESS,
  GET_ALL_PROPERTY_FAIL,
  CREATE_NEW_PROPERTY_FAIL,
  CREATE_NEW_PROPERTY_REQUEST,
  CREATE_NEW_PROPERTY_SUCCESS,
  GET_PROPERTY_BY_ID_REQUEST,
  GET_PROPERTY_BY_ID_FAIL,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_SLUG_REQUEST,
  GET_PROPERTY_BY_SLUG_SUCCESS,
  GET_PROPERTY_BY_SLUG_FAIL,
  UPDATE_PROERTY_BY_ID_FAIL,
  UPDATE_PROERTY_BY_ID_REQUEST,
  UPDATE_PROERTY_BY_ID_SUCCESS,
  GET_RENTLIO_PRICE_FAIL,
  GET_RENTLIO_PRICE_REQUEST,
  GET_RENTLIO_PRICE_SUCCESS,
  CLEAR_ERROR,
  SEARCH_PROPERTY_REQUEST,
  SEARCH_PROPERTY_SUCCESS,
  SEARCH_PROPERTY_FAIL,
  GET_ALL_DISABLE_DATE_REQUEST,
  GET_ALL_DISABLE_DATE_SUCCESS,
  GET_ALL_DISABLE_DATE_FAIL,
} from "../../constant/propertyConstant/PropertyConstant";

export const createNewProperty = (newProperty) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_NEW_PROPERTY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      `/api/v1/createProperty`,
      newProperty,
      config
    );
    dispatch({
      type: CREATE_NEW_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_NEW_PROPERTY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update property
export const updateProperty = (id, updatedata) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROERTY_BY_ID_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `/api/v1/updateProperty/${id}`,
      updatedata,
      config
    );
    dispatch({
      type: UPDATE_PROERTY_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROERTY_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get property by slug
export const getPropertyBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPERTY_BY_SLUG_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/all-property-list/${slug}`);

    dispatch({
      type: GET_PROPERTY_BY_SLUG_SUCCESS,
      payload: data.property,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_BY_SLUG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get propertyBy id
export const getPropertyById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROPERTY_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/all-property-lists/${id}`);
    dispatch({
      type: GET_PROPERTY_BY_ID_SUCCESS,
      payload: data.property,
    });
  } catch (error) {
    dispatch({
      type: GET_PROPERTY_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all property list
export const getAllProperty = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PROPERTY_REQUEST,
    });

    const { data } = await axios.get("/api/v1/all-property-list");
    // console.log(data)

    dispatch({
      type: GET_ALL_PROPERTY_SUCCESS,
      payload: data.allProperty,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PROPERTY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get rentl price
export const getRentlPrice =
  ({ dateFrom, dateTo, id, adults, clearDate}) =>
  async (dispatch) => {
    // console.log("clearDate", clearDate);
    try {
      dispatch({
        type: GET_RENTLIO_PRICE_REQUEST,
      });

      let url = `/api/v1/searchbydate?dateFrom=${dateFrom}&dateTo=${dateTo}&adults=${adults}&id=${id}&clearDate=${clearDate}`;
      // if(clearDate){
      //  url = `/api/v1/searchbydate?dateFrom=${dateFrom}&dateTo=${dateTo}&adults=${adults}&id=${id}&clearDate=${clearDate}`;
      // }

      const { data } = await axios.get(url);
      // console.log(data);

      dispatch({
        type: GET_RENTLIO_PRICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_RENTLIO_PRICE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const disabledDate =
  ({ dateFrom, dateTo, id }) =>
  async (dispatch) => {
    // console.log(dateFrom,dateTo,id)
    try {
      dispatch({
        type: GET_ALL_DISABLE_DATE_REQUEST,
      });

      const { data } = await axios.get(
        `/api/v1/disabled?dateFrom=${dateFrom}&dateTo=${dateTo}&id=${id}`
      );

      dispatch({
        type: GET_ALL_DISABLE_DATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_DISABLE_DATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

export const searchProperty = (search) => async (dispatch) => {
  // console.log(search)
  try {
    dispatch({
      type: SEARCH_PROPERTY_REQUEST,
    });

    let url = `/api/v1/search?city=${search.city}&dateFrom=${search.dateFrom}&dateTo=${search.dateTo}&adults=${search.adults}`;

    const { data } = await axios.get(url);

    dispatch({
      type: SEARCH_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_PROPERTY_FAIL,
      payload: error.response.data.message,
    });
  }
};

import axios from "axios"
import {CREATE_NEW_AMENITIES_REQUEST,CREATE_NEW_AMENITIES_SUCCESS,CREATE_NEW_AMENITIES_FAIL, GET_ALL_AMENITIES_FAIL, GET_ALL_AMENITIES_REQUEST, GET_ALL_AMENITIES_SUCCESS, GET_AMENITIES_BY_ID_FAIL, GET_AMENITIES_BY_ID_SUCCESS, GET_AMENITIES_BY_ID_REQUEST, GET_AMENITIES_BY_ID_UPADTE_FAIL, GET_AMENITIES_BY_ID_UPADTE_REQUEST, GET_AMENITIES_BY_ID_UPADTE_SUCCESS, DELETE_AMENITIES_FAIL, DELETE_AMENITIES_REQUEST, DELETE_AMENITIES_SUCCESS} from "../../constant/propertyConstant/propertyAmenities"


// create amenities
export const createAmenities = (amenities) =>async(dispatch)=>{
    try {
        dispatch({
            type: CREATE_NEW_AMENITIES_REQUEST
        })

        const {data} = await axios.post("/api/v1/create-amenities",amenities)

        dispatch({
            type: CREATE_NEW_AMENITIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_NEW_AMENITIES_FAIL,
            payload: error.response.data.message
        })
    }
}


// get all amenities
export const getAllAmenities = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_ALL_AMENITIES_REQUEST
        })

        const {data} = await axios.get("/api/v1/all-emenities");
        dispatch({
            type: GET_ALL_AMENITIES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_AMENITIES_FAIL,
            payload: error.response.data.message
        })
    }
}

// get amenities by id
export const getAmenitieByid = ({id}) =>async(dispatch)=>{
    console.log(id)
    try {
        dispatch({
            type: GET_AMENITIES_BY_ID_REQUEST
        })

        const {data} = await axios.get(`/api/v1/all-emenities/${id}`);
        dispatch({
            type: GET_AMENITIES_BY_ID_SUCCESS,
            payload: data.amenitieId
        })
    } catch (error) {
        dispatch({
            type: GET_AMENITIES_BY_ID_FAIL,
            payload: error.response.data.message
        })
    }
}

// update amenities 
export const updateAmenities = ({id},updated) =>async(dispatch)=>{
    // console.log(id,updated)
    try {
        dispatch({
            type: GET_AMENITIES_BY_ID_UPADTE_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.put(`/api/v1/all-emenities/${id}`,updated,config)
        dispatch({
            type: GET_AMENITIES_BY_ID_UPADTE_SUCCESS,
            payload: data.amenitie
        })
    } catch (error) {
        dispatch({
            type: GET_AMENITIES_BY_ID_UPADTE_FAIL,
            payload: error.response.data.message
        })
    }
}

// delete amenities
export const deleteAmenities = (id) =>async(dispatch)=>{
    console.log("deleted",id)
    try {
        dispatch({
            type: DELETE_AMENITIES_REQUEST
        })

        const {data} = await axios.delete(`/api/v1/all-emenities/${id}`);
        dispatch({
            type: DELETE_AMENITIES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_AMENITIES_FAIL,
            payload: error.response.data.message
        })
    }
}
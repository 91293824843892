import {
  CREATE_PACKAGE_TYPE_REQUEST,
  CREATE_PACKAGE_TYPE_SUCCESS,
  CREATE_PACKAGE_TYPE_FAIL,
  GET_ALL_PACKAGE_TYPE_REQUEST,
  GET_ALL_PACKAGE_TYPE_SUCCESS,
  GET_ALL_PACKAGE_TYPE_FAIL,
} from "../../constant/PackagesConstant/PackageTypeConstant";

export const packageTypeReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PACKAGE_TYPE_REQUEST:
    case GET_ALL_PACKAGE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PACKAGE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PACKAGE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        allPackgeType: action.payload,
      };

    case CREATE_PACKAGE_TYPE_FAIL:
    case GET_ALL_PACKAGE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

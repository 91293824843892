import axios from "axios";
import {
  CREATE_PACKAGES_REQUEST,
  CREATE_PACKAGES_SUCCESS,
  CREATE_PACKAGES_FAIL,
  GET_ALL_PACKAGES_REQUEST,
  GET_ALL_PACKAGES_FAIL,
  GET_ALL_PACKAGES_SUCCESS,
  GET_PACKAGE_BY_ID_FAIL,
  GET_PACKAGE_BY_ID_REQUEST,
  GET_PACKAGE_BY_ID_SUCCESS,
  GET_PACKAGES_BY_SLUG_FAIL,
  GET_PACKAGES_BY_SLUG_REQUEST,
  GET_PACKAGES_BY_SLUG_SUCCESS,
} from "../../constant/PackagesConstant/PackagesConstant";

export const createPackages = (packages) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_PACKAGES_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

        const {data} = await axios.post("/api/v1/create-package",packages,config)

        dispatch({
            type: CREATE_PACKAGES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGES_FAIL,
            payload: error.response.data.message
        })
    }
};


// get all packages
export const allPackage = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_ALL_PACKAGES_REQUEST
        })

        const {data} = await axios.get("/api/v1/get-all-package")
        dispatch({
            type: GET_ALL_PACKAGES_SUCCESS,
            payload: data.AllPackage
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_PACKAGES_FAIL,
            payload: error.response.data.message
        })
    }
} 


// get package by id
export const getPackagesById = (id) => async(dispatch)=>{
    try {
        dispatch({
            type: GET_PACKAGE_BY_ID_REQUEST
        })

        const {data} = await axios.get(`/api/v1/get-all-package/${id}`)

        dispatch({
            type: GET_PACKAGE_BY_ID_SUCCESS,
            payload: data.packageId
        })
    } catch (error) {
        dispatch({
            type: GET_PACKAGE_BY_ID_FAIL,
            payload: error.response.data.message
        })
    }
} 

// get package by slug 
export const getPackageBySlug = (slug) =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_PACKAGES_BY_SLUG_REQUEST
        })

        const {data} = await axios.get(`/api/v1/get-all-packages/${slug}`)
        dispatch({
            type: GET_PACKAGES_BY_SLUG_SUCCESS,
            payload: data.packages
        })
    } catch (error) {
        dispatch({
            type: GET_PACKAGES_BY_SLUG_FAIL,
            payload: error.response.data.message
        })
    }
}


// update package 
export const updatePackages = (id,packages) => async (dispatch) => {
    try {
        dispatch({
            type: CREATE_PACKAGES_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

        const {data} = await axios.put(`/api/v1/update-package/${id}`,packages,config)
        console.log(data)

        dispatch({
            type: CREATE_PACKAGES_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_PACKAGES_FAIL,
            payload: error.response.data.message
        })
    }
};
import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getCoupontype } from "../../../../redux/actions/CouponAction/CouponType";
import { getAllProperty } from "../../../../redux/actions/propertyActions/PropertyAction";
import { CreateCouponCode } from "../../../../redux/actions/CouponAction/CouponCode";
import { toast } from "react-toastify";

const CreateCoupon = () => {
  const [errors, setError] = useState("");
  const [formData, setFormData] = useState({
    propertyId: "",
    mobile: "",
    code: "",
    couponType: "",
    PercentOff: "",
    AmountOff: "",
    discountCategory: "",
    couponExpiration: "",
    isActive: false,
  });

  const dispatch = useDispatch();

  // Access couponType data from Redux store
  const { couponType } = useSelector((state) => state.couponType);
  const { propertyList } = useSelector((state) => state.propertyList);
  const { couponCode ,error} = useSelector((state) => state.coupon);
  console.log(couponCode)



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    // Validate PercentOff field (if discountCategory is "1" and value exceeds the limit)
    if (name === "PercentOff") {
      if (parseFloat(newValue) > 49.9) {
        setError("Percent Off cannot be 50% or more.");
        newValue = ""; // Reset the value
      } else {
        setError(""); // Clear errors message if valid
      }
    }

    // Validate AmountOff field
    if (name === "AmountOff") {
      if (parseFloat(newValue) > 50000) {
        setError("Amount Off cannot be 50000 or more.");
        newValue = ""; // Reset the value
      } else {
        setError(""); // Clear errors message if valid
      }
    }

    //  if (name === "mobile") {
    //   if (newValue.length >= 10) {
    //     setError("mobile 10 ");
    //     newValue = ""; // Reset the value
    //   } else {
    //     setError(""); // Clear errors message if valid
    //   }
    // }

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.discountCategory === "1" &&
      (formData.PercentOff === "" || parseFloat(formData.PercentOff) >= 50)
    ) {
      setError("Percent Off cannot be 50% or more.");
      return;
    }

    if (
      formData.discountCategory === "2" &&
      (formData.AmountOff === "" || parseFloat(formData.AmountOff) >= 50000)
    ) {
      setError("Amount Off cannot be 50000 or more.");
      return;
    }


    const formdata = new FormData();
    if (formData.propertyId) formdata.append("propertyId", formData.propertyId);
    formdata.append("mobile", formData.mobile);
    formdata.append("code",formData.code)
    formdata.append("couponType", formData.couponType);
    formdata.append("PercentOff", formData.PercentOff);
    formdata.append("AmountOff", formData.AmountOff);
    formdata.append("discountCategory", formData.discountCategory);
    formdata.append("couponExpiration", formData.couponExpiration);
    formdata.append("isActive", formData.isActive);

    dispatch(CreateCouponCode(formdata));
    // console.log("Form Data Submitted:", formData);
    // Add API call here
  };

 

  useEffect(() => {
    if(couponCode){
      toast.success("coupon created successfull")
    }

    if(error){
      toast.error(error)
    }

    dispatch(getCoupontype());
    dispatch(getAllProperty());
  }, [dispatch,couponCode,error]);

  return (
    <div className="pt-20 flex w-full">
      <SideMenu />

      <div className="w-full flex justify-center items-center min-h-screen bg-gray-100 p-5">
        <div className="w-10/12 max-w-2xl p-8 bg-white rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Create Coupon
          </h1>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Property ID and Mobile */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Property ID */}
              <div>
                <label
                  htmlFor="couponType"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Coupon Type
                </label>
                <select
                  id="propertyId"
                  name="propertyId"
                  value={formData.propertyId}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" className="uppercase">
                    Select PropertyId
                  </option>
                  {propertyList &&
                    propertyList?.map((type) => (
                      <option
                        className="uppercase"
                        key={type._id}
                        value={type._id}
                      >
                        {type.title}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div>
                <label
                  htmlFor="propertyId"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Property ID
                </label>
                <input
                  type="text"
                  id="propertyId"
                  name="propertyId"
                  value={formData.propertyId}
                  onChange={handleChange}
                  placeholder="Enter Property ID"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div> */}

              {/* Mobile */}
              <div>
                <label
                  htmlFor="mobile"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter Mobile Number"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Code and Coupon Type */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Code */}
              <div>
                <label
                  htmlFor="code"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Coupon Code
                </label>
                <input
                  type="text"
                  id="code"
                  name="code"
                  value={formData.code}
                  onChange={handleChange}
                  placeholder="Enter Coupon Code"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Coupon Type */}
              <div>
                <label
                  htmlFor="couponType"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Coupon Type
                </label>
                <select
                  id="couponType"
                  name="couponType"
                  value={formData.couponType}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" className="uppercase">
                    Select Coupon Type
                  </option>
                  {couponType &&
                    couponType.allCouponType?.map((type) => (
                      <option
                        className="uppercase"
                        key={type._id}
                        value={type._id}
                      >
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            {/* Discount Category */}
            <div className="flex flex-col">
              <label
                htmlFor="discountCategory"
                className="text-sm font-semibold text-gray-600 mb-2"
              >
                Discount Category
              </label>
              <select
                id="discountCategory"
                name="discountCategory"
                value={formData.discountCategory}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Category</option>
                <option value="1">Percent Off</option>
                <option value="2">Amount Off</option>
              </select>
            </div>

            {/* Conditional Percent Off or Amount Off */}
            {formData.discountCategory === "1" && (
              <div>
                <label
                  htmlFor="PercentOff"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Percent Off
                </label>
                <input
                  type="number"
                  id="PercentOff"
                  name="PercentOff"
                  value={formData.PercentOff}
                  onChange={handleChange}
                  placeholder="Enter Percentage Discount"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}

            {formData.discountCategory === "2" && (
              <div>
                <label
                  htmlFor="AmountOff"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Amount Off
                </label>
                <input
                  type="number"
                  id="AmountOff"
                  name="AmountOff"
                  value={formData.AmountOff}
                  onChange={handleChange}
                  placeholder="Enter Amount Discount"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
            {errors && <div className="text-red-600 text-sm mb-4">{errors}</div>}

            {/* Coupon Expiration and Is Active */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Coupon Expiration */}
              <div>
                <label
                  htmlFor="couponExpiration"
                  className="text-sm font-semibold text-gray-600 mb-2"
                >
                  Coupon Expiration
                </label>
                <input
                  type="date"
                  id="couponExpiration"
                  name="couponExpiration"
                  value={formData.couponExpiration}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Is Active */}
              <div className="flex items-center mt-7">
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  checked={formData.isActive}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label
                  htmlFor="isActive"
                  className="text-sm font-semibold text-gray-600"
                >
                  Is Active
                </label>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-3 rounded-lg font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCoupon;

import React from "react";
import Banner from "./image/banner.webp";


const TermsANdConditions = () => {
  return (
    <div className="pb-20 space-y-4 md:space-y-4 font-Mont">
      <div
        className="h-[400px] w-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
        }}
      >
        <div className="w-full h-[400px] flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
          <div className="pt-20 md:pt-0">
            <h1 className="mt-3 font-semibold text-4xl text-white">
              Terms &amp; Conditions
            </h1>
          </div>
        </div>
      </div>
     <div className="pl-7 md:pl-10 pr-5 md:pr:10">
     <div className="space-y-4 pt-5 md:pt-10">
        <p className="font-medium text-base text-justify">
          This document is an electronic record in terms of the Information
          Technology Act 2000 (as amended in 2008) and applicable rules therein,
          as well as the amended provisions related to electronic records in
          various statutes as updated by the Information Technology Act. This
          electronic record is generated by a computer system and does not
          require any physical or digital signature; it has been incorporated by
          reference in the Terms of Use below.
        </p>
        <p className="font-medium text-base text-justify">
          This page provides information about our identity and the legal Terms
          of Use that may apply to your use of www.thealohas.com and any
          bookings/reservations you make through us or when availing any of our
          services. Please read these Terms of Use, Privacy Policy, and other
          policies carefully before you start using the Website.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Website / Site
        </h1>
        <p className="font-medium text-base text-justify">
          This website is owned and operated by Alohas Private Limited. Unless
          stated otherwise, the terms “Da Alohas,” “we,” and “us” refer to Da
          Alohas throughout the Website Terms of Use, which include our Terms of
          Use, Privacy Policy, and other policies displayed on the Website
          (collectively referred to as “Terms of Use”).
        </p>
        <p className="font-medium text-base text-justify">
          If you have any questions regarding our services, this website, any of
          our Terms of Use, or other concerns, please contact us.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Website Terms
        </h1>
        <p className="font-medium text-base text-justify">
          The Terms of Use apply to your use of the Da Alohas website at
          www.thealohas.com and to any services you purchase from Da Alohas.
          These Terms of Use apply regardless of how you access the website,
          including any technologies or devices by which Da Alohas makes the
          website available to you.
        </p>
        <p className="font-medium text-base text-justify">
          By accessing, browsing, using, registering with, or placing an order
          on the website, you confirm that you have read, understood, and agree
          to these Terms of Use in their entirety. If you do not agree to these
          Terms of Use in their entirety, please do not use this Website.
        </p>
        <p className="font-medium text-base text-justify">
          All legal notices on this website which relate to your use of the
          website, together with all applicable Terms of Use and our Privacy and
          other policies, govern your use of this Website.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          The Agreement is governed by the provisions of Indian law, including,
          but not limited to:
        </h1>
        <ul className="list-disc">
          <li>Indian Contract Act, 1872;</li>
          <li>Information Technology Act, 2000;</li>
          <li>
            Rules, regulations, guidelines, and clarifications framed
            thereunder, including the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011 (“SPI Rules”) and;
          </li>
          <li>
            Information Technology (Intermediaries Guidelines) Rules, 2011 (“IG
            Rules”).
          </li>
        </ul>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Use of this Website
        </h1>
        <p className="font-medium text-base text-justify">
          You may only use this Website for lawful purposes, and you must not
          use it in a way that infringes the rights of anyone else or restricts
          or inhibits anyone else’s enjoyment of the Website. Without our prior
          written consent, you may not copy, reproduce, crawl, frame, republish,
          download, print, post, distribute, re-post, broadcast, record,
          transmit, edit, communicate to the public, link to, deep-link into, or
          distribute in any way the web pages or materials on the website, or
          the computer codes or elements comprising the website, other than
          solely for your own personal or internal business use. You may not use
          the content of the website for any commercial purposes whatsoever.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Ownership of Rights
        </h1>
        <p className="font-medium text-base text-justify">
          All rights, including copyright and other intellectual property
          rights, in and to this Website are owned by or licensed to Da Alohas.
          Your use of the Website and its contents grants no rights to you in
          relation to our intellectual property rights or those of third
          parties, in the Website or its contents. By submitting information
          (other than your personal data), text, photos, videos, graphics, or
          other content to the website, you confirm that you have the right to
          use the same and grant us the right to use such materials at our own
          discretion (with or without accreditation) in any media, including,
          without limitation, to edit, copy, reproduce, disclose, post, and
          remove such materials from the Website.
        </p>
        <p className="font-medium text-base text-justify">
          You further agree to execute all such documents and perform all such
          acts and things as we may reasonably require in order to assign any
          rights to us.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Ownership of Submissions / Comments
        </h1>
        <p className="font-medium text-base text-justify">
          Other than personal/sensitive data (which is subject to our Privacy
          Policy), all comments, blogs, feedback, suggestions, pictures, videos,
          questions, ideas, artwork, images, product or marketing ideas, and any
          other submissions disclosed, submitted, or offered to Da Alohas on or
          through the Website, or otherwise disclosed, submitted, or offered by
          you (collectively, “Submissions/Comments”), shall become and remain
          the property of Da Alohas once submitted. You must comply with the
          content standards set out in these terms. You warrant that you own or
          have the right to use any submissions, and that your submissions will
          comply with these standards and guidelines.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Social Networking Sites
        </h1>
        <p className="font-medium text-base text-justify">
          These Website Terms also govern any submissions you make on any Da
          Alohas-related third-party website or page, such as our Facebook page,
          Instagram, Twitter, Pinterest, YouTube, or other social networking
          site. All comments, images, videos, and any other type of material
          posted on any third party’s social networking site do not necessarily
          reflect the opinions or ideas of Da Alohas or its employees, and Da
          Alohas is not responsible for any such content. In any event, all
          material posted on any third-party social networking site must comply
          with these Website Terms and the third-party social networking sites’
          Terms of Use, as applicable.
        </p>
        <p className="font-medium text-base text-justify">Intellectual Property Rights</p>
        <p className="font-medium text-base text-justify">
          A disclosure, comments, and your agreement to these Website Terms
          shall constitute an assignment to Da Alohas of any intellectual
          property rights. Whenever you make use of a feature that allows you to
          upload material to our site, including questions, pictures, videos,
          etc., or to make contact with other users of our site, or to make
          submissions on any Da Alohas-related third-party website or page, you
          must comply with the content standards set out below and these Website
          Terms. Any third-party advertising on Da Alohas pages or third-party
          social networking sites are not the responsibility of, nor endorsed
          by, Da Alohas. All rights, including copyright on Da Alohas Website
          pages, are owned by or licensed to Da Alohas. Any use of Da
          Alohas&#39; social networking pages or their contents, including
          copying or storing them in whole or in part, other than for your own
          personal, non-commercial use, is prohibited without the permission of
          Da Alohas. By making a submission you confirm that the submission is
          your own and that the content does not infringe the material,
          trademarks, or intellectual property of others.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Confidentiality
        </h1>
        <p className="font-medium text-base text-justify">
          Any material you upload to our website will be considered
          non-confidential and non- proprietary, and we have the right to use,
          copy, distribute, and disclose to third parties any material in the
          submissions for any purpose. We also have the right to disclose your
          identity to any third-party who is claiming that any material posted
          or uploaded by you to our site, or any Da Alohas-related third-party
          social networking site, constitutes a violation of their intellectual
          property rights or their right to privacy. We will not be responsible
          or liable to any third party for the content or accuracy of any
          materials posted by you or any other user of our website. We reserve
          the right to remove any submissions you make on our Website if, in our
          opinion, such submission does not comply with the content standards
          set out below.
        </p>
      </div>

      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Rules for Submissions
        </h1>
        <p className="font-medium text-base text-justify">
          You must be 18 years of age or older and registered to post a
          submission. Submissions should not include:
        </p>
        <ul className="list-disc">
          <li>Abusive, offensive, or otherwise inappropriate language;</li>
          <li>
            Profanity, blasphemy, spiteful, racist, sexually explicit, sexually
            gratuitous, or discriminatory comments, or content that might be
            considered harassment, abuse, or threats against the personal safety
            or property of others;
          </li>
          <li>Comments about other reviewers or bloggers;</li>
          <li>
            Remarks that repeat criminal accusations, false, defamatory, or
            misleading statements;
          </li>
          <li>
            Remarks that repeat criminal accusations, false, defamatory, or
            misleading statements;
          </li>
          <li>Spam or advertising;</li>
          <li>Third-party brand names or trademarks;</li>
          <li>HTML code, computer scripts, or website URLs;</li>
          <li>
            Availability, price, promotions, or alternative ordering or delivery
            information;
          </li>
          <li>Information about Da Alohas service providers;</li>
          <li>
            An unsupported claim about the product producing an effect other
            than that for which it is indicated;
          </li>
          <li>
            References to seasonal products which are no longer in season;
          </li>
          <li>
            References to any products other than the product to which the
            submission relates.
          </li>
          <p className="font-medium text-base text-justify">
            Da Alohas, in its absolute discretion, reserves the right not to
            publish the submission or to remove it, take any appropriate action
            if deemed necessary, or remove reviews that do not comply with the
            rules above. Please inform us if you come across any submissions
            that do not comply with our rules.
          </p>
        </ul>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Accuracy of Content
        </h1>
        <p className="font-medium text-base text-justify">
          To the extent permitted by applicable law, Da Alohas disclaims all
          representations and warranties, express or implied, that content or
          information displayed in or on this website is accurate, complete,
          up-to-date, and/or does not infringe the rights of any third party.
          The views expressed in user-generated content represent the opinions
          of those users and do not reflect the views, opinions, beliefs, or
          values of Da Alohas or any of its group companies.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Damage to Your Computer or Other Devices
        </h1>
        <p className="font-medium text-base text-justify">
          Da Alohas employs reasonable efforts to ensure that this Website is
          free from viruses and other malicious or harmful content. However, we
          cannot guarantee that your use of this Website (including any content
          on it or any website accessible from it) will not cause damage to your
          computer or other devices. It is your responsibility to ensure that
          you have the right equipment (including antivirus software) to use the
          website safely and to screen out anything that may damage or harm your
          computer or other devices. Except where required by applicable law, Da
          Alohas shall not be liable to any person for any loss or damage they
          suffer as a result of viruses or other malicious or harmful content
          that they access from or via the website.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Links to Other Websites
        </h1>
        <p className="font-medium text-base text-justify">
          We have placed links on this website to other websites that we think
          you may want to visit. We do not vet these websites and do not have
          any control over their content. Da Alohas accepts no liability in
          respect of the content or use of these websites.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Availing Our Services and Making Bookings or Reservations Through Our
          Website
        </h1>
        <p className="font-medium text-base text-justify">
          You can open an account with us, which will require you to provide
          some mandatory personal information. Alternatively, you can choose to
          place your order via the guest checkout. Please refer to our Privacy
          and Cookie Policy/other policies for more information on how your
          personal information will be used.
        </p>
        <p className="font-medium text-base text-justify">
          You confirm that all information and details provided by you to us
          (including during registration) are true, accurate, and up-to-date in
          all respects and at all times. You can update or correct your details
          at any time by going to Your Account. When you create an account, we
          may provide you with, and/or ask you to use, passwords or other means
          to allow you to access certain areas of the website and/or maintain
          your account security. It is your responsibility to maintain the
          confidentiality of your password and account information. Da Alohas
          shall not be liable to any person for any loss or damage which may
          arise as a result of any failure by you to protect your password or
          account. Should you become aware of or suspect any unauthorized use of
          your password or account, please contact us immediately. If Da Alohas
          suspects any fraudulent activity coming from your account, it reserves
          the right to refuse you access to your account and delete the account.
        </p>
        <p className="font-medium text-base text-justify">
          When you make a booking/reservation through the Website via our guest
          checkout, you will still need to provide us with certain compulsory
          personal information for us to process your booking/reservation. We
          will not store the details that you provide via guest checkout (except
          to save them for processing your order, or if you have opted to
          receive marketing from us). However, you can choose to register an
          account with us after submitting your order. Please refer to our
          Privacy and Cookie Policy to see how your personal information will be
          used and stored.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Hotel Reservation
        </h1>
        <p className="font-medium text-base text-justify">
          The reservations feature of the Website is provided solely to assist
          customers in determining the availability of travel-related services
          and to make legitimate reservations for no other purpose. You warrant
          that you are at least 18 years of age and possess the legal authority
          to enter into the legal agreement constituted by your acceptance of
          these conditions/policies and use the Website in accordance with such
          conditions.
        </p>
        <p className="font-medium text-base text-justify">
          You agree to be financially responsible for your use of the Website,
          including, without limitation, for all reservations made by you or on
          your account for you, whether authorized by you or not. For any
          reservations or other services for which fees may be charged, you
          agree to abide by the terms or conditions, including, without
          limitation, the payment of all moneys due under such terms or
          conditions.
        </p>
        <p className="font-medium text-base text-justify">
          The Website contains details of hotel charges and room rates
          (including any available special offers) for hotels and resorts owned
          or managed by Alohas Private Ltd. Hotel reservation terms and
          conditions of booking are set out on the Website, and payment will be
          in accordance with the procedure set out in such Terms of Use of the
          particular listing.
        </p>
        <p className="font-medium text-base text-justify">
          No contract will subsist between you and Alohas Private Ltd or any of
          its affiliates in respect of any services offered through the Website
          unless and until Alohas Private Ltd accepts your order by e-mail or
          automated confirmation through the Website confirming that it has
          accepted your reservation, booking, or order. Any such contract shall
          be deemed to incorporate the hotel reservation Terms of Use of
          booking. Any other relevant Terms of Use relating to particular
          services/products are set out on the Website.
        </p>
        <p className="font-medium text-base text-justify">
          You are required to ensure that all details provided in connection
          with any services or products offered by Alohas Private Ltd on the
          Website (including hotel room reservations) are accurate. When
          applicable, you must confirm that the credit card used for payment is
          your own and that sufficient funds are available to cover the cost of
          the services or products you intend to purchase. Alohas Private Ltd
          reserves the right to validate your credit card details prior to
          providing any services or products. Alohas Private Ltd retains the
          sole discretion to decline any booking without providing a reason.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Payment
        </h1>
        <p className="font-medium text-base text-justify">
          In alignment with our Payment Policy, you will be prompted to enter
          your payment details during the checkout process. By completing this
          step, you affirm that the credit or debit card being used is yours.
          All mandatory fields must be filled accurately.
        </p>
        <p className="font-medium text-base text-justify">
          Payment for services/bookings can be made using the accepted methods
          facilitated by our appointed payment gateway/processor (&quot;Payment
          Processors&quot;). The processing of payments is subject to the terms,
          conditions, and privacy policies of the Payment Processor, in addition
          to these Terms &amp; Conditions. We hold no responsibility for errors
          made by the Payment Processor. By choosing Da Alohas, you consent to
          pay us, through the Payment Processor, all charges at the rates then
          in effect for any services, including Auto-Renewals, as per the
          applicable payment terms, and authorize us, through the Payment
          Processor, to charge your selected payment provider (your “Payment
          Method”). You agree to fulfill the payment using the designated
          Payment Method. We reserve the right to correct any mistakes made by
          the Payment Processor even if payment has already been requested or
          received. If your payment fails due to insufficient funds, expiration,
          or other reasons, you remain liable for any outstanding amounts.
        </p>
        <p className="font-medium text-base text-justify">
          The terms of your payment will be governed by agreements between you
          and the financial institution, credit card issuer, or other provider
          of your chosen Payment Method. If we, through the Payment Processor,
          do not receive payment from you, you agree to settle all amounts due
          on your billing account upon demand.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Changes to Bookings/Reservations
        </h1>
        <p className="font-medium text-base text-justify">
          Any modifications to your order must comply with the Rescheduling,
          Cancellation &amp; Refund policy available on the Website. Changes
          must be communicated to us in writing and within a reasonable
          timeframe.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Legal Compliance
        </h1>
        <p className="font-medium text-base text-justify">
          You agree not to use the services of Da Alohas unlawfully.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Exclusions of Liability
        </h1>
        <p className="font-medium text-base text-justify">
          We disclaim all representations, warranties, conditions, and terms
          (whether express or implied by statute, law, or otherwise) to the
          fullest extent permitted by law. We accept no liability for losses or
          damages that are not reasonably foreseeable arising out of or in
          connection with these Terms of Use or your use of the Website. These
          Terms of Use do not limit or exclude any other liability that we are
          not permitted to limit or exclude under applicable law.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Other Legal Notices
        </h1>
        <p className="font-medium text-base text-justify">
          Additional legal notices on other areas of this website pertain to
          your use of the Website and, together with these Terms of Use, govern
          your use of this Website.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Assignment and Third Party Rights
        </h1>
        <p className="font-medium text-base text-justify">
          We may update or amend these Terms of Use periodically to comply with
          the law or to meet changing business requirements without prior
          notification. Updates or amendments will be posted on the website.
        </p>
        <p className="font-medium text-base text-justify">
          You may not assign or sub-contract any of your rights or obligations
          under these Terms of Use to any third party unless we agree in
          writing. We retain the right to assign, transfer, or sub-contract any
          of our rights or obligations under these Terms of Use to any third
          party at our discretion.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Termination of Access
        </h1>
        <p className="font-medium text-base text-justify">
          If you breach any of these Terms of Use, your license to use the
          Website will terminate immediately without notice. Otherwise, access
          to the Website may be terminated at any time by Alohas Private Limited
          without notice. Provisions excluding, limiting, and disclaiming
          liability of Alohas Private Limited will survive such termination.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Waiver
        </h1>
        <p className="font-medium text-base text-justify">
          No relaxation or delay by us in exercising any right or remedy under
          these Terms of Use shall operate as a waiver of that right or remedy,
          nor affect our ability to subsequently exercise that right or remedy.
          Any waiver must be agreed upon by us in writing.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Severability
        </h1>
        <p className="font-medium text-base text-justify">
          If any provision of these Terms of Use is found to be illegal,
          invalid, or unenforceable by any court of competent jurisdiction, the
          remaining provisions shall continue in full force and effect.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Entire Agreement
        </h1>
        <p className="font-medium text-base text-justify">
          {" "}
          These Terms of Use constitute the entire agreement between you and us,
          superseding any prior terms, conditions, warranties, and/or
          representations to the fullest extent permitted by law.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Law, Jurisdiction, and Language
        </h1>
        <p className="font-medium text-base text-justify">
          Any matters arising out of your use of this website (including any
          contracts formed through the Website) shall be governed by Indian laws
          and subject to the exclusive jurisdiction of the courts at Gurugram,
          Haryana. All contracts shall be conducted in English.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Notices
        </h1>
        <p className="font-medium text-base text-justify">
          All notices and communications must be in writing, in English, and
          shall be deemed given if delivered personally, by commercial
          messenger, courier service, registered or certified mail (return
          receipt requested), or email, with acknowledgment or complete
          transmission, to the following address:
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Postal Address:
        </h1>
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Email ID: info@thealohas.com
        </h1>
        <p className="font-medium text-base text-justify">
          Grievance Officer - Any grievances regarding the services or
          information shared hereunder may be directed to the Grievance Officer
          of Da Alohas, Mr. Sumit Gupta, at the following email id –
          info@thealohas.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Changes to These Terms of Use
        </h1>
        <p className="font-medium text-base text-justify">
          We reserve the right to change and update these Terms of Use
          periodically. We recommend revisiting this page regularly to stay
          informed of the current Terms of Use. By continuing to access, browse,
          and use this website, you agree to any changes or updates to these
          Terms of Use.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          General Information
        </h1>
        <p className="font-medium text-base text-justify">
          This site is designed with accessibility in mind, featuring clear,
          simple language and common web conventions.
        </p>
      </div>
      <div className="space-y-4">
        <h1 className="mt-3 font-bold md:font-semibold text-lg md:text-base">
          Interpretation
        </h1>
        <p className="font-medium text-base text-justify">
          Headings, subheadings, titles, subtitles to clauses, sub-clauses, and
          paragraphs are for informational purposes only and do not form part of
          the operative provisions of the Agreement. Words denoting the singular
          include the plural and words denoting any gender include all genders.
          The words “include” and “including” are to be construed without
          limitation.
        </p>
      </div>
     </div>
    </div>
  );
};

export default TermsANdConditions;

// upload theme
export const UPLOAD_THEME_REQUEST = "UPLOAD_THEME_REQUEST"
export const UPLOAD_THEME_SUCCESS = "UPLOAD_THEME_SUCCESS"
export const UPLOAD_THEME_FAIL = "UPLOAD_THEME_FAIL"


// get ALl home theme
export const GET_ALL_THEME_REQUEST = "GET_ALL_THEME_REQUEST"
export const GET_ALL_THEME_SUCCESS = "GET_ALL_THEME_SUCCESS"
export const GET_ALL_THEME_FAIL = "GET_ALL_THEME_FAIL"

// update theme
export const UPDATE_THEME_REQUEST = "UPDATE_THEME_REQUEST"
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS"
export const UPDATE_THEME_FAIL = "UPDATE_THEME_FAIL"

// get theme by id
export const GET_THEME_BY_ID_REQUEST = "GET_THEME_BY_ID_REQUEST"
export const GET_THEME_BY_ID_SUCCESS = "GET_THEME_BY_ID_SUCCESS"
export const GET_THEME_BY_ID_FAIL = "GET_THEME_BY_ID_FAIL"

export  const UPDATE_THEME_STATUS_REQUEST = "UPDATE_THEME_STATUS_REQUEST"
export  const UPDATE_THEME_STATUS_SUCCESS = "UPDATE_THEME_STATUS_SUCCESS"
export  const UPDATE_THEME_STATUS_FAIL = "UPDATE_THEME_STATUS_FAIL"
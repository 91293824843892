import {
  GET_ALL_PROPERTY_COLLECTION_REQUEST,
  GET_ALL_PROPERTY_COLLECTION_SUCCESS,
  GET_ALL_PROPERTY_COLLECTION_FAIL,
  CREATE_PROPERTY_COLLECTION_REQUEST,
  CREATE_PROPERTY_COLLECTION_SUCCESS,
  CREATE_PROPERTY_COLLECTION_FAIL,
} from "../../constant/propertyConstant/propertyCollection";

export const collectionReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_PROPERTY_COLLECTION_REQUEST:
    case CREATE_PROPERTY_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROPERTY_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_PROPERTY_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        collections: action.payload,
      };

    case GET_ALL_PROPERTY_COLLECTION_FAIL:
    case CREATE_PROPERTY_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

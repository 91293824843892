import axios from "axios";
import {
  UPLOAD_THEME_REQUEST,
  UPLOAD_THEME_SUCCESS,
  UPLOAD_THEME_FAIL,
  GET_ALL_THEME_FAIL,
  GET_ALL_THEME_REQUEST,
  GET_ALL_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
  UPDATE_THEME_REQUEST,
  UPDATE_THEME_SUCCESS,
  GET_THEME_BY_ID_REQUEST,
  GET_THEME_BY_ID_FAIL,
  GET_THEME_BY_ID_SUCCESS,
  UPDATE_THEME_STATUS_FAIL,
  UPDATE_THEME_STATUS_REQUEST,
  UPDATE_THEME_STATUS_SUCCESS,
} from "../../constant/HomeTheme/HomeTheme";

export const UploadTheme = (theme) => async (dispatch) => {
  // console.log(theme);
  try {
    dispatch({
      type: UPLOAD_THEME_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post("/api/v1/banner", theme, config);
    dispatch({
      type: UPLOAD_THEME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_THEME_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update theme
export const updateTheme = (id, themeData) => async (dispatch) => {
  // console.log(id, themeData);
  try {
    dispatch({
      type: UPDATE_THEME_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `/api/v1/update-banner/${id}`,
      themeData,
      config
    );

    dispatch({
      type: UPDATE_THEME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_THEME_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update theme status
export const updateThemeStatus = (updateStatus) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_THEME_STATUS_REQUEST,
    });

    const { data } = await axios.put(
      `/api/v1/update-theme-status`,
      updateStatus
    );
    dispatch({
      type: UPDATE_THEME_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_THEME_STATUS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get theme by id
export const getThemeById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_THEME_BY_ID_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/all-banner/${id}`);
    // console.log(data.themeId);
    dispatch({
      type: GET_THEME_BY_ID_SUCCESS,
      payload: data.themeId,
    });
  } catch (error) {
    dispatch({
      type: GET_THEME_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get all theme
export const getAllTheme = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_THEME_REQUEST,
    });

    const { data } = await axios.get("/api/v1/all-banner");
    // console.log(data);
    dispatch({
      type: GET_ALL_THEME_SUCCESS,
      payload: data.allTheme,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_THEME_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React from "react";
import { MdOutlineLogout } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/userAction/userAction";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { FaRegCircleUser } from "react-icons/fa6";
import { BiHomeSmile } from "react-icons/bi";



const SideBar = () => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onHandleLogout = () => {
    dispatch(logOut());
    toast.success("log out successfull");
    navigate("/login");
  };
  return (
    <div className="w-full md:w-64 bg-gray-100 text-black flex flex-col font-Mont">
    <div className="text-2xl font-medium p-6">General</div>
    <nav className="flex-1 p-6">
      <Link
        to="/account-setting/profile"
        className={`block py-2.5 px-4 mt-2 rounded hover:bg-gray-400 flex items-center ${
          location.pathname === "/account-setting/profile" ? "bg-gray-300" : ""
        }`}
      >
        <FaRegCircleUser size={20} /> <span className="ml-2">My Profile</span>
      </Link>
      <Link
        to="/account-setting/trips"
        className={`block py-2.5 px-4 mt-2 rounded hover:bg-gray-400 flex items-center ${
          location.pathname === "/account-setting/trips" ? "bg-gray-300" : ""
        }`}
      >
        <BiHomeSmile size={20} /> <span className="ml-2">My Trips</span>
      </Link>
      <button
        onClick={onHandleLogout}
        className="flex items-center border border-[1.5px] border-gray-300 w-full mt-10 block px-4 py-2 text-start text-gray-800 hover:bg-gray-400"
      >
        <MdOutlineLogout size={20} /> <span className="pl-3">Logout</span>
      </button>
    </nav>
  </div>
  );
};

export default SideBar;

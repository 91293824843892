import axios from "axios";
import {
  // USER_LOGIN_REQUEST,
  // USER_LOGIN_SUCCESS,
  // USER_LOGIN_FAIL,
  LOAD_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_SEND_OTP_MOBILE_FAIL,
  USER_SEND_OTP_MOBILE_REQUEST,
  USER_SEND_OTP_MOBILE_SUCCESS,
  USER_VERITY_OTP_FAIL,
  USER_VERITY_OTP_REQUEST,
  USER_VERITY_OTP_SUCCESS,
  ADD_USER_DETAILS_FAIL,
  // ADD_USER_DETAILS_REQUEST,
  ADD_USER_DETAILS_SUCCESS,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
} from "../../constant/userConstant/userConstant";

// export const userLogin = (loginData) => async (dispatch) => {
//   try {
//     dispatch({
//       type: USER_LOGIN_REQUEST,
//     });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const { data } = await axios.post("/api/v1/login", loginData, config);
//     dispatch({
//       type: USER_LOGIN_SUCCESS,
//       payload: data.user,
//     });
//   } catch (error) {
//     dispatch({
//       type: USER_LOGIN_FAIL,
//       payload: error.response.data.message,
//     });
//   }
// };

// get user details

// user login with the mobile number
export const userLoginByMobile = (mobile) => async (dispatch) => {
  try {
    // console.log(mobile);
    dispatch({
      type: USER_SEND_OTP_MOBILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/v1/send-otp", mobile, config);
    // console.log(data);
    dispatch({
      type: USER_SEND_OTP_MOBILE_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: USER_SEND_OTP_MOBILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// user mobile verify
export const userMobileVerify = (verifyMobile) => async (dispatch) => {
  // console.log(verifyMobile);
  try {
    dispatch({
      type: USER_VERITY_OTP_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/otp-verify",
      verifyMobile,
      {
        withCredentials: true,
      },
      config
    );
    // console.log(data);
    dispatch({
      type: USER_VERITY_OTP_SUCCESS,
      payload: data,
    });

    if (data.profileIncomplete === true) {
      return;
    } else {
      dispatch(LoadUser());
    }
  } catch (error) {
    dispatch({
      type: USER_VERITY_OTP_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const addMoreDetails = (userDetails) => async (dispatch) => {
  try {
    // dispatch({
    //   type: ADD_USER_DETAILS_REQUEST,
    // });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/add-details",
      userDetails,
      {
        withCredentials: true,
      },
      config
    );

    dispatch({
      type: ADD_USER_DETAILS_SUCCESS,
      payload: data,
    });

    // dispatch(LoadUser());
  } catch (error) {
    dispatch({
      type: ADD_USER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const LoadUser = () => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_USER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get("/api/v1/me", config);
    // localStorage.setItem("token",data.token)
    // console.log(data);
    dispatch({
      type: LOAD_USER_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateProfile = (id, updatedData) => async (dispatch) => {
  // console.log(id, updatedData);
  try {
    dispatch({
      type: UPDATE_USER_PROFILE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/v1/update/${id}`,
      updatedData,
      {
        withCredentials: true,
      },
      config
    );

    dispatch({
      type: UPDATE_USER_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch(LoadUser());
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// getall users
export const getAllUsers = () =>async(dispatch)=>{
  try {
    dispatch({
      type: GET_ALL_USERS_REQUEST 
    })

    const {data} = await axios.get("/api/v1/alluser")
    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: data.allUsers
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: error.response.data.message
    })
  }
}

// user logout
export const logOut = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/logout", { withCredentials: true });
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: error.response.data.message,
    });
  }
};

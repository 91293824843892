import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="relative w-16 h-16">
        <div className="absolute inset-0 rounded-full border-4 border-t-4 border-t-blue-500 border-gray-300 animate-spin"></div>
        <div className="absolute inset-0 rounded-full border-4 border-t-4 border-t-gray-300 border-blue-500 animate-[spin_1.5s_reverse_linear_infinite]"></div>
      </div>
    </div>
  );
};

export default Loader;

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Loader from "./Loader";

const ProtectedRoute = ({ children }) => {
  const { loading, isAuthenticated } = useSelector((state) => state.user); 
  // console.log("isAuthenticated",isAuthenticated)
  const location = useLocation(); 
  // console.log(loading)
  // console.log(isAuthenticated)
  if (loading) {
    return <div><Loader /></div>;
  }
  if (!isAuthenticated && !loading) {
    // console.log(loading);
    // console.log(user);
    // Redirect to login if not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  // Render children or outlet when authenticated
  return <Fragment>{children & !loading ? children : <Outlet />}</Fragment>;
};

export default ProtectedRoute;

import React, { useEffect, useState } from "react";
import SideMenu from "../../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { createAmenitiesType } from "../../../../../redux/actions/propertyActions/propertyAmenitiesType";
import { toast } from "react-toastify";

const CreateAmenitiesType = () => {
  const [amenitiesType, setAmenitiesType] = useState("");
  const { success, error } = useSelector((state) => state.amenitiesType);
  const dispatch = useDispatch();

  console.log(amenitiesType);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createAmenitiesType({ name: amenitiesType }));
  };

  useEffect(() => {
    if (success) {
      toast.success("created amenities type successfull");
    }

    if (error) {
      toast.error(error);
    }
  }, [dispatch, success, error]);

  return (
    <div className="flex pt-20">
      <SideMenu />

      <div className="pt-5 flex justify-center w-full ">
        <div className="w-3/6 h-[300px] p-6 border border-gray-300 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4">Create Amenities Type</h2>
          <form onSubmit={handleSubmit} className="p-4 border rounded">
            {/* Name Field */}
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2">
                Name:
              </label>
              <input
                type="text"
                id="amenitiesType"
                name="amenitiesType"
                value={amenitiesType}
                onChange={(e) => setAmenitiesType(e.target.value)}
                className="p-2 border rounded w-full"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded w-full"
            >
              Submit
            </button>{" "}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAmenitiesType;

// create property category
export const CREATE_PROPERTY_CATEGORY_REQUEST = "CREATE_PROPERTY_CATEGORY_REQUEST"
export const CREATE_PROPERTY_CATEGORY_SUCCESS = "CREATE_PROPERTY_CATEGORY_SUCCESS"
export const CREATE_PROPERTY_CATEGORY_FAIL = "CREATE_PROPERTY_CATEGORY_FAIL"


// get all property category
export const GET_ALL_PROPERTY_CATEGORY_REQUEST = "GET_ALL_PROPERTY_CATEGORY_REQUEST"
export const GET_ALL_PROPERTY_CATEGORY_SUCCESS = "GET_ALL_PROPERTY_CATEGORY_SUCCESS"
export const GET_ALL_PROPERTY_CATEGORY_FAIL = "GET_ALL_PROPERTY_CATEGORY_FAIL"

import axios from "axios";
import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAIL,
  GET_ALL_CONTACT_LIST_REQUEST,
  GET_ALL_CONTACT_LIST_SUCCESS,
  GET_ALL_CONTACT_LIST_FAIL,
} from "../../constant/ContactConstant/ContactConstant";

export const createContact = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_CONTACT_REQUEST,
    });

    const { data } = await axios.post("/api/v1/create-contact", formData);

    dispatch({
      type: CREATE_CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CONTACT_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const getAllContact = () =>async(dispatch)=>{
    try {

        dispatch({
            type: GET_ALL_CONTACT_LIST_REQUEST,
          });
      
          const { data } = await axios.get("/api/v1/get-all-contact");
      
          dispatch({
            type: GET_ALL_CONTACT_LIST_SUCCESS,
            payload: data.ContactList,
          });
        
    } catch (error) {
        dispatch({
            type: GET_ALL_CONTACT_LIST_FAIL,
            payload: error.response.data.message,
          });
    }
}

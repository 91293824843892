import {
  CREATE_OWNER_LEADS_REQUEST,
  CREATE_OWNER_LEADS_SUCCESS,
  CREATE_OWNER_LEADS_FAIL,
  GET_ALL_OWNER_LEADS_REQUEST,
  GET_ALL_OWNER_LEADS_SUCCESS,
  GET_ALL_OWNER_LEADS_FAIL,
} from "../../constant/OwnerLeads/OwnerLeads";

export const OwnerLeadsReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_OWNER_LEADS_REQUEST:
    case GET_ALL_OWNER_LEADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_OWNER_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_OWNER_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOwnerLeads: action.payload,
      };

    case CREATE_OWNER_LEADS_FAIL:
    case GET_ALL_OWNER_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React from "react";
import MetaData from "../MetaData";
import Services from "../Content/Services/Services";
import Banner from "../image/banner.webp";

const AboutUs = () => {
  return (
    <div className="font-Mont flex  items-center justify-center">
      <MetaData title={`Da Alohas story`} />
      <div className="flex flex-col items-center justify-center">
        <div
          className="h-[400px] w-full"
          style={{
            backgroundImage: `url(${Banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat:"no-repeat",
            backgroundPositionX:"center"
          }}
        >
          <div className="w-full h-[400px] flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
            <div className="pt-20 md:pt-0">
              <h1 className="font-semibold text-4xl text-white">About Us</h1>
            </div>
          </div>

        </div>
        <div className="space-y-4 mt-10 w-10/12">
          <p className="font-medium text-base">
            At <strong>Da Alohas</strong>, we are revolutionizing the experience
            of buying, owning, and enjoying vacation homes. As the premier
            second home investment and vacation rental management company in the
            country, we strive to ensure that every getaway feels like a
            homecoming. Our meticulously curated selection of villas, estates,
            and apartments combines the luxury of an upscale retreat with the
            comfort and privacy of a personal residence. Whether you seek to
            invest, relax, or earn from your property,{" "}
            <strong>
              Da Alohas is your trusted partner in making every moment
              significant.
            </strong>
          </p>
         <div>
         <h2 className="font-semibold text-lg">Your Perfect Getaway Awaits</h2>
          <p className="font-medium text-base">
            Looking for the ideal vacation home for your next getaway? With{" "}
            <strong>Da Alohas,</strong> you are not merely booking
            accommodation—you are entering a thoughtfully designed space that
            feels uniquely yours. From serene beachfront villas and cozy
            hillside retreats to stylish urban escapes, each of our properties
            has been carefully chosen to provide an ideal blend of comfort,
            privacy, and indulgence. Our strong commitment to exceptional
            service, seamless experiences, and quality stays ensures stress-free
            vacation planning. Our accommodations cater to families, friends,
            and solo travelers seeking distinctive experiences. Enjoy tranquil
            mornings with stunning views, lively evenings by a private pool, and
            hotel-like amenities, personalized services, and local
            recommendations that make your vacation effortless and enjoyable.
          </p>
         </div>
         <div>
         <h2 className="font-semibold text-lg">
            Maximize the potential of your second home
          </h2>
          <p className="font-medium text-base">
            For homeowners, Da Alohas offers an opportunity to maximize the
            potential of their second home. While you relax and appreciate your
            space, we manage the process of generating income from unused stays,
            ensuring your property serves as both a personal sanctuary and a
            wise investment. Additionally, all homeowners on our platform gain
            exclusive access to a network of premium vacation homes, allowing
            them to explore new destinations while remaining within the Da
            Alohas community.
          </p>

         </div>
          <p className="font-medium text-base">
            Allow us to handle the details so you can concentrate on creating
            lasting memories.
          </p>
         <div>
         <h2 className="font-semibold text-lg">Sumit and Neha Gupta</h2>
          <p className="font-medium text-base">
            Founders, Da Alohas - The perfect way to own and enjoy a vacation
            home.
          </p>
         </div>
        </div>
        <div className="mt-10 w-full">
          <Services />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

export const HappyPartnerObj = [
    // {
    //     id: 0,
    //     summary: "Da Alohas was able to kick off apartments and heritage property in time resulting in fabulous revenues and an automated system with 100% transparency. We are happy to collaborate with Da Alohas.",
    //     name: "Mr. Sishodia (Delhi NCR)",
    //     propertyName: "Arvelia Mansion – Luxe by Da Alohas, Mussoorie",
    //     state: "Uttarakhand"
    // },
    // {
    //     id: 1,
    //     summary: "We are very happy to let Da Alohas take care of our 4BHK villa style uber luxury apartment in Calangute. They are professional and very good in their field of operation. I have peace of mind and money hitting our bank account on monthly basis.",
    //     name: "Mr. Satjit Bindra",
    //     propertyName: "La Brisa, 4 Bedroom Villa, Candolim",
    //     state: "Goa"
    // },
    // {
    //     id: 2,
    //     summary: "Da Alohas helped us transform the place and launch the property in record tie very trustworthy. We gave them raw apartment and they converted it into a 5 star 2 BHK suite. Right from design to interiors to execution to running it as Airbnb. They did it all with perfection.",
    //     name: "Mr. Sishodia (Delhi NCR)",
    //     propertyName: "Maple by Da Alohas, Mussoorie",
    //     state: "Uttarakhand"
    // },
    {
        id: 3,
        summary: "We are happy to have chosen Da Alohas than letting the builder take care of our apartment. Da Alohas does the job professionally with eye for detail. They are highly recommended.",
        name: "Pankaj Gupta",
        propertyName: "Cedar by Da Alohas, Mussoorie",
        state: "Uttarakhand"
    },
    {
        id: 4,
        summary: "“Our experience as a host with Da Alohas as our sole booking platform has been excellent. They are involved at every stage of booking and ensure that each aspect is looked into with detail, especially with feedback being taken seriously.",
        name: "Jayesh Golatkar",
        propertyName: "Luxury Boutique Picturesque 2 BHK",
        state: "Goa"
    },
    {
        id: 5,
        summary: "“Da Alohas is the perfect way to earn and enjoy your vacation home. We are getting just the kind of guests we would like to have in our property” ",
        name: "Nirmal Agarwal",
        propertyName: "Tranquil Peaks Penthouse, Dehradun",
        state: "Uttarakhand"
    },
    {
        id: 6,
        summary: "“We have an open communication with the Da Alohas management and staff. Whenever we raise our concerns, they take it in the right spirit. We receive handsome revenue/ bookings.”",
        name: "Harshat Pednekar",
        propertyName: "Magnolia by Da Alohas, EL Arbol",
        state: "North Goa"
    },
    {
        id: 7,
        summary: "“We moved from Bluekite to Da Alohas and our experience with Da Alohas has been perfect till date. We sit back and relax while Da Alohas team takes care of everything from sales, bookings, property management and check-in and checkouts. Highly transparent.",
        name: "Vijay and Shivani Verma",
        propertyName: "Hibiscus Oasis by Da Alohas , Reis Magos",
        state: "Goa"
    },
    {
        id: 8,
        summary: "We are very happy to let Da Alohas take care of our 2BHK villa style uber luxury apartment in Calangute. They are professional and very good in their field of operation. I have peace of mind and money hitting our bank account on monthly basis.",
        name: "Christopher Da Costa",
        propertyName: "Susegado, 2 BHK ultra luxury, Calangute",
        state: "Goa"
    },
    {
        id: 9,
        summary: "Da Alohas is very trustworthy. We gave them raw apartment and they converted it into a 5 star 2 BHK suite. Right from design to interiors to execution to running it as Airbnb. They did it all with perfection.",
        name: "Vikram Banerjee",
        propertyName: "Tulip by Da Alohas, Dabolim",
        state: "Goa"
    },
    {
        id: 10,
        summary: "We are happy to have chosen Da Alohas than letting the builder take care of our apartment. Da Alohas does the job professionally with eye for detail. They are highly recommended",
        name: "Shital Verma",
        propertyName: "Humble Pineapple, Calangute",
        state: "North Goa"
    },
]
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import PropertyCard from "./propertyCard";
import InPageRoute from "../InPageRoute";

const FilterProperty = () => {
  const location = useLocation();
  const { city } = useParams();
  const { property } = location.state || {};
  // console.log(city);

  // console.log(property);
  return (
    <div className="pt-20 pl-5 md:pl-10 pr-5 md:pr-10 pb-20">
      <InPageRoute />
      <div className="flex flex-wrap items-center justify-center pt-5 md:pt-10">
        {property && property.length > 0 ? (
          property &&
          property
            .filter((property) => property.live === "Yes")
            .map((property) => {
              return <PropertyCard property={property} />;
            })
        ) : (
          <div>
            <h1>{`${city} coming soon`}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterProperty;

import React from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemType = "image";

// A single draggable image component
const DraggableImage = ({ file, index, moveImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index; // Update the index to reflect the current position
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className="w-[70px] border m-2 "
    >
      <img className="w-full h-full " src={file.url} alt="property" />
    </div>
  );
};

export default DraggableImage;

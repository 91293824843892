import {
  CREATE_OFFER_PAGE_REQUEST,
  CREATE_OFFER_PAGE_SUCCESS,
  CREATE_OFFER_PAGE_FAIL,
  GET_ALL_OFFERS_REQUEST,
  GET_ALL_OFFERS_SUCCESS,
  GET_ALL_OFFERS_FAIL,
  GET_OFFER_BY_ID_REQUEST,
  GET_OFFER_BY_ID_SUCCESS,
  GET_OFFER_BY_ID_FAIL,
  UPDATE_OFFER_BY_ID_REQUEST,
  UPDATE_OFFER_BY_ID_SUCCESS,
  UPDATE_OFFER_BY_ID_FAIL,
} from "../../constant/OfferConstant/OfferConstant";

export const offerReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_OFFER_PAGE_REQUEST:
    case GET_ALL_OFFERS_REQUEST:
    case GET_OFFER_BY_ID_REQUEST:
    case UPDATE_OFFER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_OFFER_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        createdOffers: action.payload,
      };

    case GET_ALL_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allOffers: action.payload,
      };

    case UPDATE_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdate: action.payload,
      };

    case GET_OFFER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        offerId: action.payload,
      };

    case CREATE_OFFER_PAGE_FAIL:
    case GET_ALL_OFFERS_FAIL:
    case GET_OFFER_BY_ID_FAIL:
    case UPDATE_OFFER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

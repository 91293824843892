import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteAmenities, getAllAmenities } from "../../../../redux/actions/propertyActions/propertyAmenities";
import * as FaIcons from "react-icons/fa"; // FontAwesome Icons
import * as MdIcons from "react-icons/md"; // Material Design Icons
import * as BsIcons from "react-icons/bs"; // Bootstrap Icons
import * as FiIcons from "react-icons/fi"; // Feather Icons
import * as HiIcons from "react-icons/hi"; // Hero Icons
import * as IoIcons from "react-icons/io"; // Ionicons
import * as PiIcons from "react-icons/pi"; // Phosphor Icons
import * as SiIcons from "react-icons/si"; // Simple Icons
import * as AiIcons from "react-icons/ai"; // Ant Design Icons
import * as RiIcons from "react-icons/ri"; // Remix Icons
import * as CgIcons from "react-icons/cg"; // Chakra UI Icons
import * as WiIcons from "react-icons/wi"; // Weather Icons
import * as LiaIcons from "react-icons/lia"; // Line
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";


const ListOfAmenities = () => {
  const dispatch = useDispatch();
  const [toggleDelete,setToggleDelete] = useState(false)
  const [deleteAmenitie,setDeleteAmenities] = useState(null)
  const { allAmenities,loading,isDeleted } = useSelector((state) => state.amenities);
  const createIconMapping = () => {
    return {
      ...FaIcons,
      ...MdIcons,
      ...BsIcons,
      ...FiIcons,
      ...HiIcons,
      ...IoIcons,
      ...PiIcons,
      ...SiIcons,
      ...AiIcons,
      ...RiIcons,
      ...CgIcons,
      ...WiIcons,
      ...LiaIcons,
    };
  };

  // Generate the icon mapping
  const iconMapping = createIconMapping();

  const handleDelete = (id) =>{
    dispatch(deleteAmenities(id))
  }

  useEffect(() => {
    if(isDeleted){
      toast.success(isDeleted.message)
    }
    dispatch(getAllAmenities());

  }, [dispatch,isDeleted]);
  return (
    <div className="flex pt-20">
      <SideMenu />
      <div className="w-full shadow-xl pl-3 pr-3">
        <h1 className="font-semibold text-lg">Amenities List</h1>
       {
        loading ? <div>
          Loading...
          </div> :  <div class="relative overflow-x-auto mt-5">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Amenities name
                </th>
                <th scope="col" class="px-6 py-3">
                  Icons
                </th>
                <th scope="col" class="px-6 py-3">
                  Edit
                </th>
                <th scope="col" class="px-6 py-3">
                  Delete
                </th>
              </tr>
            </thead>

            <tbody>
              {allAmenities &&
                allAmenities.map((ame) => {
                  // console.log(ame);
                  const IconComponent = iconMapping[ame.icons]; // Get the icon component

                  return (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={ame._id}>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white capitalize"
                      >
                        {ame.name}
                      </th>
                      <td class="px-6 py-4">
                        {IconComponent ? (
                          <IconComponent className="text-2xl" /> // Render the icon
                        ) : (
                          <span className="text-gray-500">Icon not found</span> // Fallback
                        )}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex space-x-2">
                          <Link
                            to={`/dashboard/amenities/list/${ame._id}`}
                            className="text-white bg-green-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded"
                          >
                            Edit
                          </Link>
                        </div>
                      </td>
                      <td className="py-4 px-4 relative">
                        <div className="flex space-x-2">
                          <button className="text-red-500 hover:text-red-600 font-semibold " onClick={()=>{
                            setDeleteAmenities(ame._id)
                            setToggleDelete(true)}}>
                            <MdDeleteForever size={30}/>
                          </button>

                          {
                             toggleDelete  && deleteAmenitie === ame._id && <div className="absolute top-0 right-0 bg-white shadow-xl w-full p-2">
                              <h1 className="font-medium text-xs">Are you sure you want to remove this.</h1>
                              <div className="w-full items-center justify-between pt-2">
                                <button className="capitalize font-medium text-sm bg-gray-300 pt-1 pb-1 pl-2 pr-2 rounded text-white " onClick={()=>setToggleDelete(!toggleDelete)}>Cancle</button>
                                <button className="capitalize font-medium text-sm bg-red-600 text-white pb-1 pt-1 pl-2 pr-2 rounded" onClick={()=>handleDelete(ame._id)}>yes remove</button>
                                </div>
                              </div> 
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
       }
      </div>
    </div>
  );
};

export default ListOfAmenities;

import React, { useEffect } from "react";

const Counter = ({ count, setCount, propertyId }) => {
  const maxCount = propertyId && propertyId.max_guests;

  // useEffect(()=>{
  //   if(propertyId){
  //     setCount(propertyId?.extra_guest || 0)
  //   }
  // },[propertyId,setCount])

  const increment = () => {
    if (count < maxCount) {
      setCount(count + 1);
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
    // setCount(count > 0 ? count - 1 : 0); // Prevents count from going below 0
  };

  useEffect(()=>{
    if(propertyId){
      setCount(Number(propertyId.extra_guest))
    }
  },[propertyId,setCount])

  return (
    <div className="w-3/6">
      <div className="flex items-center justify-between rounded border w-full">
        <button
          type="button"
          className="border-[1.5px] rounded w-2/6 leading-10 text-gray-600 transition hover:opacity-75"
          onClick={decrement}
        >
          &minus;
        </button>

        <input
          type="number"
          name="count"
          value={count}
          className="h-10 w-16 border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
        />

        <button
          type="button"
          onClick={increment}
          className="border-[1.5px] rounded w-2/6 leading-10 text-gray-600 transition hover:opacity-75"
        >
          +
        </button>
      </div>
      <p>{count === maxCount && "Maximum count reached!"}</p>
    </div>
  );
};

export default Counter;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createPackageCategory } from '../../../../redux/actions/PackagesAction/PackageCategoryAction';
import SideMenu from '../../sideMenu/SideMenu';

const CreatePackageCategory = () => {
    const [packagecategory,setPackageCategory] = useState("");
    const {success,error} = useSelector((state)=>state.packageCategory);
    const dispatch = useDispatch()
    const handleSubmit = (e) =>{
        e.preventDefault()

    dispatch(createPackageCategory({name: packagecategory}))
    }

    useEffect(()=>{
        if(success){
            toast.success("package category created successfull")
        }

        if(error){
            toast.error(error)
        }
    },[dispatch,success,error])

  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full">
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold text-center mb-6">
            Create Category
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="categoryName"
              >
                Feature Name
              </label>
              <input
                type="text"
                id="collection"
                value={packagecategory}
                onChange={(e) => setPackageCategory(e.target.value)}
                className="w-full lowercase px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter collection name"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePackageCategory
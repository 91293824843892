import React, { useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { UploadTheme } from "../../../../redux/actions/HomeTheme/HomeTheme";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import ListOfTheme from "./ListOfTheme";

const ThemeUpload = () => {
  const [banner, setBanner] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [summary, setSummary] = useState("");
  const [themeRoute,setThemeRoute] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useSelector((state) => state.homeTheme);
  console.log(banner);
  console.log(theme);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBanner(file);
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (banner) formData.append("banner", banner);
    formData.append("summary", summary);
    formData.append("route", themeRoute)
    dispatch(UploadTheme(formData));

    if (theme) {
      toast.success("banner uploaded successfull");
      navigate("/dashboard/list-of-all-theme");
    }
  };

  // useEffect(() => {

  // }, [dispatch, theme, navigate]);

  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full min-h-screen flex items-center justify-center bg-gray-50">
        {/* <div>
          <ListOfTheme />
        </div> */}
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md"
        >
          <h2 className="text-2xl font-bold text-gray-700 mb-4 text-center">
            Upload Your Image & Notes
          </h2>

          {/* Image Upload Field */}
          <div className="mb-3">
            <label className="block text-gray-700 font-medium mb-2">
              Upload Image
            </label>
            <div className="relative w-full h-40 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition">
              <input
                type="file"
                name="banner"
                onChange={handleImageChange}
                className="absolute w-full h-full opacity-0 cursor-pointer"
              />
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <span className="text-gray-500">
                  Click or drag to upload image
                </span>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor="categoryName"
            >
              Theme Route
            </label>
            <input
              type="text"
              id="themeRoute"
              value={themeRoute}
              onChange={(e) => setThemeRoute(e.target.value)}
              className="w-full lowercase px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="theme route"
              required
            />
          </div>

          {/* Textarea Field */}
          <div className="relative mb-6">
            <textarea
              rows="4"
              name="summary"
              placeholder="Write your summary here..."
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 resize-none transition"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition font-medium"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ThemeUpload;

// create features 
export const CREATE_PROPERTY_FEATURES_REQUEST = "CREATE_PROPERTY_FEATURES_REQUEST"
export const CREATE_PROPERTY_FEATURES_SUCCESS = "CREATE_PROPERTY_FEATURES_SUCCESS"
export const CREATE_PROPERTY_FEATURES_FAIL = "CREATE_PROPERTY_FEATURES_FAIL"


// get all property features
export const GET_ALL_PROERTY_FEATURES_REQUEST = "GET_ALL_PROERTY_FEATURES_REQUEST";
export const GET_ALL_PROERTY_FEATURES_SUCCESS = "GET_ALL_PROERTY_FEATURES_SUCCESS";
export const GET_ALL_PROERTY_FEATURES_FAIL = "GET_ALL_PROERTY_FEATURES_FAIL";



import React, { useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import axios from "axios";

// Google Maps API Key
const API_KEY = "AIzaSyASDBMuaSVYTM5y-FRwdDbfsEK-fc4UfXk";

// Map container style
const containerStyle = {
  width: "100%",
  height: "500px",
};

// Default center of the map
const center = {
  lat: 20.5937,
  lng: 78.9629,
};

const MapComponent = ({setLatitude,setLongitude}) => {
  const [markerPosition, setMarkerPosition] = useState(null); // State to store marker position
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
  });

  const [address, setAddress] = useState("");

  const onMapClick = useCallback(async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    console.log(`Clicked Latitude: ${lat}, Longitude: ${lng}`);
    setMarkerPosition({ lat, lng });
    setLatitude(lat)
    setLongitude(lng)

    // Send lat, lng to backend to get the address
    try {
      const response = await axios.post("/api/v1/getMapAddress", { lat, lng });
      setAddress(response.data.address); // Set the address in state
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  }, [setLatitude,setLongitude]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  console.log(address);

  return (
    <div className="mt-20">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={onMapClick} // Handle click event
      >
        {markerPosition && (
          <Marker
            position={markerPosition} // Position of the marker
          />
        )}
        {/* Your map markers or other components go here */}
      </GoogleMap>

      {address && <p>Address: {address}</p>}
    </div>
  );
};

export default MapComponent;

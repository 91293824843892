import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useSelector } from "react-redux";
// import { getRentlPrice } from "../../redux/actions/propertyActions/PropertyAction";

const DatePicker = ({
  checkIn,
  setCheckIn,
  // TotalAdults,
  align,
  // clearDate,
  setClearDate,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for dropdown container
  const { propertyId } = useSelector((state) => state.propertyList);
  const { rentlId } = useSelector((state) => state.propertyList);
  // const [clearDate, setClearDate] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  // const dispatch = useDispatch();

  // console.log(checkIn)
  // console.log(setCheckIn)

  // console.log(checkIn && checkIn.startDate);

  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };

  useEffect(() => {
    if (rentlId && rentlId.disabledDate) {
      setDisabledDates(rentlId.disabledDate.map((d) => new Date(d.date)));
    }
  }, [rentlId]);

  // const filterDisableDate = () =>{
  //   const dis = disabledDates.filter((item)=>
  //     formatDate(item)
  //   }
  //   )
  //   console.log(formatDate(checkIn.startDate))
  // }

  // filterDisableDate()

  const toggleDatePicker = () => setIsDatePickerOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDatePickerOpen(false);
    }
  };

  const calculateMaxDate = () => {
    const startDate = checkIn?.startDate || new Date();
    const maxDays = propertyId?.availability_in_advance || 90; // Default to 90 days
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + maxDays);
    return maxDate;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("checkInDates", JSON.stringify([checkIn]));
  }, [checkIn]);

  useEffect(() => {
    const savedDates = sessionStorage.getItem("checkInDates");
    // console.log(savedDates)
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates).map((range) => ({
        ...range,
        startDate: new Date(range.startDate),
        endDate: new Date(range.endDate),
      }));
      setCheckIn(parsedDates[0]);
    }
  }, [setCheckIn]);

  const handleSelect = (ranges) => {
    setCheckIn(ranges.selection);
    setClearDate(false);
  };

  const clearDates = () => {
    setCheckIn({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setClearDate(true);
  };

  const onHandleDate = () => {
    setCheckIn({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setClearDate(true);
  };

  // useEffect(()=>{
  //   setCheckIn({
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   });
  //   setClearDate(true);
  // },[setCheckIn,setClearDate])

  // useEffect(() => {
  //     if (propertyId && checkIn.startDate && checkIn.endDate && TotalAdults) {
  //       dispatch(
  //         getRentlPrice({
  //           dateFrom: formatDate(checkIn.startDate),
  //           dateTo: formatDate(checkIn.endDate),
  //           id: propertyId._id,
  //           adults: TotalAdults,
  //           clearDate: false,
  //         })
  //       );
  //     } else if (clearDate) {
  //       dispatch(
  //         getRentlPrice({
  //           dateFrom: null,
  //           dateTo: null,
  //           id: propertyId._id,
  //           adults: TotalAdults,
  //           clearDate: true,
  //         })
  //       );
  //       setClearDate(false);
  //     }

  // }, [dispatch, checkIn, propertyId, TotalAdults, clearDate]);

  return (
    <div>
      <div className="relative" ref={dropdownRef}>
        <div
          className="cursor-pointer w-full border border-gray-300 rounded-md"
          onClick={toggleDatePicker}
        >
          <div
            className="flex w-full h-12 justify-around pt-1 pb-1"
            onClick={onHandleDate}
          >
            <div>
              <h1 className="font-medium text-xs">Check in</h1>
              <p className="font-medium text-sm">
                {checkIn?.startDate
                  ? checkIn?.startDate?.toDateString().slice(0, 10)
                  : "Select Date"}
              </p>
            </div>
            <div className="border-l"></div>
            <div>
              <h1 className="font-medium text-xs">Check out</h1>
              <p className="font-medium text-sm">
                {checkIn?.endDate
                  ? checkIn?.endDate?.toDateString().slice(0, 10)
                  : "Select Date"}
              </p>
            </div>
          </div>
        </div>

        {isDatePickerOpen && (
          <div
            className={`absolute h-[400px] top-[-70px]  z-10 rounded rounded-lg shadow-lg ${align}`}
          >
            <div className="flex-1">
              <DateRange
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[
                  {
                    startDate: checkIn?.startDate,
                    endDate: checkIn?.endDate,
                    key: "selection",
                  },
                ]}
                direction="horizontal"
                minDate={new Date()}
                maxDate={calculateMaxDate()}
                disabledDates={disabledDates.map((date) => new Date(date))}
              />
            </div>
            <div className="flex items-center justify-between bg-white w-full pl-10 pr-10">
              <button
                className="flex items-center justify-center  bg-white text-red-500 underline pr-5 pb-5 "
                onClick={clearDates}
              >
                Clear Date
              </button>
              <button
                className="pt-1 pb-1 flex items-center justify-center  bg-red-400 text-white w-[100px] rounded"
                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;

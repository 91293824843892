import {
  GET_ALL_COUPON_TYPE_REQUEST,
  GET_ALL_COUPON_TYPE_SUCCESS,
  GET_ALL_COUPON_TYPE_FAIL,
} from "../../constant/CouponConstant/CouponType";

export const getCouponTypeReducers = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_COUPON_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_COUPON_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        couponType: action.payload,
      };

    case GET_ALL_COUPON_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

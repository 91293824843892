import Akshay from "../image/Akshay.jpeg";
import Dhruv from "../image/Dhruv.jpeg";
import Mayur from "../image/Mayur.jpeg";
import Keshar from "../image/Keshar.jpeg";
import Ankush from "../image/Ankush.jpg"

export const LeadershipObj = [
  {
    id: 0,
    url: Akshay,
    name: "Akshay Govekar",
    profession: "Ops and Guest Exp, Goa",
    summary:
      "Akshay is busy pursuing his passion for vacation rental management, leading the business operations in the vibrant state of Goa. He continues to leverage his expertise in establishing efficient systems for managing properties, which includes hiring and supervising staff to ensure optimal guest experiences. Akshay is a Travel and Tourism Management graduate from Amity University Mumbai.",
    Linkedin: "https://www.linkedin.com/in/akshay-govekar-86149899/",
  },
  {
    id: 1,
    url: Dhruv,
    name: "Dhruv Rajvanshi",
    profession: "Revenue Head, India",
    summary:
      "Dhruv Rajvanshi is a seasoned professional in the hospitality industry, specialising in revenue management and guest relations. With a proven track record in marketing and sales, he effectively promotes properties to maximize occupancy and revenue. Dhruv is passionate about creating memorable stays and continuously seeks innovative strategies to enhance guest satisfaction.",
    Linkedin: "https://www.linkedin.com/in/dhruv-rajvanshi-876276164",
  },
  {
    id: 2,
    url: Ankush,
    name: "Ankush kumar",
    profession: "Full stack developer",
    summary:
      "With 2 years of hands-on experience building robust and scalable web applications, Ankush specializes in both front-end and back-end development, creating seamless and user-friendly digital experiences. His journey is driven by continuous learning and a commitment to writing clean, efficient code that contributes to the success of Da Alohas. Ankush possess a B.tech degree from Bhopal, Madhya Pradesh.",
    Linkedin: "https://www.linkedin.com/in/ankush-kumar-275129176/",
  },
  {
    id: 3,
    url: Mayur,
    name: "Mayur Mejari",
    profession: "Homes Acquisition Lead, Goa",
    summary:
    "Mayur has extensive real estate knowledge and a global perspective on Goa's vacation rental market trends, alongside a deep understanding of local nuances. He uses data insights and traveller preferences to maximize homeowner returns. Passionate about creating exceptional vacation experiences, Mayur enjoys meeting new people, building relationships, and helping homeowners achieve the best possible rental experience.",
    Linkedin: "",
  },
  {
    id: 4,
    url: Keshar,
    name: "Keshar Singh Negi",
    profession: "Ops and Guest Exp Head, Uttarakhand",
    summary:
    "Keshar provides the ultimate guest experience couple with running smooth operations. His ability of making guests at ease make his job easy and fun. His 12+ years of experience in hospitality operations, encompassing hotels, administration, and time share concepts, have equipped him to ensure smooth and hassle-free stays for all Da Alohas guests. Keshar is a graduate from IHM.",
    Linkedin: "https://www.linkedin.com/in/keshar-singh-negi-094447aa/",
  },
  // {
  //     id: 5,
  //     url: "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     name: "",
  //     profession:"",
  //     summary:"",
  //     Linkedin:""
  // },
];

import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAIL,
  GET_ALL_CONTACT_LIST_REQUEST,
  GET_ALL_CONTACT_LIST_SUCCESS,
  GET_ALL_CONTACT_LIST_FAIL,
} from "../../constant/ContactConstant/ContactConstant";

export const ContactReducers = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONTACT_REQUEST:
    case GET_ALL_CONTACT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case GET_ALL_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allcontact: action.payload,
      };

    case CREATE_CONTACT_FAIL:
    case GET_ALL_CONTACT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Mukhteshwer from "../image/TrendingMukteshwar.jpg";
import Dehradun from "../image/TrendingDehradun.jpeg";
import Mussoorrie from "../image/TrendingLocationMussoorie.jpeg";
import Goa from "../image/TrendingLocationGoa.jpg";
import Rishikesh from "../image/TrendingRishikesh.jpg";
import { getAllProperty } from "../../redux/actions/propertyActions/PropertyAction";
import Slider from "react-slick";

const TrendingDestinations = () => {
  const dispatch = useDispatch();
  const { propertyList } = useSelector((state) => state.propertyList);
  const navigate = useNavigate();

  const filterCityByProperty = (city) => {
    const filteredProperties =
      propertyList &&
      propertyList.filter(
        (property) =>
          property.city.toLowerCase() === city.toLowerCase() ||
          property.state.toLowerCase() === city.toLowerCase()
      );

    navigate(`/all-locations/${city}`, {
      state: { property: filteredProperties },
    });
  };

  const cityObject = [
    {
      id: 0,
      city: "goa",
      image: Goa,
    },
    {
      id: 1,
      city: "dehradun",
      image: Dehradun,
    },
    {
      id: 2,
      city: "mussoorie",
      image: Mussoorrie,
    },
    {
      id: 3,
      city: "rishikesh",
      image: Rishikesh,
    },
    {
      id: 4,
      city: "mukteshwar",
      image: Mukhteshwer,
    },
  ];

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getAllProperty());
  }, [dispatch]);
  return (
    <div className="">
      <div className="pl-2 md:pl-[70px] pr-2 md:pr-[70px] flex flex-col pb-10">
        <h1 className="font-semibold text-xl">Trending Destinations</h1>
        {/* {propertyList &&
          propertyList.map((item) => {
            return ( */}

        <div className="mt-5">
          <Slider {...settings}>
            {cityObject &&
              cityObject.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="w-[100px] h-[230px] p-2 rounded overflow-hidden  bg-white cursor-pointer"
                    onClick={() => filterCityByProperty(item.city)}
                  >
                    <div className="relative rounded w-full h-full bg-[#808080] group overflow-hidden">
                      <img
                        className="w-full h-full rounded object-cover transform transition-transform duration-300 ease-in-out group-hover:scale-110"
                        src={item.image}
                        alt={item.city}
                      />

                      <div className="absolute w-full bottom-0  flex items-center justify-center pointer-events-none">
                        <p className="text-white text-lg font-semibold  text-center capitalize w-full bg-opacity-50 px-3 py-1 rounded">
                          {item.city}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>

        {/* );
          })} */}
      </div>
    </div>
  );
};

export default TrendingDestinations;

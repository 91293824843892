import React, { useEffect, useState } from "react";
import SideMenu from "../../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllPropertyType } from "../../../../redux/actions/propertyActions/propertyType";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getAllCategory } from "../../../../redux/actions/propertyActions/propertyCategory";
import { createNewProperty } from "../../../../redux/actions/propertyActions/PropertyAction";
import { getAllAmenities } from "../../../../redux/actions/propertyActions/propertyAmenities";
import { toast } from "react-toastify";
import { getPropertyCollection } from "../../../../redux/actions/propertyActions/propertyCollection";
import { getPropertyFeatures } from "../../../../redux/actions/propertyActions/PropertyFeatures";
import MapComponent from "./getAddress";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableImage from "./Drag_and_Drop";
import { useNavigate } from "react-router-dom";

const CreateNewProperty = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const { success, error } = useSelector((state) => state.createProperty);
  const { allProprtyType } = useSelector((state) => state.propertyType);
  const { Allcategory } = useSelector((state) => state.category);
  const { allAmenities } = useSelector((state) => state.amenities);
  const { collections } = useSelector((state) => state.collection);
  const { Allfeatures } = useSelector((state) => state.feature);
  const [houseRoles, setHouseRole] = useState("");
  const [thing_to_know, setThingToKnow] = useState("");
  const [cancellation_policy, setCancellationPolicy] = useState("");
  const [summary, setSummary] = useState("");
  const [aboutspace, setAboutSpace] = useState("");
  const [nearestPoint, setNearestPoint] = useState("");
  const [category, setCategory] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [collection, setCollection] = useState("");
  const [features, setFeatures] = useState("");
  const [isOpen, setIsOpen] = useState(false); 
  const [selectedAmenities, setSelectedAmenities] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // console.log(success);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (amenityId) => {
    if (selectedAmenities.includes(amenityId)) {
      setSelectedAmenities(selectedAmenities.filter((id) => id !== amenityId)); 
    } else {
      setSelectedAmenities([...selectedAmenities, amenityId]); 
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter amenities based on search term
  const filteredAmenities =
    allAmenities &&
    allAmenities.filter((amenity) =>
      amenity.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const [formData, setFormData] = useState({
    title: "",
    internal_name: "",
    Address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    video_link: "",
    max_guests: "",
    checkIn: "",
    checkOut: "",
    latitude: "",
    longitude: "",
    accomodation_bedrooms: "",
    accomodation_beds: "",
    accomodation_washrooms: "",
    security_Deposit: "",
    instant_booking: "",
    same_day_instant_booking: "",
    same_day_instant_booking_cut_of_time: "",
    starting_from_price: "",
    availability_in_advance: "",
    mapUrl: "",
    channel_manager1: "",
    channel_manager_unitTypeId: "",
    completeAddress:""
  });
  const [live, setLive] = useState(false);
  const [home_page_display, setHomePageDispaly] = useState(false);
  const [images, setImages] = useState([]); // State to hold uploaded images
  // const [newImageFiles, setNewImageFiles] = useState([]);
  // console.log(newImageFiles);


  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }

    setHouseRole(localStorage.getItem("houseRoles") || "");
    setThingToKnow(localStorage.getItem("thing_to_know") || "");
    setCancellationPolicy(localStorage.getItem("cancellation_policy") || "");
    setSummary(localStorage.getItem("summary") || "");
    setAboutSpace(localStorage.getItem("aboutspace") || "");
    setCategory(localStorage.getItem("category") || "");
    setPropertyType(localStorage.getItem("propertyType") || "");
    setCollection(localStorage.getItem("collection") || "");
    setFeatures(localStorage.getItem("features") || "");
  }, []);

  useEffect(() => {
    localStorage.setItem("houseRoles", houseRoles);
    localStorage.setItem("thing_to_know", thing_to_know);
    localStorage.setItem("cancellation_policy", cancellation_policy);
    localStorage.setItem("summary", summary);
    localStorage.setItem("aboutspace", aboutspace);
    localStorage.setItem("category", category);
    localStorage.setItem("propertyType", propertyType);
    localStorage.setItem("collection", collection);
    localStorage.setItem("features", features);
  }, [
    houseRoles,
    thing_to_know,
    cancellation_policy,
    summary,
    aboutspace,
    category,
    propertyType,
    collection,
    features,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);

    localStorage.setItem("formData", JSON.stringify(updatedData));
  };

  // image
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const fileObjects = files.map((file) => ({
      url: URL.createObjectURL(file), // Create a preview URL for the uploaded file
      file, // Keep the original file object for uploading
      _id: file.name, // Use file name as a temporary ID
    }));

    // Update the images state
    setImages((prevImages) => [...prevImages, ...fileObjects]);
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages); // Update state with the new order
  };

  // Submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    const data = new FormData();
    data.append("title", formData.title);
    data.append("internal_name", formData.internal_name);
    data.append("Address", formData.Address);
    data.append("completeAddress",formData.completeAddress)
    data.append("city", formData.city);
    data.append("state", formData.state);
    data.append("country", formData.country);
    data.append("pincode", formData.pincode);
    for (let i = 0; i < images.length; i++) {
      data.append("picture", images[i].file);
    }
    // amenities
    for (let i = 0; i < selectedAmenities.length; i++) {
      data.append("amenities", selectedAmenities[i]);
    }
    data.append("property_type", propertyType);
    data.append("special_feature", features);
    data.append("collections", collection);
    data.append("video_link", formData.video_link);
    data.append("category", category);
    data.append("max_guests", formData.max_guests);
    data.append("accomodation_bedrooms", formData.accomodation_bedrooms);
    data.append("accomodation_beds", formData.accomodation_beds);
    data.append("accomodation_washrooms", formData.accomodation_washrooms);
    data.append("security_Deposit", formData.security_Deposit);
    data.append("check_in_time", formData.checkIn);
    data.append("check_out_time", formData.checkOut);
    data.append("live", live);
    data.append("home_page_display", home_page_display);
    data.append("instant_booking", formData.instant_booking);
    data.append("same_day_instant_booking", formData.same_day_instant_booking);
    data.append("starting_from_price", formData.starting_from_price);
    data.append("availability_in_advance", formData.availability_in_advance);
    data.append("house_roles", houseRoles);
    data.append("thing_to_know", thing_to_know);
    data.append("about_space", aboutspace);
    data.append("latitude", latitude);
    data.append("longitude", longitude);
    data.append("summary", summary);
    data.append("nearestPoint", nearestPoint);
    data.append("mapUrl", formData.mapUrl);
    data.append("cancellation_policy", cancellation_policy);
    data.append("channel_manager1", formData.channel_manager1);
    data.append("channel_manager_unitTypeId", formData.channel_manager_unitTypeId);

    dispatch(createNewProperty(data));
  };

  // console.log(images);

  // useEffect(() => {
  //   return () => {
  //     images && images.forEach((image) => URL.revokeObjectURL(image.url));
  //   };
  // }, [images]);

  useEffect(() => {
    if (success) {
      toast.success(success.message);
      setTimeout(() => {
        navigate("/dashboard/property/list");
      }, 3000);
    }

    if (error) {
      toast.error(error);
    }

    dispatch(getAllPropertyType());
    dispatch(getAllCategory());
    dispatch(getAllAmenities());
    dispatch(getPropertyCollection());
    dispatch(getPropertyFeatures());
  }, [dispatch, success, error, navigate]);
  return (
    <div className="flex pt-20">
      <SideMenu className="" />
      <div className="w-full bg-gray-100 p-3 flex items-center justify-center overflow-y-scroll">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-4 rounded-lg shadow-lg w-full"
        >
          {/* <MapComponent /> */}
          <h2 className="text-3xl font-bold mb-8 text-center">Property Form</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Title */}
            <div className="relative">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Internal Name */}
            <div className="relative">
              <label className="block text-gray-700">Internal Name</label>
              <input
                type="text"
                name="internal_name"
                value={formData.internal_name}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Address */}
            <div className="relative">
              <label className="block text-gray-700">Complete Address</label>
              <input
                type="text"
                name="completeAddress"
                value={formData.completeAddress}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div className="relative">
              <label className="block text-gray-700">Address</label>
              <input
                type="text"
                name="Address"
                value={formData.Address}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* City */}
            <div className="relative">
              <label className="block text-gray-700 mb-2">City</label>
              <select
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Location</option>
                <option value="north goa">North Goa</option>
                <option value="south goa">South Goa</option>
                <option value="dehradun">Dehradun</option>
                <option value="mussoorie">Mussoorie</option>
                {/* <option value="dehradun">Dehradun</option> */}
                <option value="rishikesh">Rishikesh</option>
                <option value="mukteshwar">Mukteshwar</option>
                <option value="delhi">Delhi</option>
                <option value="jaipur">Jaipur</option>
                <option value="varanasi">Varanasi</option>
              </select>
            </div>

            {/* State */}
            <div className="relative">
              <label className="block text-gray-700">State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Country */}
            <div className="relative">
              <label className="block text-gray-700">Country</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Pincode */}
            <div className="relative">
              <label className="block text-gray-700">Pincode</label>
              <input
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Video Link */}
            <div className="relative">
              <label className="block text-gray-700">Video Link</label>
              <input
                type="url"
                name="video_link"
                value={formData.video_link}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Property Type */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Property Type</label>
              <select
                name="propertyType"
                value={propertyType}
                onChange={(e) => setPropertyType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a property type
                </option>
                {allProprtyType &&
                  allProprtyType.map((propertyType) => {
                    // console.log(propertyType);
                    return (
                      <option key={propertyType._id} value={propertyType._id}>
                        {propertyType.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {/* collection property */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Collection</label>
              <select
                name="collection"
                value={collection}
                onChange={(e) => setCollection(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a collection
                </option>
                {collections &&
                  collections.map((col) => {
                    // console.log(col);
                    return <option key={col._id} value={col._id}>{col.name}</option>;
                  })}
              </select>
            </div>

            {/* features property */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Features</label>
              <select
                name="features"
                value={features}
                onChange={(e) => setFeatures(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a features
                </option>
                {Allfeatures &&
                  Allfeatures.map((col) => {
                    // console.log(col);
                    return (
                      <option key={col._id} value={col._id} defaultValue={col._id}>
                        {col.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {/* category */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Category</label>
              <select
                name="property_type"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select a category
                </option>
                {Allcategory &&
                  Allcategory.map((category) => {
                    return (
                      <option key={category._id} value={category._id} defaultValue={category._id}>
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            {/*max_guests */}
            <div className="relative">
              <label className="block text-gray-700">Max Guests</label>
              <input
                type="text"
                name="max_guests"
                value={formData.max_guests}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Amenities */}
            <div className="relative w-full">
              <label className="block text-gray-700">Amenities</label>
              <div className="relative inline-block text-left w-full">
                <button
                  onClick={toggleDropdown}
                  className="w-full flex items-center justify-between input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="button"
                >
                  Select Amenities
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                <div className="w-full">
                  {isOpen && (
                    <div className="w-full z-10 absolute right-0 mt-2 bg-indigo-200 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                      {/* Search Input */}
                      <div className="p-1 w-full">
                        <input
                          type="text"
                          placeholder="Search amenities..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="w-full p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>

                      {/* Checkbox List */}
                      <ul className="w-full p-3 space-y-2 text-sm text-gray-700 dark:text-gray-200">
                        {filteredAmenities.length > 0 ? (
                          filteredAmenities.map((amenity) => (
                            <li key={amenity._id}>
                              <div className="flex items-center">
                                <input
                                  id={amenity._id}
                                  type="checkbox"
                                  checked={selectedAmenities.includes(
                                    amenity._id
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(amenity._id)
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                                />
                                <label
                                  htmlFor={amenity._id}
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {amenity.name}
                                </label>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className="text-gray-500">No amenities found</li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/*accomodation_bedrooms */}
            <div className="relative">
              <label className="block text-gray-700">
                Accomodation bedrooms
              </label>
              <input
                type="text"
                name="accomodation_bedrooms"
                value={formData.accomodation_bedrooms}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*accomodation_beds */}
            <div className="relative">
              <label className="block text-gray-700">Accomodation beds</label>
              <input
                type="text"
                name="accomodation_beds"
                value={formData.accomodation_beds}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*accomodation_washrooms */}
            <div className="relative">
              <label className="block text-gray-700">
                Accomodation washrooms
              </label>
              <input
                type="text"
                name="accomodation_washrooms"
                value={formData.accomodation_washrooms}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*security_Deposit */}
            <div className="relative">
              <label className="block text-gray-700">security Deposit</label>
              <input
                type="number"
                name="security_Deposit"
                value={formData.security_Deposit}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*instant_booking */}
            <div className="relative">
              <label className="block text-gray-700 mb-2">
                Instant booking
              </label>
              <select
                name="instant_booking"
                value={formData.instant_booking}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  select instant booking
                </option>
                <option value="No">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>

            {/* same_day_instant_booking */}
            <div className="relative">
              <label className="block text-gray-700 mb-2">
                same day instant booking
              </label>
              <select
                name="same_day_instant_booking"
                value={formData.same_day_instant_booking}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  select same day instant booking
                </option>
                <option value="No">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>

            {/*starting from price  */}
            <div className="relative">
              <label className="block text-gray-700">Starting from price</label>
              <input
                type="text"
                name="starting_from_price"
                value={formData.starting_from_price}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*chanel manager 1  */}
            <div className="relative">
              <label className="block text-gray-700">Channel Manager 1</label>
              <input
                type="text"
                name="channel_manager1"
                value={formData.channel_manager1}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* chanel manager 2 */}
            <div className="relative">
              <label className="block text-gray-700 capitalize">channel manager unitType Id</label>
              <input
                type="text"
                name="channel_manager_unitTypeId"
                value={formData.channel_manager_unitTypeId}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*availability in advance  */}
            <div className="relative">
              <label className="block text-gray-700">
                Availability in advance
              </label>
              <input
                type="text"
                name="availability_in_advance"
                value={formData.availability_in_advance}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Picture */}
            <DndProvider backend={HTML5Backend}>
              <div className="image-upload-container">
                <div className="flex flex-wrap justify-start">
                  {images.map((file, index) => (
                    <DraggableImage
                      key={file._id}
                      file={file}
                      index={index}
                      moveImage={moveImage}
                    />
                  ))}
                </div>

                <label className="text-gray-700 mt-4">Upload New Images:</label>
                <input
                  type="file"
                  name="picture"
                  onChange={handleFileChange}
                  className="input-transition mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  multiple
                />
              </div>
            </DndProvider>

            {/* Summary */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Summary</label>
              <ReactQuill theme="snow" value={summary} onChange={setSummary} />
            </div>

            {/* About Space */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">About Space</label>
              <ReactQuill
                theme="snow"
                value={aboutspace}
                onChange={setAboutSpace}
              />
            </div>

            {/* Things to Know */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Things to Know</label>
              <ReactQuill
                theme="snow"
                value={thing_to_know}
                onChange={setThingToKnow}
              />
            </div>

            {/* House Rules */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">House Rules</label>
              <ReactQuill
                theme="snow"
                value={houseRoles}
                onChange={setHouseRole}
              />
            </div>
          </div>

          {/*check in time */}
          <div className="flex mt-3 ">
            <div className="relative w-full">
              <label className="block text-gray-700">Check in time</label>
              <input
                type="time"
                name="checkIn"
                value={formData.checkIn}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*check out time */}
            <div className="relative w-full ml-3">
              <label className="block text-gray-700">Check out time</label>
              <input
                type="time"
                name="checkOut"
                value={formData.checkOut}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/*add map location url  */}
          <div className="relative w-full  mt-3">
            <label className="block text-gray-700 mb-1">Direction Url</label>
            <input
              type="url"
              name="mapUrl"
              value={formData.mapUrl}
              onChange={handleChange}
              className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* select your cordinate  using map*/}
          <div className="mt-4">
            <button
              type="button"
              onClick={() => setToggle(!toggle)}
              class="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Select your property address
            </button>
            <div className="sticky">
              {toggle ? (
                <MapComponent
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {/* About Space */}
          <div className="relative col-span-2">
            <label className="block text-gray-700">Cancellation Policy</label>
            <ReactQuill
              theme="snow"
              value={cancellation_policy}
              onChange={setCancellationPolicy}
            />
          </div>

          {/* nearest point  */}
          <div className="relative col-span-2">
            <label className="block text-gray-700">Nearest </label>
            <ReactQuill
              theme="snow"
              value={nearestPoint}
              onChange={setNearestPoint}
            />
          </div>

          <div className="flex w-full mt-5">
            {/*going to live yes / NO */}
            <div className="mb-4 w-1/2">
              <label className="block text-gray-700 mb-2">
                Live (Yes / No)
              </label>
              <select
                name="live"
                value={live}
                onChange={(e) => setLive(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select (Yes/No)
                </option>

                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            {/*Display property */}
            <div className="mb-4 w-1/2 ml-2">
              <label className="block text-gray-700 mb-2">
                Home page display
              </label>
              <select
                name="home_page_display"
                value={home_page_display}
                onChange={(e) => setHomePageDispaly(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  home page display
                </option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>

          {/* More fields */}
          {/* Continue adding the rest fields as demonstrated above */}

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewProperty;

// create packages
export const CREATE_PACKAGES_REQUEST = "CREATE_PACKAGES_REQUEST";
export const CREATE_PACKAGES_SUCCESS = "CREATE_PACKAGES_SUCCESS";
export const CREATE_PACKAGES_FAIL = "CREATE_PACKAGES_FAIL";

// get all packages
export const GET_ALL_PACKAGES_REQUEST = "GET_ALL_PACKAGES_REQUEST";
export const GET_ALL_PACKAGES_SUCCESS = "GET_ALL_PACKAGES_SUCCESS";
export const GET_ALL_PACKAGES_FAIL = "GET_ALL_PACKAGES_FAIL";

// get by id
export const GET_PACKAGE_BY_ID_REQUEST = "GET_PACKAGE_BY_ID_REQUEST";
export const GET_PACKAGE_BY_ID_SUCCESS = "GET_PACKAGE_BY_ID_SUCCESS";
export const GET_PACKAGE_BY_ID_FAIL = "GET_PACKAGE_BY_ID_FAIL";

// get package by slug
export const GET_PACKAGES_BY_SLUG_REQUEST = "GET_PACKAGES_BY_SLUG_REQUEST";
export const GET_PACKAGES_BY_SLUG_SUCCESS = "GET_PACKAGES_BY_SLUG_SUCCESS";
export const GET_PACKAGES_BY_SLUG_FAIL = "GET_PACKAGES_BY_SLUG_FAIL";

// update package
export const UPDATE_PACKAGE_REQUEST = "UPDATE_PACKAGE_REQUEST";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

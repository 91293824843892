import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyBySlug } from "../../redux/actions/propertyActions/PropertyAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Availability from "./Availability";
// import DOMPurify from "dompurify";
import { FaArrowLeftLong, FaBed } from "react-icons/fa6";
import { PiMapPin } from "react-icons/pi";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";
import * as HiIcons from "react-icons/hi";
import * as IoIcons from "react-icons/io";
import * as PiIcons from "react-icons/pi";
import * as SiIcons from "react-icons/si";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as CgIcons from "react-icons/cg";
import * as WiIcons from "react-icons/wi";
import * as LiaIcons from "react-icons/lia";
import { CiUser } from "react-icons/ci";
import CollapseItem from "./collapse";
import MapView from "./MapView";
import "./PropertyDetails.css";
// import { addDays } from "date-fns";
import { FaArrowLeft } from "react-icons/fa";
import Slider from "react-slick";
import AvailabilitySmallScreen from "./AvailabilitySmallScreen";
import { RWebShare } from "react-web-share";
import { MdOutlineShare } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineBathroom } from "react-icons/md";
import MetaData from "../MetaData";
// import Reviews from "../Content/Reviews/Reviews";
import { ReviewObj } from "../Content/Reviews/ReviewsObj";
// import Stapers from "../Booking/Stapers";
// import { toast } from "react-toastify";
// import InPageRoute from "../InPageRoute";
// import DatePicker from "../PropertySearch/DatePicker";

// const YOUR_API_KEY = "AIzaSyASDBMuaSVYTM5y-FRwdDbfsEK-fc4UfXk";

const PropertyDetails = () => {
  const [toggle, setToggle] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [amenitiesToggle, setAmenitiesToggle] = useState(false);
  const [visibleItems, setVisibleItems] = useState(6);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useParams();
  const [isFixed, setIsFixed] = useState(false);
  const videoRef = useRef();
  const mapRef = useRef();
  // const [dateToggle, setDateToggle] = useState(false);
  // console.log(dateToggle);
  // const [showMore, setShowMore] = useState(false);
  // const { rentlId } = useSelector((state) => state.propertyList);
  // console.log(rentlId);

  const { propertyId } = useSelector((state) => state.propertyList);
  // console.log(propertyId);

  const videoUrl = propertyId && propertyId.video_link.split("/").pop();
  // console.log(videoUrl);
  const scrollToVideo = () => {
    if (videoRef.current) {
      videoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToMap = () => {
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const [checkIn, setCheckIn] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 0),
  //     key: "selection",
  //   },
  // ]);

  const createIconMapping = () => {
    return {
      ...FaIcons,
      ...MdIcons,
      ...BsIcons,
      ...FiIcons,
      ...HiIcons,
      ...IoIcons,
      ...PiIcons,
      ...SiIcons,
      ...AiIcons,
      ...RiIcons,
      ...CgIcons,
      ...WiIcons,
      ...LiaIcons,
    };
  };

  // Generate the icon mapping
  const iconMapping = createIconMapping();

  const fixedImageScroll = () => {
    if (window.scrollY >= 300) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", fixedImageScroll);

  // redmore and red less
  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return `${words.slice(0, wordLimit).join(" ")}...`;
  };

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const setting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onHandleBack = () => {
    navigate("/");
  };
  const [expandedReviews, setExpandedReviews] = useState({});

  const toggleShowMore = (id) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  var settingss = {
    dots: false,
    infinite: true, 
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const updateVisibleItems = () => {
      if (window.innerWidth >= 768) {
        // md breakpoint
        setVisibleItems(9);
      } else {
        setVisibleItems(6);
      }
    };

    updateVisibleItems();
    window.addEventListener("resize", updateVisibleItems);
    return () => {
      window.removeEventListener("resize", updateVisibleItems);
    };
  }, []);

  useEffect(() => {
    dispatch(getPropertyBySlug(name));
  }, [dispatch, name]);
  return (
    <div className="mx-auto py-8 font-Mont pl-5 pr-5 pt-20 bg-gray-30">
      {/* <InPageRoute /> */}
      {/* Image Section */}
      {/* {loading ? (
        <div>
          <h1>Loading...</h1>
        </div>
      ) : ( */}
      <MetaData
        title={`${propertyId && propertyId?.title}, ${
          propertyId && propertyId?.city
        }, ${propertyId && propertyId?.state} by Da Alohas`}
        image={propertyId && propertyId?.picture[0]?.url}
      />
      <div>
        <div className="w-full flex hidden lg:block">
          <div className="w-full h-[400px] flex items-center justify-between overflow-hidden	">
            {/* Left side large image */}
            <div className="w-6/12 h-full">
              <img
                className="cursor-pointer w-full h-full rounded-lg hover:bg-slate-300"
                src={propertyId && propertyId?.picture[0]?.url}
                onClick={() => setToggle(true)}
                alt={propertyId?.title}
                loading="lazy"
              />
            </div>

            {/* Right side small images */}
            <div className="flex  flex-wrap relative w-6/12">
              {propertyId &&
                propertyId?.picture.slice(1, 5).map((pic, index) => (
                  <div key={index} className="w-3/6 h-[200px] p-1">
                    <img
                      className="w-full h-full cursor-pointer rounded-lg hover:bg-slate-300"
                      onClick={() => setToggle(true)}
                      src={pic?.url}
                      alt={propertyId?.title}
                      loading="lazy"
                    />
                  </div>
                ))}
              {/* View All images button */}
              <h1
                onClick={() => setToggle(true)}
                className="cursor-pointer rounded-full m-2 pl-3 pr-3 pt-2 pb-2 font-medium text-sm absolute right-3 bottom-3 flex items-center justify-center text-black bg-gray-200"
              >
                View All images
              </h1>
            </div>

            {/* {propertyId?.picture &&
            propertyId?.picture.map((pic) => {
              return (
                <img
                  onClick={() => setToggle(true)}
                  className="h-full cursor-pointer"
                  src={pic.url}
                  alt={propertyId?.title}
                />
              );
            })} */}
          </div>
        </div>
        <div className="block lg:hidden w-full relative">
          <Slider {...setting}>
            {propertyId &&
              propertyId?.picture.map((pic) => {
                return (
                  <div className="h-[250px] w-full relative" key={pic._id}>
                    <img
                      className="cursor-pointer rounded-lg h-full w-full"
                      onClick={() => setToggle(true)}
                      src={pic?.url}
                      alt={propertyId?.title}
                      loading="lazy"
                    />
                  </div>
                );
              })}
          </Slider>
          <div className="absolute top-3 left-3 p-3 shadow w-10 h-10 bg-white rounded-full p-2 flex items-center justify-center">
            <FaArrowLeftLong onClick={onHandleBack} />
          </div>
          <div className="absolute top-3 right-3">
            {/* https://plucky-balm-314606.el.r.appspot.com */}
            <RWebShare
              data={{
                text: "By Da Alohas",
                url: `https://thealohas.com/property/${propertyId?.slug}`,
                title: `I found this intersting property at Da Alohas. Have a look at ${propertyId?.title} .`,
              }}
              // onClick={() => toast.success("shared successfully!")}
            >
              <button className="shadow w-10 h-10 bg-white rounded-full p-2 flex items-center justify-center">
                <MdOutlineShare size={20} />
              </button>
            </RWebShare>
          </div>
        </div>
        <div className=" ">
          {toggle && (
            <div className="w-full h-full  fixed top-0 left-0 right-0 z-30 h-[500px] overflow-y-scroll bg-white">
              <div
                className="h-20  bg-white shadow-sm shadow-indigo-100 pl-5 lg:pl-10 pr-5 lg:pr-10 fixed w-full top-0 left-0 "
                onClick={() => setToggle(false)}
              >
                <div className="cursor-pointer flex  items-center h-full">
                  <span className="bg-gray border p-3 rounded-full hover:bg-indigo-200 hover:text-white font-semibold text-base">
                    <FaArrowLeftLong />
                  </span>
                  <span className="ml-3">Back</span>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center pt-[100px] pb-10 space-y-5 pl-3 pr-3 lg:pr-0 lg:pl-0">
                {propertyId?.picture.map((pic) => {
                  return (
                    <img
                      onClick={() => setToggle(true)}
                      className="lg:h-[550px]"
                      src={pic.url}
                      alt={propertyId?.title}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-between pt-5 md:pt-10 ">
          <div className="w-full lg:w-7/12">
            {/* Title & Price Section */}
            <div className="flex justify-between items-center">
              <div className="w-full">
                <div className="flex items-center justify-between">
                  <h1 className="text-xl md:text-2xl font-bold">
                    {propertyId?.title}
                  </h1>
                  <div className="hidden md:block">
                    <RWebShare
                      data={{
                        text: " By Da Alohas",
                        url: `https://thealohas.com/property/${propertyId?.slug}`,
                        title: `I found this intersting property at Da Alohas. Have a look at ${propertyId?.title} .`,
                      }}
                      // onClick={() => toast.success("shared successfully!")}
                    >
                      <button className="shadow w-10 h-10 rounded-full p-2 flex items-center justify-center">
                        {" "}
                        <MdOutlineShare size={20} />{" "}
                      </button>
                    </RWebShare>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <p className="text-gray-500 flex items-center font-medium text-sm">
                    <PiMapPin className="size-4 text-gray" />
                    <span className="ml-1 font-medium text-base">
                      {propertyId?.Address}
                    </span>
                    <div
                      onClick={scrollToMap}
                      className="pl-5 pr-5 pt-2 pb-2 capitalize text-red-400 cursor-pointer underline"
                    >
                      view Map
                    </div>
                  </p>
                  {videoUrl && (
                    <div className="mt-1">
                      <h1
                        onClick={scrollToVideo}
                        className="pl-3 md:pl-4 pr-3 md:pr-4 pt-1 md:pt-1 pb-1 md:pb-1 border capitalize rounded cursor-pointer text-white bg-red-500 font-meduim text-xs md:text-base"
                      >
                        video
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Rooms Section */}
            <div className=" flex flex-wrap gap-2 md:gap-4  rounded-lg mt-5">
              {/* Beds */}
              <div className="flex items-center space-x-1 sm:space-x-2">
                <CiUser className="size-4 md:size-6 text-indigo-700" />
                <span className="text-gray-700 text-xs sm:text-base font-medium">
                  {propertyId?.max_guests} Guests
                </span>
              </div>

              {/* Washrooms */}
              <div className="flex items-center space-x-1">
                <MdOutlineBathroom className="size-4 md:size-6 text-indigo-700" />
                <span className="text-gray-700 text-xs sm:text-base font-medium">
                  {propertyId?.accomodation_washrooms} Bathroom
                </span>
              </div>

              {/* Bedrooms */}
              <div className="flex items-center space-x-1 md:space-x-2">
                <MdOutlineBedroomParent className="size-4 md:size-6 text-indigo-700" />
                <span className="text-gray-700 text-xs sm:text-base font-medium">
                  {propertyId?.accomodation_bedrooms} Bedrooms
                </span>
              </div>

              {/* Guests */}
              <div className="flex items-center space-x-1 md:space-x-2">
                <FaBed className="size-4 md:size-6 text-indigo-700" />
                <span className="text-gray-700 text-xs sm:text-base font-medium">
                  {propertyId?.accomodation_beds} beds
                </span>
              </div>
            </div>

            {/* summary */}
            <div className="mt-5 border-b pb-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: showMore
                    ? propertyId?.summary
                    : truncateText(propertyId?.summary, 100),
                }}
              />
              {propertyId?.summary?.split(" ").length > 100 && (
                <button
                  onClick={() => setShowMore(!showMore)}
                  className="text-white bg-gray-500 font-medium text-xs mt-2 pl-2 pr-2 pt-1 pb-1 rounded"
                >
                  {showMore ? "Read Less" : "Read More"}
                </button>
              )}
            </div>

            {/* Amenities Section */}
            <div className="mt-5">
              <h3 className="text-xl font-bold mb-4">Amenities</h3>
              <div className="flex flex-col w-full">
                <div className="flex flex-wrap md:flex-row md:flex-wrap">
                  {propertyId &&
                    propertyId.amenities.slice(0, visibleItems).map((ameti) => {
                      const IconComponent = iconMapping[ameti.icons];

                      return (
                        <div className="flex w-1/2 md:w-1/3 p-2" key={ameti._id}>
                          {IconComponent ? (
                            <IconComponent className="text-2xl" /> // Render the icon
                          ) : (
                            <span className="text-gray-500">
                              Icon not found
                            </span> // Fallback
                          )}
                          <h1 className="ml-2 capitalize">{ameti.name}</h1>
                        </div>
                      );
                    })}
                </div>
                {/* <div className="flex  flex-wrap block md:hidden">
                  {propertyId &&
                    propertyId.amenities.slice(0, 6).map((ameti) => {
                      const IconComponent = iconMapping[ameti.icons];

                      return (
                        <div className="flex w-1/2 md:w-1/3  p-2">
                          {IconComponent ? (
                            <IconComponent className="text-2xl" /> // Render the icon
                          ) : (
                            <span className="text-gray-500">
                              Icon not found
                            </span> // Fallback
                          )}
                          <h1 className="ml-2 capitalize">{ameti.name}</h1>
                        </div>
                      );
                    })}
                </div> */}

                <div>
                  <button
                    className="pl-3 pr-3 pt-3 pb-3 border capitalize font-medium text-sm mt-4 shadow rounded"
                    onClick={() => setAmenitiesToggle(true)}
                  >
                    View All amenities
                  </button>
                  {amenitiesToggle ? (
                    <div className="w-full h-full z-20 fixed top-0 left-0 bottom-0 md:bottom-auto flex items-center justify-center bg-[#9f9e9ec1] ">
                      <div className="bg-white overflow-y-hidden	rounded h-full  w-full lg:w-5/12 md:h-5/6 md:pb-20 pt-[80px] md:pt-0">
                        <div className="h-[70px] shadow flex justify-between  items-center pl-5 pr-5">
                          <div
                            className="w-10 h-10 p-3 rounded-full border cursor-pointer hover:bg-[#808080] hover:text-white"
                            onClick={() => setAmenitiesToggle(false)}
                          >
                            <FaArrowLeft />
                          </div>

                          <h1 className="text-center font-semibold text-base">
                            Amenities
                          </h1>
                          <div></div>
                        </div>
                        <div className="h-full overflow-y-scroll">
                          <div className="flex flex-wrap pl-5 pr-5 pt-5 ">
                            {propertyId &&
                              propertyId.amenities.map((ameti) => {
                                const IconComponent = iconMapping[ameti.icons]; // Get the icon component

                                return (
                                  <div className="flex w-3/6 p-3">
                                    {IconComponent ? (
                                      <IconComponent className="text-2xl" /> // Render the icon
                                    ) : (
                                      <span className="text-gray-500">
                                        Icon not found
                                      </span> // Fallback
                                    )}
                                    <h1 className="ml-2 capitalize">
                                      {ameti.name}
                                    </h1>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* all collapse */}
            <div className="border-t border-b mt-10">
              {/* About spaces */}
              <CollapseItem title="About Space" collaoseOpen={true}>
                {propertyId?.about_space}
              </CollapseItem>

              {/* thing to know */}
              <CollapseItem title="Things to Know"  collaoseOpen={false}>
                {propertyId?.thing_to_know}
              </CollapseItem>

              {/* House roles */}
              <CollapseItem title="House Rules"  collaoseOpen={false}>
                {propertyId?.house_roles}
              </CollapseItem>

              {/* Cancellation policy */}
              <CollapseItem title="Cancellation Policy"  collaoseOpen={false}>
                {propertyId?.cancellation_policy}
              </CollapseItem>
            </div>

            {/* space */}
            {propertyId && propertyId.space.length > 0 ? (
              <div className="mt-5">
                <h1 className="font-semibold text-base">Spaces</h1>
                {propertyId && propertyId.space.length > 3 ? (
                  <div className="mt-2 flex slider-container w-full flex items-center justify-center">
                    <Slider {...settings} className="w-full ">
                      {propertyId &&
                        propertyId.space.map((spac) => {
                          return (
                            <div key={spac._id} className="w-2/6 bg-white border border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700">
                              <img
                                className="rounded-t-sm"
                                src={spac.ImageUrl}
                                alt={spac.spaceId.name}
                              />
                              <div className="p-2">
                                <p className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                                  {spac.spaceId.name}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                ) : (
                  <div className="flex space-x-2 mt-3">
                    {propertyId &&
                      propertyId.space.map((spac) => {
                        return (
                          <div key={spac._id} className="w-2/6 bg-white border border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700">
                            <img
                              className="rounded-t-sm"
                              src={spac.ImageUrl}
                              alt={spac.spaceId.name}
                            />
                            <div className="p-3">
                              <p className="mb-2 font-normal text-gray-700 dark:text-gray-400 capitalize">
                                {spac.spaceId.name}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {/* Locations */}
            <div className="mt-5 flex flex-col md:flex-row" ref={mapRef}>
              <div className="w-full md:w-7/12">
                <h1 className="font-semibold text-lg mb-2">Locations</h1>
                <MapView propertyId={propertyId} />
              </div>

              {/* nearest point */}
              <div className="ml-0 md:ml-5 mt-10 md:mt-0">
                <h1 className="font-bold text-lg">Nearest point of interest</h1>
                {propertyId?.nearestPoint &&
                propertyId?.nearestPoint.length > 0 ? (
                  <div className="mt-2">
                    {/* <h1>Nearest point</h1> */}
                    <div
                      className="text-gray-700"
                      dangerouslySetInnerHTML={{
                        __html: decodeHTML(propertyId?.nearestPoint),
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* video link */}
            {videoUrl && (
              <div className="pt-5 md:pt-20" ref={videoRef}>
                <iframe
                  className="w-full  h-[250px] md:h-[300px] rounded"
                  src={`https://www.youtube.com/embed/${videoUrl}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            )}

            <div className="mt-10">
              <h1 className="font-semibold text-lg ">Reviews</h1>
              <Slider {...settingss} className="">
                {ReviewObj &&
                  ReviewObj.map((review) => {
                    const isExpanded = expandedReviews[review.id] || false;
                    return (
                      <div
                        key={review.id}
                        className="p-2 !w-[230px] h-auto rounded-xl border bg-white"
                      >
                        <div className="p-2">
                          <div className="flex items-center">
                            <div className="w-8 h-8 flex items-center justify-center bg-black text-white rounded-full text-sm font-bold">
                              {review.name.charAt(0)}
                            </div>
                            <div className="ml-2">
                              <h3 className="font-bold text-sm">
                                {review.name}
                              </h3>
                              <p className="text-gray-500 text-xs">
                                {review.date}
                              </p>
                            </div>
                          </div>

                          <div className="flex my-2">
                            {[...Array(5)].map((_, i) => (
                              <span
                                key={i}
                                className={`w-4 h-4 inline-block ${
                                  i < review.rating
                                    ? "text-yellow-500"
                                    : "text-gray-300"
                                }`}
                              >
                                ★
                              </span>
                            ))}
                          </div>

                          <p className="text-gray-700 text-xs flex-wrap text-justify">
                            {isExpanded
                              ? review?.comment
                              : truncateText(review?.comment, 20)}
                            {review?.comment?.split(" ").length > 20 && (
                              <button
                                onClick={() => toggleShowMore(review.id)}
                                className="text-white bg-gray-500 font-medium text-xs mt-2 px-2 py-1 rounded"
                              >
                                {isExpanded ? "Read Less" : "Read More"}
                              </button>
                            )}
                          </p>

                          <Link
                            to={review.url}
                            className="mt-3 flex items-center gap-1"
                          >
                            <h1 className="font-medium text-xs">Posted on</h1>
                            <img
                              src={review.logo}
                              alt="Airbnb"
                              className="w-4 h-4"
                            />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
          <div className="hidden lg:block w-4/12 ">
            <div className={`product_img ${isFixed ? "fixed" : ""}`}>
              <Availability propertyId={propertyId} />
            </div>
          </div>
          <div className="block lg:hidden w-full z-30 fixed bottom-0 right-0">
            <AvailabilitySmallScreen propertyId={propertyId} />
          </div>
        </div>
        {/* Reviews Section */}

        {/* <div className="w-full mt-8 additional-content">
          <h3 className="text-xl font-bold mb-4">Ratings</h3>
          <div className="flex space-x-4">
            <div className="flex items-center space-x-2">
              <span>⭐⭐⭐⭐⭐</span>
              <p>5.0 Overall Rating</p>
            </div> */}
        {/* Add more reviews */}
        {/* </div>
        </div> */}
      </div>
      {/* )} */}
    </div>
  );
};

export default PropertyDetails;

import {
    CREATE_OWNER_LEADS_REQUEST,
    CREATE_OWNER_LEADS_SUCCESS,
    CREATE_OWNER_LEADS_FAIL,
    GET_ALL_OWNER_LEADS_REQUEST,
    GET_ALL_OWNER_LEADS_SUCCESS,
    GET_ALL_OWNER_LEADS_FAIL,
} from "../../constant/OwnerLeads/OwnerLeads"
import axios from "axios";



export const createOwnersLead = (formdata) =>async(dispatch)=>{
    try {
        dispatch({
            type: CREATE_OWNER_LEADS_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.post("/api/v1/create-owners-leads",formdata,config)

        dispatch({
            type: CREATE_OWNER_LEADS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_OWNER_LEADS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getAllOwnerLeads = () =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_ALL_OWNER_LEADS_REQUEST
        })

        const {data} = await axios.get("/api/v1/get-owners-leads")
        dispatch({
            type: GET_ALL_OWNER_LEADS_SUCCESS,
            payload: data.allOwnersLeads
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_OWNER_LEADS_FAIL,
            payload: error.response.data.message
        })
    }
}
import React from "react";
import { HappyPartnerObj } from "./HappyPartnerObj";
import Slider from "react-slick";

const HappyPartner = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true
  };
  return (
    <div className="flex  md:h-[300px] flex-col items-center justify-center pt-10 pb-10 bg-gray-100">
      <h1 className="font-bold md:font-semibold text-xl">What do our Home Owners speak about us</h1>
      <div className="w-10/12 pt-5">
        <Slider {...settings}>
          {HappyPartnerObj &&
            HappyPartnerObj.map((partner) => {
              return (
                <div className="w-10/12 flex flex-col items-start text-start space-y-2" key={partner.id}>
                  <p className="font-medium text-base text-center">{partner.summary}</p>
                  <div className="">
                    <h2 className="font-bold md:font-semibold text-lg text-center">{partner.name}</h2>
                    <div className="flex flex-wrap items-center justify-center">
                      <p className="font-medium text-base flex  text-center">{partner.propertyName}</p>, <h1 className="font-bold md:font-semibold text-base">{partner.state}</h1>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default HappyPartner;

// create category
export const CREATE_PACKAGE_CATEGORY_REQUEST = "CREATE_PACKAGE_CATEGORY_REQUEST"
export const CREATE_PACKAGE_CATEGORY_SUCCESS = "CREATE_PACKAGE_CATEGORY_SUCCESS"
export const CREATE_PACKAGE_CATEGORY_FAIL = "CREATE_PACKAGE_CATEGORY_FAIL"


// get all category
export const GET_ALL_PACKAGE_CATEGORY_REQUEST = "GET_ALL_PACKAGE_CATEGORY_REQUEST"
export const GET_ALL_PACKAGE_CATEGORY_SUCCESS = "GET_ALL_PACKAGE_CATEGORY_SUCCESS"
export const GET_ALL_PACKAGE_CATEGORY_FAIL = "GET_ALL_PACKAGE_CATEGORY_FAIL"


// update package category
export const UPDATE_PACKAGE_CATEGORY_REQUEST = "UPDATE_PACKAGE_CATEGORY_REQUEST"
export const UPDATE_PACKAGE_CATEGORY_SUCCESS = "UPDATE_PACKAGE_CATEGORY_SUCCESS"
export const UPDATE_PACKAGE_CATEGORY_FAIL = "UPDATE_PACKAGE_CATEGORY_FAIL"

// update category status
export const UPDATE_PACKAGE_CATEGORY_STATUS_REQUEST = "UPDATE_PACKAGE_CATEGORY_STATUS_REQUEST"
export const UPDATE_PACKAGE_CATEGORY_STATUS_SUCCESS = "UPDATE_PACKAGE_CATEGORY_STATUS_SUCCESS"
export const UPDATE_PACKAGE_CATEGORY_STATUS_FAIL = "UPDATE_PACKAGE_CATEGORY_STATUS_FAIL"


import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DraggableImage from "../Property/Property/Drag_and_Drop";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SideMenu from "../sideMenu/SideMenu";
import { toast } from "react-toastify";
import { createPackages } from "../../../redux/actions/PackagesAction/PackagesAction";
import { getAllPackageAmenities } from "../../../redux/actions/PackagesAction/PackageAmenitiesAction";
import { getAllPackageType } from "../../../redux/actions/PackagesAction/PackageTypeAction";

const Packages = () => {
  const dispatch = useDispatch();
  const [cancellation_policy, setCancellationPolicy] = useState("");
  const [summary, setSummary] = useState("");
  const [highlights, setHighlights] = useState("");
  const navigate = useNavigate();
  const [inclusions, setInclusions] = useState("");
  const [exclusions, setExclusions] = useState("");
  const [operatorDetails, setOperatorDetails] = useState("");
  const [isOpen, setIsOpen] = useState({});
  const [packagesType, setPackageType] = useState("");
  const [itinerarypdf, setItinerarypdf] = useState(null);

  // const [selectedAmenities, setSelectedAmenities] = useState([]);

  // const [placeToVisit,setPlacetovisit] = useState("")

  const [formData, setFormData] = useState({
    title: "",
    internal_name: "",
    origin_city: "",
    country: "",
    pincode: "",
    video_link: "",
    price: "",
    duration: "",
  });

  const [live, setLive] = useState(false);
  const [images, setImages] = useState([]);
  const [itinerary, setItinerary] = useState([
    {
      title: "",
      summary: "",
      amenities: [],
    },
  ]);

  const { success, error } = useSelector((state) => state.packages);
  const { packageAmenities } = useSelector((state) => state.packageAmenities);
  const { allPackgeType } = useSelector((state) => state.packageType);

  // console.log(packageAmenities)

  const addMoreItinerary = () => {
    setItinerary([
      ...itinerary,
      {
        title: "",
        summary: "",
        amenities: [],
      },
    ]);
    
    setIsOpen((prevState) => ({
      ...prevState,
      [itinerary.length]: false, 
    }));
    
  };

  const toggleDropdown = (index) => {
    console.log(index)
      setIsOpen((prevState) => ({
        ...prevState,
        [index]: !prevState[index], 
      }));
    
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (amenityId, index) => {
    setItinerary((prevItinerary) =>
      prevItinerary.map((item, i) => {
        if (i === index) {
          const isAmenitySelected = item.amenities.includes(amenityId);
          return {
            ...item,
            amenities: isAmenitySelected
              ? item.amenities.filter((id) => id !== amenityId) // Remove if already selected
              : [...item.amenities, amenityId], // Add if not selected
          };
        }
        return item;
      })
    );
  };

  const handleChangeItinerary = (e, index) => {
    const data = [...itinerary];
    data[index][e.target.name] = e.target.value;
    setItinerary(data);
  };

  // New handler for ReactQuill
  const handleChangeQuill = (value, index) => {
    const data = [...itinerary];
    data[index].summary = value;
    setItinerary(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
  };

  // image
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const fileObjects = files.map((file) => ({
      url: URL.createObjectURL(file), // Create a preview URL for the uploaded file
      file, // Keep the original file object for uploading
      _id: file.name, // Use file name as a temporary ID
    }));

    // Update the images state
    setImages((prevImages) => [...prevImages, ...fileObjects]);
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages); // Update state with the new order
  };

  //   // upload pdf
  const handleFilePdfChange = (e) => {
    if (e.target.files) {
      setItinerarypdf(e.target.files[0]);
    }
  };

  console.log(itinerarypdf);

  // Submit form data
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    const data = new FormData();
    data.append("title", formData.title);
    data.append("internal_name", formData.internal_name);
    data.append("origin_city", formData.origin_city);
    data.append("country", formData.country);
    data.append("pincode", formData.pincode);
    for (let i = 0; i < images.length; i++) {
      data.append("picture", images[i].file);
    }
    data.append("video_link", formData.video_link);
    data.append("live", live); 
    data.append("starting_from_price", formData.price);
    data.append("summary", summary);
    data.append("mapUrl", formData.mapUrl);
    data.append("cancellation_policy", cancellation_policy);
    data.append("operator_details", operatorDetails);
    data.append("itinerary", JSON.stringify(itinerary));
    data.append("highlights", highlights);

    data.append("inclusions", inclusions);
    data.append("exclusions", exclusions);
    data.append("duration", formData.duration);
    data.append("price", formData.price);
    data.append("itinerary_pdf",itinerarypdf)

    // amenities
    // for (let i = 0; i < selectedAmenities.length; i++) {
    //   data.append("package_amenities", selectedAmenities[i]);
    // }
    // data.append("places_to_visit",placeToVisit)

    dispatch(createPackages(data));
  };

  // console.log(images);

  // useEffect(() => {
  //   return () => {
  //     images && images.forEach((image) => URL.revokeObjectURL(image.url));
  //   };
  // }, [images]);
  console.log(itinerary);

  useEffect(() => {
    if (success) {
      toast.success(success.message);
      setTimeout(() => {
        navigate("/dashboard/package/list");
      }, 3000);
    }
    if (error) {
      toast.error(error);
    }

    dispatch(getAllPackageAmenities());
    dispatch(getAllPackageType());
  }, [dispatch, success, error, navigate]);
  return (
    <div className="flex pt-20 font-Mont">
      <SideMenu className="" />
      <div className="w-full bg-gray-100 p-3 flex items-center justify-center overflow-y-scroll">
        <form
          onSubmit={handleSubmit}
          className="bg-white p-4 rounded-lg shadow-lg w-full"
        >
          {/* <MapComponent /> */}
          <h2 className="text-3xl font-bold mb-8 text-center">Packages Form</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Title */}
            <div className="relative">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Internal Name */}
            <div className="relative">
              <label className="block text-gray-700">Internal Name</label>
              <input
                type="text"
                name="internal_name"
                value={formData.internal_name}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Address */}
            <div className="relative">
              <label className="block text-gray-700">Duration</label>
              <input
                type="text"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            {/* city */}
            <div className="relative">
              <label className="block text-gray-700">City</label>
              <input
                type="text"
                name="origin_city"
                value={formData.origin_city}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Country */}
            <div className="relative">
              <label className="block text-gray-700">Country</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Pincode */}
            <div className="relative">
              <label className="block text-gray-700">Pincode</label>
              <input
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Video Link */}
            <div className="relative">
              <label className="block text-gray-700">Video Link</label>
              <input
                type="url"
                name="video_link"
                value={formData.video_link}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/*starting from price  */}
            <div className="relative">
              <label className="block text-gray-700">Price</label>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex space-x-2 w-full">
              <div className="mb-4 w-full mt-2">
                <label className="block text-gray-700  capitalize">
                  package type
                </label>
                <select
                  name="packagesType"
                  value={packagesType}
                  onChange={(e) => setPackageType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select package type
                  </option>
                  {allPackgeType &&
                    allPackgeType.map((pal) => {
                      // console.log(pal);
                      return (
                        <option key={pal._id} value={pal._id}>
                          {pal.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="relative w-full">
                <label className="block text-gray-700">
                  Upload itinerary pdf
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFilePdfChange}
                  className="input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Picture */}
            <DndProvider backend={HTML5Backend}>
              <div className="image-upload-container w-full">
                <div className="flex flex-wrap justify-start">
                  {images.map((file, index) => (
                    <DraggableImage
                      key={file._id}
                      file={file}
                      index={index}
                      moveImage={moveImage}
                    />
                  ))}
                </div>

                <label className="text-gray-700 mt-4">Upload New Images:</label>
                <input
                  type="file"
                  name="picture"
                  onChange={handleFileChange}
                  className="input-transition mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  multiple
                />
              </div>
            </DndProvider>

            {/* Highlights */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Highlights</label>
              <ReactQuill
                theme="snow"
                value={highlights}
                onChange={setHighlights}
              />
            </div>

            {/*inclusions  */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Inclusions</label>
              <ReactQuill
                theme="snow"
                value={inclusions}
                onChange={setInclusions}
              />
            </div>

            {/* Exclusions */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Exclusions</label>
              <ReactQuill
                theme="snow"
                value={exclusions}
                onChange={setExclusions}
              />
            </div>

            {/* Summary */}
            <div className="relative col-span-2">
              <label className="block text-gray-700">Summary</label>
              <ReactQuill theme="snow" value={summary} onChange={setSummary} />
            </div>
          </div>

          {/* Cancellation Policy*/}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Cancellation Policy</label>
            <ReactQuill
              theme="snow"
              value={cancellation_policy}
              onChange={setCancellationPolicy}
            />
          </div>

          {/* operator_details */}
          <div className="relative col-span-2 mt-3">
            <label className="block text-gray-700">Operator Details</label>
            <ReactQuill
              theme="snow"
              value={operatorDetails}
              onChange={setOperatorDetails}
            />
          </div>

          <div>
            <h1 className="font-bold text-lg text-gray-800">Tour Itinerary</h1>
            {itinerary.map((tour, index) => (
              <div
                key={index}
                className="flex flex-col mt-4 space-y-4 bg-gray-50 p-4 rounded-lg shadow"
              >
                <label className="block text-gray-700 font-medium">
                  Day {index + 1}
                </label>
                <input
                  type="text"
                  name="title"
                  value={tour.title}
                  placeholder="Title"
                  onChange={(e) => handleChangeItinerary(e, index)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                />
                <ReactQuill
                  theme="snow"
                  value={tour.summary}
                  onChange={(value) => handleChangeQuill(value, index)}
                />

                {/* Amenities */}
                <div className="relative w-full">
                  <label className="block text-gray-700">
                    Package Amenities
                  </label>
                  <div className="relative inline-block text-left w-full">
                    <button
                      onClick={()=>toggleDropdown(index)}
                      className="w-full flex items-center justify-between input-transition w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      type="button"
                    >
                      Select Amenities
                      <svg
                        className="w-2.5 h-2.5 ms-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>

                    <div className="w-full">
                      {isOpen[index] && (
                        <div className="w-full z-10 absolute right-0 mt-2 bg-indigo-200 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                          {/* Checkbox List */}
                          <ul className="w-full p-3 space-y-2 text-sm text-gray-700 dark:text-gray-200">
                            {packageAmenities.length > 0 ? (
                              packageAmenities.map((amenity) => {
                                // console.log(amenity);
                                return (
                                  <li key={amenity._id}>
                                    <div className="flex items-center">
                                      <input
                                        id={amenity._id}
                                        type="checkbox"
                                        checked={tour.amenities.includes(
                                          amenity._id
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            amenity._id,
                                            index
                                          )
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                                      />
                                      <label
                                        htmlFor={amenity._id}
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >
                                        {amenity.name}
                                      </label>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <li className="text-gray-500">
                                No package amenities found
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {itinerary.length - 1 === index && (
                  <button
                    type="button"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg self-start hover:bg-blue-600"
                    onClick={addMoreItinerary}
                  >
                    Add More
                  </button>
                )}
              </div>
            ))}
          </div>

          <div className="flex w-full mt-5">
            {/*going to live yes / NO */}
            <div className="mb-4 w-1/2">
              <label className="block text-gray-700 mb-2">
                Live (Yes / No)
              </label>
              <select
                name="live"
                value={live}
                onChange={(e) => setLive(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>
                  Select (Yes/No)
                </option>

                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </div>

          {/* More fields */}
          {/* Continue adding the rest fields as demonstrated above */}

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Packages;

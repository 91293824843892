import React from 'react'

const PageNotFound = () => {
  return (
    <div className="font-Mont flex flex-col items-center justify-center min-h-screen bg-gray-200 text-center pt-20">
    <h1 className="text-6xl font-bold text-gray-800">404</h1>
    <p className="text-xl text-gray-600 mt-4">Oops! The page you're looking for doesn't exist.</p>
    <a href="/" className="mt-6 px-6 py-3 text-black rounded-lg shadow-md  transition">
      Go Home
    </a>
  </div>
  )
}

export default PageNotFound
import React, { useEffect, useState } from "react";
import {
  getAllPackageCategory,
  updatePackageCategoryStatus,
} from "../../../../redux/actions/PackagesAction/PackageCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SideMenu from "../../sideMenu/SideMenu";
import Loader from "../../../Loader";
import { Link } from "react-router-dom";

const PackageListCategory = () => {
  const [selectedItems, setSelectedItems] = useState({});
  const { packageCategory, categoryUpdate, loading, error } = useSelector(
    (state) => state.packageCategory
  );
  const dispatch = useDispatch();

  console.log(packageCategory);

  const handleCheckboxChange = (id, currentStatus) => {
    setSelectedItems((prev) => ({
      ...prev,
      [id]: !prev[id] ?? !currentStatus,
    }));
  };

  const handleUpdateStatus = () => {
    const categoryUpdate = {
      categoryStatus: Object.entries(selectedItems).map(([id, isActive]) => ({
        id,
        isActive,
      })),
    };

    console.log("Dispatching:", categoryUpdate); // Debugging Log

    dispatch(updatePackageCategoryStatus(categoryUpdate));
  };

  useEffect(() => {
    if (categoryUpdate) {
      toast.success("status updated successfull");
    }

    if (error) {
      toast.error(error);
    }
    dispatch(getAllPackageCategory());
  }, [dispatch, categoryUpdate, error]);

  return (
    <div className="pt-20 flex font-Mont">
      <SideMenu />
      <div className="p-4 font-Mont w-full">
        <h2 className="text-lg font-semibold mb-4">
          List of Package Category ({packageCategory && packageCategory.length}{" "}
          )
        </h2>
        <div className="space-y-4  overflow-y-scroll">
          {loading ? (
            <Loader />
          ) : (
            packageCategory &&
            packageCategory.map((item) => (
              <label
                key={item._id}
                className="flex items-center space-x-4 p-2 border rounded-lg shadow-sm hover:shadow-md cursor-pointer"
              >
                <input
                  type="checkbox"
                  className="h-6 w-6 text-blue-600 rounded focus:ring focus:ring-blue-300"
                  checked={selectedItems[item._id] ?? item.isActive} // Use updated status or fallback to item's current status
                  onChange={() => handleCheckboxChange(item._id, item.isActive)}
                />
                {/* <img src={item.banner.url} alt={item.label} className="h-10" /> */}
                <span className="text-gray-700 font-medium text-sm w-9/12">
                  {item.name}
                </span>
                <Link
                  to={`/dashboard/list-of-all-theme/${item._id}`}
                  className="flex items-center justify-center text-white h-10 pl-10 pr-10 bg-green-400 rounded"
                >
                  Edit
                </Link>
              </label>
            ))
          )}
        </div>
        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={handleUpdateStatus}
        >
          Update Status
        </button>
      </div>
    </div>
  );
};

export default PackageListCategory;

import axios from "axios"
import {CREATE_AMENITIES_TYPE_REQUEST,CREATE_AMENITIES_TYPE_SUCCESS,CREATE_AMENITIES_TYPE_FAIL, GET_ALL_AMENITIES_TYPE_FAIL, GET_ALL_AMENITIES_TYPE_REQUEST, GET_ALL_AMENITIES_TYPE_SUCCESS, GET_AMENITIES_BY_ID_FAIL, GET_AMENITIES_BY_ID_REQUEST, GET_AMENITIES_BY_ID_SUCCESS, UPDATE_AMENITIES_TYPE_FAIL, UPDATE_AMENITIES_TYPE_REQUEST, UPDATE_AMENITIES_TYPE_SUCCESS, DELETE_AMENITIES_TYPE_FAIL, DELETE_AMENITIES_TYPE_REQUEST, DELETE_AMENITIES_TYPE_SUCCESS} from "../../constant/propertyConstant/proeprtyAmenitiesType"

export const createAmenitiesType = (amenitiesType) => async(dispatch) =>{
    try {
        dispatch({
            type: CREATE_AMENITIES_TYPE_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.post("/api/v1/create-amenities-type",amenitiesType,config)
        dispatch({
            type: CREATE_AMENITIES_TYPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_AMENITIES_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}


// get all amenities type 
export const getAllAmenities = () => async(dispatch)=>{
    try {
        dispatch({
            type: GET_ALL_AMENITIES_TYPE_REQUEST
        })

        

        const {data} = await axios.get("/api/v1/get-all-amenities")
        dispatch({
            type: GET_ALL_AMENITIES_TYPE_SUCCESS,
            payload: data.amenitiesTypes
        })
    } catch (error) {
        dispatch({
            type: GET_ALL_AMENITIES_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}

// get amenities type by id

export const  getAmenititesTypeById = (id) =>async(dispatch)=>{
    try {
        dispatch({
            type: GET_AMENITIES_BY_ID_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.get(`/api/v1/get-amenities/${id}`,config)

        dispatch({
            type: GET_AMENITIES_BY_ID_SUCCESS,
            payload: data.amenitiesTypeId
        })
    } catch (error) {
        dispatch({
            type: GET_AMENITIES_BY_ID_FAIL,
            payload: error.response.data.message
        })
    }
}


// update amenities type
export const updateAmenitiesType = (id,UpdateAmenities) =>async(dispatch)=>{
    try {
        dispatch({
            type: UPDATE_AMENITIES_TYPE_REQUEST
        })

        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };


        const {data} = await axios.put(`/api/v1/update-amenities-type/${id}`,UpdateAmenities,config);
        dispatch({
            type: UPDATE_AMENITIES_TYPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_AMENITIES_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}

// delete amenities type
export const deleteAmenitiesType = (id) =>async(dispatch)=>{
    try {
        dispatch({
            type: DELETE_AMENITIES_TYPE_REQUEST
        })


        const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

        const {data} = await axios.delete(`/api/v1/delete/${id}`,config)

        dispatch({
            type: DELETE_AMENITIES_TYPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_AMENITIES_TYPE_FAIL,
            payload: error.response.data.message
        })
    }
}
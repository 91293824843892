import React from "react";
import MetaData from "../MetaData";
import { FaLinkedin } from "react-icons/fa6";
import { LeadershipObj } from "./TeamLeadershipObj";
import Neha from "../image/Neha.jpeg";
import Sumit from "../image/sumit.png"

const OurTeam = () => {
  return (
    <div className="font-Mont">
      <MetaData title={`Da Alohas Core Team`} />

      <div className="h-[400px]">
        <div
          className="h-[400px] flex items-center justify-center font-bold text-4xl text-white"
          style={{
            backgroundImage:
              "url(https://d31za8na64dkj7.cloudfront.net/banner_Team_Page_969f773944.webp)",
            backgroundSize: "cover",
            backgroundPosition: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          Our Team
        </div>
      </div>
      <div className="mt-20">
        <div className="flex flex-col md:flex-row items-center justify-center space-x-5 p-4">
          <div className="text-center flex flex-col items-center justify-center md:w-2/6">
            <div className="relative w-[100px] md:w-[250px] h-[100px] md:h-[250px] rounded-full group overflow-hidden shadow-xl">
              <img
                className="w-full h-full rounded-full object-cover"
                src={Sumit}
                alt="sumit"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <a
                  href="https://www.linkedin.com/in/sumitguptaprofile/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="text-white text-4xl cursor-pointer" />
                </a>
              </div>
            </div>
            <h1 className="mt-2 font-semibold">Sumit Gupta</h1>
            <p className="text-gray-600">CEO / Co-Founder</p>
            <p className="text-justify text-sm h-[200px]">
              Sumit is responsible for creating scalable systems and increasing
              the company’s brand awareness and acquisition across cities. He
              graduated with a Bachelor of Commerce from Delhi University and a
              Master in Computer Applications from Dept. of Electronics, Delhi.
              Sumit led large business units in the technology industry for two
              decades before founding Da Alohas.
            </p>
          </div>

          <div className="text-center flex flex-col items-center justify-center md:w-2/6">
            <div className="relative w-[100px] md:w-[250px] h-[100px] md:h-[250px] rounded-full group overflow-hidden shadow-xl">
              <img
                className="w-full h-full rounded-full object-cover"
                src={Neha}
                alt="neha mittal"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <a
                  href="https://www.linkedin.com/in/ankush-kumar-275129176/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="text-white text-4xl cursor-pointer" />
                </a>
              </div>
            </div>
            <h2 className="mt-2 font-semibold">Neha Mittal</h2>
            <p className="text-gray-600">Product Head / Co-Founder</p>
            <p className="text-justify text-sm h-[200px]">
              Neha has extensive experience in building technology teams with a
              strong interest in the hospitality business. She is certified yoga
              practitioner and among the early women entrepreneurs in the
              homestay sector. She holds a Masters in Computer Applications
              degree from Gurukul Kangri University, Uttarakhand. Neha is
              leading the product development and product management at Da
              Alohas.
            </p>
          </div>
        </div>

        <div className="md:mt-5">
          <div className="flex items-center justify-center">
            <div className="w-20 h-[2px] bg-red-400"></div>
            <h2 className="font-bold text-xl md:text-4xl m-2">
              Leadership Team
            </h2>
            <div className="w-20 h-[2px] bg-red-400"></div>
          </div>
        </div>

        <div className="flex items-center justify-center mb-20 mt-10 pl-10 pr-10">
          <div className="flex flex-wrap items-center justify-center ">
            {LeadershipObj &&
              LeadershipObj.map((item) => {
                return (
                  <div
                    key={item.name}
                    className="w-[350px] text-center flex flex-col items-center justify-center m-1 p-3"
                  >
                    <div className="relative w-[100px] md:w-[200px] h-[100px] md:h-[200px] rounded-full group overflow-hidden object-cover">
                      <img
                        className="w-full h-full rounded-full object-cover"
                        src={item.url}
                        alt={item.name}
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <a
                          href={item.Linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaLinkedin className="text-white text-4xl cursor-pointer" />
                        </a>
                      </div>
                    </div>
                    <h2 className="mt-2 font-semibold">{item.name}</h2>
                    <p className="text-gray-600">{item.profession}</p>
                    <p className="text-gray-600 text-xs sm:text-sm text-justify sm:h-[350px]">
                      {item.summary}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;

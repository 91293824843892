import React from "react";

const Stapers = ({ activeStep}) => {
  const data = [
    {
      step: 1,
      name: "Select Property",
    },
    {
      step: 2,
      name: "Review Booking",
    },
    {
      step: 3,
      name: "Payment",
    },
  ];

  return (
    <div className="flex items-center justify-center space-x-4 my-4 w-5/6 lg:w-3/6">
      {data.map((item, index) => (
        <React.Fragment key={item.step}>
          {/* Step Item */}
          <div className="flex flex-col items-center">
            <div
              className={`flex items-center justify-center w-8 h-8 rounded-full font-bold ${
                activeStep === item.step
                  ? "bg-gray-600 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              {item.step}
            </div>
            <p
              className={`font-medium text-xs md:text-sm ${
                activeStep === item.step ? "text-black" : "text-black"
              }`}
            >
              {item.name}
            </p>
          </div>

          {/* Line between steps (not after the last step) */}
          {index < data.length - 1 && (
            <div className="flex-1 h-[2px] bg-blue-300"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stapers;

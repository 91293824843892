import axios from "axios";
import {
  GET_ALL_PROPERTY_CATEGORY_REQUEST,
  GET_ALL_PROPERTY_CATEGORY_SUCCESS,
  GET_ALL_PROPERTY_CATEGORY_FAIL,
  CREATE_PROPERTY_CATEGORY_FAIL,
  CREATE_PROPERTY_CATEGORY_REQUEST,
  CREATE_PROPERTY_CATEGORY_SUCCESS,
} from "../../constant/propertyConstant/propertyCategory";

// create property category
export const createCategory = (category) => async (dispacth) => {
  try {
    dispacth({
      type: CREATE_PROPERTY_CATEGORY_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      "/api/v1/property-category",
      category,
      config
    );

    dispacth({
      type: CREATE_PROPERTY_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispacth({
      type: CREATE_PROPERTY_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllCategory = () => async (dispacth) => {
  try {
    dispacth({
      type: GET_ALL_PROPERTY_CATEGORY_REQUEST,
    });

    const { data } = await axios.get("/api/v1/property-category-list");

    dispacth({
      type: GET_ALL_PROPERTY_CATEGORY_SUCCESS,
      payload: data.category,
    });
  } catch (error) {
    dispacth({
      type: GET_ALL_PROPERTY_CATEGORY_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { addDays } from "date-fns";
import { getRentlPrice } from "../../redux/actions/propertyActions/PropertyAction";
import { createOrder } from "../../redux/actions/createOrderActions/createOrderAction";
import AgeSelecter from "./AgeSelecter";
import { DateRange } from "react-date-range";
import { getCouponByID } from "../../redux/actions/CouponAction/CouponCode";
// import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";

// import whatsappIcon from "../image/whatsappicon.png";

const AvailabilitySmallScreen = ({ propertyId }) => {
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [children05, setChildren05] = useState(0);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0); // Store calculated discount
  const [couponError, setCouponError] = useState(" ");
  const [clearDate, setClearDate] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [gstAmount, setGstAmount] = useState(0);
  const dropdownRef = useRef(null); // Ref for dropdown container
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservationCreated, setReservationCreated] = useState(false);
  const { rentlId } = useSelector((state) => state.propertyList);
  const disabledDates =
    rentlId && rentlId?.disabledDate.map((date) => new Date(date.date));
  const { couponId,loading, error } = useSelector((state) => state.coupon);

  // console.log(disabledDates)

  const toggleModal = () => {
    setCheckIn({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setClearDate(true);
    setIsModalOpen(!isModalOpen);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const getDayAndMonth = (date) => {
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    return { day, month };
  };

  const formatDateRange = (startDate, endDate) => {
    const start = getDayAndMonth(new Date(startDate));
    const end = getDayAndMonth(new Date(endDate));

    if (start.month === end.month) {
      return `${start.day}-${end.day} ${start.month}`;
    } else {
      return `${start.day} ${start.month} - ${end.day} ${end.month}`;
    }
  };

  const [checkIn, setCheckIn] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });

  // const createWhatsAppLink = (phone, message) => {
  //   const encodedMessage = encodeURIComponent(message);
  //   return `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
  // };

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };
  const onHandleBooking = async () => {
    const checkInDate = formatDate(checkIn.startDate);
    const checkoutDate = formatDate(checkIn.endDate);

    // Prepare booking data
    const bookingData = {
      amount: totalAmount,
      user,
      propertyId,
      dateFrom: checkInDate,
      dateTo: checkoutDate,
      adults: adultCount,
      children612: childCount,
      children05,
      perNightPrice,
      number_of_nights: rentlId?.rentlPrice?.length || 0,
      base_amount: totalRentPrice,
      taxes: gstAmount,
      total_amount: totalAmount,
    };

    // Log the booking data for debugging
    // console.log("Booking data being sent to API:", bookingData);

    if (!reservationCreated) {
      setReservationCreated(true);
      dispatch(createOrder(bookingData));
    }

    if (!isAuthenticated) {
      navigate(`/login?redirect=/booking/${propertyId?._id}`);
    } else {
      navigate(`/booking/${propertyId?._id}`);
    }
  };

  let TotalAdults = adultCount + childCount;

  // const onHandleSubmit = () => {
  // e.preventDefault();
  // console.log(typeof TotalAdults)

  // };
  // let id = propertyId && propertyId._id
  const toggleDatePicker = () => setIsDatePickerOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedDates = sessionStorage.getItem("checkInDates");
    // console.log(savedDates);
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates).map((range) => ({
        ...range,
        startDate: new Date(range.startDate),
        endDate: new Date(range.endDate),
      }));
      setCheckIn(parsedDates[0]);
    }
  }, [setCheckIn]);

  useEffect(() => {
    sessionStorage.setItem("checkInDates", JSON.stringify([checkIn]));
  }, [checkIn]);

  const handleSelect = (ranges) => {
    setCheckIn(ranges.selection);
    setClearDate(false);
  };

  const totalRentPrice =
    rentlId && rentlId?.rentlPrice.reduce((sum, item) => sum + item.price, 0);
  // Calculate per-night price by dividing by the number of nights (assume nights = rentlPrice.length)
  const calculateMaxDate = () => {
    const startDate = checkIn?.startDate || new Date();
    const maxDays = propertyId?.availability_in_advance || 90; // Default to 90 days
    const maxDate = new Date(startDate);
    maxDate.setDate(maxDate.getDate() + maxDays);
    return maxDate;
  };

  const clearDates = () => {
    setCheckIn({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setClearDate(true);
  };

  const calculateDiscount = useCallback(() => {
    if (!couponId || !couponId?.isActive) return 0; // Return 0 if no valid coupon
    const percentOff = parseFloat(couponId?.PercentOff || 0);
    const amountOff = parseFloat(couponId?.AmountOff || 0);

    if (percentOff > 0) {
      return (totalRentPrice * percentOff) / 100;
    } else if (amountOff > 0) {
      return amountOff;
    }
    return 0;
  }, [couponId, totalRentPrice]);

  const calculateGST = useCallback(() => {
    const taxableAmount = totalRentPrice - discount;
    return taxableAmount < 7500
      ? (taxableAmount * 12) / 100
      : (taxableAmount * 18) / 100;
  }, [totalRentPrice, discount]);

  useEffect(() => {
    const newDiscount = calculateDiscount();
    setDiscount(newDiscount);
  }, [totalRentPrice, couponId, calculateDiscount]);

  const onHandlePromo = () => {
    // setCouponError("");
    setDiscount(0);
    // sessionStorage.removeItem("discount");

    const validInfo = {
      propertyId: propertyId && propertyId._id,
      mobile: Number(user && user.mobile.slice(3)),
    };

    try {
      dispatch(getCouponByID(coupon, validInfo)); 

      if (error) {
        setCouponError(prev => prev || error); 
        setDiscount(0);
        return;
      }

      // If coupon is valid, calculate the discount
      // if (couponId && couponId.isActive) {
      const percentOff = parseFloat(couponId?.PercentOff || 0);
      const amountOff = parseFloat(couponId?.AmountOff || 0);
      let calculatedDiscount = 0;

      if (percentOff > 0) {
        calculatedDiscount = (totalRentPrice * percentOff) / 100;
      } else if (amountOff > 0) {
        calculatedDiscount = amountOff;
      }

      setDiscount(calculatedDiscount); 
      setCouponError("");
      setToggle(false); 
     
      
    } catch (err) {
      setCouponError("Failed to apply the coupon. Please try again.");
    }
  };

  // useEffect(() => {
  //   sessionStorage.setItem("discount", discount);
  // }, [discount]);

  // useEffect(() => {
  //   const storedDiscount = sessionStorage.getItem("discount");
  //   if (storedDiscount) {
  //     setDiscount(Number(storedDiscount));
  //   }
  // }, []);

  let perNightPrice =
    (totalRentPrice - discount) / (rentlId?.rentlPrice.length || 1);

  useEffect(() => {
    setGstAmount(calculateGST());

    // if (error) {
    //      // alert(error)
    //      dispatch(clearErrors);
    //    }
  }, [dispatch,discount, totalRentPrice, calculateDiscount, calculateGST]);

  let totalAmount = Number(totalRentPrice - discount) + Number(gstAmount);

  useEffect(() => {
    if (propertyId && checkIn.startDate && checkIn.endDate && TotalAdults) {
      dispatch(
        getRentlPrice({
          dateFrom: formatDate(checkIn.startDate),
          dateTo: formatDate(checkIn.endDate),
          id: propertyId._id,
          adults: TotalAdults,
          clearDate,
        })
      );
    }
  }, [
    dispatch,
    adultCount,
    childCount,
    checkIn,
    clearDate,
    TotalAdults,
    propertyId,
  ]);

  return (
    <div className="block lg:hidden w-full z-30 fixed bottom-0 right-0 font-Mont">
      <div
        className={
          rentlId && rentlId.rentlPrice.length === 0
            ? "h-auto w-full bg-white flex border shadow-xl rounded pl-4 pr-4 pt-2 pb-2"
            : "border w-full bg-white flex shadow-xl rounded p-2"
        }
      >
        {/* Rental Price Section */}
        <div className="w-full">
          <h1 className="font-medium text-sm">
            {rentlId && rentlId.rentlPrice?.length > 0 ? (
              <div>
                <div>
                  ₹
                  {Number(
                    (
                      rentlId.rentlPrice.reduce(
                        (price, val) => price + Number(val.price), // Ensure val.price is a number
                        0
                      ) / rentlId.rentlPrice.length
                    ).toFixed(0) // Round to the nearest whole number
                  ).toLocaleString()}
                  /night
                </div>
                <div>
                  <h1>{formatDateRange(checkIn.startDate, checkIn.endDate)}</h1>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="font-medium text-base text-gray-500">
                  Starting From
                </h1>
                <span className="font-bold">{`₹ ${propertyId?.starting_from_price} /night`}</span>
              </div>
            )}
          </h1>
          {/* Modal Trigger */}
          <h1
            className="text-red-500 cursor-pointer underline font-bold"
            onClick={toggleModal}
          >
            Select Date
          </h1>
          {/* <div className="flex">
              <h1>{checkIn?.startDate.toLocaleDateString()}</h1>
              <span className="ml-2 mr-2">-</span>
              <h1>{checkIn?.endDate.toLocaleDateString()}</h1>
          </div> */}

          {/* Modal for Selecting Dates */}
          <div
            className={`fixed inset-0 bg-black shadow-2xl shadow-black rounded-t-2xl bg-opacity-50 mt-[60px] flex justify-center items-end transition-opacity duration-300 ${
              isModalOpen ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <div
              className={`bg-white pl-4 pr-4 pt-6 rounded-t-2xl shadow-2xl shadow-black  h-full w-full md:w-3/4 transform ${
                isModalOpen ? "translate-y-0" : "translate-y-full"
              } transition-transform duration-300`}
            >
              <div className="flex items-center justify-between">
                <h1 className="font-bold text-base">
                  Select Dates and Number of Guests
                </h1>
                <IoCloseSharp size={25} onClick={() => setIsModalOpen(false)} />
              </div>
              <p className="font-medium text-sm mt-3">
                {propertyId && propertyId.title}
              </p>
              <p className="font-medium text-sm capitalize text-gray-700">
                {propertyId && propertyId.city},{propertyId && propertyId.state}
              </p>
              {/* Check-in and Guests Section */}
              <div className="mt-3  rounded-lg w-full">
                {/* Date Pickers */}
                {/* <button
                  className="flex items-end justify-end  bg-white text-red-500 underline"
                  onClick={clearDates}
                >
                  Clear Date
                </button> */}
                <div className="relative" ref={dropdownRef}>
                  <div
                    className="cursor-pointer w-full border border-gray-300 rounded-md"
                    onClick={toggleDatePicker}
                  >
                    <div className="flex w-full h-12 justify-around pt-1 pb-1">
                      <div>
                        <h1 className="font-medium text-xs">Check in</h1>
                        <p className="font-medium text-sm">
                          {checkIn?.startDate
                            ? checkIn?.startDate?.toDateString().slice(0, 10)
                            : "Select Date"}
                        </p>
                      </div>
                      <div className="border-l"></div>
                      <div>
                        <h1 className="font-medium text-xs">Check out</h1>
                        <p className="font-medium text-sm">
                          {checkIn?.endDate
                            ? checkIn?.endDate?.toDateString().slice(0, 10)
                            : "Select Date"}
                        </p>
                      </div>
                    </div>
                  </div>

                  {isDatePickerOpen && (
                    <div
                      className={`absolute  right-0 z-10 rounded rounded-lg shadow-lg`}
                    >
                      <div className="flex-1 w-full">
                        <DateRange
                          onChange={handleSelect}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={[
                            {
                              startDate: checkIn?.startDate,
                              endDate: checkIn?.endDate,
                              key: "selection",
                            },
                          ]}
                          direction="vertical"
                          scroll={{ enabled: true }}
                          minDate={new Date()}
                          maxDate={calculateMaxDate()}
                          disabledDates={disabledDates.map(
                            (date) => new Date(date)
                          )}
                        />
                      </div>
                      <div className="w-full h-10 bg-white flex items-center justify-between pl-2 pr-2">
                        <button
                          className="flex items-end justify-end  bg-white text-red-500 underline"
                          onClick={clearDates}
                        >
                          Clear Date
                        </button>
                        <button
                          className="pt-1 pb-1 flex items-center justify-center  bg-red-400 text-white w-[100px] rounded"
                          onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* guest */}
                <AgeSelecter
                  propertyId={propertyId}
                  setAdultCount={setAdultCount}
                  adultCount={adultCount}
                  setChildCount={setChildCount}
                  childCount={childCount}
                  children05={children05}
                  setChildren05={setChildren05}
                />
              </div>
              {rentlId?.rentlPrice.length > 0 ? (
                <div className="mt-5 space-y-2">
                  <div className="flex justify-between pl-2 md:pl-5 pr-2 md:pr-5">
                    {/* Display per-night price and the number of nights */}
                    <span>{`${Number(
                      perNightPrice.toFixed()
                    )?.toLocaleString()} X ${
                      rentlId && rentlId?.rentlPrice.length
                    } Night(s)`}</span>
                    {/* Display the total amount */}
                    <span className="font-medium">
                      ₹{Number((totalRentPrice - discount).toFixed()).toLocaleString()}
                    </span>
                  </div>

                  {
                    loading ? <h1>loading...</h1> :   <div className="flex items-center justify-center text-green-600">
                    <div className="flex justify-between w-full pl-2 md:pl-5 pr-2 md:pr-5">
                    {couponId && couponId?.code !== null ?  <spna>Coupon Discount ({`${couponId && couponId?.code}`})</spna> : "Coupon Discount"}
                    <span className="font-medium">
                        {discount > 0 ? (
                          <div className="flex justify-between">
                            <span className="font-medium flex justify-between">
                              - ₹{Number(discount.toFixed()).toLocaleString()}
                              <IoCloseSharp
                                className="cursor-pointer"
                                color="red"
                                size={20}
                                onClick={() => {
                                  setDiscount(0);
                                  setCoupon("");
                                  setCouponError("");
                                  // dispatch({type:CLEAR_COUPON_CODE_SUCCESS})
                              
                                  // sessionStorage.removeItem("discount");
                                }}
                              />
                            </span>
                          </div>
                        ) : (
                          <button onClick={() => setToggle(!toggle)}>
                            Apply Coupon
                          </button>
                        )}
                      </span>
                    </div>

                    {toggle && (
                      <div className="w-full h-[250px] bg-white rounded-xl absolute top-0 pl-10 pr-10 flex flex-col items-center justify-center">
                        <div className="flex items-center justify-between w-full">
                          <h1 className="text-black font-semibold text-base">
                            Enter a coupon code
                          </h1>
                          <IoCloseSharp
                            color="black"
                            size={25}
                            onClick={() => setToggle(false)}
                          />
                        </div>

                        <input
                          className="w-full rounded mt-5"
                          type="text"
                          onChange={(e) => setCoupon(e.target.value)}
                          placeholder="Enter coupon code"
                        />
                        {couponError && (
                          <p className="text-red-400 ">{couponError}</p>
                        )}
                        <div className="flex space-x-2 mt-5 w-full">
                          {/* <button
                            className="pl-4 pr-4 pt-1 pb-1 border"
                            onClick={() => setToggle(false)}
                          >
                            Cancel
                          </button> */}
                          <button
                            className="w-full pt-1 pb-1 border bg-red-400 text-white"
                            onClick={onHandlePromo}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  }
                
                  <div className="flex justify-between pl-2 md:pl-5 pr-2 md:pr-5">
                    {/* Display per-night price and the number of nights */}
                    <span>Taxes</span>
                    {/* Display the total amount */}
                    <span className="font-medium">
                      ₹{Number(gstAmount?.toFixed()).toLocaleString()}
                    </span>
                  </div>
                  <div className="flex justify-between border-t border-t-2 pl-2 md:pl-5 pr-2 md:pr-5 pt-2">
                    {/* Display per-night price and the number of nights */}
                    <span className="font-semibold text-sm">Total Amount</span>
                    {/* Display the total amount */}
                    <span className="font-semibold text-base">
                      ₹{Number(totalAmount.toFixed()).toLocaleString()}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="fixed bottom-0 right-0 left-0 w-full flex items-center justify-center shadow-2xl shadow-black h-20 p-5">
                {/* <button className="font-medium text-lg capitalize">
                  clear all
                </button> */}
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                    onHandleBooking();
                  }}
                  className={`${
                    (rentlId && rentlId.rentlPrice.length === 0) ||
                    (propertyId && propertyId.instant_booking === "no")
                      ? "bg-gray-400"
                      : "bg-red-500"
                  } w-full text-white p-2 rounded-full font-medium flex items-center justify-center text-lg capitalize text-right text-white w-[200px] pl-10 pr-10 pt-1 pb-1 rounded-xl`}
                  disabled={
                    (rentlId && rentlId.rentlPrice.length === 0) ||
                    (propertyId && propertyId.instant_booking === "no")
                  }
                >
                  Review Booking
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
        <button className="absolute bottom-20 right-3 mt-4  text-white p-3 rounded-full hover:bg-red-600 uppercase">
        <a
          href={createWhatsAppLink(
            9870141315,
            ` ${propertyId && propertyId?.title}, checkIn - ${checkIn.startDate
              .toDateString()
              ?.slice(0, 10)} , CheckOut - ${checkIn.endDate
              .toDateString()
              ?.slice(0, 10)},  Number of night - ${
              rentlId && rentlId?.rentlPrice.length
            },  Adults - ${adultCount}, Children - ${childCount}, infants - ${children05}, Total Amount - ${totalAmount} `
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
        <img className="w-10 md:w-auto" src={whatsappIcon} alt="whatsappicon" />
        </a>
      </button>
        </div> */}

        {/* Booking Button */}
        <div className="rounded-lg w-full">
          <button
            onClick={onHandleBooking}
            className={`${
              (rentlId && rentlId.rentlPrice.length === 0) ||
              (propertyId && propertyId.instant_booking === "no")
                ? "bg-gray-400"
                : "bg-red-500"
            } w-full text-white p-2 rounded-full`}
            disabled={
              (rentlId && rentlId.rentlPrice.length === 0) ||
              (propertyId && propertyId.instant_booking === "no")
            }
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvailabilitySmallScreen;

import axios from "axios";
import {
  GET_ALL_PROPERTY_COLLECTION_REQUEST,
  GET_ALL_PROPERTY_COLLECTION_SUCCESS,
  GET_ALL_PROPERTY_COLLECTION_FAIL,
  CREATE_PROPERTY_COLLECTION_REQUEST,
  CREATE_PROPERTY_COLLECTION_FAIL,
  CREATE_PROPERTY_COLLECTION_SUCCESS,
} from "../../constant/propertyConstant/propertyCollection";

export const createCollection = (collection) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PROPERTY_COLLECTION_REQUEST,
    });

    const { data } = await axios.post("/api/v1/create-collection", collection);
    dispatch({
      type: CREATE_PROPERTY_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_PROPERTY_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getPropertyCollection = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_PROPERTY_COLLECTION_REQUEST,
    });

    const { data } = await axios.get("/api/v1/list-collection");
    // console.log(data);
    dispatch({
      type: GET_ALL_PROPERTY_COLLECTION_SUCCESS,
      payload: data.collection,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PROPERTY_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React, { useState } from "react";
import SideMenu from "../../../sideMenu/SideMenu";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAmenitiesType,
  getAllAmenities,
  getAmenititesTypeById,
  updateAmenitiesType,
} from "../../../../../redux/actions/propertyActions/propertyAmenitiesType";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import Loader from "../../../../Loader";
import { MdDeleteForever } from "react-icons/md";

const ListOfAmenitiesType = () => {
  const [toggle, setToggle] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteAmenitie, setDeleteAmenities] = useState(null);
  const [getId, setGetId] = useState("");
  const [amenitiesType, setAmenitiesType] = useState("");
  const { getAllAmenitiesType, amenitiesTypeId, isUpdate, loading } =
    useSelector((state) => state.amenitiesType);
  console.log(amenitiesTypeId);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(deleteAmenitiesType(id));
  };

  const onHandleEdit = (id) => {
    setToggle(!toggle);
    dispatch(getAmenititesTypeById(id));
    setGetId(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateAmenitiesType(getId, { name: amenitiesType }));
  };

  useEffect(() => {
    if (amenitiesTypeId) {
      setAmenitiesType(amenitiesTypeId && amenitiesTypeId?.name);
    }
  }, [amenitiesTypeId]);

  useEffect(() => {
    if (isUpdate) {
      toast.success("updated successfull");
      setToggle(false);
    }
    dispatch(getAllAmenities());
  }, [dispatch, isUpdate]);
  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full p-4 bg-gray-100 shadow-md rounded-lg relative">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <h2 className="text-2xl font-bold mb-4">Amenities Types list</h2>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  {/* <th className="border border-gray-300 px-4 py-2 text-left">ID</th> */}
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2">Edit</th>
                  <th className="border border-gray-300 px-4 py-2">Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllAmenitiesType &&
                  getAllAmenitiesType.map((item) => (
                    <tr key={item._id} className="hover:bg-gray-100 relative">
                      {/* <td className="border border-gray-300 px-4 py-2">
                      {item._id}
                    </td> */}
                      <td className="border border-gray-300 px-4 py-2 capitalize">
                        {item.name}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center space-x-2">
                        <button
                          onClick={() => onHandleEdit(item._id)}
                          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                        >
                          edit
                        </button>
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        <div className="flex">
                          <button
                            className="text-red-500 hover:text-red-600 font-semibold "
                            onClick={() => {
                              setDeleteAmenities(item._id);
                              setToggleDelete(true);
                            }}
                          >
                            <MdDeleteForever size={30} />
                          </button>

                          {toggleDelete && deleteAmenitie === item._id && (
                            <div className="absolute top-0 right-0 z-20 bg-white shadow-xl p-2 rounded">
                              <h1 className="font-medium text-xs">
                                Are you sure you want to remove this.
                              </h1>
                              <div className="flex items-center pt-2">
                                <button
                                  className="capitalize font-medium text-sm bg-gray-300 pt-1 pb-1 pl-2 pr-2 rounded text-white "
                                  onClick={() => setToggleDelete(!toggleDelete)}
                                >
                                  Cancle
                                </button>
                                <button
                                  className="capitalize font-medium text-sm bg-red-600 text-white pb-1 pt-1 pl-2 pr-2 rounded"
                                  onClick={() => handleDelete(item._id)}
                                >
                                  yes remove
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="absolute top-0 left-20 right-20 flex items-center justify-center ">
          {toggle && (
            <div className="pt-5 flex justify-center w-full ">
              <div className="w-3/6 h-[300px] bg-gray-300 p-6 border border-gray-300 rounded-lg shadow-lg">
                <div className="flex items-center justify-between">
                  <h2 className="text-xl font-bold mb-4">
                    Update Amenities Type
                  </h2>
                  <div
                    onClick={() => setToggle(false)}
                    className="cursor-pointer"
                  >
                    <IoMdClose size={25} />
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="p-4 border rounded mt-5"
                >
                  {/* Name Field */}
                  <div className="mb-4">
                    <label htmlFor="name" className="block mb-2">
                      Name:
                    </label>
                    <input
                      type="text"
                      id="amenitiesType"
                      name="amenitiesType"
                      value={amenitiesType}
                      onChange={(e) => setAmenitiesType(e.target.value)}
                      className="p-2 border rounded w-full"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white p-2 rounded w-full"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListOfAmenitiesType;

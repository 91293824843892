import React from "react";
import Banner from "./image/banner.webp";

const PrivacyAndPolicy = () => {
  return (
    <div className="pb-20  font-Mont">
      <div
        className="h-[400px] w-full"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
        }}
      >
        <div className="w-full h-[400px] flex flex-col md:flex-row items-center justify-center md:justify-around bg-[#0202026c]">
          <div className="pt-20 md:pt-0">
            <h1 className="mt-3 font-bold md:font-semibold text-4xl text-white text-center">
              Privacy policy
            </h1>
          </div>
        </div>
      </div>
      <div className="pl-7 md:pl-10 pr-5 md:pr-10 pt-5 md:pt-10 ">
        <div className="space-y-4">
          <p className="font-medium text-base text-justify">
            Alohas Private Limited (“we”, “us” , ” www.thealohas.com” “Da Alohas”) is
            committed to protecting the privacy of the users (“you” / “your” /
            “yourself“) of its website offered by Da Alohas (“Website“) and has
            provided this privacy policy (“Policy“) to inform you about how Da
            Alohas uses and discloses your information collected through the
            Website.
          </p>
          <p className="font-medium text-base text-justify">
            The terms of the Policy govern your use of the Website and the
            information accessible on or from these Websites. The Policy also
            explains how Da Alohas may collect, use, and share any information
            you provide. Da Alohas reserves the right to change, modify, add, or
            remove portions of this Policy at any time. Da Alohas recommends
            that you review this Policy periodically to stay informed about
            current privacy practices.
          </p>
          <p className="font-medium text-base text-justify">
            This Policy is provided in compliance with the Information
            Technology Act, 2000 as amended and read with the Information
            Technology (Reasonable Security Practices and Procedures and
            Sensitive Personal Data or Information) Rules, 2011.
          </p>
        </div>
        <div className="p-2 md:p-6">
          {/* Main list with numbered (decimal) items */}
          <ol className="list-decimal pl-1 md:pl-6 space-y-6">
            {/* Section 1 */}
            <li className="space-y-2">
              <span className="font-bold md:font-semibold">
                Collection of Information
              </span>
              <ol className="list-[upper-roman] pl-1 md:pl-6 space-y-4">
                <li>
                  Da Alohas may collect the following types of information:
                  <ul className="list-[lower-alpha] pl-1 md:pl-6 space-y-4">
                    <li className="font-medium text-base text-justify">
                      “Personal Information” which can identify you personally,
                      such as your name, email address, registration account
                      details, physical address, and other personal details; and
                      demographic information, such as gender, pin code, or
                      similar information.
                    </li>
                    <li className="font-medium text-base text-justify">
                      “Non-Personal Information” which does not identify you or
                      any other individual. This includes session data, web
                      beacons, usage data, log data, and aggregate information.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Da Alohas may install cookies or similar data collection
                      software on your device with your consent.
                    </li>
                  </ul>
                </li>
                <li className="font-medium text-base text-justify">
                  You represent that the information or data you provide is
                  correct, current, and updated, and that you have all rights,
                  permissions, and consents to provide such information or data.
                </li>
              </ol>
            </li>

            {/* Section 2 */}
            <li className="space-y-2">
              <span className="font-bold md:font-semibold">
                Use of Information
              </span>
              <ol className="list-[upper-roman] pl-1 md:pl-6 space-y-4">
                <li className="font-medium text-base text-justify">
                  You may need to provide Da Alohas with your Personal
                  Information while registering on the Website. The information
                  provided by you to Da Alohas may be used for purposes
                  connected with Da Alohas’s business operations, including:
                  <ul className="list-[lower-alpha] pl-1 md:pl-6 space-y-4">
                    <li className="font-medium text-base text-justify">
                      Processing reservations, orders, etc.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Provisioning of services, testing or improvement of
                      services, recommending various services including those of
                      third parties.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Handling requests, inquiries, and complaints, customer
                      services, and related activities.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Marketing products/services of Da Alohas and analysis.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Responding to your queries and fulfilling your information
                      requests regarding the Website.
                    </li>

                    <li className="font-medium text-base text-justify">
                      Notifying you of new offers or services, and important
                      information regarding the Website, changes to policies,
                      and other administrative information.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Keeping you informed about the latest content available on
                      the Website and special offers.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Sending surveys and marketing communications that may
                      interest you.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Conducting internal reviews and data analysis for the
                      Website.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Personalizing your experience on the Website by presenting
                      advertising, products, and offers tailored to you.
                    </li>

                    <li className="font-medium text-base text-justify">
                      Improving the services, content, and advertising on the
                      Website.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Protecting the integrity of the Website.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Responding to legal/judicial processes and providing
                      information to law enforcement agencies or in connection
                      with investigations related to public safety, as permitted
                      by law.
                    </li>
                  </ul>
                </li>
                <li className="font-medium text-base text-justify">
                  Your Personal Information will be kept confidential and used
                  to support your relationship with Da Alohas, notify you of
                  updates and new activities, and related functions offered by
                  Da Alohas. Publicly available information or information
                  posted/uploaded by users on public sections of the Website is
                  not considered personally identifiable information subject to
                  this Policy.
                </li>
                <li className="font-medium text-base text-justify">
                  Da Alohas may use Personal Information to verify your
                  entitlement to access and use the Website and the services
                  provided. This information may also enhance your Website
                  experience.
                </li>
                <li className="font-medium text-base text-justify">
                  With respect to Non-Personal Information automatically
                  collected, Da Alohas uses it to understand and analyze trends,
                  administer the Website, learn about user behavior, and gather
                  demographic information. Da Alohas may use this information
                  for marketing and advertising services and to measure traffic
                  patterns. As Non-Personal Information does not personally
                  identify you, Da Alohas may use and disclose this information
                  for any purpose.
                </li>
              </ol>
            </li>

            {/* Section 3 */}
            <li className="space-y-2">
              <span className="font-bold md:font-semibold">Disclosure</span>
              <ol className="list-[upper-roman] pl-1 md:pl-6 space-y-4">
                <li className="font-medium text-base text-justify">
                  Da Alohas does not sell or rent Personal Information. Personal
                  Information may be disclosed under the following
                  circumstances:
                  <ul className="list-[lower-alpha] pl-1 md:pl-6 space-y-4">
                    <li className="font-medium text-base text-justify">
                      To affiliates, group companies, consultants, vendors, and
                      contractors who provide various services under
                      confidentiality restrictions.
                    </li>
                    <li className="font-medium text-base text-justify">
                      To partners, associates, advertisers, service providers,
                      or other third parties to market their products and/or
                      services. You have the choice to opt-out of such
                      communications.
                    </li>
                    <li className="font-medium text-base text-justify">
                      As necessary or appropriate under applicable law, to
                      comply with legal processes, respond to public and
                      government authorities, enforce Da Alohas’s terms and
                      conditions, protect rights and property, and pursue
                      available remedies or limit damages.
                    </li>

                    <li className="font-medium text-base text-justify">
                      To third-party services certain personally identifiable
                      information such as name, email, and mobile phone number,
                      and information collected as you access and use our
                      service.
                    </li>
                    <li className="font-medium text-base text-justify">
                      This information is shared with third-party service
                      providers to personalize the Website for you and perform
                      behavioral analytics.
                    </li>
                  </ul>
                </li>
                <li className="font-medium text-base text-justify">
                  Your consent being part of the terms and conditions through
                  which Da Alohas provides you with a service. Your consent to
                  collect Personal and Non-Personal Information may be implicit
                  or implied or through course of conduct.
                </li>
                <li className="font-medium text-base text-justify">
                  The Website may present display advertising and may collect
                  data about traffic via Google Analytics or equivalent
                  analytical tools.
                </li>
              </ol>
              <ol className="list-[upper-roman] pl-1 md:pl-6 space-y-4 mt-5">
                <li className="font-bold md:font-semibold space-y-2">
                  Information Security and Storage
                  <ul className="list-[lower-roman] pl-1 md:pl-6 space-y-4 font-medium text-base">
                    <li className="font-medium text-base text-justify">
                      Da Alohas uses reasonable security measures to safeguard
                      your data and information. Da Alohas implements these
                      measures to protect against unauthorized access and
                      unlawful interception of Personal Information. You accept
                      the security implications of providing information over
                      networks and will not hold Da Alohas responsible for any
                      breach of security unless Da Alohas has been grossly
                      negligent.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Your information or data will primarily be stored
                      electronically, but certain data may also be stored
                      physically. Da Alohas may store, collect, process and use
                      your data in countries other than India but under full
                      compliance with applicable laws. Da Alohas may enter into
                      agreements with third parties (in or outside of India) to
                      store your information or data. These third parties may
                      have their own security standards to safeguard your
                      information and Da Alohas will require such third parties
                      to adopt reasonable security standards.
                    </li>
                    <li className="font-medium text-base text-justify">
                      Da Alohas shall not be held responsible for any loss,
                      damage or misuse of your Personal Information if caused by
                      a Force Majeure Event. A Force Majeure Event means an
                      event beyond Da Alohas’s control, including sabotage,
                      fire, flood, explosion, acts of God, civil commotion,
                      strikes, riots, insurrection, war, acts of government,
                      computer hacking, unauthorized access to computer data,
                      computer crashes, etc.
                    </li>
                  </ul>
                </li>
                <li className="font-bold md:font-semibold">
                  Third Party Websites, Website and Services
                </li>
                <p className="font-medium text-base text-justify">
                  {" "}
                  The Website may include links to other websites governed by
                  their respective privacy policies. Once you leave our servers,
                  use of any information you provide is governed by the privacy
                  policy of the operator of the website you are visiting. If you
                  cannot find the privacy policy of any of these websites, you
                  should contact the website directly for more information.
                </p>
                <li className="font-bold md:font-semibold">
                  Access, Correction, and Deletion
                </li>
                <p className="font-medium text-base text-justify">
                  Da Alohas provides you with the information it maintains about
                  you, such as transaction and contact information upon
                  verification. You may request access and/or correct the
                  information Da Alohas has collected from you by contacting
                  <a
                    className="text-red-400 ml-1"
                    href="mailto:info@thealohas.com"
                  >
                    info@thealohas.com
                  </a>
                  . If you wish to delete the information you provided, you can
                  send a request to the provided email. Note that correction or
                  deletion of some information may lead to cancellation of your
                  registration or access to services/certain features of the
                  Website. Some information cannot be corrected or deleted as
                  required under applicable law, law enforcement requests, or
                  judicial proceedings.
                </p>
              </ol>
              <p className="font-semibold text-base text-justify">
                If you have questions or concerns about this Policy, please
                contact Da Alohas at{" "}
              
              <span className="text-red-400">
                <a href="mailto:info@thealohas.com">info@thealohas.com</a>.
              </span>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import Loader from "../Loader";
// import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../redux/actions/userAction/userAction";
import MetaData from "../MetaData";

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(userDetails);

  // Fetch user data on component mount
  useEffect(() => {
    if (user) {
      setUserDetails(user);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = (e) => {
    e.preventDefault()
    setIsEditing(false);

    dispatch(updateProfile(userDetails._id, userDetails));
    // try {
    //   const response = await axios.put("/api/user/update", userDetails); // Update endpoint
    //   setIsEditing(false);
    //   // Dispatch action to update user in Redux
    //   dispatch({ type: "UPDATE_USER_SUCCESS", payload: response.data });
    //   alert("Profile updated successfully!");
    // } catch (error) {
    //   console.error("Error updating profile:", error);
    //   alert("Failed to update profile. Please try again.");
    // }
  };

  const createdDate = new Date(userDetails?.createdAt);

  return (
    <div className="flex font-Mont p-2 md:p-10">
      <MetaData title={`user profile`} />
      {/* {loading ? (
        <div>
          <Loader />
        </div>
      ) : ( */}
      <div className="w-full h-full bg-white shadow-xl rounded pl-3 md:pl-10 pr-3 md:pr-10 mt-8 md:mt-0 pt-5 pb-10">
        {/* Back Button for Small Screens */}
        <button
          onClick={() => navigate("/account-setting")} // Navigate back to sidebar or root
          className="md:hidden  bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded"
        >
          ← Back
        </button>
        <div className="flex items-center border-b-2 border-gray-500 pb-5 mt-5">
          <div>
            <h1 className="text-2xl font-medium text-gray-800">
              Account Details
            </h1>
            <p className="text-sm font-medium text-gray-800">
              Manage your Profile
            </p>
          </div>
        </div>

        {/* Profile Header */}
        <div className="w-full flex items-center space-x-6 mt-5">
          <h1 className="w-14 h-14 text-white font-medium text-lg bg-gray-500 rounded-full object-cover flex items-center justify-center">
            {userDetails?.firstName?.[0]}
          </h1>
          <div>
            <p className="text-gray-600 font-medium text-sm">
              {userDetails?.firstName} {userDetails?.lastName}
            </p>
            <p className="text-gray-600 font-medium text-sm">
              Created On {createdDate && createdDate.toDateString()}
            </p>
          </div>
        </div>

        {/* {loading ? (
          <div>
            <Loader />
          </div>
        ) : ( */}
          <div>
            {/* Edit Button */}
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg"
            >
              {isEditing ? "Cancel" : "Edit Profile"}
            </button>

            {/* Profile Details */}
            {user.firstName || user.lastName > 0 ? (
              <div className="mt-8 space-y-6 w-full">
                {["firstName", "lastName", "email"].map((field) => (
                  <div
                    className="flex w-full md:w-3/6 justify-between items-center"
                    key={field}
                  >
                    <span className="text-base font-Medium text-gray-800 capitalize">
                      {field.replace(/([A-Z])/g, " $1").trim()}
                    </span>
                    {isEditing ? (
                      <input
                        type="text"
                        name={field}
                        value={userDetails?.[field] || ""}
                        onChange={handleChange}
                        className="border border-gray-300 p-2 rounded-md w-2/3"
                      />
                    ) : (
                      <p className="text-gray-600 ">{userDetails?.[field]}</p>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
            <div className="flex w-full md:w-3/6 justify-between items-center relative">
              <span className="text-base font-Medium text-gray-800 capitalize">
                Mobile
              </span>
              <input
                type="text"
                name="phone"
                disabled={true}
                value={userDetails?.mobile}
                className="border border-gray-300 mt-5 p-2 rounded-md w-2/3 text-gray-500"
              />
              <h1 className="text-black cursor-pointer absolute right-2 bottom-2 capitalize text-green-300">
                {userDetails?.isVerify === true ? "verified" : ""}
              </h1>
            </div>

            {/* Save Button */}
            {isEditing && (
              <button
                onClick={handleSave}
                className="mt-6 bg-green-600 text-white py-2 px-4 rounded-lg"
              >
                Save Changes
              </button>
            )}
          </div>
        {/* )} */}
      </div>
      {/* )} */}
    </div>
  );
};

export default Profile;

// create property collection
export const CREATE_PROPERTY_COLLECTION_REQUEST = "CREATE_PROPERTY_COLLECTION_REQUEST"
export const CREATE_PROPERTY_COLLECTION_SUCCESS = "CREATE_PROPERTY_COLLECTION_SUCCESS"
export const CREATE_PROPERTY_COLLECTION_FAIL = "CREATE_PROPERTY_COLLECTION_FAIL"


// get all property collections
export const GET_ALL_PROPERTY_COLLECTION_REQUEST = "GET_ALL_PROPERTY_COLLECTION_REQUEST"
export const GET_ALL_PROPERTY_COLLECTION_SUCCESS = "GET_ALL_PROPERTY_COLLECTION_SUCCESS"
export const GET_ALL_PROPERTY_COLLECTION_FAIL = "GET_ALL_PROPERTY_COLLECTION_FAIL"

// 
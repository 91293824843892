import React, { useEffect } from "react";
import SideMenu from "../sideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffersPage } from "../../../redux/actions/OfferAction/OffersAction";
import { FaEdit } from "react-icons/fa";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

const OffersList = () => {
  const { allOffers, loading } = useSelector((state) => state.offers);
  const dispatch = useDispatch();

  function myFunction(summary){
    if(summary.length > 100){
      summary  = summary.substring(0,100) + "..."
    }
    return summary
  }

  useEffect(() => {
    dispatch(getAllOffersPage());
  }, [dispatch]);
  return (
    <div className="pt-20 flex">
      <SideMenu />
      <div className="w-full">
        <div className="flex pl-4">
          <h1 className="font-bold text-lg">Offers List</h1>
          <span className="font-bold text-lg pl-3">
            ({allOffers && allOffers.length})
          </span>
        </div>
        <div className="p-4 w-full">
          <table className="w-full bg-white border border-gray-200 shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-100  uppercase font-bold">
                <th className="py-3 px-4 text-left font-semibold text-gray-700">
                  Image
                </th>
                <th className="py-3 px-4 text-left font-semibold text-gray-700">
                  Summary
                </th>
                <th className="py-3 px-4 text-left font-semibold text-gray-700">
                  Coupon Code
                </th>
                <th className="py-3 px-4 text-left font-semibold text-gray-700">
                  edit
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                allOffers &&
                allOffers.map((item) => {
                  return (
                    <tr
                      key={item._id}
                      className="border-b border-gray-200 hover:bg-gray-50"
                    >
                      <td className="py-3 px-4">
                        <img
                          src={item.picture.url}
                          alt="Coupon"
                          className="h-16 w-auto object-cover rounded-md"
                        />
                      </td>
                      <td className="py-3 px-4 w-3/6  h-10 ">{myFunction(item.summary)}</td>
                      <td className="py-3 px-4 font-semibold text-blue-600">
                        {item.couponCode.code}
                      </td>
                      <td className="py-3 px-4 font-semibold text-blue-600">
                        <Link to={`/dashboard/offer-list/${item._id}`}><FaEdit size={25} /></Link>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OffersList;
